import React, { ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
import { BoxProps } from "@mui/system";

export interface BasicCardProps {
  children?: ReactNode;
}

export const BasicCard = ({
  children,
  sx,
  ...rest
}: BasicCardProps & BoxProps) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "3rem",
        backgroundColor: palette.common.white,
        boxShadow: `inset 0px -.0625rem 0px ${palette.action.disabledBackground}`,
        boxSizing: "border-box",
        border: `.0625rem solid ${palette.action.disabledBackground}`,
        borderRadius: ".375rem",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
