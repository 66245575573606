import { Box, CircularProgress, Divider, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";

import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { Label } from "../../common/Label/Label";
import { Button } from "../../common/Button/Button";
import { DocAddition } from "../DocAddition";
import { GoalAddition } from "../GoalAddition";
import { Formik } from "formik";
import {
  GetGovernanceInfoQuery,
  UpdateGovernanceDocument,
  UpdateGovernanceInput,
} from "../../generated";
import { compact } from "lodash";
import { useMutation } from "@apollo/client";
import { MAX_DESCRIPTION_CHARS } from "../../Constants";
import { convertToSentenceCase } from "../../helpers";
import { ButtonDefault, ButtonPrimary } from "./style";

export const WorkspaceDetailEdit = ({
  data,
  onSave,
  onCancel,
  ...rest
}: {
  data: GetGovernanceInfoQuery;
  onSave: () => void;
  onCancel: () => void;
}) => {
  const [update, { loading }] = useMutation(UpdateGovernanceDocument);
  const { palette } = useTheme();
  const workspaceData = data?.workspace;

  if (workspaceData)
    return (
      <Formik
        initialValues={
          {
            workspaceId: workspaceData?.id,
            workspaceName: workspaceData?.name || "",
            workspaceDescription: workspaceData?.description || "",
            goals: {
              create: [],
              delete: [],
              update:
                compact(workspaceData?.governance?.goals).map(
                  ({ id, name = "", description = "" }) => ({
                    id,
                    name,
                    description,
                  })
                ) || [],
            },
            legalDocuments: {
              create: [],
              delete: [],
              update: compact(workspaceData?.governance?.legalDocuments).map(
                ({ id, name, href = "" }) => ({ id, name, href })
              ),
            },
            referenceDocuments: {
              create: [],
              delete: [],
              update: compact(
                workspaceData?.governance?.referenceDocuments
              ).map(({ id, name, href = "" }) => ({ id, name, href })),
            },
          } as UpdateGovernanceInput
        }
        onSubmit={async (values) => {
          const input = { ...values };
          if (input?.goals?.create) {
            input.goals.create = input.goals.create.filter(
              (item) => item.name !== "" && item.description !== ""
            );
          }

          if (input?.legalDocuments?.create) {
            input.legalDocuments.create = input.legalDocuments.create.filter(
              (item) => item.name !== "" && item.href !== ""
            );
          }

          if (input?.referenceDocuments?.create) {
            input.referenceDocuments.create =
              input.referenceDocuments.create.filter(
                (item) => item.name !== "" && item.href !== ""
              );
          }

          if (input?.goals?.update) {
            const deletes = input.goals.update.filter(
              (item) => item.name === "" && item.description === ""
            );
            deletes.forEach((item) => {
              input.goals?.delete.push(item.id);
            });
          }

          if (input?.legalDocuments?.update) {
            const deletes = input.legalDocuments.update.filter(
              (item) => item.name === "" && item.href === ""
            );
            deletes.forEach((item) => {
              input.legalDocuments?.delete.push(item.id);
            });
          }

          if (input?.referenceDocuments?.update) {
            const deletes = input.referenceDocuments.update.filter(
              (item) => item.name === "" && item.href === ""
            );
            deletes.forEach((item) => {
              input.referenceDocuments?.delete.push(item.id);
            });
          }

          await update({
            variables: {
              input,
            },
          });
          onSave();
        }}
      >
        {({ handleChange, values, setFieldValue, handleSubmit }) => {
          const onModify = (
            type: "goals" | "legalDocuments" | "referenceDocuments",
            index: number,
            modifiedItem: any
          ) => {
            const anyItem = [...(values?.[type]?.update || [])];
            const isNew =
              !values?.[type]?.update.length ||
              !anyItem.filter((item) => item.id === modifiedItem.id).length;
            const createdList = values?.[type]?.create || [];
            const updatedList = values?.[type]?.update || [];
            if (isNew) {
              createdList[index - updatedList.length] = modifiedItem;
            } else {
              updatedList[index] = modifiedItem;
            }
            setFieldValue(type, {
              delete: [...(values?.[type]?.delete || [])],
              create: [...createdList],
              update: [...updatedList],
            });
          };

          const onNew =
            (type: "goals" | "legalDocuments" | "referenceDocuments") => () => {
              setFieldValue(type, {
                delete: [...(values?.[type]?.delete || [])],
                update: [...(values?.[type]?.update || [])],
                create: [
                  ...(values?.[type]?.create || []),
                  {
                    name: "",
                    ...(type === "legalDocuments" ? { href: "" } : {}),
                    ...(type === "goals"
                      ? { description: "", metrics: [] }
                      : {}),
                  },
                ],
              });
            };
          const onDelete =
            (
              type: "goals" | "legalDocuments" | "referenceDocuments",
              item: { id: string; name: string }
            ) =>
            () => {
              setFieldValue(type, {
                create: [
                  ...((values?.[type]?.create as any[]).filter(
                    (aItem: { name: string }) => item.name !== aItem.name
                  ) || []),
                ],
                delete: [
                  ...(values?.[type]?.delete || []),
                  ...(item.id ? [item.id] : []),
                ],
                update: [
                  ...((values?.[type]?.update as any[]).filter(
                    (aItem: { id: string }) => aItem.id !== item.id
                  ) || []),
                ],
              });
            };

          return (
            <BasicCard {...rest}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ padding: ".75rem 1.5rem" }}
              >
                <Typography variant={"h6"}>Workspace detail</Typography>
                <Box>
                  <ButtonDefault
                    onClick={onCancel}
                    size={"small"}
                    variant="outlined"
                    color={"grayAccent"}
                  >
                    Cancel
                  </ButtonDefault>
                  <ButtonPrimary
                    onClick={() => handleSubmit()}
                    size={"small"}
                    variant="contained"
                    disabled={loading}
                  >
                    {loading && (
                      <CircularProgress
                        sx={{
                          color: palette.primary.darkest,
                          position: "absolute",
                        }}
                        size={16}
                      />
                    )}{" "}
                    Save Changes
                  </ButtonPrimary>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ padding: "1.5rem 2rem" }}>
                <Box
                  display={"flex"}
                  pb={"1.5rem"}
                  alignItems={"flex-start"}
                  maxWidth={"58rem"}
                >
                  <Label value="Workspace name" width={"15.5rem"} />
                  <TextField
                    hiddenLabel
                    fullWidth
                    onChange={handleChange("workspaceName")}
                    defaultValue={workspaceData?.name}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                </Box>
                <Box
                  display={"flex"}
                  pb={"1.5rem"}
                  alignItems={"flex-start"}
                  maxWidth={"58rem"}
                >
                  <Label value="Description" width={"15.5rem"} />
                  {workspaceData?.governance?.isEditable ? (
                    <Box display={"flex"} flex={1} flexDirection="column">
                      <TextField
                        hiddenLabel
                        onChange={handleChange("workspaceDescription")}
                        fullWidth
                        defaultValue={data.workspace?.description || ""}
                        multiline
                        minRows={3}
                        inputProps={{
                          sx: { fontSize: "1rem" },
                          maxLength: MAX_DESCRIPTION_CHARS,
                        }}
                        size="small"
                      />
                      <Typography
                        mt={2}
                        color={palette.gray.dark}
                        variant="body3"
                      >
                        {values?.workspaceDescription?.length || 0}/
                        {MAX_DESCRIPTION_CHARS} characters
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography
                        title="Contact Brighthive to edit"
                        variant="body2"
                        color={palette.gray.dark}
                      >
                        {data?.workspace?.description}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  display={"flex"}
                  pb={"1.5rem"}
                  alignItems={"flex-start"}
                  maxWidth={"58rem"}
                >
                  <Label value="Governance type" info width={"15.5rem"} />
                  <Box>
                    <Typography variant="body2" color={palette.gray.dark}>
                      {convertToSentenceCase(
                        data?.workspace?.governance?.governanceType
                          .split("_")
                          .join(" ")
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  pb={"1.5rem"}
                  alignItems={"flex-start"}
                  maxWidth={"58rem"}
                >
                  <Label value="Goals" info width={"15.5rem"} />
                  <Box>
                    {[
                      ...(values.goals?.update || []),
                      ...(values.goals?.create || []),
                    ].map((goal: any, index: number) => (
                      <GoalAddition
                        key={goal.id}
                        id={goal.id}
                        name={goal.name}
                        description={goal.description}
                        onModify={(aGoal) => {
                          onModify("goals", index, aGoal);
                        }}
                        onDelete={onDelete("goals", goal)}
                      />
                    ))}
                    <ButtonPrimary
                      className="ml-0"
                      color={"grayAccent"}
                      variant={"contained"}
                      onClick={onNew("goals")}
                    >
                      Add a Goal
                    </ButtonPrimary>
                  </Box>
                </Box>
                <Divider sx={{ mb: 10, mt: 4 }} />
                <Box
                  display={"flex"}
                  pb={"1.5rem"}
                  alignItems={"flex-start"}
                  maxWidth={"58rem"}
                >
                  <Label value="Legal documents" info width={"15.5rem"} />
                  <Box>
                    {[
                      ...(values.legalDocuments?.update || []),
                      ...(values.legalDocuments?.create || []),
                    ].map((doc: any, index: number) => (
                      <DocAddition
                        key={doc.id}
                        id={doc.id}
                        name={doc.name}
                        href={doc.href}
                        onModify={(doc) => {
                          onModify("legalDocuments", index, doc);
                        }}
                        onDelete={onDelete("legalDocuments", doc)}
                      />
                    ))}
                    <ButtonPrimary
                      className="ml-0"
                      color={"grayAccent"}
                      variant={"contained"}
                      onClick={onNew("legalDocuments")}
                    >
                      Add a legal document
                    </ButtonPrimary>{" "}
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  pb={"1.5rem"}
                  alignItems={"flex-start"}
                  maxWidth={"58rem"}
                >
                  <Label value="Reference documents" info width={"15.5rem"} />
                  <Box>
                    {[
                      ...(values.referenceDocuments?.update || []),
                      ...(values.referenceDocuments?.create || []),
                    ].map((doc: any, index: number) => (
                      <DocAddition
                        key={doc.id}
                        id={doc.id}
                        name={doc.name}
                        href={doc.href}
                        onModify={(doc) => {
                          onModify("referenceDocuments", index, doc);
                        }}
                        onDelete={onDelete("referenceDocuments", doc)}
                      />
                    ))}
                    <ButtonPrimary
                      className="ml-0"
                      color={"grayAccent"}
                      variant={"contained"}
                      onClick={onNew("referenceDocuments")}
                    >
                      Add a reference document
                    </ButtonPrimary>
                  </Box>
                </Box>
              </Box>
            </BasicCard>
          );
        }}
      </Formik>
    );
  return null;
};
