import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const PaperInfoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        d="M8.66663 1.5V4.83333C8.66663 5.05435 8.75442 5.26631 8.9107 5.42259C9.06698 5.57887 9.27895 5.66667 9.49996 5.66667H12.8333"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M11.1666 16.5H2.83329C2.39127 16.5 1.96734 16.3244 1.65478 16.0118C1.34222 15.6993 1.16663 15.2754 1.16663 14.8333V3.16667C1.16663 2.72464 1.34222 2.30072 1.65478 1.98816C1.96734 1.67559 2.39127 1.5 2.83329 1.5H8.66663L12.8333 5.66667V14.8333C12.8333 15.2754 12.6577 15.6993 12.3451 16.0118C12.0326 16.3244 11.6087 16.5 11.1666 16.5Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M6.16663 10.6667H6.99996V14H7.83329M6.99996 8.16666H7.00829"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
