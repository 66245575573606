import {
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Check, DateRange, Input } from "@mui/icons-material";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import { ProjectShareLinkDialog } from "./ProjectShareLinkDialog";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Label } from "../../common/Label/Label";
import { CompanyIcon } from "../../common/Icons/CompanyIcon";
import {
  stringAvatarLetters,
  convertIsoDateStringToMMDDYYYY,
} from "../../helpers";
import ProjectOverviewDetails from "./ProjectOverviewDetails";
import ProjectOverviewForm from "./ProjectOverviewForm";
import IssuesCard from "./project-right-sidebar/IssuesCard";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetOrganizationsDocument,
  GetProjectDocument,
  GetWorkspaceMembersDocument,
  ProjectStatus,
  UpdateProjectDocument,
  UpdateProjectInput,
} from "../../generated";
import { Button } from "../../common/Button/Button";
import PauseIcon from "@mui/icons-material/Pause";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectPage } from "../../common/ProjectPage/ProjectPage";
import { SheetChat } from "../../Sheets/SheetChat";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ProfileAvatarIcon from "../../common/ProfileAvatarIcon/ProfileAvatarIcon";
import { ProjectOverviewMemeber } from "./ProjectOverviewMemeber";
import { ProjectManagers } from "../../common/ProjectManagers/ProjectManagers";
import { ButtonDefault, ButtonPrimary, SubHeadingEditMode, WidgetLabel, WidgetLabelIndented, WidgetSubLabel } from "./style";

export const ProjectOverviewPage = () => {
  const navigate = useNavigate();
  const [shareLinkPopup, setShareLinkPopup] = useState(false);
  const { projectId, workspaceId, edit: editView } = useParams();
  const [editting, edit] = useState(!!editView);
  const [editMember, setEditMemeber] = useState(false);
  const [editStatus, setEditStatus] = useState<null | HTMLElement>(null);

  const [editStartDate, setEditStartDate] = useState(false);
  const [editEndDate, setEditEndDate] = useState(false);

  const [modifiedProject, modifyProject] = useState<UpdateProjectInput | null>(
    null
  );
  const [mutate, { loading: loadingMutate }] = useMutation(
    UpdateProjectDocument
  );
  const { palette } = useTheme();

  // const { data: workspaceOrganizations, loading: organizationsLoading } = useQuery(GetOrganizationsDocument, {
  //   variables: {
  //     workspaceId: workspaceId || '',
  //   }
  // })

  const { data, loading, refetch } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const issues = [
    {
      type: "major",
      issue:
        "The Project Overview is missing required information. Please provide the missing information.",
    },
    {
      type: "minor",
      issue:
        "The data flow has 1 or more minor issues that should be resolved.",
    },
  ];

  useEffect(() =>
    window.addEventListener("click", (ev) => {
      const evTarget = ev.target as HTMLElement;
      const datePicker = document.getElementsByClassName(
        "MuiPickersPopper-root"
      )[0];
      const startDate = document.getElementById("dateEditor");

      if (
        evTarget.classList.contains("edit-button") ||
        startDate?.contains(ev.target as any) ||
        datePicker?.contains(ev.target as any)
      ) {
      } else {
        setEditStartDate(false);
        setEditEndDate(false);
      }
    })
  );

  const handleCloseEdit = () => {
    edit(false);
    modifyProject(null);
  };
  const onSave = async () => {
    if (modifiedProject) {
      await mutate({
        variables: {
          input: {
            ...modifiedProject,
            goals: {
              delete: modifiedProject.goals?.delete || [],
              update: modifiedProject.goals?.update || [],
              create: (modifiedProject.goals?.create || []).filter(
                (item) => item.description !== ""
              ),
            },
          },
        },
      });
    }
    refetch();
    handleCloseEdit();
  };

  const workspace = data?.workspace;
  const project = data?.workspace?.projects?.[0];
  if (!project) {
    navigate(`/workspace/${workspaceId}/projects`);
  }

  return (
    <>
      <ProjectPage
        leftHeaderElements={
          <Typography variant="h5">Project Overview</Typography>
        }
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} xl={9}>
              <BasicCard
                display={"flex"}
                sx={{ width: "100%" }}
                justifyContent="space-between"
              >
                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{
                        padding: "1.25rem 2rem",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            mb: 2,
                            fontWeight: 500,
                            color: palette.common.black,
                          }}
                        >
                          {editting ? "" : "Project Overview"}
                        </Typography>
                        <SubHeadingEditMode
                        >
                          {project?.name || ""}
                        </SubHeadingEditMode>
                      </Box>
                      {editting ? (
                        <Box>
                          <ButtonDefault
                            variant="outlined"
                            color="grayAccent"
                            sx={{ fontSize: "0.875rem", mr: 2 }}
                            onClick={handleCloseEdit}
                          >
                            Cancel
                          </ButtonDefault>
                          <ButtonPrimary
                            color="primary"
                            variant="contained"
                            sx={{ fontSize: "0.875rem" }}
                            onClick={() => onSave()}
                          >
                            Save Changes
                          </ButtonPrimary>
                        </Box>
                      ) : (
                        <ButtonDefault
                          onClick={() => edit(true)}
                          color="grayAccent"
                          style={{
                            display: !project?.isEditable ? "none" : undefined,
                          }}
                        >
                          <EditOutlinedIcon
                            sx={{ fontSize: "0.875rem", mr: 1 }}
                          />
                          Edit
                        </ButtonDefault>
                      )}
                    </Box>
                    <Divider />
                    {project && !editting && (
                      <ProjectOverviewDetails
                        project={project}
                        projectId={projectId}
                      />
                    )}
                    {project && editting && (
                      <ProjectOverviewForm
                        project={project}
                        onChange={modifyProject}
                      />
                    )}
                  </Box>
                </Box>
              </BasicCard>
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <BasicCard
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 5,
                  padding: "0.75rem",
                  gap: "5px",

                  "@media (min-width: 1350px)": {
                    padding: "1rem 1.5rem",
                  },
                }}
              >
                <WidgetLabel
                  variant="h6"
                >
                  Project Status
                </WidgetLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.675rem",
                    width: "45%",
                    justifyContent: "space-between",

                    "@media (min-width: 1350px)": {
                      fontSize: "0.875rem",
                      width: "40%",
                    },
                  }}
                >
                  <Chip
                    size="small"
                    icon={
                      <PeopleAltOutlinedIcon
                        sx={{
                          color: palette.gray.dark + "!important",
                        }}
                      />
                    }
                    label={project?.status}
                    sx={{
                      background: palette.primary.light,
                      color: palette.common.black,
                      fontSize: "0.6125rem",

                      "@media (min-width: 1350px)": {
                        fontSize: "0.8125rem",
                      },
                    }}
                  />
                  {project?.isEditable && (
                    <>
                      <IconButton
                        disabled={loadingMutate}
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          setEditStatus(event.currentTarget);
                        }}
                      >
                        <EditOutlinedIcon sx={{ fontSize: "1rem" }} />
                      </IconButton>

                      <Menu
                        id="basic-menu"
                        anchorEl={editStatus}
                        open={Boolean(editStatus)}
                        onClose={() => setEditStatus(null)}
                        sx={{
                          ".MuiMenuItem-root": {
                            fontSize: "14px",
                            lineHeight: "20px",
                            padding: "10px 20px",
                          },
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {[
                          "ACTIVE",
                          "COMPLETE",
                          "DRAFT",
                          "PRIVATE",
                          "PUBLISHED",
                          "ARCHIVED",
                        ].map((item) => (
                          <MenuItem
                            key={item}
                            onClick={() => {
                              setEditStatus(null);
                              mutate({
                                variables: {
                                  input: {
                                    workspaceId: workspaceId || "",
                                    projectId: projectId || "",
                                    projectStatus: item as ProjectStatus,
                                  },
                                },
                              }).then((res) => {
                                refetch();
                              });
                            }}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </Box>
              </BasicCard>
              <BasicCard
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 5,
                  padding: "0.75rem",
                  gap: "5px",

                  "@media (min-width: 1350px)": {
                    padding: "1rem 1.5rem",
                  },
                }}
              >
                <WidgetLabel
                  variant="h6"
                >
                  Data Flow Rate
                </WidgetLabel>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.675rem",
                    width: "45%",
                    justifyContent: "space-between",

                    "@media (min-width: 1350px)": {
                      fontSize: "0.875rem",
                      width: "40%",
                    },
                  }}
                >
                  <Chip
                    size="small"
                    icon={
                      project?.workflowState === "PAUSED" ? (
                        <PauseIcon
                          sx={{
                            fontSize: "1rem",
                            color: palette.gray.dark + "!important",
                          }}
                        />
                      ) : (
                        <PlayArrowIcon
                          sx={{
                            fontSize: "1rem",
                            color: palette.gray.dark + "!important",
                          }}
                        />
                      )
                    }
                    label={project?.workflowState}
                    sx={{
                      background:
                        project?.workflowState === "PAUSED"
                          ? palette.warning.light
                          : palette.primary.light,
                      color: palette.common.black,
                      mr: 4,
                      fontSize: "0.6125rem",

                      "@media (min-width: 1350px)": {
                        fontSize: "0.8125rem",
                      },
                    }}
                  />
                  {/* <EditOutlinedIcon sx={{ fontSize: "1rem" }} /> */}
                </Box>
              </BasicCard>
              {false && <IssuesCard issues={issues} />}
              {false && (
                <BasicCard
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 5,
                    padding: "0.75rem",
                    gap: "5px",

                    "@media (min-width: 1350px)": {
                      padding: "1rem 1.5rem",
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      margin: "0",
                      fontSize: "1rem",

                      "@media (min-width: 1350px)": {
                        fontSize: "1.125rem",
                      },
                    }}
                  >
                    Issues
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "0.675rem",
                      width: "45%",
                      justifyContent: "space-between",

                      "@media (min-width: 1350px)": {
                        fontSize: "0.875rem",
                        width: "40%",
                      },
                    }}
                  >
                    <Chip
                      icon={
                        <Check
                          sx={{
                            fontSize: "1rem",
                            color: palette.gray.dark + "!important",
                          }}
                        />
                      }
                      label={"No Active"}
                      sx={{
                        background: palette.primary.light,
                        color: palette.common.black,
                        mr: 4,
                        fontSize: "0.6125rem",

                        "@media (min-width: 1350px)": {
                          fontSize: "0.8125rem",
                        },
                      }}
                    />
                  </Box>
                </BasicCard>
              )}
              <BasicCard
                sx={{
                  mb: 5,
                  padding: "0.75rem",
                  gap: "5px",

                  "@media (min-width: 1350px)": {
                    padding: "1rem 1.5rem",
                  },
                }}
              >
                <WidgetLabelIndented
                  variant="h6"
                >
                  Key Details
                </WidgetLabelIndented>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <WidgetSubLabel
                      variant="body2"
                    >
                      Start Date
                    </WidgetSubLabel>
                    <Box display="flex" alignItems="center">
                      {!editStartDate ? (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "0.875rem",
                              color: palette.gray.darkest,
                            }}
                          >
                            {project?.startDate
                              ? convertIsoDateStringToMMDDYYYY(
                                  project?.startDate
                                )
                              : null}
                          </Typography>
                          <Button
                            id="button"
                            onClick={() =>
                              setEditStartDate((prevState) => !prevState)
                            }
                            className="edit-button"
                            color="grayAccent"
                            sx={{
                              display: !project?.isEditable
                                ? "none"
                                : undefined,
                              padding: "5px 0 5px 5px !important",
                              minWidth: "inherit",
                              marginLeft: "5px",
                            }}
                          >
                            <EditOutlinedIcon
                              sx={{
                                fontSize: "0.875rem",
                                mr: 0,
                                pointerEvents: "none",
                              }}
                            />
                          </Button>
                        </>
                      ) : (
                        <Box
                          sx={{
                            ".MuiInputBase-input": {
                              height: "20px",
                              width: "100px",
                              fontSize: "14px",
                              lineHeight: "20px",
                            },
                            ".MuiIconButton-root": {
                              padding: "8px 5px",
                              alignItems: "center",
                            },
                            ".MuiSvgIcon-root": {
                              width: "18px",
                              height: "18px",
                            },
                          }}
                        >
                          <div id="dateEditor">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={project?.startDate || ""}
                                onChange={(value) => {
                                  mutate({
                                    variables: {
                                      input: {
                                        workspaceId: workspaceId || "",
                                        projectId: projectId || "",
                                        startDate: value,
                                      },
                                    },
                                  }).then((res) => {
                                    setEditStartDate(false);
                                    refetch();
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} size="small" />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <WidgetSubLabel
                      variant="body2"
                    >
                      End Date
                    </WidgetSubLabel>
                    <Box display="flex" alignItems="center">
                      {!editEndDate ? (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "0.875rem",
                              color: palette.gray.darkest,
                            }}
                          >
                            {project?.startDate
                              ? convertIsoDateStringToMMDDYYYY(project?.endDate)
                              : null}
                          </Typography>
                          {project?.startDate && (
                            <Button
                              onClick={() =>
                                setEditEndDate((prevState) => !prevState)
                              }
                              color="grayAccent"
                              className="edit-button"
                              sx={{
                                display: !project?.isEditable
                                  ? "none"
                                  : undefined,
                                padding: "5px 0 5px 5px !important",
                                minWidth: "inherit",
                                marginLeft: "5px",
                              }}
                            >
                              <EditOutlinedIcon
                                sx={{
                                  fontSize: "0.875rem",
                                  mr: 1,
                                  pointerEvents: "none",
                                }}
                              />
                            </Button>
                          )}
                        </>
                      ) : (
                        <Box
                          sx={{
                            ".MuiInputBase-input": {
                              height: "20px",
                              width: "100px",
                              fontSize: "14px",
                              lineHeight: "20px",
                            },
                            ".MuiIconButton-root": {
                              padding: "8px 5px",
                              alignItems: "center",
                            },
                            ".MuiSvgIcon-root": {
                              width: "18px",
                              height: "18px",
                            },
                          }}
                        >
                          <div id="dateEditor">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                value={project?.endDate || ""}
                                minDate={project?.startDate || new Date()}
                                onChange={(value) => {
                                  mutate({
                                    variables: {
                                      input: {
                                        workspaceId: workspaceId || "",
                                        projectId: projectId || "",
                                        endDate: value as Date,
                                      },
                                    },
                                  }).then((res) => {
                                    refetch();
                                    setEditEndDate(false);
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} size="small" />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Label
                      value="Input data assets"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 700,
                        color: palette.common.black,
                      }}
                      info
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.875rem",
                        color: palette.gray.darkest,
                      }}
                    >
                      {project?.inputAssetCount}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Label
                      value="New Data Product"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 700,
                        color: palette.common.black,
                      }}
                      info
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.875rem",
                        color: palette.gray.darkest,
                      }}
                    >
                      {project?.dataProductCount}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <WidgetSubLabel
                      variant="body2"
                    >
                      Created Date
                    </WidgetSubLabel>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.875rem",
                        color: palette.gray.darkest,
                      }}
                    >
                      {project?.createdAt
                        ? convertIsoDateStringToMMDDYYYY(project?.createdAt)
                        : null}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <WidgetSubLabel
                      variant="body2"
                    >
                      Last Update
                    </WidgetSubLabel>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "0.875rem",
                        color: palette.gray.darkest,
                      }}
                    >
                      {project?.modifiedAt
                        ? convertIsoDateStringToMMDDYYYY(project?.modifiedAt)
                        : null}
                    </Typography>
                  </Box>
                </Box>
              </BasicCard>
              <BasicCard
                sx={{
                  mb: 5,
                  padding: "0.75rem",
                  gap: "5px",

                  "@media (min-width: 1350px)": {
                    padding: "1rem 1.5rem",
                  },
                }}
              >
                <WidgetLabelIndented
                  variant="h6"
                >
                  Project Roles
                </WidgetLabelIndented>
                <Box>
                  <Box sx={{ mb: 4 }}>
                    <Label
                      value="Project Owner"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: palette.common.black,
                        mb: 1,
                      }}
                      info
                    />
                    <Box display="flex" mt={1}>
                      <CompanyIcon />
                      <Typography
                        variant="body2"
                        sx={{
                          pl: 2,
                          color: palette.gray.darkest,
                          fontSize: "0.875rem",

                          "@media (min-width: 1350px)": {
                            fontSize: "1rem",
                          },
                        }}
                      >
                        {project?.owner?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <ProjectManagers
                    isEditable={project?.isEditable}
                    projectManagers={project?.managers}
                    refetch={refetch}
                  />
                  {/* <Box sx={{ mb: 6 }}>
                    <Label
                      value="Project Manager"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: palette.common.black,
                        mb: 1,
                      }}
                      info
                    />
                    <Box display="flex" alignItems="center">
                      {project?.managers.map((manager) => {
                        <>
                          <ProfileAvatarIcon
                            name={`${manager?.firstName} ${manager?.lastName}`}
                            width={24}
                            height={24}
                          />
                          <Typography
                            variant="body2"
                            sx={{ color: palette.gray.darkest }}
                            ml={1}
                          >
                            {`${manager?.firstName} ${manager?.lastName}`}{" "}
                          </Typography>
                        </>;
                      })}
                    </Box>
                  </Box> */}
                  <Paper
                    sx={{
                      color: palette.settings.darkest,
                      p: 2,
                      background: palette.settings.lighter,
                      boxShadow: "none",

                      "@media (min-width: 1350px)": {
                        p: 4,
                      },
                    }}
                  >
                    <Box sx={{ mb: 4 }}>
                      <Label
                        value="Collaborating organizations"
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: 500,
                          color: palette.common.black,
                          mb: 1,
                        }}
                        info
                      />
                      {/* {!organizationsLoading &&
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={workspaceOrganizations?.workspace?.participatingOrganizations || []}
                          getOptionLabel={(option) => option?.name || ''}
                          // defaultValue={selecedMemebers || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder="Organizations"
                            />
                          )}
                        />
                      } */}
                      {project?.collaboratingOrganizations?.map((org) => (
                        <Box display="flex" mt={1}>
                          <CompanyIcon />
                          <Typography
                            variant="body2"
                            sx={{
                              pl: 2,
                              color: palette.gray.darkest,
                            }}
                          >
                            {org}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      <Label
                        value="Contributing members"
                        sx={{
                          fontSize: "0.875rem",
                          fontWeight: 500,
                          color: palette.common.black,
                          mb: 1,
                        }}
                        info
                      />
                      {project?.isEditable && (
                        <>
                          {editMember ? (
                            <ProjectOverviewMemeber
                              members={project?.collaboratingMembers}
                              setShow={setEditMemeber}
                              refetch={refetch}
                            />
                          ) : (
                            <>
                              <IconButton
                                disabled={loadingMutate}
                                onClick={() => setEditMemeber(true)}
                              >
                                <EditOutlinedIcon sx={{ fontSize: "1rem" }} />
                              </IconButton>
                            </>
                          )}
                        </>
                      )}
                      {editMember ||
                        project?.collaboratingMembers?.map((member) => (
                          <Box
                            display="flex"
                            sx={{ mb: 2 }}
                            alignItems="center"
                          >
                            <ProfileAvatarIcon
                              name={`${member.firstName} ${member.lastName}`}
                              width={24}
                              height={24}
                            />
                            <Typography
                              variant="body2"
                              sx={{ color: palette.gray.darkest }}
                              ml={1}
                            >
                              {`${member.firstName} ${member.lastName}`}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </Paper>
                </Box>
              </BasicCard>
            </Grid>
          </Grid>
        }
      />
      <ProjectShareLinkDialog
        open={shareLinkPopup}
        onClose={() => setShareLinkPopup(false)}
      />
    </>
  );
};
