import { Box, Tab, Typography, useTheme, lighten } from "@mui/material";
import { useState } from "react";
import { Button } from "../../common/Button/Button";
import { CustomTextArea } from "../../common/CustomTextArea/CustomTextArea";
import { BrightBotSmileGreenIcon } from "../../common/Icons/BrightBotSmileGreenIcon";
import { BrightBotSmileIcon } from "../../common/Icons/BrightBotSmileIcon";
import { CheckBox } from "../../common/CheckBox/CheckBox";
import {
  Header,
  Wrapper,
  AvatarWrapper,
  Description,
  Suggestion,
  ButtonWrapper,
} from "./style";

export const SuggestionBox = ({ children }: any) => {
  const { palette } = useTheme();

  return (
    <Wrapper>
      <Header>
        <AvatarWrapper>
          <BrightBotSmileIcon />
        </AvatarWrapper>
        <Typography variant="body3">BrightBot</Typography>
      </Header>
      <Description>
        <AvatarWrapper className="success">
          <BrightBotSmileGreenIcon />
        </AvatarWrapper>
        <Suggestion>
          <Typography variant="body3">
            Would you like me to extract data terms from governance.txt?
          </Typography>
          <ButtonWrapper>
            <Button variant="contained">Confirm</Button>
            <Button variant="outlined" color="grayAccent">
              Deny
            </Button>
          </ButtonWrapper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              ">div": {
                position: "relative",
                padding: "7px 0 0 20px",
                color: palette.gray.darkest,
              },

              ".MuiCheckbox-root": {
                position: "absolute",
                left: "5px",
                top: "10px",
                padding: "0",
                width: "16px",
                height: "16px",

                "input[type='checkbox']": {
                  zIndex: 3,
                },

                "&:hover": {
                  background: "none",
                },

                "&:after": {
                  width: "16px",
                  height: "16px",
                  borderRadius: "5px",
                  border: `1px solid ${palette.gray.contrastText}`,
                  content: '""',
                  position: "absolute",
                  left: "0",
                  top: "0",
                },

                "&:before": {
                  content: '""',
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  position: "absolute",
                  left: "6px",
                  top: "2px",
                  width: "16px",
                  height: "12px",
                  opacity: "0",
                  zIndex: "2",
                },

                "&.Mui-checked": {
                  "&:before": {
                    opacity: "1",
                    backgroundImage: `url(${"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjUgMS42NzM4M0w2LjUgMTIuNjczOEwxLjUgNy42NzM4MyIgc3Ryb2tlPSIjMDA4NTc1IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K"})`,
                    content: '""',
                  },

                  "&:after": {
                    width: "16px",
                    height: "16px",
                    background: lighten(palette.primary.main, 0.8),
                    boxShadow: "0 0 1px white inset",
                    borderRadius: "5px",
                    border: `1px solid ${palette.gray.contrastText}`,
                    content: '""',
                    position: "absolute",
                    left: "0",
                    top: "0",
                  },
                },

                ".MuiTouchRipple-root": {
                  display: "none",
                },

                svg: {
                  color: palette.gray.contrastText,
                  width: "21px",
                  height: "21px",
                  borderRadius: "5px",
                  display: "none",

                  path: {
                    borderRadius: "5px",
                  },
                },
              },
            }}
          >
            <CheckBox label={"Auto approve all actions"} />
          </Box>
        </Suggestion>
      </Description>
    </Wrapper>
  );
};
