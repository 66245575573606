import {
  Box,
  Button,
  Drawer,
  FormLabel,
  List,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import { CloseIcon } from "../../../common/Icons/CloseIcon";
import { DeleteIcon } from "../../../common/Icons/DeleteIcon";
import { EditPencilIcon } from "../../../common/Icons/EditPencilIcon";
import { MessagesIcon } from "../../../common/Icons/MessagesIcon";
import { ShareIcon } from "../../../common/Icons/ShareIcon";
import ProfileAvatarIcon from "../../../common/ProfileAvatarIcon/ProfileAvatarIcon";
import { dateHelper } from "../../../helpers";
import { useMutation } from "@apollo/client";
import { DeleteTermDocument, TermsDocument } from "../../../generated";
import { toast } from "react-toastify";
import { useState } from "react";
import { ButtonDefault, ButtonPrimary } from "../style";

export const TermDetail = ({ data, setDetail, onDelete }: any) => {
  const { palette } = useTheme();
  const [edit, setEdit] = useState(false);
  const [deleteTerm, { loading }] = useMutation(DeleteTermDocument, {
    refetchQueries: [TermsDocument],
  });

  const deleteTermHandler = (id: string) => {
    deleteTerm({
      variables: {
        deleteTermInput2: {
          termId: id,
        },
      },
    }).then(() => {
      toast.success(`Term ${id} Deleted`);
      setDetail();
    });
  };

  const creator = data.creator;

  return (
    <Formik
      initialValues={{
        type: data.type,
        description: data.description,
      }}
      onSubmit={async (values) => {
        console.log(values);
      }}
    >
      {({ handleChange, values, handleSubmit, setFieldValue }) => (
        <Drawer anchor="right" open={data} onClose={() => setDetail()}>
          <CloseIcon
            onClick={() => setDetail()}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
              color: palette.gray.dark,
            }}
          />
          <Box width={400} p={6}>
            <Box
              sx={{
                borderBottom: `1px solid ${palette.outline}`,
              }}
            >
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: palette.gray.dark,
                    mb: 1,
                    display: "flex",
                  }}
                >
                  Term
                </Typography>
                <Typography variant="h4" color="gray.darkest">
                  {data.name}
                </Typography>
              </Box>
              <List
                sx={{
                  display: "inline-flex",

                  ".MuiListItem-root": {
                    padding: "0",
                  },
                }}
              >
                <ListItem>
                  <Button
                    onClick={() => console.log("err")}
                    disabled={true}
                    sx={{
                      display: "flex",
                      columnGap: "13px",
                      alignItems: "center",
                      borderRadius: "0",
                      fontSize: "16px",
                      lineHeight: "24px",
                      padding: "8px 12px",
                      minWidth: "inherit",
                      justifyContent: "space-between",
                      color: palette.settings.contrastText,
                      fontWeight: "400",

                      "&:hover": {
                        background: palette.settings.lighter,
                        color: palette.common.black,
                      },

                      ".csb-dropdown-icn": {
                        width: "13px",
                        height: "13px",
                      },

                      ".icn": {
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "0",
                      },

                      ".MuiSvgIcon-root": {
                        width: "15px",
                        height: "15px",
                      },
                    }}
                  >
                    <i className="icn">
                      <MessagesIcon />
                    </i>
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    onClick={() => setEdit(!edit)}
                    sx={{
                      display: "flex",
                      columnGap: "13px",
                      alignItems: "center",
                      borderRadius: "0",
                      fontSize: "16px",
                      lineHeight: "24px",
                      padding: "8px 12px",
                      minWidth: "inherit",
                      justifyContent: "space-between",
                      color: palette.settings.contrastText,
                      fontWeight: "400",

                      "&:hover": {
                        background: palette.settings.lighter,
                        color: palette.common.black,
                      },

                      ".csb-dropdown-icn": {
                        width: "13px",
                        height: "13px",
                      },

                      ".icn": {
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "0",
                      },

                      ".MuiSvgIcon-root": {
                        width: "15px",
                        height: "15px",
                      },
                    }}
                  >
                    <i className="icn">
                      <EditPencilIcon />
                    </i>
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    onClick={() => console.log("err")}
                    disabled={true}
                    sx={{
                      display: "flex",
                      columnGap: "13px",
                      alignItems: "center",
                      borderRadius: "0",
                      fontSize: "16px",
                      lineHeight: "24px",
                      padding: "8px 12px",
                      minWidth: "inherit",
                      justifyContent: "space-between",
                      fontWeight: "400",
                      color: palette.settings.contrastText,

                      "&:hover": {
                        background: palette.settings.lighter,
                        color: palette.common.black,
                      },

                      ".csb-dropdown-icn": {
                        width: "13px",
                        height: "13px",
                      },

                      ".icn": {
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "0",
                      },

                      ".MuiSvgIcon-root": {
                        width: "15px",
                        height: "15px",
                      },
                    }}
                  >
                    <i className="icn">
                      <ShareIcon />
                    </i>
                  </Button>
                </ListItem>
                <ListItem>
                  <Button
                    onClick={() => deleteTermHandler(data.id)}
                    sx={{
                      display: "flex",
                      columnGap: "13px",
                      alignItems: "center",
                      borderRadius: "0",
                      fontSize: "16px",
                      lineHeight: "24px",
                      padding: "8px 12px",
                      minWidth: "inherit",
                      justifyContent: "space-between",
                      color: palette.common.black,
                      fontWeight: "400",
                      borderLeft: `1px solid ${palette.action.disabledBackground}`,

                      "&:hover": {
                        background: palette.settings.lighter,
                        color: palette.common.black,
                      },

                      ".csb-dropdown-icn": {
                        width: "13px",
                        height: "13px",
                      },

                      ".icn": {
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        lineHeight: "0",
                        color: palette.grayAccent.light,
                      },

                      ".MuiSvgIcon-root": {
                        width: "15px",
                        height: "15px",
                      },
                    }}
                  >
                    <i className="icn">
                      <DeleteIcon />
                    </i>
                  </Button>
                </ListItem>
              </List>
            </Box>

            <Box mt={5}>
              <Typography gutterBottom variant="subtitle3">
                Type
              </Typography>
              {!edit ? (
                <Typography variant="body2" color="gray.darkest">
                  <Box display="flex" gap={2} alignItems="center">
                    {data.type}
                  </Box>
                </Typography>
              ) : (
                <Box>
                  <TextField
                    hiddenLabel
                    fullWidth
                    onChange={handleChange("type")}
                    defaultValue={values.type}
                    multiline
                    inputProps={{
                      sx: {
                        fontSize: "1rem",
                        minHeight: "70px",
                        maxHeight: "200px",
                      },
                    }}
                    size="medium"
                  />
                </Box>
              )}
            </Box>

            <Box mt={5}>
              <Typography gutterBottom variant="subtitle3">
                Description
              </Typography>
              {edit ? (
                <Box>
                  <TextField
                    hiddenLabel
                    fullWidth
                    onChange={handleChange("description")}
                    defaultValue={values.description}
                    multiline
                    inputProps={{
                      sx: {
                        fontSize: "1rem",
                        minHeight: "70px",
                        maxHeight: "200px",
                      },
                    }}
                    size="medium"
                  />
                </Box>
              ) : (
                <Typography variant="body2" color="gray.darkest">
                  <Box display="flex" gap={2} alignItems="center">
                    {data.description}
                  </Box>
                </Typography>
              )}
            </Box>

            <Box mt={5}>
              <Typography gutterBottom variant="subtitle3">
                Added By
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  <ProfileAvatarIcon
                    name={`${creator?.firstName} ${creator?.lastName}`}
                  />
                  {`${creator?.firstName} ${creator.lastName}`}
                </Box>
              </Typography>
            </Box>

            <Box mt={5}>
              <Typography gutterBottom variant="subtitle3">
                Added On
              </Typography>
              <Typography variant="body2" color="gray.darkest">
                <Box display="flex" gap={2} alignItems="center">
                  {dateHelper(data.addedOn)}
                </Box>
              </Typography>
            </Box>

            {edit && (
              <Box display="flex" justifyContent="flex-end">
                <ButtonDefault
                  sx={{ mr: 3 }}
                  color="grayAccent"
                  variant="outlined"
                  onClick={() => {
                    setEdit(false);
                  }}
                >
                  Cancel
                </ButtonDefault>
                <ButtonPrimary color="grayAccent" variant="contained" type="submit">
                  Confirm
                </ButtonPrimary>
              </Box>
            )}
          </Box>
        </Drawer>
      )}
    </Formik>
  );
};
