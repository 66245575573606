import { FormikValues } from "formik";
import React, { useState } from "react";
import { LeftRightContent } from "../LeftRightContent/LeftRightContent";
import { Box, Divider, SxProps, useTheme } from "@mui/material";
import { SortByButtonMenu, SortByType } from "./SortByButtonMenu";
import { Typography } from "../Typography/Typography";
import { FilterByStatusButton } from "./FilterByStatusButton";
import { FilterByOwnerButton } from "./FilterByOwnerButton";
import { GetProjectsQuery } from "../../generated";
import { Button } from "../Button/Button";
import { CardsViewIcon } from "../Icons/CardsViewIcon";
import { TableViewIcon } from "../Icons/TableViewIcon";
import { SearchBar } from "../SearchBar/SearchBar";
import { ActionBarWrapper, FilterLabel, ButtonDefault } from "./style";

export interface ActionBarProjectsProps {
  sx?: SxProps;
  setCurrSortByType: (value: SortByType | null) => any;
  sortByType: SortByType | null;
  statusValues: FormikValues;
  setStatusValues: (values: FormikValues) => void;
  ownerValues: FormikValues;
  setOwnerValues: (values: FormikValues) => void;
  // tagValues: FormikValues;
  // setTagValues: (values: FormikValues) => void;
  amountOfProjects: number;
  amountOfActiveProjects: number;
  amountOfArchivedProjects: number;
  amountOfDraftProjects: number;
  amountOfPublishedProjects: number;
  amountOfPrivateProjects: number;
  amountOfCompleteProjects: number;
  workspace?: GetProjectsQuery["workspace"];
  cardView: boolean;
  setCardView: Function;
  searchText: string;
  setSearchText: Function;
}

export const ActionBarProjects = ({
  sx,
  setCurrSortByType,
  sortByType,
  statusValues,
  setStatusValues,
  ownerValues,
  setOwnerValues,
  // tagValues,
  // setTagValues,
  amountOfProjects,
  amountOfActiveProjects,
  amountOfPublishedProjects,
  amountOfArchivedProjects,
  amountOfDraftProjects,
  amountOfPrivateProjects,
  amountOfCompleteProjects,
  workspace,
  cardView,
  setCardView,
  searchText,
  setSearchText,
}: ActionBarProjectsProps) => {
  const { palette } = useTheme();

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
  };

  return (
    <ActionBarWrapper>
      <LeftRightContent
        sx={{ p: 4 }}
        leftElements={
          // <SortByButtonMenu
          //   currSortByType={sortByType}
          //   setCurrSortByType={setCurrSortByType}
          // />
          <SearchBar
            onChange={onChangeSearch}
            value={searchText}
            placeholder="Find a project"
          />
        }
        rightElements={
          <>
            <ButtonDefault
              onClick={() => setCardView(true)}
              className={`${cardView ? "active small" : "small"}`}
            >
              <CardsViewIcon />
            </ButtonDefault>
            <ButtonDefault
              onClick={() => setCardView(false)}
              className={`${cardView ? "small" : "active small"}`}
            >
              <TableViewIcon />
            </ButtonDefault>
            <FilterLabel
              mr={3}
              variant="button"
            >
              Filter:{" "}
            </FilterLabel>
            {cardView && (
              <FilterByStatusButton
                text="Status"
                sx={{ marginRight: ".5rem" }}
                values={statusValues}
                onSubmit={setStatusValues}
                amountOfProjects={amountOfProjects}
                amountOfActiveProjects={amountOfActiveProjects}
                amountOfPublishedProjects={amountOfPublishedProjects}
                amountOfArchivedProjects={amountOfArchivedProjects}
                amountOfDraftProjects={amountOfDraftProjects}
                amountOfPrivateProjects={amountOfPrivateProjects}
                amountOfCompleteProjects={amountOfCompleteProjects}
              />
            )}
            <FilterByOwnerButton
              text="Owner"
              sx={{ marginRight: ".5rem" }}
              values={ownerValues}
              onSubmit={setOwnerValues}
              workspace={workspace}
            />
            {/*<FilterByTagButton*/}
            {/*  tags={tags}*/}
            {/*  text="Tag"*/}
            {/*  sx={{ marginRight: ".5rem" }}*/}
            {/*  values={tagValues}*/}
            {/*  onSubmit={setTagValues}*/}
            {/*/>*/}
          </>
        }
      />
    </ActionBarWrapper>
  );
};
