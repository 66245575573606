import { useLocation, useParams } from "react-router-dom";
import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { CollectionIcon } from "../Icons/CollectionIcon";
import { ConnectionIcon } from "../Icons/ConnectionIcon";
import { DataQualityIcon } from "../Icons/DataQualityIcon";
import { WarehouseIcon } from "../Icons/WarehouseIcon";
import { TransformationIcon } from "../Icons/TransformationIcon";
import { UseIcon } from "../Icons/UseIcon";
import { LockIcon } from "../Icons/LockIcon";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { PapersListIcon } from "../Icons/PapersListIcon";
import { PaperInfoIcon } from "../Icons/PaperInfoIcon";
import { VectorIcon } from "../Icons/VectorIcon";
import { ProjectsFolderViiIcon } from "../Icons/ProjectsFolderViiIcon";

export const ProjectsLink = ({}) => {
  const { workspaceId } = useWorkspaceId();
  const { pathname } = useLocation();

  return (
    <>
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/projects`}
        icon={<ProjectsFolderViiIcon />}
        label="Projects"
        className={pathname.includes("projects") ? "active" : ""}
        active={pathname.includes("projects")}
      />
      {/* <SidenavButtonLink
        to={`/workspace/${workspaceId}/projects`}
        icon={<PaperInfoIcon />}
        label="Project Data Flow"
        active={pathname.includes("projects")}
        className={pathname.includes("projects") ? "active" : ""}
      />
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/projects`}
        icon={<VectorIcon />}
        label="Input Data Assets"
        active={pathname.includes("projects")}
        className={pathname.includes("projects") ? "active" : ""}
      /> */}
    </>
  );
};
