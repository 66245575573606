import React, { useState } from "react";
import {
  Box,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
  TextareaAutosize,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Grid,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DeleteConfirmationModal } from "./DeleteConfirmationModal";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import {
  CreateDestinationDocument,
  DestinationType,
  UpdateDestinationDocument,
  WorkflowDestinationOutput,
} from "../../generated";
import * as Yup from "yup";
import { Formik } from "formik";
import { CustomTextArea } from "../../common/CustomTextArea/CustomTextArea";
import { CustomTextField } from "../../common/CustomTextField/CustomTextField";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { DestinationForm } from "./DestinationForm/DestinationForm";

export const EditDestination = ({
  destination,
  toggleEditView,
  showToast,
}: {
  destination?: WorkflowDestinationOutput;
  toggleEditView: any;
  showToast?: any;
}) => {
  const { palette } = useTheme();
  const { projectId, workspaceId } = useParams();
  const [issueType, setIssueType] = useState("Major issue");
  const [isEdited, setisEdited] = useState("Major issue");
  const [show, setShow] = useState(false);
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [update] = useMutation(UpdateDestinationDocument);

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        method: Yup.string().required(),
        destinationURL: Yup.string().required(),
      })}
      initialValues={{
        name: destination?.name || "",
        description: destination?.description || "",
        method: destination?.destinationType,
        destinationURL: destination?.destinationUrl || "",
      }}
      onSubmit={async (values) => {
        const { errors } = await update({
          variables: {
            input: {
              destinationId: destination?.id || "",
              destinationType: values.method,
              description: values.description,
              name: values.name,
              destinationUrl: values.destinationURL,
              projectId: projectId || "",
              workspaceId: workspaceId || "",
            },
          },
          refetchQueries: ["getDestinationSheets"],
        });
        if (errors)
          showToast(
            "Unabele to update destination due to following error \n" + errors
          );
        else {
          toggleEditView(false);
          showToast("Destination updated");
        }
      }}
    >
      {({
        values,
        submitForm,
        setFieldValue,
        errors,
        touched,
        handleSubmit,
        handleChange,
        dirty,
      }) => (
        <>
          <DestinationForm
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
          {toggleConfirmationModal && (
            <DeleteConfirmationModal
              destinationAssetGroupId={destination?.id || ""}
              destinationAssetGroupName={destination?.name || ""}
            />
          )}
        </>
      )}
    </Formik>
  );
};
