import React, { useState } from "react";
import { Box, Dialog, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Alert } from "../../common/Alert";
import { SnackBar } from "../../common/SnakBar/SnakBar";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteInputDataAssetGroupDocument,
  GetProjectDocument,
} from "../../generated";
import { getLoader } from "../helpers";

export const DeleteConfirmationInputSchemaSet = ({
  inputDataAssetGroupId,
  inputDataAssetGroupName,
}: {
  inputDataAssetGroupId: string;
  inputDataAssetGroupName: string;
}) => {
  const { palette } = useTheme();
  const [open, setOpen] = React.useState(true);
  const { projectId, workspaceId } = useParams();
  const [showSnakBar, setShowSnakBar] = React.useState(false);
  const [showSnakBarToast, setShowSnakBarToast] = useState<boolean>(false);
  const [del] = useMutation(DeleteInputDataAssetGroupDocument);
  const { data, loading } = useQuery(GetProjectDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });

  const onDelete = async () => {
    await del({
      variables: {
        input: {
          inputDataAssetGroupId,
          projectId: projectId || "",
          workspaceId: workspaceId || "",
        },
      },
    });
  };

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setShowSnakBarToast(false);
    window.location.reload();
  };
  const toggleDialog = () => {
    setOpen(false);
  };

  const toggleSnakBar = () => {
    setShowSnakBar(true);
  };

  if (loading) return getLoader("Fetching Data", "custom-loader full-height");
  return (
    <>
      {showSnakBarToast && (
        <SnackBar
          open={showSnakBarToast}
          message="Input data asset schema set deleted from project"
          onClose={handleCloseSnakBar}
        />
      )}
      <Dialog maxWidth="sm" open={open}>
        <Box width="560px" height="auto" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5" color={palette.common.black}>
            Delete data product schema set?
          </Typography>
          <Box mb={8}>
            <Alert
              severity="warning"
              sx={{
                marginBottom: "16px",
              }}
            >
              Deleting this data product schema set will also delete its
              produced data products from the workspace data catalog.
            </Alert>
            <Alert
              severity="warning"
              sx={{
                marginBottom: "16px",
              }}
            >
              This action may cause validation, data lineage, or data quality
              issues.
            </Alert>
            <Typography
              variant="body3"
              color={palette.gray.dark}
              sx={{ mb: 4 }}
            >
              Are you sure you want to <strong>delete</strong> the following
              data product from the workspace and from the project,{" "}
              <strong>{data?.workspace?.projects?.[0]?.name}</strong>?
            </Typography>
            <Typography
              variant="body3"
              color={palette.gray.dark}
              sx={{ mb: 2 }}
            >
              <ul>
                <li>{inputDataAssetGroupName}</li>
              </ul>
            </Typography>
          </Box>
          <Box mt={4} display="flex" alignItems={"center"}>
            <Button
              color="grayAccent"
              variant="outlined"
              onClick={toggleDialog}
              sx={{ ml: "auto", mr: 3 }}
            >
              Cancel
            </Button>
            {showSnakBar ? (
              <Button
                color="error"
                variant="contained"
                onClick={async (e) => {
                  await onDelete();
                  setShowSnakBar(false);
                  setOpen(false);
                  setShowSnakBarToast(true);
                  window.location.reload();
                }}
              >
                I understand - delete schema set
              </Button>
            ) : (
              <Button color="error" variant="contained" onClick={toggleSnakBar}>
                Delete schema set
              </Button>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
