import { useContext, useState } from "react";
import { Box, Typography, useTheme, Button, Dialog } from "@mui/material";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import {
  convertToTitleCase,
  dateHelper,
  updateSvgDimension,
} from "../../helpers";
import { ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { FilterOperator } from "../../common/AGGridWrapper/gridHelpers";
import { ActionsCellRenderer } from "../../AssetDetail/ActionsCellRenderer/ActionsCellRenderer";
import { convertDateTime } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { DeleteTermDocument, TermOutput, TermsDocument } from "../../generated";
import ProfileAvatarIcon from "../../common/ProfileAvatarIcon/ProfileAvatarIcon";
import { ActionCell } from "./ActionCell";
import { useMutation } from "@apollo/client";
import { ButtonLink } from "./style";

export const GridView = ({
  setDetail,
  rowData,
  username,
  emailAddress,
}: {
  emailAddress: string;
  username: string;
  setDetail: Function;
  rowData: TermOutput[];
}) => {
  const { palette } = useTheme();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <ButtonLink
            onClick={() => setDetail(params.data)}>
            {params.value}
          </ButtonLink>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
    },
    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "added_by",
      headerName: "Added By",
      cellRenderer: (params: any) => {
        const { creator } = params.data;
        return (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",

              "> div": {
                flexShrink: "0"
              }
            }}
          >
            <ProfileAvatarIcon
              name={`${creator?.firstName} ${creator?.lastName}`}
            />{" "}
            {`${creator?.firstName} ${creator.lastName}`}
          </Box>
        );
      },
    },
    {
      field: "addedOn",
      headerName: "Added On",
      cellRenderer: (params: any) => {
        return dateHelper(params.data.addedOn);
      },
    },
    {
      sortable: false,
      field: "actions",
      type: "rightAligned",
      headerName: "Actions",
      width: 50,
      maxWidth: 85,
      resizable: false,
      cellRenderer: (params: ICellRendererParams) => {
        return <ActionCell data={params.data} setDetail={setDetail} />;
      },
      suppressMenu: true,
    },
  ];

  return (
    <Box
      className="ag-theme-material"
      sx={{
        marginTop: "-1px",

        ".ag-row.ag-row-hover": {
          background: palette.primary.lighter,
        },

        ".ag-theme-material .ag-overlay-no-rows-wrapper.ag-layout-auto-height":
          {
            paddingTop: "0 !important",
          },
        ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value > div.MuiBox-root":
          {
            flex: "1",
            maxWidth: "100%",
          },
        ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value p": {
          maxWidth: "calc(100% - 36px)",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },

        ".ag-header-cell": {
          paddingLeft: "16px",
          paddingRight: "16px",
        },

        ".ag-pinned-right-cols-container": {
          ".ag-cell": {
            borderLeft: "0 !important",

            "> div": {
              textAlign: "right",
            },
          },
        },

        ".ag-row": {
          ".ag-cell:first-child": {
            // paddingLeft: "2px",
          },
        },

        ".ag-header-cell:not(:last-child)": {
          borderRight: "0 !important",
        },

        ".ag-pinned-right-header": {
          borderLeft: "0 !important",

          ".ag-left-aligned-header": {
            justifyContent: "flex-end",
          },
        },
      }}
    >
      <AGGridWrapper
        // loading={loading}
        rowData={rowData}
        columnDefs={columns as any}
        defaultColDef={{
          sortable: true,
          flex: 1,
          minWidth: 200,
        }}
        getRowHeight={(params) => (params.node.group ? 32 : 62)}
        groupDefaultExpanded={1}
        domLayout="autoHeight"
        // onRowClicked={(params) => {
        //   setDetail(params.data);
        // }}
      />
    </Box>
  );
};
