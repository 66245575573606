import { Close } from "@mui/icons-material";
import { Box, Drawer, IconButton, Typography, useTheme } from "@mui/material";
import { Button } from "../Button/Button";
import { useEffect, useState } from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import { ButtonPrimary } from "./style";

export const Support = ({ open, onClose }: any) => {
  const [supportChat, setSupportChat] = useState(false);
  const { palette } = useTheme();

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          padding: "24px",
          width: "452px",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            flexGrow: "1",
          }}
        >
          <Box>
            <Box
              sx={{
                position: "absolute",
                top: "20px",
                right: "10px",

                ".close-button": {
                  border: `1px solid ${palette.action.disabledBackground}`,

                  "&:hover": {
                    borderColor: palette.primary.main,
                    backgroundColor: palette.primary.lighter,
                    color: palette.primary.main,
                  },
                },
              }}
            >
              <IconButton
                className="close-button"
                onClick={() => onClose()}
                size="small"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Typography
            sx={{
              color: palette.custom.dark,
              textTransform: "uppercase",
              fontSize: "14px",
              fontWeight: "600",
              marginBottom: "24px",
            }}
          >
            Quick Links
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              ".qc-links": {
                display: "flex",
                alignItems: "center",
                gap: "12px",
                padding: "18px 0",
                textDecoration: "none",
                color: palette.gray.dark,
                transition: "color 0.3s ease",

                "&:hover": {
                  color: palette.primary.dark,

                  "svg": {
                    color: "inherit"
                  }
                },

                "+ .qc-links": {
                  borderTop: `1px solid ${palette.action.disabledBackground}`,
                },

                svg: {
                  color: palette.primary.main,
                  transition: "color 0.3s ease"
                },
              },
            }}
          >
            <a
              className="qc-links"
              href="http://brighthivehelp.zendesk.com/"
              target="_blank"
              rel="noreferrer"
            >
              <MenuBookIcon />
              Knowledge Base
            </a>
            <a
              className="qc-links"
              href="https://www.youtube.com/channel/UClADExiSWym4XrNN1hgI05w"
              target="_blank"
              rel="noreferrer"
            >
              <SchoolIcon />
              Academic Training Videos
            </a>
            <a
              className="qc-links"
              href="https://brighthive.io/slack-community-signup/"
              target="_blank"
              rel="noreferrer"
            >
              <GroupWorkIcon />
              Community
            </a>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            margin: "24px -24px -24px",
            borderTop: `1px solid ${palette.action.disabledBackground}`,
            padding: "24px",
          }}
        >
          <ButtonPrimary
            className="chat-button"
            onClick={() => {
              (window as any).zE("messenger", "show");
              (window as any).zE("messenger", "open");
              onClose();
            }}
          >
            <InsertCommentIcon />
            Chat with Us
          </ButtonPrimary>
        </Box>
      </Box>
    </Drawer>
  );
};
