import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { Button } from "../../../common/Button/Button";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateSchemaDocument,
  GetSchemasDocument,
  GetTargetSchemasDocument,
  ScopeType,
  TargetSchemaStatus,
  UpdateTargetSchemaDocument,
} from "../../../generated";
import * as Yup from "yup";

import { useLocation, useParams } from "react-router-dom";
import { ProjectSchemaDetailGrid } from "../../../Projects/ProjectSchemaDetailPage/ProjectSchemaDetailGrid";
import { Field, Formik } from "formik";
import { Label } from "../../../common/Label/Label";
import { MAX_DESCRIPTION_CHARS } from "../../../Constants";
import { Radio } from "../../../common/Radio/Radio";
import { toast } from "react-toastify";
import Editor from "@monaco-editor/react";
import { PlusIcon } from "../../../common/Icons/PlusIcon";
import { AddSchemaButton } from "./style";
import { ButtonPrimary } from "../style";

export const SchemaBuilderPage = ({ data, buttonText, className }: any) => {
  const { state }: any = useLocation();
  const { projectId, workspaceId, schemaId } = useParams();
  const { palette } = useTheme();
  const [openAdd, setOpenAdd] = useState(false);

  const [createSchema] = useMutation(CreateSchemaDocument, {
    refetchQueries: [GetSchemasDocument],
  });

  useEffect(() => {
    if (state?.add) {
      setOpenAdd(true);
    }
  }, [state?.add]);

  return (
    <>
      <AddSchemaButton className={className}>
        <ButtonPrimary
          variant="contained"
          color="primary"
          onClick={() => setOpenAdd(true)}
        >
          <PlusIcon sx={{ marginRight: "5px", fontSize: "14px" }} />{" "}
          {buttonText ? buttonText : "Add Schema"}
        </ButtonPrimary>
      </AddSchemaButton>

      <Dialog maxWidth="xl" open={openAdd} onClose={() => setOpenAdd(false)}>
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            description: Yup.string().required().max(MAX_DESCRIPTION_CHARS),
            jsonSchema: Yup.string().required(),
          })}
          initialValues={{
            name: "",
            description: "",
            jsonSchema: JSON.stringify(
              {
                $id: "https://example.com/blank.schema.json",
                title: "Blank-schema-template",
                description: "",
                type: "object",
                properties: {},
              },
              null,
              2
            ),
            scope: ScopeType.Workspace,
            method: "manual",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            createSchema({
              variables: {
                input: {
                  workspaceId: workspaceId || "",
                  name: values.name,
                  description: values.description,
                  status: TargetSchemaStatus.Draft,
                  scope: values.scope,
                  jsonSchema: values.jsonSchema,
                },
              },
            })
              .then((res) => toast.success("Scehma Created " + values.name))
              .finally(() => {
                setSubmitting(false);
                setOpenAdd(false);
              });
          }}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogTitle>
                  <Typography variant="h5">{"Create new schema"}</Typography>
                </DialogTitle>
                <DialogContent>
                  <Box width="50rem" boxSizing="border-box">
                    <Box mt={4}>
                      <Label mb={1} value="Name" width={"15.5rem"} />
                      <TextField
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        hiddenLabel
                        fullWidth
                        inputProps={{
                          sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                        }}
                        size="small"
                        sx={{ mb: 6 }}
                      />
                      {errors.name && <Typography>{errors.name}</Typography>}
                    </Box>

                    <Label mb={1} value="Description" width={"15.5rem"} />
                    <TextField
                      onChange={handleChange}
                      value={values.description}
                      name="description"
                      hiddenLabel
                      rows={3}
                      fullWidth
                      multiline
                      inputProps={{
                        sx: { fontSize: "1rem" },
                        maxLength: MAX_DESCRIPTION_CHARS,
                      }}
                      size="small"
                      sx={{ mb: 2 }}
                    />
                    <Typography
                      variant="body3"
                      color={palette.gray.dark}
                      sx={{ mb: 6 }}
                    >
                      {values.description.length}/{MAX_DESCRIPTION_CHARS}{" "}
                      characters
                    </Typography>

                    {errors.description && (
                      <Typography>{errors.description}</Typography>
                    )}
                  </Box>
                  <Box>
                    <Label
                      mt={2}
                      mb={1}
                      value="Choose a method to create your schema"
                    />
                    <Radio
                      name="method"
                      label="Manual Entry"
                      value="manual"
                      checked={values?.method === "manual"}
                      onChange={handleChange("method")}
                    />
                    <Radio
                      name="method"
                      label="From Json Schema"
                      value="json"
                      checked={values?.method === "json"}
                      onChange={handleChange("method")}
                    />

                    {values.method === "json" && (
                      <Box
                        sx={{
                          border: `1px solid ${palette.grayAccent.light}`,
                          borderRadius: "4px",
                          padding: "4px",
                          mt: "8px",
                        }}
                      >
                        <Editor
                          height="300px"
                          defaultLanguage="json"
                          defaultValue={JSON.stringify(
                            {
                              $id: "https://example.com/blank.schema.json",
                              title: "Blank-schema-template",
                              description: "",
                              type: "object",
                              properties: {},
                            },
                            null,
                            2
                          )}
                          onChange={(v) => setFieldValue("jsonSchema", v)}
                        />
                      </Box>
                    )}
                    <Radio
                      name="method"
                      label="Front csv File"
                      value="csv"
                      onChange={handleChange("method")}
                      checked={values?.method === "csv"}
                    />
                    <Radio
                      name="method"
                      label="Knowledge data center"
                      value="knowledge"
                      onChange={handleChange("method")}
                      checked={values?.method === "knowledge"}
                    />
                    <Radio
                      name="method"
                      label="From data asset catalog"
                      value="catalog"
                      onChange={handleChange("method")}
                      checked={values?.method === "catalog"}
                    />
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      sx={{ marginRight: "5px" }}
                      onClick={() => setOpenAdd(false)}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Box>
                </DialogContent>
              </form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};
