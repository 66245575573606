import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { AppContext } from "../../App";
import { Button } from "../../common/Button/Button";
import { SideNavigationPage } from "../../common/SideNavigationPage/SideNavigationPage";
import { FetchResult, useMutation, useQuery } from "@apollo/client";
import {
  GetTargetSchemasDocument,
  TargetSchema,
  UpdateTargetSchemaDocument,
  UpdateTargetSchemaMutation,
  UpdateWorkspaceSchemaMutation,
} from "../../generated";

import { useParams } from "react-router-dom";
import { ProjectSchemaDetailGrid } from "./ProjectSchemaDetailGrid";
import { ModalTargetSchemaField } from "./ModalTargetSchemaField";
import { CreateNewSchema } from "../../Sheets/Schema/CreateNewSchema";
import RightSidePanel from "../../common/RightSidePanel";

export const ProjectSchemaDetailPage = ({
  data,
  update,
}: {
  data: TargetSchema;
  update: (
    parse: any
  ) => Promise<
    FetchResult<UpdateTargetSchemaMutation | UpdateWorkspaceSchemaMutation>
  >;
}) => {
  const { projectId, workspaceId, schemaId } = useParams();
  const { palette } = useTheme();
  const [openAdd, setOpenAdd] = useState(false);

  const schemaDetail = data;

  return (
    <>
      <Box
        display="flex"
        sx={{ justifyContent: "space-between" }}
        marginBottom="1rem"
      >
        <Typography variant="h6">
          Target Schema:
          <Box component="span" ml={1} fontWeight="normal">
            {schemaDetail?.name}
          </Box>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAdd(true)}
        >
          Add a field
        </Button>
      </Box>

      <Box
        bgcolor={palette.common.white}
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        flexGrow={1}
        border={`0.0625rem solid ${palette.outline}`}
        borderRadius="0.375rem"
      >
        <ProjectSchemaDetailGrid schemaDetail={schemaDetail} update={update} />
      </Box>
      {/* <ModalTargetSchemaField
        show={openAdd}
        closeDialog={() => setOpenAdd(false)}
        submit={(v: any) => {
          const parse = JSON.parse(schemaDetail?.jsonSchema || "{}");

          const properties = parse?.properties || {};

          properties[v.name] = {
            description: v.description,
            type: v.type,
          };

          if (v.minLength) {
            properties[v.name]["minLength"] = v.minLength;
          }

          if (v.maxLength) {
            properties[v.name]["maxLength"] = v.maxLength;
          }

          parse.properties = properties;
          const required: string[] = parse?.required || [];
          if (!required.includes(v.name) && v.required) {
            required.push(v.name);
          }

          if (required.length > 0) {
            parse.required = required;
          }

          return mutateUpdate({
            variables: {
              input: {
                targetSchemaId: schemaId || "",
                workspaceId: workspaceId || "",
                projectId: projectId || "",
                name: schemaDetail?.name,
                description: schemaDetail?.description,
                jsonSchema: JSON.stringify(parse),
              },
            },
          });
        }}
      /> */}
      <RightSidePanel
        isOpen={Boolean(openAdd)}
        onToggle={() => setOpenAdd(false)}
      >
        <CreateNewSchema
          targetSchema={data}
          closeDialog={() => setOpenAdd(false)}
          submit={(v: any) => {
            const parse = JSON.parse(schemaDetail?.jsonSchema || "{}");

            const properties = parse?.properties || {};

            properties[v.name] = {
              description: v.description,
              title: v.title,
              type: v.type,
              // pii: v.pii,
              ...(v.type === "string" &&
                v.format !== "none" && { format: v.format }),
              ...(v.validate && {
                ...(v.pattern && { pattern: v.patternValue }),
              }),
              ...(v.type === "array" && v.enum.length > 0 && { enum: v.enum }),
            };

            if (v.minLength) {
              properties[v.name]["minLength"] = v.minLength;
            }

            if (v.maxLength) {
              properties[v.name]["maxLength"] = v.maxLength;
            }

            parse.properties = properties;

            const required: string[] = parse?.required || [];
            if (!required.includes(v.name) && v.required) {
              required.push(v.name);
            }

            if (required.length > 0) {
              parse.required = required;
            }

            return update(parse)
              .then(() => {
                setOpenAdd(false);
              })
              .catch(() => {
                setOpenAdd(false);
              });
          }}
        />
      </RightSidePanel>
    </>
  );
};
