import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  GetSchemasDocument,
  UpdateWorkspaceSchemaDocument,
} from "../../../generated";

import Editor from "@monaco-editor/react";
import { Button } from "../../../common/Button/Button";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const JSONSchema = ({ data }: any) => {
  const { palette } = useTheme();
  const { workspaceId, schemaId } = useParams();
  const [json, setJson] = useState<any>(
    JSON.stringify(data.jsonSchema, null, 2)
  );

  const [mutateUpdate] = useMutation(UpdateWorkspaceSchemaDocument, {
    refetchQueries: [GetSchemasDocument],
  });

  const saveHandler = () => {
    mutateUpdate({
      variables: {
        input: {
          workspaceId: workspaceId || "",
          schemaId: schemaId || "",
          jsonSchema: json,
        },
      },
    }).then((res) =>
      toast.success(
        "Json Schema Updated " + res.data?.updateWorkspaceSchema.success
      )
    );
  };

  return (
    <>
      <Box
        display="flex"
        sx={{ justifyContent: "space-between" }}
        marginBottom="1rem"
      >
        <Typography variant="h6">
          Target Schema:
          <Box component="span" ml={1} fontWeight="normal">
            {data?.name}
          </Box>
        </Typography>
      </Box>

      <Box
        bgcolor={palette.common.white}
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        flexGrow={1}
        border={`0.0625rem solid ${palette.outline}`}
        borderRadius="0.375rem"
      >
        <Editor
          height="300px"
          defaultLanguage="json"
          value={json || ""}
          onChange={(v) => setJson(v)}
        />
      </Box>
      <Box>
        <Button
          onClick={() => setJson(JSON.stringify(data.jsonSchema, null, 2))}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button onClick={() => saveHandler()} variant="contained">
          Save
        </Button>
      </Box>
    </>
  );
};
