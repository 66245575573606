export const testSchemaOne = {
  $schema: "http://json-schema.org/draft-07/schema#",
  title: "JEDx Worker Personal Identification Information",
  type: "object",
  properties: {
    SocialSecurityNumber: {
      type: "string",
      description:
        "A nine-digit number that the U.S. government issues to all U.S. citizens and eligible U.S. residents who apply for one. The government uses this number to keep track of the individual's lifetime earnings and the number of years worked.",
    },
    PreviousSocialSecurityNumber: {
      type: "string",
      description:
        "An additional SSN for an employee who has performed work under more than one Social Security Number.",
    },
    FirstName: {
      type: "string",
      description: "The given name of a person.",
    },
    MiddleName: {
      type: "string",
      description: "The middle names or initials of a person.",
    },
    LastName: {
      type: "string",
      description: "The family name (or surname) of a person.",
    },
    SecondLastName: {
      type: "string",
      description:
        "The second family name (or surname) of a person, if applicable.",
    },
    PreviousLastName: {
      type: "string",
      description:
        "The former family name of a person (used if the person's name was changed).",
    },
    NameSuffix: {
      type: "string",
      description:
        "The generational designation attached to the end of a person's name (such as Jr., Sr., II, III).",
    },
    BirthDate: {
      type: "string",
      format: "date",
      description: "The birth date of a person.",
    },
    ResidenceStreetAddress: {
      type: "string",
      description: "The street address of the employee's primary residence.",
    },
    ResidenceCity: {
      type: "string",
      description:
        "The name of the city in which the employee's primary residence is located.",
    },
    ResidenceState: {
      type: "string",
      description:
        "The two-digit alphabetic code for the state in which the employee's primary residence is located.",
    },
    ResidenceZipCode: {
      type: "string",
      description:
        "The ZIP Code in which the employee's primary residence is located.",
    },
    PhoneNumber: {
      type: "string",
      description: "Employee's phone number.",
    },
    MothersMaidenName: {
      type: "string",
      description:
        "The surname that the worker's mother used from birth, prior to its being legally changed at marriage.",
    },
    DriversLicense: {
      type: "string",
      description:
        "The state and number of the driver's license held by the worker.",
    },
    VISAType: {
      type: "string",
      description:
        "The type of VISA held by a foreign worker that allows work in the reporting country. Valid values include: EB-1, EB-2, EB-3, E-4, E-5, H-1B, H-1B1, H-2A, H-2B, H-3, J1, L-1, L-2, O-1, P-1, P-2, P-3.",
    },
    Citizenship: {
      type: "string",
      description:
        "The citizenships that a person holds. This is a legal citizenship in a country (as opposed to nationality which may or may not be a country). Enter the name(s) of the country (countries) in which the individual holds citizenship status.",
    },
    MilitaryStatus: {
      type: "integer",
      description:
        "The worker's participation in federal or state military service, including but not limited to, the armed forces of the United States, the army national guard, the air national guard, and such additional forces as may be created by the federal or state government as authorized by law. Valid values include: 0--Not indicated, 1--No Military Service, 2--Vietnam Era Veteran, 3--Other Veteran, 4--Active Reserve, 5--Inactive Reserve, 6--Military Spouse, 7--Retired, 8--Active duty.",
    },
    Gender: {
      type: "integer",
      description:
        "The gender identity preferred by the worker. Valid values include: 0--Declines to state, 1--Female, 2--Male, 3--Non-binary.",
    },
    Ethnicity: {
      type: "integer",
      description:
        "The race and ethnic identity preferred by the worker. Valid values include: 0--Declines to state, 1--Hispanic or Latino, 2--White (Not Hispanic or Latino), 3--Black or African American (Not Hispanic or Latino), 4--Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino), 5--Asian (Not Hispanic or Latino), 6--American Indian or Alaska Native (Not Hispanic or Latino), 7--Two or More Races (Not Hispanic or Latino).",
    },
    Race: {
      type: "integer",
      description:
        "The race and ethnic identity preferred by the worker. Valid values include: 0--Declines to state, 1--Hispanic or Latino, 2--White (Not Hispanic or Latino), 3--Black or African American (Not Hispanic or Latino), 4--Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino), 5--Asian (Not Hispanic or Latino), 6--American Indian or Alaska Native (Not Hispanic or Latino), 7--Two or More Races (Not Hispanic or Latino).",
    },
    Disability: {
      type: "string",
      description:
        "An indicator of whether the worker has a known, obvious, or documented disability. Enter the appropriate letter from the list below: Y = Yes, N = No.",
    },
    EducationLevel: {
      type: "integer",
      description:
        "The highest degree or level of school this person has completed. Valid values include: 1--No schooling completed, 2--Nursery school, 3--Grades 1 through 11, 4--12th grade—no diploma, 5--Regular high school diploma, 6--GED or alternative credential, 7--Some college credit, but less than 1 year of college, 8--1 or more years of college credit, no degree, 9--Associates degree, 10--Bachelor’s degree, 11--Master’s degree, 12--Professional degree beyond bachelor’s degree, 13--Doctorate degree.",
    },
  },
  required: [
    "SocialSecurityNumber",
    "FirstName",
    "LastName",
    "BirthDate",
    "ResidenceStreetAddress",
    "ResidenceCity",
    "ResidenceState",
    "ResidenceZipCode",
    "PhoneNumber",
  ],
};
