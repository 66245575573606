import { toast, ToastOptions } from "react-toastify";
import { brighthiveTheme } from "../../theme/theme";

export const displayToast = (message: string, options?: ToastOptions) => {
  return toast(message, {
    style: { backgroundColor: brighthiveTheme.palette.primary.main },
    autoClose: 5000,
    closeButton: false,
    hideProgressBar: false,
    theme: "light",
    position: "top-right",
    ...options,
  });
};
