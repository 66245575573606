import { useState } from "react";
import { Button } from "../Button/Button";
import { Box } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { CollapseDrop, CollapseToggler, CollapsibleWrapper, TogllerIcon } from "./style";
import { CaretDownViiIcon } from "../Icons/CaretDownViiIcon";
import { CaretUpViiIcon } from "../Icons/CaretUpViiIcon";

export const CollapsibleButton = ({ children, show, label, icon }: any) => {
  const [open, setOpen] = useState<boolean>(show);

  return (
    <CollapsibleWrapper className={open ? 'active' : ''}>
      <CollapseToggler onClick={() => setOpen((prev) => !prev)}>
        {icon &&
          icon
        }
        <span className="cs-hidden-text">
          {label}{" "}
        </span>
        <TogllerIcon>
          {open ? <CaretUpViiIcon /> : <CaretDownViiIcon />}
        </TogllerIcon>
      </CollapseToggler>
      {open && <CollapseDrop>{children}</CollapseDrop>}
    </CollapsibleWrapper>
  );
};
