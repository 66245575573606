import React, { useState } from "react";
import { Button } from "../../common/Button/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  UpdateTargetSchemaDocument,
  GetTargetSchemasDocument,
  TargetSchema,
} from "../../generated";

export interface RemoveFieldCellRendererProps {
  id: string;
  removeField: () => void;
  targetSchema?: TargetSchema;
}

export const RemoveFieldCellRenderer = ({
  id,
  removeField,
  targetSchema,
}: RemoveFieldCellRendererProps) => {
  const [mutateUpdate] = useMutation(UpdateTargetSchemaDocument, {
    refetchQueries: [GetTargetSchemasDocument],
  });
  const { projectId, workspaceId, schemaId } = useParams();

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };
  const onClickRemoveField = async () => {
    const parse = JSON.parse(targetSchema?.jsonSchema || "{}");
    const properties = parse?.properties || {};
    delete properties[id];
    parse.properties = properties;

    await mutateUpdate({
      variables: {
        input: {
          targetSchemaId: schemaId || "",
          workspaceId: workspaceId || "",
          projectId: projectId || "",
          name: targetSchema?.name,
          description: targetSchema?.description,
          jsonSchema: JSON.stringify(parse),
        },
      },
    });
    if (removeField) removeField();
    closeDialog();
  };

  return (
    <>
      <Button
        onClick={openDialog}
        color="grayAccent"
        variant="text"
        startIcon={<CloseIcon />}
      >
        Remove
      </Button>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <Box width="35rem" p={6} boxSizing="border-box">
          <Typography mb={4} variant="h5">
            Remove Field
          </Typography>

          <Box display="flex" mt={7} justifyContent="flex-end">
            <Button
              onClick={closeDialog}
              color="grayAccent"
              variant="outlined"
              sx={{ mr: 3 }}
            >
              Cancel
            </Button>
            <Button
              onClick={onClickRemoveField}
              color="error"
              variant="contained"
            >
              Remove
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
