import { styled, Box, Typography } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const Wrapper = styled("button")`
  background-color: ${brighthiveTheme.palette.primary.lighter};
  overflow: hidden;
  padding: 24px 16px;
  border-radius: 4px;
  width: 100%;
  border: none;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: ${brighthiveTheme.palette.primary.outline};
    transition: all 0.2s linear;
  }
`;

export const Heading = styled(Typography)`
  color: ${brighthiveTheme.palette.common.black};
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.25px;
  margin-bottom: 24px;
`;

export const Description = styled(Typography)`
  color: ${brighthiveTheme.palette.gray.darkest};
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.25px;
`;
