import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  BoxProps,
  Card,
  CardProps,
  Divider,
  useTheme,
} from "@mui/material";
import { BubbleChartOutlined, CorporateFare } from "@mui/icons-material";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { OverridableStringUnion } from "@mui/types";

import { Chip } from "../Chip/Chip";
import { CreatedAssetIcon } from "../Icons/CreatedAssetIcon";
import { LinesEllipsisTypography } from "../LinesEllipsisTypography/LinesEllipsisTypography";
import { ProjectCardDataRow } from "./ProjectCardDataRow";
import {
  convertIsoDateStringToMMDDYYYY,
  convertToTitleCase,
} from "../../helpers";
import {
  DuplicateProjectDocument,
  GetProjectQuery,
  ProjectStatus,
} from "../../generated";
import { Typography } from "../Typography/Typography";
import Dropdown from "../Dropdown/Dropdown";
import { ApolloQueryResult, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { ProjectCardActionDropdown } from "../ActionBarProjects/ProjectCardActionDropdown";

interface SpecificCardProps {
  modifiedAt?: string;
  name?: string;
}

export enum Status {
  ACTIVE = "Active",
  DRAFT = "Draft",
  ARCHIVED = "Archived",
}

interface SpecificCardProps {
  id?: string;
  updatedDateText?: string;
  projectName?: string;
  cardProps?: CardProps;
  status: ProjectStatus;
  numberOfIssues?: number;
  description?: string;
  templateName?: string;
  inputAssetCount?: number;
  createdAssetCount?: number;
  ownerName?: string;
  cardSizeExpanded?: boolean;
  workspaceId?: string;
  workspaceName?: string;
  refetchProjectDocuments?: any;
}

export type ProjectCardProps = SpecificCardProps & BoxProps;

export const ProjectCard = ({
  modifiedAt,
  name,
  id,
  updatedDateText,
  projectName,
  cardProps,
  status,
  numberOfIssues,
  description,
  templateName,
  inputAssetCount,
  createdAssetCount,
  ownerName,
  cardSizeExpanded,
  workspaceName,
  workspaceId,
  refetchProjectDocuments,
  ...rest
}: ProjectCardProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  let chipColor: OverridableStringUnion<
    "primary" | "secondary" | "error" | "info" | "success" | "warning",
    ChipPropsColorOverrides
  > = "primary";
  if (status === ProjectStatus.Draft) {
    chipColor = "grayAccent";
  } else if (status === ProjectStatus.Private) {
    chipColor = "gray";
  }

  const formattedDescription = description || "No Description";
  const formattedTemplateName = templateName
    ? `Template: ${templateName}`
    : "Template: none";

  const width = "100%";
  const height = "100%";

  return (
    <Box
      sx={{
        cursor: "pointer",
        position: "relative",
      }}
      {...rest}
    >
      <Box
        sx={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
        }}
        onClick={() =>
          navigate(`/workspace/${workspaceId}/project/${id}/project-overview`, {
            state: {
              projectName: name,
              workspaceName,
            },
          })
        }
        className="project-card"
        tabIndex={1}
        aria-labelledby={`${name}-title`}
        role="link"
      >
        <Card
          sx={{
            height,
            width,
            cursor: "pointer",
            boxShadow: "none",
            borderRadius: "6px",
            border: `1px solid ${palette.action.disabledBackground}`,
          }}
          {...cardProps}
        >
          <Box
            sx={{
              px: 4,
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box mb={4} mt={4}>
              <Typography mb={1} color={palette.gray.dark} variant="caption">
                {`Updated: ${convertIsoDateStringToMMDDYYYY(modifiedAt)}`}
              </Typography>
              <LinesEllipsisTypography
                id={`${name}-title`}
                mb={3}
                fontWeight={700}
                variant="h6"
                text={name}
                maxLines={2}
              />
              <Chip
                sx={{
                  mr: 1,
                  backgroundColor: palette.grayAccent.lighter,
                  color: palette.common.black,
                }}
                size="small"
                color={chipColor}
                label={convertToTitleCase(status)}
              />
              {numberOfIssues === 1 && (
                <Chip
                  sx={{
                    mr: 1,
                    backgroundColor: palette.warning.light,
                    color: palette.common.black,
                  }}
                  size="small"
                  color="error"
                  label="1 issue"
                />
              )}
              {numberOfIssues && numberOfIssues > 1 ? (
                <Chip
                  sx={{
                    backgroundColor: palette.warning.light,
                    color: palette.common.black,
                  }}
                  size="small"
                  color="error"
                  label={`${numberOfIssues} issues`}
                />
              ) : null}
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <ProjectCardDataRow
                icon={<BubbleChartOutlined />}
                name="Input Assets"
                value={inputAssetCount}
              />
              <ProjectCardDataRow
                icon={<CreatedAssetIcon />}
                name="Created Assets"
                value={createdAssetCount}
              />
              <ProjectCardDataRow
                icon={<CorporateFare />}
                name="Owning org."
                value={ownerName}
                valueContainerProps={{
                  "aria-label": "owning-organization",
                  role: "label",
                }}
              />
              <LinesEllipsisTypography
                flexGrow={1}
                mt={1}
                mb={4}
                color={palette.gray.dark}
                variant="body3"
                text={formattedDescription}
                maxLines={2}
              />
            </Box>
            <Divider sx={{ mb: 4 }} />
            <Box>
              <LinesEllipsisTypography
                color={palette.gray.dark}
                variant="body3"
                text={formattedTemplateName}
                maxLines={1}
                sx={{
                  mb: 4,
                }}
              />
            </Box>
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          position: "absolute",
          padding: "10px 16px 8px 10px",
          right: "0px",
          bottom: "0px",
        }}
      >
        <ProjectCardActionDropdown
          workspaceId={workspaceId}
          id={id}
          refetchProjectDocuments={refetchProjectDocuments}
        />
      </Box>
    </Box>
  );
};
