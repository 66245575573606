import { BrightBotSmileIcon } from "../../common/Icons/BrightBotSmileIcon";
import { Wrapper, AvatarWrapper, Description } from "./style";

export const ChatRcvBotWidget = ({ avatar, children }: any) => {
  return (
    <Wrapper>
      <AvatarWrapper>
        {avatar && <img src={avatar} alt="" />}
        <BrightBotSmileIcon />
      </AvatarWrapper>
      <Description>
        <div style={{ whiteSpace: "pre-wrap" }}>{children}</div>
      </Description>
    </Wrapper>
  );
};
