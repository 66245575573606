import {
  AcceptWorkspacePolicyInput,
  AcceptWorkspacePolicyOutput,
  AddContributingOrganizationToProjectInput,
  AddDataAssetsToInputDataAssetGroupInput,
  AddSourceInput,
  AdminOrganization,
  AirbyteConnectionSchedule,
  AssetPolicy,
  Collaborator,
  ConfirmForgotPasswordInput,
  ConfirmUserInput,
  CreateAssetPolicyInput,
  CreateCustomPolicyInput,
  CreateDataAssetInput,
  CreateDataAssetOutput,
  CreateDestinationInput,
  CreateDestinationOutput,
  CreateFinalDataProductGroupInput,
  CreateFinalDataProductGroupOutput,
  CreateGoalInput,
  CreateGovernanceDocumentInput,
  CreateGovernanceGoalInput,
  CreateGovernanceInput,
  CreateGovernancePolicyInput,
  CreateIngestionServiceInput,
  CreateIngestionServiceOutput,
  CreateInputDataAssetGroupInput,
  CreateInputDataAssetGroupOutput,
  CreateLegalDocumentInput,
  CreateOrganizationInput,
  CreateOrganizationOutput,
  CreateProjectGoalInput,
  CreateProjectInput,
  CreateProjectOutput,
  CreateReferenceDocumentInput,
  CreateResourceFolderInput,
  CreateResourceFolderOutput,
  CreateResourceInput,
  CreateResourceOutput,
  CreateSchemaInput,
  CreateSchemaOutput,
  CreateTargetSchemaInput,
  CreateTargetSchemaOutput,
  CreateTermInput,
  CreateTermOutput,
  CreateTransformationInput,
  CreateTransformationOutput,
  CreateTransformationServiceInput,
  CreateTransformationServiceOutput,
  CreateUserInput,
  CreateUserOrganizationInput,
  CreateUserOutput,
  CreateWarehouseServiceInput,
  CreateWarehouseServiceOutput,
  CreateWorkflowConnectionInput,
  CreateWorkspaceInput,
  CreateWorkspaceOutput,
  CreateWorkspaceOwnerOrganizationInput,
  CreateWorkspaceOwnerOrganizationMemberInput,
  CreatedDataProductFilterInput,
  CreatedFinalProduct,
  CreatedFinalProductFinalDataProductGroupOutput,
  CurrentUserOrganizationGroupOutput,
  CurrentUserOrganizationGroupUserOutput,
  CurrentUserOrganizationMemberOutput,
  CurrentUserOrganizationOutput,
  CurrentUserOutput,
  CurrentUserWorkspaceFilterInput,
  CurrentUserWorkspaceOutput,
  CurrentUserWorkspacePolicy,
  CurrentUserWorkspaceRoleFilter,
  DataAsset,
  DataAssetEntityInput,
  DataAssetFilterInput,
  DataAssetInput,
  DataAssetOutput,
  DataAssetProjectOutput,
  DataAssetsInput,
  DeactivateOrganizationUserInput,
  DeactivateOrganizationUserOutput,
  DeleteDestinationInput,
  DeleteDestinationOutput,
  DeleteFinalDataProductGroupInput,
  DeleteFinalDataProductGroupOutput,
  DeleteInputDataAssetGroupInput,
  DeleteInputDataAssetGroupOutput,
  DeleteResourceInput,
  DeleteResourceOutput,
  DeleteTermInput,
  DeleteTermOutput,
  DisableUserInput,
  DisableUserOutput,
  Document,
  DownloadFileDestinationInput,
  DownloadFileDestinationOutput,
  DuplicateProjectInput,
  EntityOutput,
  Field,
  FinalDataProductManagerOutput,
  FinalDataProductOwnerOutput,
  ForgotPasswordInput,
  GeneralUser,
  Goal,
  Governance,
  HardDeleteUserInput,
  HealthOutput,
  IngestionServiceFilterInput,
  IngestionServiceOutput,
  InputDataAsset,
  InputDataAssetFilterInput,
  InputDataAssetInputDataAssetGroupOutput,
  InputDataAssetManagerOutput,
  InputDataAssetOwnerOutput,
  Issue,
  LoginChallengeResponse,
  LoginInput,
  LoginOutput,
  LogoUploadContext,
  Member,
  MemberProjectsContributed,
  MoveResourceInput,
  Mutation,
  OnboardDataAssetToInputDataAssetGroupInput,
  OnboardDataAssetToInputDataAssetGroupOutput,
  OnboardDataAssetToProjectInput,
  OnboardDataAssetToProjectOutput,
  OnboardDataAssetToWorkspaceInput,
  OnboardDataAssetToWorkspaceOutput,
  Organization,
  OrganizationAdmin,
  OrganizationDataAssetOutput,
  OrganizationFilterInput,
  OrganizationOutput,
  OrganizationPolicy,
  Policy,
  PolicyEditor,
  PreSignedUrlInput,
  PreSignedUrlOutput,
  PreSignedUrlReadInput,
  PreSignedUrlWriteInput,
  Project,
  ProjectCollaboratingMember,
  ProjectDataAssetOutput,
  ProjectDestinationOutput,
  ProjectEntityInput,
  ProjectOrganizationContributor,
  ProjectOrganizationOutput,
  ProjectOutput,
  ProjectTemplateOutput,
  Query,
  RefreshTokenInput,
  RefreshTokenOutput,
  RefreshUserTokenOutput,
  RemoveDataAssetFromFinalDataProductGroupInput,
  RemoveDataAssetFromInputDataAssetGroupInput,
  RemoveTargetSchemaFromProjectInput,
  RemoveWorkflowConnectionInput,
  ResendInviteInput,
  ResourceFolderOutput,
  ResourceOutput,
  RoleInput,
  RoleOutput,
  RunTransformationsInProjectInput,
  SchemaProjectConnectInput,
  SchemaResourceConnectInput,
  ServiceEntityOutput,
  ServiceManagerOutput,
  SourceDefinitionOutput,
  SourceDefinitionSpecFilter,
  SourceDefinitionSpecOutput,
  SourceDefinitionsFilter,
  SourceFilterInput,
  SourceManagerOutput,
  SourceOutput,
  SourceOwnerOutput,
  SystemAdmin,
  SystemAdminPolicy,
  Tag,
  TagUpdate,
  TargetSchema,
  TargetSchemaFilterInput,
  TargetSchemaManagerOutput,
  TargetSchemaOwnerOutput,
  TermOutput,
  TermResourceConnectInput,
  TermSchemaConnectInput,
  TransformationServiceFilterInput,
  TransformationServiceJobOutput,
  TransformationServiceOutput,
  UpdateAssetPoliciesInput,
  UpdateAssetPolicyInput,
  UpdateCustomPoliciesInput,
  UpdateCustomPolicyInput,
  UpdateDataAssetFileInput,
  UpdateDataAssetFileOutput,
  UpdateDataAssetInput,
  UpdateDataConsumersInput,
  UpdateDestinationInput,
  UpdateFinalDataProductGroupInput,
  UpdateFinalDataProductGroupOutput,
  UpdateGoalInput,
  UpdateGoalsInput,
  UpdateGovernanceInput,
  UpdateInputDataAssetGroupInput,
  UpdateInputDataAssetGroupOutput,
  UpdateLegalDocumentInput,
  UpdateLegalDocumentsInput,
  UpdateManagersInput,
  UpdateMonitoredPoliciesInput,
  UpdateOrganizationRoleInput,
  UpdateOutput,
  UpdateProjectGoalInput,
  UpdateProjectGoalsInput,
  UpdateProjectInput,
  UpdateProjectManagersInput,
  UpdateReferenceDocumentInput,
  UpdateReferenceDocumentsInput,
  UpdateResourceInput,
  UpdateRoleInput,
  UpdateSchemaInput,
  UpdateTagsInput,
  UpdateTargetSchemaInput,
  UpdateTargetSchemaOutput,
  UpdateTermInput,
  UpdateTransformationInput,
  UpdateWorkspaceInput,
  UpdateWorkspaceLogoInput,
  UpdateWorkspaceOutput,
  UpdateWorkspaceRoleScopeInput,
  UpdateWorkspaceSchemaInput,
  User,
  UserDataAssetOutput,
  UserOutput,
  ValidateTransformationsInProjectInput,
  Viewer,
  WarehouseServiceFilterInput,
  WarehouseServiceOutput,
  Workflow,
  WorkflowDataAssetGroupDataAssetOutput,
  WorkflowDataAssetGroupOutput,
  WorkflowDestinationFilterInput,
  WorkflowDestinationOutput,
  WorkflowFilterInput,
  WorkflowFinalDataProductGroupDataAsset,
  WorkflowFinalDataProductGroupDataAssetFilterInput,
  WorkflowFinalDataProductGroupFilterInput,
  WorkflowFinalDataProductGroupOutput,
  WorkflowInputDataAssetGroupDataAsset,
  WorkflowInputDataAssetGroupDataAssetFilterInput,
  WorkflowInputDataAssetGroupDataAssetSuggestion,
  WorkflowInputDataAssetGroupFilterInput,
  WorkflowInputDataAssetGroupOutput,
  WorkflowOrganizationContributor,
  WorkflowOrganizationFilterInput,
  WorkflowOrganizationOutput,
  WorkflowTransformationFilterInput,
  WorkflowTransformationJob,
  WorkflowTransformationOutput,
  Workspace,
  WorkspaceAdmin,
  WorkspaceInput,
  WorkspacePolicy,
  WorkspaceProjectFilterInput,
  WorkspaceResourceFilterInput,
  WorkspaceServices,
  AccessMode,
  AccessStatus,
  AirbyteConnectionScheduleType,
  AuthRole,
  AuthWorkspaceRole,
  DataProductType,
  DataType,
  DestinationType,
  Directory,
  DownloadFileFormat,
  FinalDataProductGroupCreationMethod,
  GovernanceType,
  IngestionServiceProvider,
  IngestionServiceStatus,
  InputDataAssetGroupCreationMethod,
  IssueStatus,
  IssueType,
  JobState,
  MemberStatus,
  OrganizationRoleEnum,
  PiiTagType,
  PiiType,
  PolicyCategory,
  PolicyType,
  ProjectOrganizationRole,
  ProjectStatus,
  ProjectUsage,
  RefreshRate,
  RefreshStatus,
  ResourceType,
  Role,
  RoleType,
  SchemaType,
  ScopeType,
  Sensitivity,
  ServiceStatus,
  SortDirection,
  SourceType,
  TargetSchemaStatus,
  TransformationMethod,
  TransformationServiceLanguage,
  TransformationServiceProvider,
  UploaderWriteMode,
  ValidationStatus,
  WarehouseServiceProvider,
  WorkflowIssueStatus,
  WorkflowNodeType,
  WorkflowState,
  WorkspaceRoleEnum,
} from "../../generated";

export const anAcceptWorkspacePolicyInput = (
  overrides?: Partial<AcceptWorkspacePolicyInput>
): AcceptWorkspacePolicyInput => {
  return {
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "93320fdc-da84-43d3-9552-c352d781160d",
  };
};

export const anAcceptWorkspacePolicyOutput = (
  overrides?: Partial<AcceptWorkspacePolicyOutput>
): {
  __typename: "AcceptWorkspacePolicyOutput";
} & AcceptWorkspacePolicyOutput => {
  return {
    __typename: "AcceptWorkspacePolicyOutput",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "eum",
  };
};

export const anAddContributingOrganizationToProjectInput = (
  overrides?: Partial<AddContributingOrganizationToProjectInput>
): AddContributingOrganizationToProjectInput => {
  return {
    organizationId:
      overrides && overrides.hasOwnProperty("organizationId")
        ? overrides.organizationId!
        : "f02abeee-67a0-4228-b4a0-f0ec1f436899",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "09205ca4-7354-42d6-b592-264f2c5cb49e",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "16cc45bd-beff-49ed-a31b-76472d46edab",
  };
};

export const anAddDataAssetsToInputDataAssetGroupInput = (
  overrides?: Partial<AddDataAssetsToInputDataAssetGroupInput>
): AddDataAssetsToInputDataAssetGroupInput => {
  return {
    dataAssetIds:
      overrides && overrides.hasOwnProperty("dataAssetIds")
        ? overrides.dataAssetIds!
        : ["d850bc6c-4e5c-4757-add7-92b6ee36e401"],
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "7f048ca6-462d-408c-a299-c09afe2dbda8",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "1e7486d7-7ecf-4e77-8111-7362c09f0dd4",
  };
};

export const anAddSourceInput = (
  overrides?: Partial<AddSourceInput>
): AddSourceInput => {
  return {
    connectionSchedule:
      overrides && overrides.hasOwnProperty("connectionSchedule")
        ? overrides.connectionSchedule!
        : anAirbyteConnectionSchedule(),
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "sint",
    documentationUrl:
      overrides && overrides.hasOwnProperty("documentationUrl")
        ? overrides.documentationUrl!
        : "facere",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "id",
    sourceConfigs:
      overrides && overrides.hasOwnProperty("sourceConfigs")
        ? overrides.sourceConfigs!
        : "cum",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "4affaec0-83a7-48eb-a7bc-a5d969208bb9",
  };
};

export const anAdminOrganization = (
  overrides?: Partial<AdminOrganization>
): { __typename: "AdminOrganization" } & AdminOrganization => {
  return {
    __typename: "AdminOrganization",
    adminGroup:
      overrides && overrides.hasOwnProperty("adminGroup")
        ? overrides.adminGroup!
        : anOrganizationAdmin(),
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "aliquid",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "omnis",
    generalUserGroup:
      overrides && overrides.hasOwnProperty("generalUserGroup")
        ? overrides.generalUserGroup!
        : aGeneralUser(),
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "0153be50-10ce-4730-bec3-0913c09a4474",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "doloremque",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "saepe",
    systemAdminGroup:
      overrides && overrides.hasOwnProperty("systemAdminGroup")
        ? overrides.systemAdminGroup!
        : aSystemAdmin(),
  };
};

export const anAirbyteConnectionSchedule = (
  overrides?: Partial<AirbyteConnectionSchedule>
): AirbyteConnectionSchedule => {
  return {
    cronExpression:
      overrides && overrides.hasOwnProperty("cronExpression")
        ? overrides.cronExpression!
        : "beatae",
    scheduleType:
      overrides && overrides.hasOwnProperty("scheduleType")
        ? overrides.scheduleType!
        : AirbyteConnectionScheduleType.Cron,
  };
};

export const anAssetPolicy = (
  overrides?: Partial<AssetPolicy>
): { __typename: "AssetPolicy" } & AssetPolicy => {
  return {
    __typename: "AssetPolicy",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "eveniet",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "distinctio",
    href:
      overrides && overrides.hasOwnProperty("href")
        ? overrides.href!
        : "nostrum",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "63308ec7-9d75-4aa8-9e79-e6f4edf8c5d6",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "enim",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "accusantium",
  };
};

export const aCollaborator = (
  overrides?: Partial<Collaborator>
): { __typename: "Collaborator" } & Collaborator => {
  return {
    __typename: "Collaborator",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "voluptatem",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "perspiciatis",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "ba39df1b-fe13-4f8a-8a9a-3a57f59b0895",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "in",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "voluptas",
    policy:
      overrides && overrides.hasOwnProperty("policy")
        ? overrides.policy!
        : aWorkspacePolicy(),
    users:
      overrides && overrides.hasOwnProperty("users")
        ? overrides.users!
        : [aUser()],
    workspace:
      overrides && overrides.hasOwnProperty("workspace")
        ? overrides.workspace!
        : aWorkspace(),
  };
};

export const aConfirmForgotPasswordInput = (
  overrides?: Partial<ConfirmForgotPasswordInput>
): ConfirmForgotPasswordInput => {
  return {
    confirmationCode:
      overrides && overrides.hasOwnProperty("confirmationCode")
        ? overrides.confirmationCode!
        : "est",
    password:
      overrides && overrides.hasOwnProperty("password")
        ? overrides.password!
        : "blanditiis",
    username:
      overrides && overrides.hasOwnProperty("username")
        ? overrides.username!
        : "et",
  };
};

export const aConfirmUserInput = (
  overrides?: Partial<ConfirmUserInput>
): ConfirmUserInput => {
  return {
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "voluptas",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "sunt",
    password:
      overrides && overrides.hasOwnProperty("password")
        ? overrides.password!
        : "et",
    session:
      overrides && overrides.hasOwnProperty("session")
        ? overrides.session!
        : "voluptatibus",
    username:
      overrides && overrides.hasOwnProperty("username")
        ? overrides.username!
        : "nihil",
  };
};

export const aCreateAssetPolicyInput = (
  overrides?: Partial<CreateAssetPolicyInput>
): CreateAssetPolicyInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "sequi",
    href:
      overrides && overrides.hasOwnProperty("href")
        ? overrides.href!
        : "laudantium",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "voluptatibus",
  };
};

export const aCreateCustomPolicyInput = (
  overrides?: Partial<CreateCustomPolicyInput>
): CreateCustomPolicyInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "libero",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "tempora",
    policyCategory:
      overrides && overrides.hasOwnProperty("policyCategory")
        ? overrides.policyCategory!
        : PolicyCategory.Access,
    referenceDocuments:
      overrides && overrides.hasOwnProperty("referenceDocuments")
        ? overrides.referenceDocuments!
        : [aCreateReferenceDocumentInput()],
  };
};

export const aCreateDataAssetInput = (
  overrides?: Partial<CreateDataAssetInput>
): CreateDataAssetInput => {
  return {
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "2b3981d0-2a02-4dd4-952e-d16bafb061f2",
  };
};

export const aCreateDataAssetOutput = (
  overrides?: Partial<CreateDataAssetOutput>
): { __typename: "CreateDataAssetOutput" } & CreateDataAssetOutput => {
  return {
    __typename: "CreateDataAssetOutput",
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "5dbb491b-a737-4af3-bd9b-b066e17c5072",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aCreateDestinationInput = (
  overrides?: Partial<CreateDestinationInput>
): CreateDestinationInput => {
  return {
    dataStorage:
      overrides && overrides.hasOwnProperty("dataStorage")
        ? overrides.dataStorage!
        : WarehouseServiceProvider.Redshift,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "quia",
    destinationType:
      overrides && overrides.hasOwnProperty("destinationType")
        ? overrides.destinationType!
        : DestinationType.Api,
    destinationUrl:
      overrides && overrides.hasOwnProperty("destinationUrl")
        ? overrides.destinationUrl!
        : "et",
    fileFormat:
      overrides && overrides.hasOwnProperty("fileFormat")
        ? overrides.fileFormat!
        : DownloadFileFormat.Csv,
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "dolorem",
    organizationId:
      overrides && overrides.hasOwnProperty("organizationId")
        ? overrides.organizationId!
        : "7c77361a-4a32-441c-934e-696c54c88b85",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "7fccd0d5-c5eb-44c7-b08f-18b2f3896f53",
    tableName:
      overrides && overrides.hasOwnProperty("tableName")
        ? overrides.tableName!
        : "illo",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "f44a1e89-8640-45e5-b3e0-0e0575007507",
  };
};

export const aCreateDestinationOutput = (
  overrides?: Partial<CreateDestinationOutput>
): { __typename: "CreateDestinationOutput" } & CreateDestinationOutput => {
  return {
    __typename: "CreateDestinationOutput",
    destinationId:
      overrides && overrides.hasOwnProperty("destinationId")
        ? overrides.destinationId!
        : "e9e1d262-9fba-4aee-b4cd-09eb24e7650f",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aCreateFinalDataProductGroupInput = (
  overrides?: Partial<CreateFinalDataProductGroupInput>
): CreateFinalDataProductGroupInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "voluptas",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "provident",
    method:
      overrides && overrides.hasOwnProperty("method")
        ? overrides.method!
        : FinalDataProductGroupCreationMethod.JsonUpload,
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "similique",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "f50ff3f4-b9c3-4065-80a8-4eb0bb5cca62",
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "43da6b80-8e13-49e2-9fb9-e6059e1236b0",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "a32819f7-164f-490a-b62b-7c688009d88b",
  };
};

export const aCreateFinalDataProductGroupOutput = (
  overrides?: Partial<CreateFinalDataProductGroupOutput>
): {
  __typename: "CreateFinalDataProductGroupOutput";
} & CreateFinalDataProductGroupOutput => {
  return {
    __typename: "CreateFinalDataProductGroupOutput",
    finalDataProductGroupId:
      overrides && overrides.hasOwnProperty("finalDataProductGroupId")
        ? overrides.finalDataProductGroupId!
        : "913e7c27-112f-4524-9a65-e36f85bce22e",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "e687116f-3f91-405e-843f-7b7a298e7a46",
  };
};

export const aCreateGoalInput = (
  overrides?: Partial<CreateGoalInput>
): CreateGoalInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "ut",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "hic",
  };
};

export const aCreateGovernanceDocumentInput = (
  overrides?: Partial<CreateGovernanceDocumentInput>
): CreateGovernanceDocumentInput => {
  return {
    href:
      overrides && overrides.hasOwnProperty("href")
        ? overrides.href!
        : "dolorum",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "est",
  };
};

export const aCreateGovernanceGoalInput = (
  overrides?: Partial<CreateGovernanceGoalInput>
): CreateGovernanceGoalInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "odit",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "sit",
  };
};

export const aCreateGovernanceInput = (
  overrides?: Partial<CreateGovernanceInput>
): CreateGovernanceInput => {
  return {
    customPolicies:
      overrides && overrides.hasOwnProperty("customPolicies")
        ? overrides.customPolicies!
        : [aCreateGovernancePolicyInput()],
    goals:
      overrides && overrides.hasOwnProperty("goals")
        ? overrides.goals!
        : [aCreateGovernanceGoalInput()],
    governanceType:
      overrides && overrides.hasOwnProperty("governanceType")
        ? overrides.governanceType!
        : GovernanceType.Custom,
    legalDocuments:
      overrides && overrides.hasOwnProperty("legalDocuments")
        ? overrides.legalDocuments!
        : [aCreateGovernanceDocumentInput()],
    monitoredPolicies:
      overrides && overrides.hasOwnProperty("monitoredPolicies")
        ? overrides.monitoredPolicies!
        : [aCreateGovernancePolicyInput()],
    referenceDocuments:
      overrides && overrides.hasOwnProperty("referenceDocuments")
        ? overrides.referenceDocuments!
        : [aCreateGovernanceDocumentInput()],
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "2adb3c8e-9dd6-4ee2-bdf3-6dafe2f494e9",
  };
};

export const aCreateGovernancePolicyInput = (
  overrides?: Partial<CreateGovernancePolicyInput>
): CreateGovernancePolicyInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "ratione",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "porro",
    policyCategory:
      overrides && overrides.hasOwnProperty("policyCategory")
        ? overrides.policyCategory!
        : PolicyCategory.Access,
    policyType:
      overrides && overrides.hasOwnProperty("policyType")
        ? overrides.policyType!
        : PolicyType.Custom,
    referenceDocuments:
      overrides && overrides.hasOwnProperty("referenceDocuments")
        ? overrides.referenceDocuments!
        : [aCreateGovernanceDocumentInput()],
  };
};

export const aCreateIngestionServiceInput = (
  overrides?: Partial<CreateIngestionServiceInput>
): CreateIngestionServiceInput => {
  return {
    configurations:
      overrides && overrides.hasOwnProperty("configurations")
        ? overrides.configurations!
        : "et",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "qui",
    managerId:
      overrides && overrides.hasOwnProperty("managerId")
        ? overrides.managerId!
        : "92075d66-d96e-4e18-b227-13f762435958",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "asperiores",
    provider:
      overrides && overrides.hasOwnProperty("provider")
        ? overrides.provider!
        : IngestionServiceProvider.AirbyteCloud,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "de3164be-969f-4e18-bb1d-6ad759e52c6f",
  };
};

export const aCreateIngestionServiceOutput = (
  overrides?: Partial<CreateIngestionServiceOutput>
): {
  __typename: "CreateIngestionServiceOutput";
} & CreateIngestionServiceOutput => {
  return {
    __typename: "CreateIngestionServiceOutput",
    ingestionServiceId:
      overrides && overrides.hasOwnProperty("ingestionServiceId")
        ? overrides.ingestionServiceId!
        : "be986568-2752-4741-8ba2-286a8e66cb4a",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aCreateInputDataAssetGroupInput = (
  overrides?: Partial<CreateInputDataAssetGroupInput>
): CreateInputDataAssetGroupInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "nihil",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "commodi",
    method:
      overrides && overrides.hasOwnProperty("method")
        ? overrides.method!
        : InputDataAssetGroupCreationMethod.JsonUpload,
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "qui",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "edf131e0-39a8-42f9-b864-79d035fff9f8",
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "5a1f30a1-3e5c-4aeb-a7f6-4d1306e8b5f1",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "fc0afcc9-4770-4c8d-8920-fd54eacbeb2e",
  };
};

export const aCreateInputDataAssetGroupOutput = (
  overrides?: Partial<CreateInputDataAssetGroupOutput>
): {
  __typename: "CreateInputDataAssetGroupOutput";
} & CreateInputDataAssetGroupOutput => {
  return {
    __typename: "CreateInputDataAssetGroupOutput",
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "914f4ac9-13bf-430a-acff-454837f17dc0",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "3772a160-da9a-483c-aece-a0835b688886",
  };
};

export const aCreateLegalDocumentInput = (
  overrides?: Partial<CreateLegalDocumentInput>
): CreateLegalDocumentInput => {
  return {
    href:
      overrides && overrides.hasOwnProperty("href")
        ? overrides.href!
        : "perspiciatis",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "est",
  };
};

export const aCreateOrganizationInput = (
  overrides?: Partial<CreateOrganizationInput>
): CreateOrganizationInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "deleniti",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "dolores",
  };
};

export const aCreateOrganizationOutput = (
  overrides?: Partial<CreateOrganizationOutput>
): { __typename: "CreateOrganizationOutput" } & CreateOrganizationOutput => {
  return {
    __typename: "CreateOrganizationOutput",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "animi",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "perferendis",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "9a0bf7f0-5142-4c80-8988-6e3a04fd9575",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "quia",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "quod",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aCreateProjectGoalInput = (
  overrides?: Partial<CreateProjectGoalInput>
): CreateProjectGoalInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "sint",
    metrics:
      overrides && overrides.hasOwnProperty("metrics")
        ? overrides.metrics!
        : ["quisquam"],
  };
};

export const aCreateProjectInput = (
  overrides?: Partial<CreateProjectInput>
): CreateProjectInput => {
  return {
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "28468e23-7c58-482c-aa7e-0876b85d5000",
  };
};

export const aCreateProjectOutput = (
  overrides?: Partial<CreateProjectOutput>
): { __typename: "CreateProjectOutput" } & CreateProjectOutput => {
  return {
    __typename: "CreateProjectOutput",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "8e3fb3c8-756d-4cd5-9ab5-55ca259720d1",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aCreateReferenceDocumentInput = (
  overrides?: Partial<CreateReferenceDocumentInput>
): CreateReferenceDocumentInput => {
  return {
    href:
      overrides && overrides.hasOwnProperty("href")
        ? overrides.href!
        : "dignissimos",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "laudantium",
  };
};

export const aCreateResourceFolderInput = (
  overrides?: Partial<CreateResourceFolderInput>
): CreateResourceFolderInput => {
  return {
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "omnis",
    parentId:
      overrides && overrides.hasOwnProperty("parentId")
        ? overrides.parentId!
        : "dd221950-706c-42b3-a892-4a3b006641eb",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "de947e25-790a-47f3-887d-b05b98f43adb",
  };
};

export const aCreateResourceFolderOutput = (
  overrides?: Partial<CreateResourceFolderOutput>
): {
  __typename: "CreateResourceFolderOutput";
} & CreateResourceFolderOutput => {
  return {
    __typename: "CreateResourceFolderOutput",
    resourceFolderId:
      overrides && overrides.hasOwnProperty("resourceFolderId")
        ? overrides.resourceFolderId!
        : "f881b9f8-a689-4a59-bbbb-b18ccafb9b70",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aCreateResourceInput = (
  overrides?: Partial<CreateResourceInput>
): CreateResourceInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "iste",
    internal:
      overrides && overrides.hasOwnProperty("internal")
        ? overrides.internal!
        : false,
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "soluta",
    parentId:
      overrides && overrides.hasOwnProperty("parentId")
        ? overrides.parentId!
        : "e81a6fcb-8616-49ea-9267-699c3f564778",
    path:
      overrides && overrides.hasOwnProperty("path") ? overrides.path! : "et",
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["fugiat"],
    type:
      overrides && overrides.hasOwnProperty("type")
        ? overrides.type!
        : ResourceType.Document,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "8b6b55c6-0bc4-44e7-b341-5a0ce66f9b80",
  };
};

export const aCreateResourceOutput = (
  overrides?: Partial<CreateResourceOutput>
): { __typename: "CreateResourceOutput" } & CreateResourceOutput => {
  return {
    __typename: "CreateResourceOutput",
    resourceId:
      overrides && overrides.hasOwnProperty("resourceId")
        ? overrides.resourceId!
        : "f8f4aa6e-fea9-486d-9a39-f6ec197a52b0",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aCreateSchemaInput = (
  overrides?: Partial<CreateSchemaInput>
): CreateSchemaInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "fugit",
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "b585840a-234c-4b4c-9130-3f9eae6aa506",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "repellendus",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "cupiditate",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "eda7a759-dd90-4708-afb2-9e99f3e6c45d",
    scope:
      overrides && overrides.hasOwnProperty("scope")
        ? overrides.scope!
        : ScopeType.DataAsset,
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : TargetSchemaStatus.Draft,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "49db83c0-4f7c-4a10-97f7-16217172f45d",
  };
};

export const aCreateSchemaOutput = (
  overrides?: Partial<CreateSchemaOutput>
): { __typename: "CreateSchemaOutput" } & CreateSchemaOutput => {
  return {
    __typename: "CreateSchemaOutput",
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "0a8bee91-3a44-44d3-adaa-8191e8d2962a",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aCreateTargetSchemaInput = (
  overrides?: Partial<CreateTargetSchemaInput>
): CreateTargetSchemaInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "dolores",
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "822000a6-3fde-4c05-b010-0049c6c1de79",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "adipisci",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "vitae",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "75a2ff6a-158a-4b47-b8ca-c9ebd1e69832",
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : TargetSchemaStatus.Draft,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "255a178c-d5dc-45d1-84c5-61d32fdc2716",
  };
};

export const aCreateTargetSchemaOutput = (
  overrides?: Partial<CreateTargetSchemaOutput>
): { __typename: "CreateTargetSchemaOutput" } & CreateTargetSchemaOutput => {
  return {
    __typename: "CreateTargetSchemaOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "1ec84d59-d520-4500-a25c-6eee308bc37f",
  };
};

export const aCreateTermInput = (
  overrides?: Partial<CreateTermInput>
): CreateTermInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "voluptatem",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "aspernatur",
    scope:
      overrides && overrides.hasOwnProperty("scope")
        ? overrides.scope!
        : ScopeType.DataAsset,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["labore"],
    type:
      overrides && overrides.hasOwnProperty("type") ? overrides.type! : "et",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "878f1804-1fb7-4e72-9ce7-890e42bff7b7",
  };
};

export const aCreateTermOutput = (
  overrides?: Partial<CreateTermOutput>
): { __typename: "CreateTermOutput" } & CreateTermOutput => {
  return {
    __typename: "CreateTermOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aCreateTransformationInput = (
  overrides?: Partial<CreateTransformationInput>
): CreateTransformationInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "voluptatem",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "quia",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "0a56b880-4f15-4ff6-9f30-65af468ceb58",
    sourceUrl:
      overrides && overrides.hasOwnProperty("sourceUrl")
        ? overrides.sourceUrl!
        : "rem",
    transformationMethod:
      overrides && overrides.hasOwnProperty("transformationMethod")
        ? overrides.transformationMethod!
        : TransformationMethod.DbtCloud,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "8bd4c40c-2615-44c0-b548-c37bcd594476",
  };
};

export const aCreateTransformationOutput = (
  overrides?: Partial<CreateTransformationOutput>
): {
  __typename: "CreateTransformationOutput";
} & CreateTransformationOutput => {
  return {
    __typename: "CreateTransformationOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
    transformationId:
      overrides && overrides.hasOwnProperty("transformationId")
        ? overrides.transformationId!
        : "9dae5635-7487-4e2f-936f-6f464785cfa2",
  };
};

export const aCreateTransformationServiceInput = (
  overrides?: Partial<CreateTransformationServiceInput>
): CreateTransformationServiceInput => {
  return {
    accountId:
      overrides && overrides.hasOwnProperty("accountId")
        ? overrides.accountId!
        : "quis",
    apiKey:
      overrides && overrides.hasOwnProperty("apiKey")
        ? overrides.apiKey!
        : "soluta",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "dignissimos",
    managerId:
      overrides && overrides.hasOwnProperty("managerId")
        ? overrides.managerId!
        : "f0c11108-ba6a-487d-9230-d38acfe56946",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "eum",
    provider:
      overrides && overrides.hasOwnProperty("provider")
        ? overrides.provider!
        : TransformationServiceProvider.DbtCloud,
    warehouseServiceId:
      overrides && overrides.hasOwnProperty("warehouseServiceId")
        ? overrides.warehouseServiceId!
        : "2c69eeb0-863a-4eef-938b-d11fb3c384fc",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "3e80cad8-519e-421e-b9ae-cef30fad680a",
  };
};

export const aCreateTransformationServiceOutput = (
  overrides?: Partial<CreateTransformationServiceOutput>
): {
  __typename: "CreateTransformationServiceOutput";
} & CreateTransformationServiceOutput => {
  return {
    __typename: "CreateTransformationServiceOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
    transformationServiceId:
      overrides && overrides.hasOwnProperty("transformationServiceId")
        ? overrides.transformationServiceId!
        : "35b515dc-c10a-415c-8f81-ddf00686954a",
  };
};

export const aCreateUserInput = (
  overrides?: Partial<CreateUserInput>
): CreateUserInput => {
  return {
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "recusandae",
    organizationId:
      overrides && overrides.hasOwnProperty("organizationId")
        ? overrides.organizationId!
        : "6450eb5d-2ac8-4577-9282-69275f9ccbe9",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "d3764f7c-f16c-4c96-87f3-e7e82b61cf2a",
    workspaceRole:
      overrides && overrides.hasOwnProperty("workspaceRole")
        ? overrides.workspaceRole!
        : WorkspaceRoleEnum.Admin,
  };
};

export const aCreateUserOrganizationInput = (
  overrides?: Partial<CreateUserOrganizationInput>
): CreateUserOrganizationInput => {
  return {
    connect:
      overrides && overrides.hasOwnProperty("connect")
        ? overrides.connect!
        : "d18d6695-3341-43d1-9885-dee8fb1aa4dc",
    create:
      overrides && overrides.hasOwnProperty("create")
        ? overrides.create!
        : aCreateOrganizationInput(),
  };
};

export const aCreateUserOutput = (
  overrides?: Partial<CreateUserOutput>
): { __typename: "CreateUserOutput" } & CreateUserOutput => {
  return {
    __typename: "CreateUserOutput",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "voluptatibus",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "0267d195-3214-4e2c-8b81-fe8d1d6d33cd",
    organizationId:
      overrides && overrides.hasOwnProperty("organizationId")
        ? overrides.organizationId!
        : "14a1635a-425c-4225-a2b0-73b9d5346548",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aCreateWarehouseServiceInput = (
  overrides?: Partial<CreateWarehouseServiceInput>
): CreateWarehouseServiceInput => {
  return {
    configurations:
      overrides && overrides.hasOwnProperty("configurations")
        ? overrides.configurations!
        : "qui",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "nihil",
    managed:
      overrides && overrides.hasOwnProperty("managed")
        ? overrides.managed!
        : false,
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "voluptatem",
    provider:
      overrides && overrides.hasOwnProperty("provider")
        ? overrides.provider!
        : WarehouseServiceProvider.Redshift,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "3a1d125e-7533-4e7c-a290-f10937d5db0c",
  };
};

export const aCreateWarehouseServiceOutput = (
  overrides?: Partial<CreateWarehouseServiceOutput>
): {
  __typename: "CreateWarehouseServiceOutput";
} & CreateWarehouseServiceOutput => {
  return {
    __typename: "CreateWarehouseServiceOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
    warehouseServiceId:
      overrides && overrides.hasOwnProperty("warehouseServiceId")
        ? overrides.warehouseServiceId!
        : "19d0c3cd-6c41-4c02-8c77-8bf8f75255b0",
  };
};

export const aCreateWorkflowConnectionInput = (
  overrides?: Partial<CreateWorkflowConnectionInput>
): CreateWorkflowConnectionInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "8df8fdb0-7b0a-413d-9daa-b7af273b8cf7",
    sourceId:
      overrides && overrides.hasOwnProperty("sourceId")
        ? overrides.sourceId!
        : "5f699a0b-5de4-497a-9786-b395baa08c9a",
    sourceNodeType:
      overrides && overrides.hasOwnProperty("sourceNodeType")
        ? overrides.sourceNodeType!
        : WorkflowNodeType.Destination,
    targetId:
      overrides && overrides.hasOwnProperty("targetId")
        ? overrides.targetId!
        : "5e1c9367-3132-4df4-b276-2f648af88f65",
    targetNodeType:
      overrides && overrides.hasOwnProperty("targetNodeType")
        ? overrides.targetNodeType!
        : WorkflowNodeType.Destination,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "e1002356-b9e0-443c-85c4-e2ee55811fbe",
  };
};

export const aCreateWorkspaceInput = (
  overrides?: Partial<CreateWorkspaceInput>
): CreateWorkspaceInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "eum",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "non",
    ownerOrganization:
      overrides && overrides.hasOwnProperty("ownerOrganization")
        ? overrides.ownerOrganization!
        : aCreateWorkspaceOwnerOrganizationInput(),
  };
};

export const aCreateWorkspaceOutput = (
  overrides?: Partial<CreateWorkspaceOutput>
): { __typename: "CreateWorkspaceOutput" } & CreateWorkspaceOutput => {
  return {
    __typename: "CreateWorkspaceOutput",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "omnis",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "dolor",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "dc950678-572d-4c7b-8f4d-a5ff6456955e",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "impedit",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "at",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aCreateWorkspaceOwnerOrganizationInput = (
  overrides?: Partial<CreateWorkspaceOwnerOrganizationInput>
): CreateWorkspaceOwnerOrganizationInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "voluptate",
    member:
      overrides && overrides.hasOwnProperty("member")
        ? overrides.member!
        : aCreateWorkspaceOwnerOrganizationMemberInput(),
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "minima",
  };
};

export const aCreateWorkspaceOwnerOrganizationMemberInput = (
  overrides?: Partial<CreateWorkspaceOwnerOrganizationMemberInput>
): CreateWorkspaceOwnerOrganizationMemberInput => {
  return {
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "voluptatem",
  };
};

export const aCreatedDataProductFilterInput = (
  overrides?: Partial<CreatedDataProductFilterInput>
): CreatedDataProductFilterInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "e0895615-5e2f-41f1-8335-866d5376a03d",
  };
};

export const aCreatedFinalProduct = (
  overrides?: Partial<CreatedFinalProduct>
): { __typename: "CreatedFinalProduct" } & CreatedFinalProduct => {
  return {
    __typename: "CreatedFinalProduct",
    connectionType:
      overrides && overrides.hasOwnProperty("connectionType")
        ? overrides.connectionType!
        : "odit",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "ut",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "dolor",
    finalDataProductGroup:
      overrides && overrides.hasOwnProperty("finalDataProductGroup")
        ? overrides.finalDataProductGroup!
        : aCreatedFinalProductFinalDataProductGroupOutput(),
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "e26ccede-0643-41d0-a2eb-56d4afba9b01",
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : [aFinalDataProductManagerOutput()],
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "id",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "id",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : aFinalDataProductOwnerOutput(),
    piiTypes:
      overrides && overrides.hasOwnProperty("piiTypes")
        ? overrides.piiTypes!
        : ["ut"],
    refreshRate:
      overrides && overrides.hasOwnProperty("refreshRate")
        ? overrides.refreshRate!
        : RefreshRate.Daily,
    sensitivity:
      overrides && overrides.hasOwnProperty("sensitivity")
        ? overrides.sensitivity!
        : Sensitivity.Tier_1,
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : WorkflowIssueStatus.Incomplete,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["aspernatur"],
  };
};

export const aCreatedFinalProductFinalDataProductGroupOutput = (
  overrides?: Partial<CreatedFinalProductFinalDataProductGroupOutput>
): {
  __typename: "CreatedFinalProductFinalDataProductGroupOutput";
} & CreatedFinalProductFinalDataProductGroupOutput => {
  return {
    __typename: "CreatedFinalProductFinalDataProductGroupOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "d620857a-beb0-4fb4-9061-9be4f8f9df8b",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "occaecati",
  };
};

export const aCurrentUserOrganizationGroupOutput = (
  overrides?: Partial<CurrentUserOrganizationGroupOutput>
): {
  __typename: "CurrentUserOrganizationGroupOutput";
} & CurrentUserOrganizationGroupOutput => {
  return {
    __typename: "CurrentUserOrganizationGroupOutput",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "numquam",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "excepturi",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "b7ee3cdd-ffb4-4b93-ac52-fa702ebec2fd",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "animi",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "minima",
    users:
      overrides && overrides.hasOwnProperty("users")
        ? overrides.users!
        : [aCurrentUserOrganizationGroupUserOutput()],
  };
};

export const aCurrentUserOrganizationGroupUserOutput = (
  overrides?: Partial<CurrentUserOrganizationGroupUserOutput>
): {
  __typename: "CurrentUserOrganizationGroupUserOutput";
} & CurrentUserOrganizationGroupUserOutput => {
  return {
    __typename: "CurrentUserOrganizationGroupUserOutput",
    active:
      overrides && overrides.hasOwnProperty("active")
        ? overrides.active!
        : false,
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "autem",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "dolores",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "temporibus",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "d085626c-4250-47d0-ad04-794d1b2e8921",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "minus",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "eos",
  };
};

export const aCurrentUserOrganizationMemberOutput = (
  overrides?: Partial<CurrentUserOrganizationMemberOutput>
): {
  __typename: "CurrentUserOrganizationMemberOutput";
} & CurrentUserOrganizationMemberOutput => {
  return {
    __typename: "CurrentUserOrganizationMemberOutput",
    assetManagedCount:
      overrides && overrides.hasOwnProperty("assetManagedCount")
        ? overrides.assetManagedCount!
        : 4809,
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "veritatis",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "voluptatem",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "cc9aa9be-7fed-4a85-9564-a664a9003dd0",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "consectetur",
    organizationRole:
      overrides && overrides.hasOwnProperty("organizationRole")
        ? overrides.organizationRole!
        : OrganizationRoleEnum.Admin,
    projectManagedCount:
      overrides && overrides.hasOwnProperty("projectManagedCount")
        ? overrides.projectManagedCount!
        : 928,
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : false,
    workspaceCount:
      overrides && overrides.hasOwnProperty("workspaceCount")
        ? overrides.workspaceCount!
        : 8592,
  };
};

export const aCurrentUserOrganizationOutput = (
  overrides?: Partial<CurrentUserOrganizationOutput>
): {
  __typename: "CurrentUserOrganizationOutput";
} & CurrentUserOrganizationOutput => {
  return {
    __typename: "CurrentUserOrganizationOutput",
    adminGroup:
      overrides && overrides.hasOwnProperty("adminGroup")
        ? overrides.adminGroup!
        : aCurrentUserOrganizationGroupOutput(),
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "quae",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "laborum",
    externalWorkspaces:
      overrides && overrides.hasOwnProperty("externalWorkspaces")
        ? overrides.externalWorkspaces!
        : [aCurrentUserWorkspaceOutput()],
    generalUserGroup:
      overrides && overrides.hasOwnProperty("generalUserGroup")
        ? overrides.generalUserGroup!
        : aCurrentUserOrganizationGroupOutput(),
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "a3c2e167-f08d-4454-ad11-96fdc992ebf8",
    isEditable:
      overrides && overrides.hasOwnProperty("isEditable")
        ? overrides.isEditable!
        : false,
    members:
      overrides && overrides.hasOwnProperty("members")
        ? overrides.members!
        : [aCurrentUserOrganizationMemberOutput()],
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "aut",
    ownedWorkspaces:
      overrides && overrides.hasOwnProperty("ownedWorkspaces")
        ? overrides.ownedWorkspaces!
        : [aCurrentUserWorkspaceOutput()],
    terms:
      overrides && overrides.hasOwnProperty("terms")
        ? overrides.terms!
        : [aTermOutput()],
  };
};

export const aCurrentUserOutput = (
  overrides?: Partial<CurrentUserOutput>
): { __typename: "CurrentUserOutput" } & CurrentUserOutput => {
  return {
    __typename: "CurrentUserOutput",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "recusandae",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "vel",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "88e9131b-0f56-47df-8b82-73b26a9b7891",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "corporis",
    organization:
      overrides && overrides.hasOwnProperty("organization")
        ? overrides.organization!
        : aCurrentUserOrganizationOutput(),
    workspaceRole:
      overrides && overrides.hasOwnProperty("workspaceRole")
        ? overrides.workspaceRole!
        : WorkspaceRoleEnum.Admin,
    workspaces:
      overrides && overrides.hasOwnProperty("workspaces")
        ? overrides.workspaces!
        : [aCurrentUserWorkspaceOutput()],
  };
};

export const aCurrentUserWorkspaceFilterInput = (
  overrides?: Partial<CurrentUserWorkspaceFilterInput>
): CurrentUserWorkspaceFilterInput => {
  return {
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "10d5bdad-5cb4-47ee-af1a-2a6ec11d4089",
  };
};

export const aCurrentUserWorkspaceOutput = (
  overrides?: Partial<CurrentUserWorkspaceOutput>
): {
  __typename: "CurrentUserWorkspaceOutput";
} & CurrentUserWorkspaceOutput => {
  return {
    __typename: "CurrentUserWorkspaceOutput",
    acceptedPolicies:
      overrides && overrides.hasOwnProperty("acceptedPolicies")
        ? overrides.acceptedPolicies!
        : true,
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "82586091-e756-443a-b118-c4c5d42b132d",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "et",
    ownerOrganizationName:
      overrides && overrides.hasOwnProperty("ownerOrganizationName")
        ? overrides.ownerOrganizationName!
        : "provident",
    policies:
      overrides && overrides.hasOwnProperty("policies")
        ? overrides.policies!
        : [aCurrentUserWorkspacePolicy()],
  };
};

export const aCurrentUserWorkspacePolicy = (
  overrides?: Partial<CurrentUserWorkspacePolicy>
): {
  __typename: "CurrentUserWorkspacePolicy";
} & CurrentUserWorkspacePolicy => {
  return {
    __typename: "CurrentUserWorkspacePolicy",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "animi",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "debitis",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "ipsum",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "aut",
  };
};

export const aCurrentUserWorkspaceRoleFilter = (
  overrides?: Partial<CurrentUserWorkspaceRoleFilter>
): CurrentUserWorkspaceRoleFilter => {
  return {
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "730929fb-1740-49f8-9e5a-21d49ef259c5",
  };
};

export const aDataAsset = (
  overrides?: Partial<DataAsset>
): { __typename: "DataAsset" } & DataAsset => {
  return {
    __typename: "DataAsset",
    accessStatus:
      overrides && overrides.hasOwnProperty("accessStatus")
        ? overrides.accessStatus!
        : AccessStatus.Private,
    assetPolicies:
      overrides && overrides.hasOwnProperty("assetPolicies")
        ? overrides.assetPolicies!
        : [anAssetPolicy()],
    assetSize:
      overrides && overrides.hasOwnProperty("assetSize")
        ? overrides.assetSize!
        : "totam",
    childAssetCount:
      overrides && overrides.hasOwnProperty("childAssetCount")
        ? overrides.childAssetCount!
        : 4963,
    connectionDescription:
      overrides && overrides.hasOwnProperty("connectionDescription")
        ? overrides.connectionDescription!
        : "dignissimos",
    connectionType:
      overrides && overrides.hasOwnProperty("connectionType")
        ? overrides.connectionType!
        : "quo",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "voluptas",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "minus",
    fields:
      overrides && overrides.hasOwnProperty("fields")
        ? overrides.fields!
        : [aField()],
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "93449442-a181-468d-a0d2-ff4450f8abc9",
    isEditable:
      overrides && overrides.hasOwnProperty("isEditable")
        ? overrides.isEditable!
        : false,
    issues:
      overrides && overrides.hasOwnProperty("issues")
        ? overrides.issues!
        : [anIssue()],
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : [aUserDataAssetOutput()],
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "fugiat",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : anOrganizationDataAssetOutput(),
    piiTypes:
      overrides && overrides.hasOwnProperty("piiTypes")
        ? overrides.piiTypes!
        : [PiiType.None],
    projectCount:
      overrides && overrides.hasOwnProperty("projectCount")
        ? overrides.projectCount!
        : 2991,
    projects:
      overrides && overrides.hasOwnProperty("projects")
        ? overrides.projects!
        : [aDataAssetProjectOutput()],
    redshiftTableName:
      overrides && overrides.hasOwnProperty("redshiftTableName")
        ? overrides.redshiftTableName!
        : "ab",
    refreshRate:
      overrides && overrides.hasOwnProperty("refreshRate")
        ? overrides.refreshRate!
        : RefreshRate.Daily,
    refreshStatus:
      overrides && overrides.hasOwnProperty("refreshStatus")
        ? overrides.refreshStatus!
        : RefreshStatus.Current,
    sensitivity:
      overrides && overrides.hasOwnProperty("sensitivity")
        ? overrides.sensitivity!
        : Sensitivity.Tier_1,
    snowflakeTableName:
      overrides && overrides.hasOwnProperty("snowflakeTableName")
        ? overrides.snowflakeTableName!
        : "blanditiis",
    source:
      overrides && overrides.hasOwnProperty("source")
        ? overrides.source!
        : "ut",
    storageService:
      overrides && overrides.hasOwnProperty("storageService")
        ? overrides.storageService!
        : "nisi",
    tableName:
      overrides && overrides.hasOwnProperty("tableName")
        ? overrides.tableName!
        : "et",
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["consequatur"],
    updatedAt:
      overrides && overrides.hasOwnProperty("updatedAt")
        ? overrides.updatedAt!
        : "corrupti",
    validationStatus:
      overrides && overrides.hasOwnProperty("validationStatus")
        ? overrides.validationStatus!
        : ValidationStatus.Invalid,
  };
};

export const aDataAssetEntityInput = (
  overrides?: Partial<DataAssetEntityInput>
): DataAssetEntityInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "e94c80a2-b95b-4cb7-afce-01392dcbbabe",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "82b07c34-f686-417b-aaf6-d024dee80848",
  };
};

export const aDataAssetFilterInput = (
  overrides?: Partial<DataAssetFilterInput>
): DataAssetFilterInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "811f9b39-44ec-436f-a697-d53d07ee76bf",
  };
};

export const aDataAssetInput = (
  overrides?: Partial<DataAssetInput>
): DataAssetInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "caa3e39a-4ee4-4201-bdcb-e8f9423858a8",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "ec2b5faf-c5f7-44e8-b668-8e17e3b0b656",
  };
};

export const aDataAssetOutput = (
  overrides?: Partial<DataAssetOutput>
): { __typename: "DataAssetOutput" } & DataAssetOutput => {
  return {
    __typename: "DataAssetOutput",
    accessStatus:
      overrides && overrides.hasOwnProperty("accessStatus")
        ? overrides.accessStatus!
        : AccessStatus.Private,
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "labore",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "praesentium",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "eaba7971-3c95-4ca9-a891-1da680d62ed9",
    manager:
      overrides && overrides.hasOwnProperty("manager")
        ? overrides.manager!
        : aUserOutput(),
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "qui",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "occaecati",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : anOrganizationOutput(),
    sensitivity:
      overrides && overrides.hasOwnProperty("sensitivity")
        ? overrides.sensitivity!
        : Sensitivity.Tier_1,
  };
};

export const aDataAssetProjectOutput = (
  overrides?: Partial<DataAssetProjectOutput>
): { __typename: "DataAssetProjectOutput" } & DataAssetProjectOutput => {
  return {
    __typename: "DataAssetProjectOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "bbef1309-c420-40ea-91bc-ae466af5abec",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "ducimus",
  };
};

export const aDataAssetsInput = (
  overrides?: Partial<DataAssetsInput>
): DataAssetsInput => {
  return {
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "6c07b84c-eb94-422b-aa1e-899f9221abe0",
  };
};

export const aDeactivateOrganizationUserInput = (
  overrides?: Partial<DeactivateOrganizationUserInput>
): DeactivateOrganizationUserInput => {
  return {
    userId:
      overrides && overrides.hasOwnProperty("userId")
        ? overrides.userId!
        : "voluptates",
  };
};

export const aDeactivateOrganizationUserOutput = (
  overrides?: Partial<DeactivateOrganizationUserOutput>
): {
  __typename: "DeactivateOrganizationUserOutput";
} & DeactivateOrganizationUserOutput => {
  return {
    __typename: "DeactivateOrganizationUserOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aDeleteDestinationInput = (
  overrides?: Partial<DeleteDestinationInput>
): DeleteDestinationInput => {
  return {
    destinationId:
      overrides && overrides.hasOwnProperty("destinationId")
        ? overrides.destinationId!
        : "585177d4-61fc-4c3e-805f-4f646aaeb055",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "71319f82-32c1-4584-9432-28b98fc2026b",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "53d11295-14ea-41ba-83ef-80bb133d081e",
  };
};

export const aDeleteDestinationOutput = (
  overrides?: Partial<DeleteDestinationOutput>
): { __typename: "DeleteDestinationOutput" } & DeleteDestinationOutput => {
  return {
    __typename: "DeleteDestinationOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aDeleteFinalDataProductGroupInput = (
  overrides?: Partial<DeleteFinalDataProductGroupInput>
): DeleteFinalDataProductGroupInput => {
  return {
    finalDataProductGroupId:
      overrides && overrides.hasOwnProperty("finalDataProductGroupId")
        ? overrides.finalDataProductGroupId!
        : "1a59c37d-63b7-4f77-a0fc-ba4507844ce0",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "19df5e6e-d415-44ef-ab97-1834996ba405",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "39606dc5-4969-4306-8d7b-71f98ef5e785",
  };
};

export const aDeleteFinalDataProductGroupOutput = (
  overrides?: Partial<DeleteFinalDataProductGroupOutput>
): {
  __typename: "DeleteFinalDataProductGroupOutput";
} & DeleteFinalDataProductGroupOutput => {
  return {
    __typename: "DeleteFinalDataProductGroupOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aDeleteInputDataAssetGroupInput = (
  overrides?: Partial<DeleteInputDataAssetGroupInput>
): DeleteInputDataAssetGroupInput => {
  return {
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "76db73d7-e94d-48dc-bb22-5a92a84a8fa5",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "3df4f99f-0f83-4c83-bef5-a4249d01f68b",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "b7530640-0760-4b9d-9421-b6dbbc3963fb",
  };
};

export const aDeleteInputDataAssetGroupOutput = (
  overrides?: Partial<DeleteInputDataAssetGroupOutput>
): {
  __typename: "DeleteInputDataAssetGroupOutput";
} & DeleteInputDataAssetGroupOutput => {
  return {
    __typename: "DeleteInputDataAssetGroupOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aDeleteResourceInput = (
  overrides?: Partial<DeleteResourceInput>
): DeleteResourceInput => {
  return {
    resourceId:
      overrides && overrides.hasOwnProperty("resourceId")
        ? overrides.resourceId!
        : "2d782c59-c316-4b46-b57a-d786fb4e41d3",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "f7995204-2404-4164-9eda-f5e97eb0d2fb",
  };
};

export const aDeleteResourceOutput = (
  overrides?: Partial<DeleteResourceOutput>
): { __typename: "DeleteResourceOutput" } & DeleteResourceOutput => {
  return {
    __typename: "DeleteResourceOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aDeleteTermInput = (
  overrides?: Partial<DeleteTermInput>
): DeleteTermInput => {
  return {
    termId:
      overrides && overrides.hasOwnProperty("termId")
        ? overrides.termId!
        : "8ae2f6a1-16f4-4ad7-8ab4-592685ad87b5",
  };
};

export const aDeleteTermOutput = (
  overrides?: Partial<DeleteTermOutput>
): { __typename: "DeleteTermOutput" } & DeleteTermOutput => {
  return {
    __typename: "DeleteTermOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aDisableUserInput = (
  overrides?: Partial<DisableUserInput>
): DisableUserInput => {
  return {
    userId:
      overrides && overrides.hasOwnProperty("userId")
        ? overrides.userId!
        : "sint",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "c44485dc-6121-4e81-84fd-a4856a20585b",
  };
};

export const aDisableUserOutput = (
  overrides?: Partial<DisableUserOutput>
): { __typename: "DisableUserOutput" } & DisableUserOutput => {
  return {
    __typename: "DisableUserOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aDocument = (
  overrides?: Partial<Document>
): { __typename: "Document" } & Document => {
  return {
    __typename: "Document",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "adipisci",
    href:
      overrides && overrides.hasOwnProperty("href")
        ? overrides.href!
        : "maiores",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "b2be747b-6e98-4897-a0f9-9a93e428ffe2",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "vel",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "quam",
  };
};

export const aDownloadFileDestinationInput = (
  overrides?: Partial<DownloadFileDestinationInput>
): DownloadFileDestinationInput => {
  return {
    destinationId:
      overrides && overrides.hasOwnProperty("destinationId")
        ? overrides.destinationId!
        : "4a7ebe1c-04a4-4159-9543-7fcda310008c",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "cd303b7e-2989-453a-94ad-27d7a9a416d6",
  };
};

export const aDownloadFileDestinationOutput = (
  overrides?: Partial<DownloadFileDestinationOutput>
): {
  __typename: "DownloadFileDestinationOutput";
} & DownloadFileDestinationOutput => {
  return {
    __typename: "DownloadFileDestinationOutput",
    destinationId:
      overrides && overrides.hasOwnProperty("destinationId")
        ? overrides.destinationId!
        : "823efc21-8934-4e7e-a6af-3d0b32b3478e",
    presignedUrl:
      overrides && overrides.hasOwnProperty("presignedUrl")
        ? overrides.presignedUrl!
        : "odit",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aDuplicateProjectInput = (
  overrides?: Partial<DuplicateProjectInput>
): DuplicateProjectInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "df737f34-6349-445d-ad85-98c9dc0b1e0f",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "1587bd3c-2f03-4708-b5c9-f3e0b767c667",
  };
};

export const anEntityOutput = (
  overrides?: Partial<EntityOutput>
): { __typename: "EntityOutput" } & EntityOutput => {
  return {
    __typename: "EntityOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "846b379d-f904-4be4-bb76-064bf93a10e4",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "porro",
  };
};

export const aField = (
  overrides?: Partial<Field>
): { __typename: "Field" } & Field => {
  return {
    __typename: "Field",
    dataType:
      overrides && overrides.hasOwnProperty("dataType")
        ? overrides.dataType!
        : DataType.Array,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "consequuntur",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "veritatis",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "ipsa",
    pii:
      overrides && overrides.hasOwnProperty("pii")
        ? overrides.pii!
        : PiiType.None,
  };
};

export const aFinalDataProductManagerOutput = (
  overrides?: Partial<FinalDataProductManagerOutput>
): {
  __typename: "FinalDataProductManagerOutput";
} & FinalDataProductManagerOutput => {
  return {
    __typename: "FinalDataProductManagerOutput",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "omnis",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "84554d35-844d-472e-b408-9b1b5274dbd7",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "dolores",
  };
};

export const aFinalDataProductOwnerOutput = (
  overrides?: Partial<FinalDataProductOwnerOutput>
): {
  __typename: "FinalDataProductOwnerOutput";
} & FinalDataProductOwnerOutput => {
  return {
    __typename: "FinalDataProductOwnerOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "682a5456-ce6e-4bfe-a456-720f82243792",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "suscipit",
  };
};

export const aForgotPasswordInput = (
  overrides?: Partial<ForgotPasswordInput>
): ForgotPasswordInput => {
  return {
    username:
      overrides && overrides.hasOwnProperty("username")
        ? overrides.username!
        : "alias",
  };
};

export const aGeneralUser = (
  overrides?: Partial<GeneralUser>
): { __typename: "GeneralUser" } & GeneralUser => {
  return {
    __typename: "GeneralUser",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "voluptas",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "porro",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "ebee6165-ab87-4eb9-872d-77b080ef0c33",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "et",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "nulla",
    organization:
      overrides && overrides.hasOwnProperty("organization")
        ? overrides.organization!
        : anOrganization(),
    policy:
      overrides && overrides.hasOwnProperty("policy")
        ? overrides.policy!
        : anOrganizationPolicy(),
    users:
      overrides && overrides.hasOwnProperty("users")
        ? overrides.users!
        : [aUser()],
  };
};

export const aGoal = (
  overrides?: Partial<Goal>
): { __typename: "Goal" } & Goal => {
  return {
    __typename: "Goal",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "maiores",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "sit",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "8d28bae2-da61-4d68-9588-b032e905afc3",
    metrics:
      overrides && overrides.hasOwnProperty("metrics")
        ? overrides.metrics!
        : ["provident"],
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "illo",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "nesciunt",
  };
};

export const aGovernance = (
  overrides?: Partial<Governance>
): { __typename: "Governance" } & Governance => {
  return {
    __typename: "Governance",
    customPolicies:
      overrides && overrides.hasOwnProperty("customPolicies")
        ? overrides.customPolicies!
        : [aPolicy()],
    goals:
      overrides && overrides.hasOwnProperty("goals")
        ? overrides.goals!
        : [aGoal()],
    governanceType:
      overrides && overrides.hasOwnProperty("governanceType")
        ? overrides.governanceType!
        : GovernanceType.Custom,
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "aacef205-137b-4365-8e70-fdd6bfe1b4f8",
    isEditable:
      overrides && overrides.hasOwnProperty("isEditable")
        ? overrides.isEditable!
        : true,
    legalDocuments:
      overrides && overrides.hasOwnProperty("legalDocuments")
        ? overrides.legalDocuments!
        : [aDocument()],
    monitoredPolicies:
      overrides && overrides.hasOwnProperty("monitoredPolicies")
        ? overrides.monitoredPolicies!
        : [aPolicy()],
    referenceDocuments:
      overrides && overrides.hasOwnProperty("referenceDocuments")
        ? overrides.referenceDocuments!
        : [aDocument()],
  };
};

export const aHardDeleteUserInput = (
  overrides?: Partial<HardDeleteUserInput>
): HardDeleteUserInput => {
  return {
    userId:
      overrides && overrides.hasOwnProperty("userId")
        ? overrides.userId!
        : "5cf6fc70-5e58-4b6a-93f6-f76d85632f59",
  };
};

export const aHealthOutput = (
  overrides?: Partial<HealthOutput>
): { __typename: "HealthOutput" } & HealthOutput => {
  return {
    __typename: "HealthOutput",
    openmetadata:
      overrides && overrides.hasOwnProperty("openmetadata")
        ? overrides.openmetadata!
        : true,
  };
};

export const anIngestionServiceFilterInput = (
  overrides?: Partial<IngestionServiceFilterInput>
): IngestionServiceFilterInput => {
  return {
    ingestionServiceId:
      overrides && overrides.hasOwnProperty("ingestionServiceId")
        ? overrides.ingestionServiceId!
        : "1c91977a-afc0-4479-b127-a47c7bd79e77",
  };
};

export const anIngestionServiceOutput = (
  overrides?: Partial<IngestionServiceOutput>
): { __typename: "IngestionServiceOutput" } & IngestionServiceOutput => {
  return {
    __typename: "IngestionServiceOutput",
    configurations:
      overrides && overrides.hasOwnProperty("configurations")
        ? overrides.configurations!
        : "repellendus",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "accusamus",
    dataAssetCount:
      overrides && overrides.hasOwnProperty("dataAssetCount")
        ? overrides.dataAssetCount!
        : 9452,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "nobis",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "22606767-2626-44cd-86d7-14c6d80f0039",
    manager:
      overrides && overrides.hasOwnProperty("manager")
        ? overrides.manager!
        : aServiceManagerOutput(),
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "quidem",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "eligendi",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : aServiceEntityOutput(),
    provider:
      overrides && overrides.hasOwnProperty("provider")
        ? overrides.provider!
        : IngestionServiceProvider.AirbyteCloud,
    sourceCount:
      overrides && overrides.hasOwnProperty("sourceCount")
        ? overrides.sourceCount!
        : 9739,
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : ServiceStatus.Connected,
  };
};

export const anInputDataAsset = (
  overrides?: Partial<InputDataAsset>
): { __typename: "InputDataAsset" } & InputDataAsset => {
  return {
    __typename: "InputDataAsset",
    connectionType:
      overrides && overrides.hasOwnProperty("connectionType")
        ? overrides.connectionType!
        : "voluptas",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "aut",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "recusandae",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "a100d131-0224-43a3-bddc-56b728d3e6e0",
    inputDataAssetGroup:
      overrides && overrides.hasOwnProperty("inputDataAssetGroup")
        ? overrides.inputDataAssetGroup!
        : anInputDataAssetInputDataAssetGroupOutput(),
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : [anInputDataAssetManagerOutput()],
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "et",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "aut",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : anInputDataAssetOwnerOutput(),
    piiTypes:
      overrides && overrides.hasOwnProperty("piiTypes")
        ? overrides.piiTypes!
        : ["illo"],
    refreshRate:
      overrides && overrides.hasOwnProperty("refreshRate")
        ? overrides.refreshRate!
        : RefreshRate.Daily,
    sensitivity:
      overrides && overrides.hasOwnProperty("sensitivity")
        ? overrides.sensitivity!
        : Sensitivity.Tier_1,
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : WorkflowIssueStatus.Incomplete,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["totam"],
  };
};

export const anInputDataAssetFilterInput = (
  overrides?: Partial<InputDataAssetFilterInput>
): InputDataAssetFilterInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "6bc59de6-2299-450b-ba3b-bfa4bc727c2e",
    organizationIds:
      overrides && overrides.hasOwnProperty("organizationIds")
        ? overrides.organizationIds!
        : ["d91845de-e7c0-4313-806c-9c05882dc618"],
  };
};

export const anInputDataAssetInputDataAssetGroupOutput = (
  overrides?: Partial<InputDataAssetInputDataAssetGroupOutput>
): {
  __typename: "InputDataAssetInputDataAssetGroupOutput";
} & InputDataAssetInputDataAssetGroupOutput => {
  return {
    __typename: "InputDataAssetInputDataAssetGroupOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "ea9bab29-99a2-4219-a7b2-b27d4d3aa47e",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "rem",
  };
};

export const anInputDataAssetManagerOutput = (
  overrides?: Partial<InputDataAssetManagerOutput>
): {
  __typename: "InputDataAssetManagerOutput";
} & InputDataAssetManagerOutput => {
  return {
    __typename: "InputDataAssetManagerOutput",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "laborum",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "466b96c6-85e6-43f0-9c98-690a4a4ac135",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "repellendus",
  };
};

export const anInputDataAssetOwnerOutput = (
  overrides?: Partial<InputDataAssetOwnerOutput>
): { __typename: "InputDataAssetOwnerOutput" } & InputDataAssetOwnerOutput => {
  return {
    __typename: "InputDataAssetOwnerOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "0dbce6dd-5283-4078-b427-34c824eef373",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "eius",
  };
};

export const anIssue = (
  overrides?: Partial<Issue>
): { __typename: "Issue" } & Issue => {
  return {
    __typename: "Issue",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "quo",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "6cc9664c-5994-41da-b8b4-7e68a7dc65ed",
    issueType:
      overrides && overrides.hasOwnProperty("issueType")
        ? overrides.issueType!
        : IssueType.ComplianceIssue,
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "sit",
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : "dolor",
  };
};

export const aLoginChallengeResponse = (
  overrides?: Partial<LoginChallengeResponse>
): { __typename: "LoginChallengeResponse" } & LoginChallengeResponse => {
  return {
    __typename: "LoginChallengeResponse",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "ex",
    organizationName:
      overrides && overrides.hasOwnProperty("organizationName")
        ? overrides.organizationName!
        : "sit",
    session:
      overrides && overrides.hasOwnProperty("session")
        ? overrides.session!
        : "perspiciatis",
  };
};

export const aLoginInput = (overrides?: Partial<LoginInput>): LoginInput => {
  return {
    password:
      overrides && overrides.hasOwnProperty("password")
        ? overrides.password!
        : "consequatur",
    username:
      overrides && overrides.hasOwnProperty("username")
        ? overrides.username!
        : "aspernatur",
  };
};

export const aLoginOutput = (
  overrides?: Partial<LoginOutput>
): { __typename: "LoginOutput" } & LoginOutput => {
  return {
    __typename: "LoginOutput",
    accessToken:
      overrides && overrides.hasOwnProperty("accessToken")
        ? overrides.accessToken!
        : "quis",
    challengeResponse:
      overrides && overrides.hasOwnProperty("challengeResponse")
        ? overrides.challengeResponse!
        : aLoginChallengeResponse(),
    idToken:
      overrides && overrides.hasOwnProperty("idToken")
        ? overrides.idToken!
        : "animi",
    refreshToken:
      overrides && overrides.hasOwnProperty("refreshToken")
        ? overrides.refreshToken!
        : "iste",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
    userToken:
      overrides && overrides.hasOwnProperty("userToken")
        ? overrides.userToken!
        : "expedita",
  };
};

export const aLogoUploadContext = (
  overrides?: Partial<LogoUploadContext>
): { __typename: "LogoUploadContext" } & LogoUploadContext => {
  return {
    __typename: "LogoUploadContext",
    contentType:
      overrides && overrides.hasOwnProperty("contentType")
        ? overrides.contentType!
        : "labore",
    fields:
      overrides && overrides.hasOwnProperty("fields")
        ? overrides.fields!
        : "fugit",
    url:
      overrides && overrides.hasOwnProperty("url") ? overrides.url! : "minus",
  };
};

export const aMember = (
  overrides?: Partial<Member>
): { __typename: "Member" } & Member => {
  return {
    __typename: "Member",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "ut",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "ut",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "nobis",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "029071c0-3eaa-48dc-acc9-a3321b5f945e",
    isReinvitable:
      overrides && overrides.hasOwnProperty("isReinvitable")
        ? overrides.isReinvitable!
        : false,
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "iste",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "ipsa",
    organizationName:
      overrides && overrides.hasOwnProperty("organizationName")
        ? overrides.organizationName!
        : "temporibus",
    projectsContributed:
      overrides && overrides.hasOwnProperty("projectsContributed")
        ? overrides.projectsContributed!
        : [aMemberProjectsContributed()],
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : MemberStatus.Active,
    workspaceRole:
      overrides && overrides.hasOwnProperty("workspaceRole")
        ? overrides.workspaceRole!
        : WorkspaceRoleEnum.Admin,
  };
};

export const aMemberProjectsContributed = (
  overrides?: Partial<MemberProjectsContributed>
): { __typename: "MemberProjectsContributed" } & MemberProjectsContributed => {
  return {
    __typename: "MemberProjectsContributed",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "d1e2c926-c458-49ec-9269-4591385534ce",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "aut",
  };
};

export const aMoveResourceInput = (
  overrides?: Partial<MoveResourceInput>
): MoveResourceInput => {
  return {
    parentId:
      overrides && overrides.hasOwnProperty("parentId")
        ? overrides.parentId!
        : "90ad140f-f39d-4271-9a51-c85072ddbbfa",
    resourceId:
      overrides && overrides.hasOwnProperty("resourceId")
        ? overrides.resourceId!
        : "ef35d47a-4d44-4cb5-bf0a-b1202b48cb9f",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "d98e6eb2-2566-4fa4-afb8-c353dc39aa18",
  };
};

export const aMutation = (
  overrides?: Partial<Mutation>
): { __typename: "Mutation" } & Mutation => {
  return {
    __typename: "Mutation",
    acceptWorkspacePolicy:
      overrides && overrides.hasOwnProperty("acceptWorkspacePolicy")
        ? overrides.acceptWorkspacePolicy!
        : anAcceptWorkspacePolicyOutput(),
    addContributingOrganizationToProject:
      overrides &&
      overrides.hasOwnProperty("addContributingOrganizationToProject")
        ? overrides.addContributingOrganizationToProject!
        : false,
    addDataAssetsToInputDataAssetGroup:
      overrides &&
      overrides.hasOwnProperty("addDataAssetsToInputDataAssetGroup")
        ? overrides.addDataAssetsToInputDataAssetGroup!
        : true,
    addSchemaToProject:
      overrides && overrides.hasOwnProperty("addSchemaToProject")
        ? overrides.addSchemaToProject!
        : anUpdateOutput(),
    addSchemaToResource:
      overrides && overrides.hasOwnProperty("addSchemaToResource")
        ? overrides.addSchemaToResource!
        : anUpdateOutput(),
    addSource:
      overrides && overrides.hasOwnProperty("addSource")
        ? overrides.addSource!
        : false,
    addTermToResource:
      overrides && overrides.hasOwnProperty("addTermToResource")
        ? overrides.addTermToResource!
        : anUpdateOutput(),
    addTermToSchema:
      overrides && overrides.hasOwnProperty("addTermToSchema")
        ? overrides.addTermToSchema!
        : anUpdateOutput(),
    confirmForgotPassword:
      overrides && overrides.hasOwnProperty("confirmForgotPassword")
        ? overrides.confirmForgotPassword!
        : true,
    confirmUser:
      overrides && overrides.hasOwnProperty("confirmUser")
        ? overrides.confirmUser!
        : aLoginOutput(),
    createDestination:
      overrides && overrides.hasOwnProperty("createDestination")
        ? overrides.createDestination!
        : aCreateDestinationOutput(),
    createFinalDataProductGroup:
      overrides && overrides.hasOwnProperty("createFinalDataProductGroup")
        ? overrides.createFinalDataProductGroup!
        : aCreateFinalDataProductGroupOutput(),
    createIngestionService:
      overrides && overrides.hasOwnProperty("createIngestionService")
        ? overrides.createIngestionService!
        : aCreateIngestionServiceOutput(),
    createInputDataAssetGroup:
      overrides && overrides.hasOwnProperty("createInputDataAssetGroup")
        ? overrides.createInputDataAssetGroup!
        : aCreateInputDataAssetGroupOutput(),
    createProject:
      overrides && overrides.hasOwnProperty("createProject")
        ? overrides.createProject!
        : aCreateProjectOutput(),
    createResource:
      overrides && overrides.hasOwnProperty("createResource")
        ? overrides.createResource!
        : aCreateResourceOutput(),
    createResourceFolder:
      overrides && overrides.hasOwnProperty("createResourceFolder")
        ? overrides.createResourceFolder!
        : aCreateResourceFolderOutput(),
    createSchema:
      overrides && overrides.hasOwnProperty("createSchema")
        ? overrides.createSchema!
        : aCreateSchemaOutput(),
    createTargetSchema:
      overrides && overrides.hasOwnProperty("createTargetSchema")
        ? overrides.createTargetSchema!
        : aCreateTargetSchemaOutput(),
    createTerm:
      overrides && overrides.hasOwnProperty("createTerm")
        ? overrides.createTerm!
        : aCreateTermOutput(),
    createTransformation:
      overrides && overrides.hasOwnProperty("createTransformation")
        ? overrides.createTransformation!
        : aCreateTransformationOutput(),
    createTransformationService:
      overrides && overrides.hasOwnProperty("createTransformationService")
        ? overrides.createTransformationService!
        : aCreateTransformationServiceOutput(),
    createUser:
      overrides && overrides.hasOwnProperty("createUser")
        ? overrides.createUser!
        : aCreateUserOutput(),
    createWarehouseService:
      overrides && overrides.hasOwnProperty("createWarehouseService")
        ? overrides.createWarehouseService!
        : aCreateWarehouseServiceOutput(),
    createWorkflowConnection:
      overrides && overrides.hasOwnProperty("createWorkflowConnection")
        ? overrides.createWorkflowConnection!
        : false,
    deactivateOrganizationUser:
      overrides && overrides.hasOwnProperty("deactivateOrganizationUser")
        ? overrides.deactivateOrganizationUser!
        : aDeactivateOrganizationUserOutput(),
    deleteDestination:
      overrides && overrides.hasOwnProperty("deleteDestination")
        ? overrides.deleteDestination!
        : aDeleteDestinationOutput(),
    deleteFinalDataProductGroup:
      overrides && overrides.hasOwnProperty("deleteFinalDataProductGroup")
        ? overrides.deleteFinalDataProductGroup!
        : aDeleteFinalDataProductGroupOutput(),
    deleteInputDataAssetGroup:
      overrides && overrides.hasOwnProperty("deleteInputDataAssetGroup")
        ? overrides.deleteInputDataAssetGroup!
        : aDeleteInputDataAssetGroupOutput(),
    deleteResource:
      overrides && overrides.hasOwnProperty("deleteResource")
        ? overrides.deleteResource!
        : aDeleteResourceOutput(),
    deleteTerm:
      overrides && overrides.hasOwnProperty("deleteTerm")
        ? overrides.deleteTerm!
        : aDeleteTermOutput(),
    disableUser:
      overrides && overrides.hasOwnProperty("disableUser")
        ? overrides.disableUser!
        : aDisableUserOutput(),
    downloadFileDestination:
      overrides && overrides.hasOwnProperty("downloadFileDestination")
        ? overrides.downloadFileDestination!
        : aDownloadFileDestinationOutput(),
    duplicateProject:
      overrides && overrides.hasOwnProperty("duplicateProject")
        ? overrides.duplicateProject!
        : true,
    forgotPassword:
      overrides && overrides.hasOwnProperty("forgotPassword")
        ? overrides.forgotPassword!
        : false,
    hardDeleteUser:
      overrides && overrides.hasOwnProperty("hardDeleteUser")
        ? overrides.hardDeleteUser!
        : false,
    login:
      overrides && overrides.hasOwnProperty("login")
        ? overrides.login!
        : aLoginOutput(),
    moveResource:
      overrides && overrides.hasOwnProperty("moveResource")
        ? overrides.moveResource!
        : anUpdateOutput(),
    onboardDataAssetToInputDataAssetGroup:
      overrides &&
      overrides.hasOwnProperty("onboardDataAssetToInputDataAssetGroup")
        ? overrides.onboardDataAssetToInputDataAssetGroup!
        : anOnboardDataAssetToInputDataAssetGroupOutput(),
    onboardDataAssetToProject:
      overrides && overrides.hasOwnProperty("onboardDataAssetToProject")
        ? overrides.onboardDataAssetToProject!
        : anOnboardDataAssetToProjectOutput(),
    onboardDataAssetToWorkspace:
      overrides && overrides.hasOwnProperty("onboardDataAssetToWorkspace")
        ? overrides.onboardDataAssetToWorkspace!
        : anOnboardDataAssetToWorkspaceOutput(),
    refreshToken:
      overrides && overrides.hasOwnProperty("refreshToken")
        ? overrides.refreshToken!
        : aRefreshTokenOutput(),
    removeDataAssetFromFinalDataProductGroup:
      overrides &&
      overrides.hasOwnProperty("removeDataAssetFromFinalDataProductGroup")
        ? overrides.removeDataAssetFromFinalDataProductGroup!
        : false,
    removeDataAssetFromInputDataAssetGroup:
      overrides &&
      overrides.hasOwnProperty("removeDataAssetFromInputDataAssetGroup")
        ? overrides.removeDataAssetFromInputDataAssetGroup!
        : false,
    removeSchemaFromResource:
      overrides && overrides.hasOwnProperty("removeSchemaFromResource")
        ? overrides.removeSchemaFromResource!
        : anUpdateOutput(),
    removeTargetSchemaFromProject:
      overrides && overrides.hasOwnProperty("removeTargetSchemaFromProject")
        ? overrides.removeTargetSchemaFromProject!
        : true,
    removeTermFromResource:
      overrides && overrides.hasOwnProperty("removeTermFromResource")
        ? overrides.removeTermFromResource!
        : anUpdateOutput(),
    removeTermFromSchema:
      overrides && overrides.hasOwnProperty("removeTermFromSchema")
        ? overrides.removeTermFromSchema!
        : anUpdateOutput(),
    removeWorkflowConnection:
      overrides && overrides.hasOwnProperty("removeWorkflowConnection")
        ? overrides.removeWorkflowConnection!
        : false,
    resendInvite:
      overrides && overrides.hasOwnProperty("resendInvite")
        ? overrides.resendInvite!
        : false,
    runTransformationsInProject:
      overrides && overrides.hasOwnProperty("runTransformationsInProject")
        ? overrides.runTransformationsInProject!
        : false,
    updateDataAsset:
      overrides && overrides.hasOwnProperty("updateDataAsset")
        ? overrides.updateDataAsset!
        : true,
    updateDataAssetFile:
      overrides && overrides.hasOwnProperty("updateDataAssetFile")
        ? overrides.updateDataAssetFile!
        : anUpdateDataAssetFileOutput(),
    updateDestination:
      overrides && overrides.hasOwnProperty("updateDestination")
        ? overrides.updateDestination!
        : true,
    updateFinalDataProductGroup:
      overrides && overrides.hasOwnProperty("updateFinalDataProductGroup")
        ? overrides.updateFinalDataProductGroup!
        : anUpdateFinalDataProductGroupOutput(),
    updateGovernance:
      overrides && overrides.hasOwnProperty("updateGovernance")
        ? overrides.updateGovernance!
        : true,
    updateInputDataAssetGroup:
      overrides && overrides.hasOwnProperty("updateInputDataAssetGroup")
        ? overrides.updateInputDataAssetGroup!
        : anUpdateInputDataAssetGroupOutput(),
    updateProject:
      overrides && overrides.hasOwnProperty("updateProject")
        ? overrides.updateProject!
        : false,
    updateResource:
      overrides && overrides.hasOwnProperty("updateResource")
        ? overrides.updateResource!
        : anUpdateOutput(),
    updateRole:
      overrides && overrides.hasOwnProperty("updateRole")
        ? overrides.updateRole!
        : true,
    updateSchema:
      overrides && overrides.hasOwnProperty("updateSchema")
        ? overrides.updateSchema!
        : false,
    updateTargetSchema:
      overrides && overrides.hasOwnProperty("updateTargetSchema")
        ? overrides.updateTargetSchema!
        : anUpdateTargetSchemaOutput(),
    updateTerm:
      overrides && overrides.hasOwnProperty("updateTerm")
        ? overrides.updateTerm!
        : anUpdateOutput(),
    updateTransformation:
      overrides && overrides.hasOwnProperty("updateTransformation")
        ? overrides.updateTransformation!
        : true,
    updateUserOrganizationRole:
      overrides && overrides.hasOwnProperty("updateUserOrganizationRole")
        ? overrides.updateUserOrganizationRole!
        : false,
    updateWorkspace:
      overrides && overrides.hasOwnProperty("updateWorkspace")
        ? overrides.updateWorkspace!
        : anUpdateWorkspaceOutput(),
    updateWorkspaceRoleScope:
      overrides && overrides.hasOwnProperty("updateWorkspaceRoleScope")
        ? overrides.updateWorkspaceRoleScope!
        : false,
    updateWorkspaceSchema:
      overrides && overrides.hasOwnProperty("updateWorkspaceSchema")
        ? overrides.updateWorkspaceSchema!
        : anUpdateOutput(),
    validateTransformationsInProject:
      overrides && overrides.hasOwnProperty("validateTransformationsInProject")
        ? overrides.validateTransformationsInProject!
        : true,
  };
};

export const anOnboardDataAssetToInputDataAssetGroupInput = (
  overrides?: Partial<OnboardDataAssetToInputDataAssetGroupInput>
): OnboardDataAssetToInputDataAssetGroupInput => {
  return {
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "e2bb7392-c090-4caf-9246-075a3e3153bd",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "consequuntur",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "dc448a29-780c-4ef6-ad5c-d941fcd5316d",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "345b927d-a93a-4468-9b62-22199cc57135",
  };
};

export const anOnboardDataAssetToInputDataAssetGroupOutput = (
  overrides?: Partial<OnboardDataAssetToInputDataAssetGroupOutput>
): {
  __typename: "OnboardDataAssetToInputDataAssetGroupOutput";
} & OnboardDataAssetToInputDataAssetGroupOutput => {
  return {
    __typename: "OnboardDataAssetToInputDataAssetGroupOutput",
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "41d808cd-6c7e-434b-9181-741366c766b5",
    presignedUrl:
      overrides && overrides.hasOwnProperty("presignedUrl")
        ? overrides.presignedUrl!
        : "aliquid",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const anOnboardDataAssetToProjectInput = (
  overrides?: Partial<OnboardDataAssetToProjectInput>
): OnboardDataAssetToProjectInput => {
  return {
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "ut",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "e3def8ae-c39e-489c-8f9a-857e18540e00",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "f02bc200-0e45-4914-9e5e-f620df55fb17",
  };
};

export const anOnboardDataAssetToProjectOutput = (
  overrides?: Partial<OnboardDataAssetToProjectOutput>
): {
  __typename: "OnboardDataAssetToProjectOutput";
} & OnboardDataAssetToProjectOutput => {
  return {
    __typename: "OnboardDataAssetToProjectOutput",
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "1b2e0fb4-f12b-403a-9156-945c99217945",
    presignedUrl:
      overrides && overrides.hasOwnProperty("presignedUrl")
        ? overrides.presignedUrl!
        : "delectus",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const anOnboardDataAssetToWorkspaceInput = (
  overrides?: Partial<OnboardDataAssetToWorkspaceInput>
): OnboardDataAssetToWorkspaceInput => {
  return {
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "eveniet",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "482f73c6-5f13-4235-897a-2e8ec4dffc62",
  };
};

export const anOnboardDataAssetToWorkspaceOutput = (
  overrides?: Partial<OnboardDataAssetToWorkspaceOutput>
): {
  __typename: "OnboardDataAssetToWorkspaceOutput";
} & OnboardDataAssetToWorkspaceOutput => {
  return {
    __typename: "OnboardDataAssetToWorkspaceOutput",
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "9dc02d9a-13c6-4ef5-bbac-ba9b9b80a99f",
    presignedUrl:
      overrides && overrides.hasOwnProperty("presignedUrl")
        ? overrides.presignedUrl!
        : "eligendi",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const anOrganization = (
  overrides?: Partial<Organization>
): { __typename: "Organization" } & Organization => {
  return {
    __typename: "Organization",
    adminGroup:
      overrides && overrides.hasOwnProperty("adminGroup")
        ? overrides.adminGroup!
        : anOrganizationAdmin(),
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "rerum",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "fugiat",
    externalWorkspaces:
      overrides && overrides.hasOwnProperty("externalWorkspaces")
        ? overrides.externalWorkspaces!
        : [aWorkspace()],
    generalUserGroup:
      overrides && overrides.hasOwnProperty("generalUserGroup")
        ? overrides.generalUserGroup!
        : aGeneralUser(),
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "4ffe8aa6-593a-4cfe-8bef-6cb371713d36",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "nisi",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "cumque",
    ownedDataAssets:
      overrides && overrides.hasOwnProperty("ownedDataAssets")
        ? overrides.ownedDataAssets!
        : [aDataAsset()],
    ownedWorkspaces:
      overrides && overrides.hasOwnProperty("ownedWorkspaces")
        ? overrides.ownedWorkspaces!
        : [aWorkspace()],
  };
};

export const anOrganizationAdmin = (
  overrides?: Partial<OrganizationAdmin>
): { __typename: "OrganizationAdmin" } & OrganizationAdmin => {
  return {
    __typename: "OrganizationAdmin",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "nemo",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "saepe",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "aa7e0dc9-761c-4109-8f88-0d09532d608c",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "quia",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "laborum",
    organization:
      overrides && overrides.hasOwnProperty("organization")
        ? overrides.organization!
        : anOrganization(),
    policy:
      overrides && overrides.hasOwnProperty("policy")
        ? overrides.policy!
        : anOrganizationPolicy(),
    users:
      overrides && overrides.hasOwnProperty("users")
        ? overrides.users!
        : [aUser()],
  };
};

export const anOrganizationDataAssetOutput = (
  overrides?: Partial<OrganizationDataAssetOutput>
): {
  __typename: "OrganizationDataAssetOutput";
} & OrganizationDataAssetOutput => {
  return {
    __typename: "OrganizationDataAssetOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "a5e46cf5-79ce-476f-a586-3ea70ac694a8",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "beatae",
  };
};

export const anOrganizationFilterInput = (
  overrides?: Partial<OrganizationFilterInput>
): OrganizationFilterInput => {
  return {
    organizationId:
      overrides && overrides.hasOwnProperty("organizationId")
        ? overrides.organizationId!
        : "f80af3bc-37fb-4ba2-9f1e-40ded2bcd066",
  };
};

export const anOrganizationOutput = (
  overrides?: Partial<OrganizationOutput>
): { __typename: "OrganizationOutput" } & OrganizationOutput => {
  return {
    __typename: "OrganizationOutput",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "eius",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "est",
    domain:
      overrides && overrides.hasOwnProperty("domain")
        ? overrides.domain!
        : "repellendus",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "8a9c93d3-8389-4797-adc3-528307dcb205",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "eos",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "et",
  };
};

export const anOrganizationPolicy = (
  overrides?: Partial<OrganizationPolicy>
): { __typename: "OrganizationPolicy" } & OrganizationPolicy => {
  return {
    __typename: "OrganizationPolicy",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "perspiciatis",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "ut",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "64cea637-cd5e-4833-9329-92921bd3804b",
    memberCreate:
      overrides && overrides.hasOwnProperty("memberCreate")
        ? overrides.memberCreate!
        : false,
    memberDelete:
      overrides && overrides.hasOwnProperty("memberDelete")
        ? overrides.memberDelete!
        : true,
    memberRead:
      overrides && overrides.hasOwnProperty("memberRead")
        ? overrides.memberRead!
        : true,
    memberUpdate:
      overrides && overrides.hasOwnProperty("memberUpdate")
        ? overrides.memberUpdate!
        : false,
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "dolor",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "nemo",
    orgCreate:
      overrides && overrides.hasOwnProperty("orgCreate")
        ? overrides.orgCreate!
        : true,
    orgDelete:
      overrides && overrides.hasOwnProperty("orgDelete")
        ? overrides.orgDelete!
        : false,
    orgRead:
      overrides && overrides.hasOwnProperty("orgRead")
        ? overrides.orgRead!
        : true,
    orgUpdate:
      overrides && overrides.hasOwnProperty("orgUpdate")
        ? overrides.orgUpdate!
        : true,
    organizationRole:
      overrides && overrides.hasOwnProperty("organizationRole")
        ? overrides.organizationRole!
        : aGeneralUser(),
    userRead:
      overrides && overrides.hasOwnProperty("userRead")
        ? overrides.userRead!
        : false,
    workspaceCreate:
      overrides && overrides.hasOwnProperty("workspaceCreate")
        ? overrides.workspaceCreate!
        : false,
  };
};

export const aPolicy = (
  overrides?: Partial<Policy>
): { __typename: "Policy" } & Policy => {
  return {
    __typename: "Policy",
    addedBy:
      overrides && overrides.hasOwnProperty("addedBy")
        ? overrides.addedBy!
        : aPolicyEditor(),
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "ipsa",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "qui",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "97a65792-baf9-4fa8-a5ab-f80df51973ba",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "repellendus",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "rerum",
    policyCategory:
      overrides && overrides.hasOwnProperty("policyCategory")
        ? overrides.policyCategory!
        : PolicyCategory.Access,
    referenceDocuments:
      overrides && overrides.hasOwnProperty("referenceDocuments")
        ? overrides.referenceDocuments!
        : [aDocument()],
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : [aTag()],
  };
};

export const aPolicyEditor = (
  overrides?: Partial<PolicyEditor>
): { __typename: "PolicyEditor" } & PolicyEditor => {
  return {
    __typename: "PolicyEditor",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "doloribus",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "66272c13-fc2d-4fa4-8e2c-70589c5ac1ae",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "qui",
  };
};

export const aPreSignedUrlInput = (
  overrides?: Partial<PreSignedUrlInput>
): PreSignedUrlInput => {
  return {
    access:
      overrides && overrides.hasOwnProperty("access")
        ? overrides.access!
        : AccessMode.ReadOnly,
    directory:
      overrides && overrides.hasOwnProperty("directory")
        ? overrides.directory!
        : Directory.Resource,
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "officiis",
    readInfo:
      overrides && overrides.hasOwnProperty("readInfo")
        ? overrides.readInfo!
        : aPreSignedUrlReadInput(),
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "3fffa154-d810-453a-a789-85d1ad459d92",
    writeInfo:
      overrides && overrides.hasOwnProperty("writeInfo")
        ? overrides.writeInfo!
        : aPreSignedUrlWriteInput(),
  };
};

export const aPreSignedUrlOutput = (
  overrides?: Partial<PreSignedUrlOutput>
): { __typename: "PreSignedUrlOutput" } & PreSignedUrlOutput => {
  return {
    __typename: "PreSignedUrlOutput",
    key:
      overrides && overrides.hasOwnProperty("key")
        ? overrides.key!
        : "molestiae",
    presignedUrl:
      overrides && overrides.hasOwnProperty("presignedUrl")
        ? overrides.presignedUrl!
        : "et",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const aPreSignedUrlReadInput = (
  overrides?: Partial<PreSignedUrlReadInput>
): PreSignedUrlReadInput => {
  return {
    key:
      overrides && overrides.hasOwnProperty("key")
        ? overrides.key!
        : "sapiente",
  };
};

export const aPreSignedUrlWriteInput = (
  overrides?: Partial<PreSignedUrlWriteInput>
): PreSignedUrlWriteInput => {
  return {
    fileExt:
      overrides && overrides.hasOwnProperty("fileExt")
        ? overrides.fileExt!
        : "sint",
    uploaderProvider:
      overrides && overrides.hasOwnProperty("uploaderProvider")
        ? overrides.uploaderProvider!
        : "eos",
  };
};

export const aProject = (
  overrides?: Partial<Project>
): { __typename: "Project" } & Project => {
  return {
    __typename: "Project",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "deserunt",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "voluptatem",
    endDate:
      overrides && overrides.hasOwnProperty("endDate")
        ? overrides.endDate!
        : "dolores",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "3e7d7963-406a-47bd-ad80-0448296895bf",
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : [aUser()],
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "sit",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "et",
    ownedDataAssets:
      overrides && overrides.hasOwnProperty("ownedDataAssets")
        ? overrides.ownedDataAssets!
        : [aDataAsset()],
    startDate:
      overrides && overrides.hasOwnProperty("startDate")
        ? overrides.startDate!
        : "velit",
    workspace:
      overrides && overrides.hasOwnProperty("workspace")
        ? overrides.workspace!
        : aWorkspace(),
  };
};

export const aProjectCollaboratingMember = (
  overrides?: Partial<ProjectCollaboratingMember>
): {
  __typename: "ProjectCollaboratingMember";
} & ProjectCollaboratingMember => {
  return {
    __typename: "ProjectCollaboratingMember",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "illo",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "voluptatem",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "id",
  };
};

export const aProjectDataAssetOutput = (
  overrides?: Partial<ProjectDataAssetOutput>
): { __typename: "ProjectDataAssetOutput" } & ProjectDataAssetOutput => {
  return {
    __typename: "ProjectDataAssetOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "1dc7f22c-d432-4708-a920-ca2971d4789c",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "saepe",
  };
};

export const aProjectDestinationOutput = (
  overrides?: Partial<ProjectDestinationOutput>
): { __typename: "ProjectDestinationOutput" } & ProjectDestinationOutput => {
  return {
    __typename: "ProjectDestinationOutput",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "quas",
    destinationType:
      overrides && overrides.hasOwnProperty("destinationType")
        ? overrides.destinationType!
        : DestinationType.Api,
    destinationUrl:
      overrides && overrides.hasOwnProperty("destinationUrl")
        ? overrides.destinationUrl!
        : "et",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "2a22fe0e-41d2-4a48-806e-d2e19c8b0bf2",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "ea",
  };
};

export const aProjectEntityInput = (
  overrides?: Partial<ProjectEntityInput>
): ProjectEntityInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "8bed9380-35cd-4c0e-b00b-f55542e37e9a",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "b7604894-9a42-44b0-94de-efc598978806",
  };
};

export const aProjectOrganizationContributor = (
  overrides?: Partial<ProjectOrganizationContributor>
): {
  __typename: "ProjectOrganizationContributor";
} & ProjectOrganizationContributor => {
  return {
    __typename: "ProjectOrganizationContributor",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "beatae",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "9546b087-283b-4dcb-8854-0f4ee0ab5e06",
    isProjectManager:
      overrides && overrides.hasOwnProperty("isProjectManager")
        ? overrides.isProjectManager!
        : false,
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "distinctio",
  };
};

export const aProjectOrganizationOutput = (
  overrides?: Partial<ProjectOrganizationOutput>
): { __typename: "ProjectOrganizationOutput" } & ProjectOrganizationOutput => {
  return {
    __typename: "ProjectOrganizationOutput",
    contributors:
      overrides && overrides.hasOwnProperty("contributors")
        ? overrides.contributors!
        : [aProjectOrganizationContributor()],
    dataProductCount:
      overrides && overrides.hasOwnProperty("dataProductCount")
        ? overrides.dataProductCount!
        : 2658,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "dolorem",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "5ba669ed-68e5-4588-8400-9c8467c91465",
    inputDataAssetCount:
      overrides && overrides.hasOwnProperty("inputDataAssetCount")
        ? overrides.inputDataAssetCount!
        : 3384,
    issueState:
      overrides && overrides.hasOwnProperty("issueState")
        ? overrides.issueState!
        : WorkflowIssueStatus.Incomplete,
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "dignissimos",
    projectRole:
      overrides && overrides.hasOwnProperty("projectRole")
        ? overrides.projectRole!
        : ProjectOrganizationRole.CollaboratingOrganization,
  };
};

export const aProjectOutput = (
  overrides?: Partial<ProjectOutput>
): { __typename: "ProjectOutput" } & ProjectOutput => {
  return {
    __typename: "ProjectOutput",
    backgroundMaterials:
      overrides && overrides.hasOwnProperty("backgroundMaterials")
        ? overrides.backgroundMaterials!
        : [aDocument()],
    collaboratingMembers:
      overrides && overrides.hasOwnProperty("collaboratingMembers")
        ? overrides.collaboratingMembers!
        : [aProjectCollaboratingMember()],
    collaboratingOrganizations:
      overrides && overrides.hasOwnProperty("collaboratingOrganizations")
        ? overrides.collaboratingOrganizations!
        : ["sed"],
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "tempore",
    createdDataProducts:
      overrides && overrides.hasOwnProperty("createdDataProducts")
        ? overrides.createdDataProducts!
        : [aCreatedFinalProduct()],
    dataConsumers:
      overrides && overrides.hasOwnProperty("dataConsumers")
        ? overrides.dataConsumers!
        : ["sint"],
    dataProductCount:
      overrides && overrides.hasOwnProperty("dataProductCount")
        ? overrides.dataProductCount!
        : 2672,
    dataProductType:
      overrides && overrides.hasOwnProperty("dataProductType")
        ? overrides.dataProductType!
        : DataProductType.FinalDataProduct,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "et",
    destinations:
      overrides && overrides.hasOwnProperty("destinations")
        ? overrides.destinations!
        : [aProjectDestinationOutput()],
    endDate:
      overrides && overrides.hasOwnProperty("endDate")
        ? overrides.endDate!
        : "unde",
    goals:
      overrides && overrides.hasOwnProperty("goals")
        ? overrides.goals!
        : [aGoal()],
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "e0a7a4b2-2ec9-4c15-a143-6ac433870d16",
    inputAssetCount:
      overrides && overrides.hasOwnProperty("inputAssetCount")
        ? overrides.inputAssetCount!
        : 5431,
    inputDataAssets:
      overrides && overrides.hasOwnProperty("inputDataAssets")
        ? overrides.inputDataAssets!
        : [anInputDataAsset()],
    isEditable:
      overrides && overrides.hasOwnProperty("isEditable")
        ? overrides.isEditable!
        : false,
    issueCount:
      overrides && overrides.hasOwnProperty("issueCount")
        ? overrides.issueCount!
        : 6610,
    issueState:
      overrides && overrides.hasOwnProperty("issueState")
        ? overrides.issueState!
        : WorkflowIssueStatus.Incomplete,
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : [aUserOutput()],
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "et",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "est",
    organizations:
      overrides && overrides.hasOwnProperty("organizations")
        ? overrides.organizations!
        : [aProjectOrganizationOutput()],
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : anOrganizationOutput(),
    sensitivity:
      overrides && overrides.hasOwnProperty("sensitivity")
        ? overrides.sensitivity!
        : [Sensitivity.Tier_1],
    startDate:
      overrides && overrides.hasOwnProperty("startDate")
        ? overrides.startDate!
        : "optio",
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : ProjectStatus.Active,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["vero"],
    targetSchemas:
      overrides && overrides.hasOwnProperty("targetSchemas")
        ? overrides.targetSchemas!
        : [aTargetSchema()],
    template:
      overrides && overrides.hasOwnProperty("template")
        ? overrides.template!
        : aProjectTemplateOutput(),
    workflowState:
      overrides && overrides.hasOwnProperty("workflowState")
        ? overrides.workflowState!
        : WorkflowState.Active,
  };
};

export const aProjectTemplateOutput = (
  overrides?: Partial<ProjectTemplateOutput>
): { __typename: "ProjectTemplateOutput" } & ProjectTemplateOutput => {
  return {
    __typename: "ProjectTemplateOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "712c5523-a364-4935-b233-456c56a504a5",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "vitae",
  };
};

export const aQuery = (
  overrides?: Partial<Query>
): { __typename: "Query" } & Query => {
  return {
    __typename: "Query",
    currentUser:
      overrides && overrides.hasOwnProperty("currentUser")
        ? overrides.currentUser!
        : aCurrentUserOutput(),
    dataAssetManagerCandidates:
      overrides && overrides.hasOwnProperty("dataAssetManagerCandidates")
        ? overrides.dataAssetManagerCandidates!
        : [anEntityOutput()],
    health:
      overrides && overrides.hasOwnProperty("health")
        ? overrides.health!
        : aHealthOutput(),
    preSignedUrl:
      overrides && overrides.hasOwnProperty("preSignedUrl")
        ? overrides.preSignedUrl!
        : aPreSignedUrlOutput(),
    projectManagerCandidates:
      overrides && overrides.hasOwnProperty("projectManagerCandidates")
        ? overrides.projectManagerCandidates!
        : [anEntityOutput()],
    projectOwnerCandidates:
      overrides && overrides.hasOwnProperty("projectOwnerCandidates")
        ? overrides.projectOwnerCandidates!
        : [anEntityOutput()],
    refreshUserToken:
      overrides && overrides.hasOwnProperty("refreshUserToken")
        ? overrides.refreshUserToken!
        : aRefreshUserTokenOutput(),
    workflow:
      overrides && overrides.hasOwnProperty("workflow")
        ? overrides.workflow!
        : aWorkflow(),
    workspace:
      overrides && overrides.hasOwnProperty("workspace")
        ? overrides.workspace!
        : aWorkspace(),
  };
};

export const aRefreshTokenInput = (
  overrides?: Partial<RefreshTokenInput>
): RefreshTokenInput => {
  return {
    refreshToken:
      overrides && overrides.hasOwnProperty("refreshToken")
        ? overrides.refreshToken!
        : "rerum",
  };
};

export const aRefreshTokenOutput = (
  overrides?: Partial<RefreshTokenOutput>
): { __typename: "RefreshTokenOutput" } & RefreshTokenOutput => {
  return {
    __typename: "RefreshTokenOutput",
    accessToken:
      overrides && overrides.hasOwnProperty("accessToken")
        ? overrides.accessToken!
        : "impedit",
    idToken:
      overrides && overrides.hasOwnProperty("idToken")
        ? overrides.idToken!
        : "cumque",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const aRefreshUserTokenOutput = (
  overrides?: Partial<RefreshUserTokenOutput>
): { __typename: "RefreshUserTokenOutput" } & RefreshUserTokenOutput => {
  return {
    __typename: "RefreshUserTokenOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
    userToken:
      overrides && overrides.hasOwnProperty("userToken")
        ? overrides.userToken!
        : "quisquam",
  };
};

export const aRemoveDataAssetFromFinalDataProductGroupInput = (
  overrides?: Partial<RemoveDataAssetFromFinalDataProductGroupInput>
): RemoveDataAssetFromFinalDataProductGroupInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "993a246d-3a42-48ce-b1b1-06c1548ebe02",
    finalDataProductGroupId:
      overrides && overrides.hasOwnProperty("finalDataProductGroupId")
        ? overrides.finalDataProductGroupId!
        : "1f396daa-6674-4ede-9997-c1fd771ee699",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "449af858-b55f-492e-8471-f9b78a94eeb8",
  };
};

export const aRemoveDataAssetFromInputDataAssetGroupInput = (
  overrides?: Partial<RemoveDataAssetFromInputDataAssetGroupInput>
): RemoveDataAssetFromInputDataAssetGroupInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "af439424-71e9-42dd-88c7-82a4a6210995",
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "993040cd-dd21-467c-a2d6-8bc56ebee4b0",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "af83c731-38cc-4492-8f7e-31076eb8d0dc",
  };
};

export const aRemoveTargetSchemaFromProjectInput = (
  overrides?: Partial<RemoveTargetSchemaFromProjectInput>
): RemoveTargetSchemaFromProjectInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "48680ad1-35c9-4f6e-bc68-71ea5757aa9e",
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "28d386fb-44af-475b-a47c-9d262a02e8c5",
  };
};

export const aRemoveWorkflowConnectionInput = (
  overrides?: Partial<RemoveWorkflowConnectionInput>
): RemoveWorkflowConnectionInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "a580215b-c9f7-48a5-a107-c7a3a161d27b",
    sourceId:
      overrides && overrides.hasOwnProperty("sourceId")
        ? overrides.sourceId!
        : "24df27e8-f231-482f-b058-ad96a44f1cc0",
    sourceNodeType:
      overrides && overrides.hasOwnProperty("sourceNodeType")
        ? overrides.sourceNodeType!
        : WorkflowNodeType.Destination,
    targetId:
      overrides && overrides.hasOwnProperty("targetId")
        ? overrides.targetId!
        : "9faeaff0-3d8d-43cc-a242-61c7967fac4f",
    targetNodeType:
      overrides && overrides.hasOwnProperty("targetNodeType")
        ? overrides.targetNodeType!
        : WorkflowNodeType.Destination,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "df7594e7-0880-4b3f-9147-9630d0d4a662",
  };
};

export const aResendInviteInput = (
  overrides?: Partial<ResendInviteInput>
): ResendInviteInput => {
  return {
    userId:
      overrides && overrides.hasOwnProperty("userId")
        ? overrides.userId!
        : "c9fba51c-d0f5-4024-99b1-4102e7b3325e",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "4c8aebf4-42a0-4212-a139-97194ecce284",
  };
};

export const aResourceFolderOutput = (
  overrides?: Partial<ResourceFolderOutput>
): { __typename: "ResourceFolderOutput" } & ResourceFolderOutput => {
  return {
    __typename: "ResourceFolderOutput",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "et",
    creator:
      overrides && overrides.hasOwnProperty("creator")
        ? overrides.creator!
        : aUserOutput(),
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "5be672ce-6b38-4888-afb8-de37fe2e2c2a",
    isFolder:
      overrides && overrides.hasOwnProperty("isFolder")
        ? overrides.isFolder!
        : true,
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "qui",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "enim",
  };
};

export const aResourceOutput = (
  overrides?: Partial<ResourceOutput>
): { __typename: "ResourceOutput" } & ResourceOutput => {
  return {
    __typename: "ResourceOutput",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "placeat",
    creator:
      overrides && overrides.hasOwnProperty("creator")
        ? overrides.creator!
        : aUserOutput(),
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "et",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "0a34cc26-d8e8-4859-a9d1-a0aa8eba1ee0",
    internal:
      overrides && overrides.hasOwnProperty("internal")
        ? overrides.internal!
        : false,
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "dolorem",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "ut",
    path:
      overrides && overrides.hasOwnProperty("path") ? overrides.path! : "nobis",
    schemas:
      overrides && overrides.hasOwnProperty("schemas")
        ? overrides.schemas!
        : [aTargetSchema()],
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : [aTag()],
    terms:
      overrides && overrides.hasOwnProperty("terms")
        ? overrides.terms!
        : [aTermOutput()],
    type:
      overrides && overrides.hasOwnProperty("type")
        ? overrides.type!
        : ResourceType.Document,
  };
};

export const aRoleInput = (overrides?: Partial<RoleInput>): RoleInput => {
  return {
    resourceId:
      overrides && overrides.hasOwnProperty("resourceId")
        ? overrides.resourceId!
        : "af93cb83-5dbb-4eb4-8541-d169a511517e",
    roleType:
      overrides && overrides.hasOwnProperty("roleType")
        ? overrides.roleType!
        : RoleType.Organization,
    userId:
      overrides && overrides.hasOwnProperty("userId")
        ? overrides.userId!
        : "22f8f558-bc0b-4839-86db-7749b42dc3cb",
  };
};

export const aRoleOutput = (
  overrides?: Partial<RoleOutput>
): { __typename: "RoleOutput" } & RoleOutput => {
  return {
    __typename: "RoleOutput",
    currentRole:
      overrides && overrides.hasOwnProperty("currentRole")
        ? overrides.currentRole!
        : Role.Admin,
  };
};

export const aRunTransformationsInProjectInput = (
  overrides?: Partial<RunTransformationsInProjectInput>
): RunTransformationsInProjectInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "4dc6ae56-e27a-415e-81e8-385cae19392b",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "24b39b52-0016-45c0-a217-38c0cddf7c16",
  };
};

export const aSchemaProjectConnectInput = (
  overrides?: Partial<SchemaProjectConnectInput>
): SchemaProjectConnectInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "bcf51e97-59b3-47dc-ad53-d3847b4b7c4e",
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "0e7f2679-0dce-4ed8-9d2b-b49e78a9d7e9",
  };
};

export const aSchemaResourceConnectInput = (
  overrides?: Partial<SchemaResourceConnectInput>
): SchemaResourceConnectInput => {
  return {
    resourceId:
      overrides && overrides.hasOwnProperty("resourceId")
        ? overrides.resourceId!
        : "ccfc2b82-c587-4109-ad6e-a7e39f74e476",
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "f9680344-85ac-4e9b-8acd-e6c1fcb1b608",
  };
};

export const aServiceEntityOutput = (
  overrides?: Partial<ServiceEntityOutput>
): { __typename: "ServiceEntityOutput" } & ServiceEntityOutput => {
  return {
    __typename: "ServiceEntityOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "9251f416-02cc-4326-a95f-c6e6664faa70",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "dolor",
  };
};

export const aServiceManagerOutput = (
  overrides?: Partial<ServiceManagerOutput>
): { __typename: "ServiceManagerOutput" } & ServiceManagerOutput => {
  return {
    __typename: "ServiceManagerOutput",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "fuga",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "sunt",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "488f5f98-a545-4c38-bf3a-330eed7582ad",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "autem",
  };
};

export const aSourceDefinitionOutput = (
  overrides?: Partial<SourceDefinitionOutput>
): { __typename: "SourceDefinitionOutput" } & SourceDefinitionOutput => {
  return {
    __typename: "SourceDefinitionOutput",
    documentationUrl:
      overrides && overrides.hasOwnProperty("documentationUrl")
        ? overrides.documentationUrl!
        : "sit",
    icon:
      overrides && overrides.hasOwnProperty("icon")
        ? overrides.icon!
        : "assumenda",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "perspiciatis",
    sourceDefinitionId:
      overrides && overrides.hasOwnProperty("sourceDefinitionId")
        ? overrides.sourceDefinitionId!
        : "voluptas",
  };
};

export const aSourceDefinitionSpecFilter = (
  overrides?: Partial<SourceDefinitionSpecFilter>
): SourceDefinitionSpecFilter => {
  return {
    sourceDefinitionId:
      overrides && overrides.hasOwnProperty("sourceDefinitionId")
        ? overrides.sourceDefinitionId!
        : "6b35aa30-8b72-4770-bd96-dee6516a81b1",
  };
};

export const aSourceDefinitionSpecOutput = (
  overrides?: Partial<SourceDefinitionSpecOutput>
): {
  __typename: "SourceDefinitionSpecOutput";
} & SourceDefinitionSpecOutput => {
  return {
    __typename: "SourceDefinitionSpecOutput",
    connectionSpecification:
      overrides && overrides.hasOwnProperty("connectionSpecification")
        ? overrides.connectionSpecification!
        : "ea",
    documentationUrl:
      overrides && overrides.hasOwnProperty("documentationUrl")
        ? overrides.documentationUrl!
        : "a",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "897f0023-b1e1-4e26-8b29-a73b24635cc3",
  };
};

export const aSourceDefinitionsFilter = (
  overrides?: Partial<SourceDefinitionsFilter>
): SourceDefinitionsFilter => {
  return {
    ingestionServiceId:
      overrides && overrides.hasOwnProperty("ingestionServiceId")
        ? overrides.ingestionServiceId!
        : "90f0cc31-707a-4d4a-9872-c9f34fb516f7",
  };
};

export const aSourceFilterInput = (
  overrides?: Partial<SourceFilterInput>
): SourceFilterInput => {
  return {
    sourceId:
      overrides && overrides.hasOwnProperty("sourceId")
        ? overrides.sourceId!
        : "fa959825-1d02-4aff-87cd-cbbbaf5972d2",
    sourceType:
      overrides && overrides.hasOwnProperty("sourceType")
        ? overrides.sourceType!
        : SourceType.Internal,
  };
};

export const aSourceManagerOutput = (
  overrides?: Partial<SourceManagerOutput>
): { __typename: "SourceManagerOutput" } & SourceManagerOutput => {
  return {
    __typename: "SourceManagerOutput",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "incidunt",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "est",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "329a3d8a-cfa2-40ce-b428-76fe93532a75",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "iusto",
  };
};

export const aSourceOutput = (
  overrides?: Partial<SourceOutput>
): { __typename: "SourceOutput" } & SourceOutput => {
  return {
    __typename: "SourceOutput",
    dataAssets:
      overrides && overrides.hasOwnProperty("dataAssets")
        ? overrides.dataAssets!
        : 9227,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "eligendi",
    documentationUrl:
      overrides && overrides.hasOwnProperty("documentationUrl")
        ? overrides.documentationUrl!
        : "et",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "95cdd467-60a3-4b38-98a4-495a2be5932f",
    logoUrl:
      overrides && overrides.hasOwnProperty("logoUrl")
        ? overrides.logoUrl!
        : "quibusdam",
    manager:
      overrides && overrides.hasOwnProperty("manager")
        ? overrides.manager!
        : aSourceManagerOutput(),
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "quibusdam",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : aSourceOwnerOutput(),
    sourceType:
      overrides && overrides.hasOwnProperty("sourceType")
        ? overrides.sourceType!
        : SourceType.Internal,
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : IngestionServiceStatus.Connected,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["velit"],
  };
};

export const aSourceOwnerOutput = (
  overrides?: Partial<SourceOwnerOutput>
): { __typename: "SourceOwnerOutput" } & SourceOwnerOutput => {
  return {
    __typename: "SourceOwnerOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "c7645795-d4b2-4455-894d-661106852227",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "7f0cfc7d-c503-4bd7-8337-d0376fe974ca",
  };
};

export const aSystemAdmin = (
  overrides?: Partial<SystemAdmin>
): { __typename: "SystemAdmin" } & SystemAdmin => {
  return {
    __typename: "SystemAdmin",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "nihil",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "est",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "2e4f9b64-b221-4ccc-88e4-98c9530f9621",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "qui",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "inventore",
    organization:
      overrides && overrides.hasOwnProperty("organization")
        ? overrides.organization!
        : anAdminOrganization(),
    policy:
      overrides && overrides.hasOwnProperty("policy")
        ? overrides.policy!
        : aSystemAdminPolicy(),
    users:
      overrides && overrides.hasOwnProperty("users")
        ? overrides.users!
        : [aUser()],
  };
};

export const aSystemAdminPolicy = (
  overrides?: Partial<SystemAdminPolicy>
): { __typename: "SystemAdminPolicy" } & SystemAdminPolicy => {
  return {
    __typename: "SystemAdminPolicy",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "animi",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "et",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "f29bb72b-8d8f-432c-9aa1-371578d6860d",
    memberCreate:
      overrides && overrides.hasOwnProperty("memberCreate")
        ? overrides.memberCreate!
        : false,
    memberDelete:
      overrides && overrides.hasOwnProperty("memberDelete")
        ? overrides.memberDelete!
        : false,
    memberRead:
      overrides && overrides.hasOwnProperty("memberRead")
        ? overrides.memberRead!
        : false,
    memberUpdate:
      overrides && overrides.hasOwnProperty("memberUpdate")
        ? overrides.memberUpdate!
        : false,
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "ea",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "quo",
    orgCreate:
      overrides && overrides.hasOwnProperty("orgCreate")
        ? overrides.orgCreate!
        : true,
    orgDelete:
      overrides && overrides.hasOwnProperty("orgDelete")
        ? overrides.orgDelete!
        : false,
    orgRead:
      overrides && overrides.hasOwnProperty("orgRead")
        ? overrides.orgRead!
        : false,
    orgUpdate:
      overrides && overrides.hasOwnProperty("orgUpdate")
        ? overrides.orgUpdate!
        : true,
    systemAdminRole:
      overrides && overrides.hasOwnProperty("systemAdminRole")
        ? overrides.systemAdminRole!
        : aSystemAdmin(),
    userRead:
      overrides && overrides.hasOwnProperty("userRead")
        ? overrides.userRead!
        : false,
    workspaceCreate:
      overrides && overrides.hasOwnProperty("workspaceCreate")
        ? overrides.workspaceCreate!
        : true,
  };
};

export const aTag = (overrides?: Partial<Tag>): { __typename: "Tag" } & Tag => {
  return {
    __typename: "Tag",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "7926b150-6296-4e15-b70c-92302664d98c",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "qui",
  };
};

export const aTagUpdate = (overrides?: Partial<TagUpdate>): TagUpdate => {
  return {
    toAdd:
      overrides && overrides.hasOwnProperty("toAdd")
        ? overrides.toAdd!
        : ["dolor"],
    toRemove:
      overrides && overrides.hasOwnProperty("toRemove")
        ? overrides.toRemove!
        : ["adipisci"],
  };
};

export const aTargetSchema = (
  overrides?: Partial<TargetSchema>
): { __typename: "TargetSchema" } & TargetSchema => {
  return {
    __typename: "TargetSchema",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "nobis",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "eligendi",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "29e7513c-1ae3-4245-97a2-beaea5a285da",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "est",
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : [aTargetSchemaManagerOutput()],
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "aut",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "dolores",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : aTargetSchemaOwnerOutput(),
    piiTypes:
      overrides && overrides.hasOwnProperty("piiTypes")
        ? overrides.piiTypes!
        : ["praesentium"],
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : TargetSchemaStatus.Draft,
    tags:
      overrides && overrides.hasOwnProperty("tags") ? overrides.tags! : ["est"],
    terms:
      overrides && overrides.hasOwnProperty("terms")
        ? overrides.terms!
        : [aTermOutput()],
    types:
      overrides && overrides.hasOwnProperty("types")
        ? overrides.types!
        : [SchemaType.Input],
    versionNumber:
      overrides && overrides.hasOwnProperty("versionNumber")
        ? overrides.versionNumber!
        : 8649,
  };
};

export const aTargetSchemaFilterInput = (
  overrides?: Partial<TargetSchemaFilterInput>
): TargetSchemaFilterInput => {
  return {
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "671f212d-4b10-40c3-956e-1124f4939343",
    versionNumber:
      overrides && overrides.hasOwnProperty("versionNumber")
        ? overrides.versionNumber!
        : 213,
  };
};

export const aTargetSchemaManagerOutput = (
  overrides?: Partial<TargetSchemaManagerOutput>
): { __typename: "TargetSchemaManagerOutput" } & TargetSchemaManagerOutput => {
  return {
    __typename: "TargetSchemaManagerOutput",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "magni",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "8c3f2ee1-ff8f-4d3c-9498-3b4d80614196",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "consequatur",
  };
};

export const aTargetSchemaOwnerOutput = (
  overrides?: Partial<TargetSchemaOwnerOutput>
): { __typename: "TargetSchemaOwnerOutput" } & TargetSchemaOwnerOutput => {
  return {
    __typename: "TargetSchemaOwnerOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "f2aba7f5-cae4-4fc3-ab79-f3a200a5d3e2",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "nisi",
  };
};

export const aTermOutput = (
  overrides?: Partial<TermOutput>
): { __typename: "TermOutput" } & TermOutput => {
  return {
    __typename: "TermOutput",
    addedOn:
      overrides && overrides.hasOwnProperty("addedOn")
        ? overrides.addedOn!
        : "accusantium",
    creator:
      overrides && overrides.hasOwnProperty("creator")
        ? overrides.creator!
        : aUserOutput(),
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "est",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "7f5c7f77-55f5-4033-a7a6-49238d7ec71e",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "laudantium",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "est",
    scope:
      overrides && overrides.hasOwnProperty("scope")
        ? overrides.scope!
        : ScopeType.DataAsset,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : [aTag()],
    type:
      overrides && overrides.hasOwnProperty("type")
        ? overrides.type!
        : "dolorum",
  };
};

export const aTermResourceConnectInput = (
  overrides?: Partial<TermResourceConnectInput>
): TermResourceConnectInput => {
  return {
    resourceId:
      overrides && overrides.hasOwnProperty("resourceId")
        ? overrides.resourceId!
        : "ba020dd0-7ae6-4e04-b796-c69a749f7b2a",
    termId:
      overrides && overrides.hasOwnProperty("termId")
        ? overrides.termId!
        : "69f818e1-7160-4cf5-ad9a-a3cc6864c8d8",
  };
};

export const aTermSchemaConnectInput = (
  overrides?: Partial<TermSchemaConnectInput>
): TermSchemaConnectInput => {
  return {
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "4eb2aadc-7500-4ad6-b181-f101fc015ca9",
    termId:
      overrides && overrides.hasOwnProperty("termId")
        ? overrides.termId!
        : "e5ac304c-d4ba-4a5c-ac86-8f6b3696748a",
  };
};

export const aTransformationServiceFilterInput = (
  overrides?: Partial<TransformationServiceFilterInput>
): TransformationServiceFilterInput => {
  return {
    transformationServiceId:
      overrides && overrides.hasOwnProperty("transformationServiceId")
        ? overrides.transformationServiceId!
        : "cfddf787-f956-44a5-b9d2-2172011e6440",
  };
};

export const aTransformationServiceJobOutput = (
  overrides?: Partial<TransformationServiceJobOutput>
): {
  __typename: "TransformationServiceJobOutput";
} & TransformationServiceJobOutput => {
  return {
    __typename: "TransformationServiceJobOutput",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "voluptatem",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "e6c5c827-9eac-423a-8c3a-fc1fa846bfca",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "fuga",
    projectUsage:
      overrides && overrides.hasOwnProperty("projectUsage")
        ? overrides.projectUsage!
        : ProjectUsage.InUsage,
    projects:
      overrides && overrides.hasOwnProperty("projects")
        ? overrides.projects!
        : [aServiceEntityOutput()],
    sourceUrl:
      overrides && overrides.hasOwnProperty("sourceUrl")
        ? overrides.sourceUrl!
        : "totam",
  };
};

export const aTransformationServiceOutput = (
  overrides?: Partial<TransformationServiceOutput>
): {
  __typename: "TransformationServiceOutput";
} & TransformationServiceOutput => {
  return {
    __typename: "TransformationServiceOutput",
    configurations:
      overrides && overrides.hasOwnProperty("configurations")
        ? overrides.configurations!
        : "nostrum",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "dolorem",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "modi",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "fac86fc4-25b6-42ff-9593-1bc69b653cad",
    jobCount:
      overrides && overrides.hasOwnProperty("jobCount")
        ? overrides.jobCount!
        : 8554,
    languages:
      overrides && overrides.hasOwnProperty("languages")
        ? overrides.languages!
        : [TransformationServiceLanguage.Python],
    manager:
      overrides && overrides.hasOwnProperty("manager")
        ? overrides.manager!
        : aServiceManagerOutput(),
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "qui",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "ipsa",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : aServiceEntityOutput(),
    projectCount:
      overrides && overrides.hasOwnProperty("projectCount")
        ? overrides.projectCount!
        : 4779,
    provider:
      overrides && overrides.hasOwnProperty("provider")
        ? overrides.provider!
        : TransformationServiceProvider.DbtCloud,
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : ServiceStatus.Connected,
  };
};

export const anUpdateAssetPoliciesInput = (
  overrides?: Partial<UpdateAssetPoliciesInput>
): UpdateAssetPoliciesInput => {
  return {
    create:
      overrides && overrides.hasOwnProperty("create")
        ? overrides.create!
        : [aCreateAssetPolicyInput()],
    delete:
      overrides && overrides.hasOwnProperty("delete")
        ? overrides.delete!
        : ["75ae84f7-830b-41ee-9fa0-f95bd08ebdf7"],
    update:
      overrides && overrides.hasOwnProperty("update")
        ? overrides.update!
        : [anUpdateAssetPolicyInput()],
  };
};

export const anUpdateAssetPolicyInput = (
  overrides?: Partial<UpdateAssetPolicyInput>
): UpdateAssetPolicyInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "odio",
    href:
      overrides && overrides.hasOwnProperty("href") ? overrides.href! : "est",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "ecd9e07a-e687-429c-bb0a-4080860cc4b5",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "eligendi",
  };
};

export const anUpdateCustomPoliciesInput = (
  overrides?: Partial<UpdateCustomPoliciesInput>
): UpdateCustomPoliciesInput => {
  return {
    create:
      overrides && overrides.hasOwnProperty("create")
        ? overrides.create!
        : [aCreateCustomPolicyInput()],
    delete:
      overrides && overrides.hasOwnProperty("delete")
        ? overrides.delete!
        : ["a7fe15d5-9a55-4159-be22-f3e54dd5ec41"],
    update:
      overrides && overrides.hasOwnProperty("update")
        ? overrides.update!
        : [anUpdateCustomPolicyInput()],
  };
};

export const anUpdateCustomPolicyInput = (
  overrides?: Partial<UpdateCustomPolicyInput>
): UpdateCustomPolicyInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "rerum",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "6be04111-51bd-41b5-910b-fe1ba8318dc1",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "cupiditate",
    policyCategory:
      overrides && overrides.hasOwnProperty("policyCategory")
        ? overrides.policyCategory!
        : PolicyCategory.Access,
    referenceDocuments:
      overrides && overrides.hasOwnProperty("referenceDocuments")
        ? overrides.referenceDocuments!
        : anUpdateReferenceDocumentsInput(),
  };
};

export const anUpdateDataAssetFileInput = (
  overrides?: Partial<UpdateDataAssetFileInput>
): UpdateDataAssetFileInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "fcb0af4d-eb5f-421f-ba1c-97f535e93082",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "ab",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "11975711-e131-47fb-a4de-1403670d1297",
    writeMode:
      overrides && overrides.hasOwnProperty("writeMode")
        ? overrides.writeMode!
        : UploaderWriteMode.Append,
  };
};

export const anUpdateDataAssetFileOutput = (
  overrides?: Partial<UpdateDataAssetFileOutput>
): { __typename: "UpdateDataAssetFileOutput" } & UpdateDataAssetFileOutput => {
  return {
    __typename: "UpdateDataAssetFileOutput",
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "bdf5cff7-b95b-4563-913b-9c8dff671714",
    presignedUrl:
      overrides && overrides.hasOwnProperty("presignedUrl")
        ? overrides.presignedUrl!
        : "et",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
  };
};

export const anUpdateDataAssetInput = (
  overrides?: Partial<UpdateDataAssetInput>
): UpdateDataAssetInput => {
  return {
    accessStatus:
      overrides && overrides.hasOwnProperty("accessStatus")
        ? overrides.accessStatus!
        : AccessStatus.Private,
    assetPolicies:
      overrides && overrides.hasOwnProperty("assetPolicies")
        ? overrides.assetPolicies!
        : anUpdateAssetPoliciesInput(),
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "07c83c95-83be-4acf-9e78-6793bd6d90db",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "natus",
    managerIds:
      overrides && overrides.hasOwnProperty("managerIds")
        ? overrides.managerIds!
        : ["2a166988-32f2-432f-ad2b-fed04b90465f"],
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "harum",
    ownerId:
      overrides && overrides.hasOwnProperty("ownerId")
        ? overrides.ownerId!
        : "ff79e376-5c74-4870-8626-7a0f0f0f0378",
    refreshRate:
      overrides && overrides.hasOwnProperty("refreshRate")
        ? overrides.refreshRate!
        : RefreshRate.Daily,
    sensitivity:
      overrides && overrides.hasOwnProperty("sensitivity")
        ? overrides.sensitivity!
        : Sensitivity.Tier_1,
    source:
      overrides && overrides.hasOwnProperty("source") ? overrides.source! : "a",
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : anUpdateTagsInput(),
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "37e5aa87-f880-41d8-858c-f4909e3e4e42",
  };
};

export const anUpdateDataConsumersInput = (
  overrides?: Partial<UpdateDataConsumersInput>
): UpdateDataConsumersInput => {
  return {
    connectOrCreate:
      overrides && overrides.hasOwnProperty("connectOrCreate")
        ? overrides.connectOrCreate!
        : ["consequuntur"],
    disconnect:
      overrides && overrides.hasOwnProperty("disconnect")
        ? overrides.disconnect!
        : ["voluptatem"],
  };
};

export const anUpdateDestinationInput = (
  overrides?: Partial<UpdateDestinationInput>
): UpdateDestinationInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "aut",
    destinationId:
      overrides && overrides.hasOwnProperty("destinationId")
        ? overrides.destinationId!
        : "83e7b372-d5e8-462a-91db-f01be9c82c63",
    destinationType:
      overrides && overrides.hasOwnProperty("destinationType")
        ? overrides.destinationType!
        : DestinationType.Api,
    destinationUrl:
      overrides && overrides.hasOwnProperty("destinationUrl")
        ? overrides.destinationUrl!
        : "ad",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "et",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "1012f7a0-6e74-45bb-ba24-6b85b99339c5",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "d859ae8e-ecb9-41a2-8af4-6089b6f3a822",
  };
};

export const anUpdateFinalDataProductGroupInput = (
  overrides?: Partial<UpdateFinalDataProductGroupInput>
): UpdateFinalDataProductGroupInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "porro",
    finalDataProductGroupId:
      overrides && overrides.hasOwnProperty("finalDataProductGroupId")
        ? overrides.finalDataProductGroupId!
        : "322509c0-f617-416a-bc33-1dd0adc61bb2",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "delectus",
    method:
      overrides && overrides.hasOwnProperty("method")
        ? overrides.method!
        : FinalDataProductGroupCreationMethod.JsonUpload,
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "consequatur",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "a71f2a7e-a4d9-4e8c-96ff-7d593ec3fd4d",
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "5566f7a4-7a10-4cc9-a554-d0354f358b8b",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "29aa80dd-3301-4978-8a79-25a79e6fd012",
  };
};

export const anUpdateFinalDataProductGroupOutput = (
  overrides?: Partial<UpdateFinalDataProductGroupOutput>
): {
  __typename: "UpdateFinalDataProductGroupOutput";
} & UpdateFinalDataProductGroupOutput => {
  return {
    __typename: "UpdateFinalDataProductGroupOutput",
    finalDataProductGroupId:
      overrides && overrides.hasOwnProperty("finalDataProductGroupId")
        ? overrides.finalDataProductGroupId!
        : "a08f173a-2e42-4a6b-85d4-2509e6a15e4a",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : true,
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "062b146a-cea1-4dfb-ac35-d6e304b575dd",
  };
};

export const anUpdateGoalInput = (
  overrides?: Partial<UpdateGoalInput>
): UpdateGoalInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "placeat",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "009889e8-aab0-431a-b37f-de32e492a4c1",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "aliquam",
  };
};

export const anUpdateGoalsInput = (
  overrides?: Partial<UpdateGoalsInput>
): UpdateGoalsInput => {
  return {
    create:
      overrides && overrides.hasOwnProperty("create")
        ? overrides.create!
        : [aCreateGoalInput()],
    delete:
      overrides && overrides.hasOwnProperty("delete")
        ? overrides.delete!
        : ["cbb6a5d4-f477-45d2-83b5-39e669965412"],
    update:
      overrides && overrides.hasOwnProperty("update")
        ? overrides.update!
        : [anUpdateGoalInput()],
  };
};

export const anUpdateGovernanceInput = (
  overrides?: Partial<UpdateGovernanceInput>
): UpdateGovernanceInput => {
  return {
    customPolicies:
      overrides && overrides.hasOwnProperty("customPolicies")
        ? overrides.customPolicies!
        : anUpdateCustomPoliciesInput(),
    goals:
      overrides && overrides.hasOwnProperty("goals")
        ? overrides.goals!
        : anUpdateGoalsInput(),
    legalDocuments:
      overrides && overrides.hasOwnProperty("legalDocuments")
        ? overrides.legalDocuments!
        : anUpdateLegalDocumentsInput(),
    monitoredPolicies:
      overrides && overrides.hasOwnProperty("monitoredPolicies")
        ? overrides.monitoredPolicies!
        : anUpdateMonitoredPoliciesInput(),
    referenceDocuments:
      overrides && overrides.hasOwnProperty("referenceDocuments")
        ? overrides.referenceDocuments!
        : anUpdateReferenceDocumentsInput(),
    workspaceDescription:
      overrides && overrides.hasOwnProperty("workspaceDescription")
        ? overrides.workspaceDescription!
        : "odit",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "ad394581-7921-42bf-b0f9-1732f03b036e",
    workspaceName:
      overrides && overrides.hasOwnProperty("workspaceName")
        ? overrides.workspaceName!
        : "voluptatibus",
  };
};

export const anUpdateInputDataAssetGroupInput = (
  overrides?: Partial<UpdateInputDataAssetGroupInput>
): UpdateInputDataAssetGroupInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "officiis",
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "d635414a-7838-4f12-b9be-a1f0a3073c1e",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "blanditiis",
    method:
      overrides && overrides.hasOwnProperty("method")
        ? overrides.method!
        : InputDataAssetGroupCreationMethod.JsonUpload,
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "non",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "cb61b386-d631-412f-8425-59ca2152cb7b",
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "9d761eb9-fcda-4b77-bdb3-dcbcaea5c39a",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "d7631a9c-7021-44f3-b902-c17b8dc39910",
  };
};

export const anUpdateInputDataAssetGroupOutput = (
  overrides?: Partial<UpdateInputDataAssetGroupOutput>
): {
  __typename: "UpdateInputDataAssetGroupOutput";
} & UpdateInputDataAssetGroupOutput => {
  return {
    __typename: "UpdateInputDataAssetGroupOutput",
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "af221e03-158d-4e5a-827e-60ddbab56cde",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "9b4da3a6-0ad6-4760-8561-6f20b5b6387f",
  };
};

export const anUpdateLegalDocumentInput = (
  overrides?: Partial<UpdateLegalDocumentInput>
): UpdateLegalDocumentInput => {
  return {
    href:
      overrides && overrides.hasOwnProperty("href") ? overrides.href! : "quos",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "5681729f-c56a-4aaf-b528-a2922b9d5e3d",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "asperiores",
  };
};

export const anUpdateLegalDocumentsInput = (
  overrides?: Partial<UpdateLegalDocumentsInput>
): UpdateLegalDocumentsInput => {
  return {
    create:
      overrides && overrides.hasOwnProperty("create")
        ? overrides.create!
        : [aCreateLegalDocumentInput()],
    delete:
      overrides && overrides.hasOwnProperty("delete")
        ? overrides.delete!
        : ["59202564-e4d4-4075-97c9-00ee94fe51df"],
    update:
      overrides && overrides.hasOwnProperty("update")
        ? overrides.update!
        : [anUpdateLegalDocumentInput()],
  };
};

export const anUpdateManagersInput = (
  overrides?: Partial<UpdateManagersInput>
): UpdateManagersInput => {
  return {
    connect:
      overrides && overrides.hasOwnProperty("connect")
        ? overrides.connect!
        : ["09d79f5c-b383-4499-8e84-a50e7c1ab05c"],
    disconnect:
      overrides && overrides.hasOwnProperty("disconnect")
        ? overrides.disconnect!
        : ["b53c9efa-1afc-4cd9-b299-a0f390766af6"],
  };
};

export const anUpdateMonitoredPoliciesInput = (
  overrides?: Partial<UpdateMonitoredPoliciesInput>
): UpdateMonitoredPoliciesInput => {
  return {
    connect:
      overrides && overrides.hasOwnProperty("connect")
        ? overrides.connect!
        : ["bbe98f87-4af6-401c-9089-439017fa43fe"],
    disconnect:
      overrides && overrides.hasOwnProperty("disconnect")
        ? overrides.disconnect!
        : ["0719bc76-438b-4970-8816-87643191be28"],
  };
};

export const anUpdateOrganizationRoleInput = (
  overrides?: Partial<UpdateOrganizationRoleInput>
): UpdateOrganizationRoleInput => {
  return {
    newRole:
      overrides && overrides.hasOwnProperty("newRole")
        ? overrides.newRole!
        : OrganizationRoleEnum.Admin,
    organizationId:
      overrides && overrides.hasOwnProperty("organizationId")
        ? overrides.organizationId!
        : "144dce0c-12f8-4699-995d-65b135da116f",
    userId:
      overrides && overrides.hasOwnProperty("userId")
        ? overrides.userId!
        : "3668f44b-834b-4aae-ba9c-77607c23c9ad",
  };
};

export const anUpdateOutput = (
  overrides?: Partial<UpdateOutput>
): { __typename: "UpdateOutput" } & UpdateOutput => {
  return {
    __typename: "UpdateOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const anUpdateProjectGoalInput = (
  overrides?: Partial<UpdateProjectGoalInput>
): UpdateProjectGoalInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "ut",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "395a3d92-a9ff-4eab-b2aa-5291ba2b9ee5",
    metrics:
      overrides && overrides.hasOwnProperty("metrics")
        ? overrides.metrics!
        : ["aut"],
  };
};

export const anUpdateProjectGoalsInput = (
  overrides?: Partial<UpdateProjectGoalsInput>
): UpdateProjectGoalsInput => {
  return {
    create:
      overrides && overrides.hasOwnProperty("create")
        ? overrides.create!
        : [aCreateProjectGoalInput()],
    delete:
      overrides && overrides.hasOwnProperty("delete")
        ? overrides.delete!
        : ["1b2525ec-113f-450e-9a2f-d8d53b97fa1e"],
    update:
      overrides && overrides.hasOwnProperty("update")
        ? overrides.update!
        : [anUpdateProjectGoalInput()],
  };
};

export const anUpdateProjectInput = (
  overrides?: Partial<UpdateProjectInput>
): UpdateProjectInput => {
  return {
    backgroundMaterials:
      overrides && overrides.hasOwnProperty("backgroundMaterials")
        ? overrides.backgroundMaterials!
        : anUpdateReferenceDocumentsInput(),
    dataConsumers:
      overrides && overrides.hasOwnProperty("dataConsumers")
        ? overrides.dataConsumers!
        : anUpdateDataConsumersInput(),
    dataProductType:
      overrides && overrides.hasOwnProperty("dataProductType")
        ? overrides.dataProductType!
        : DataProductType.FinalDataProduct,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "et",
    endDate:
      overrides && overrides.hasOwnProperty("endDate")
        ? overrides.endDate!
        : "1970-01-02T17:04:28.410Z",
    goals:
      overrides && overrides.hasOwnProperty("goals")
        ? overrides.goals!
        : anUpdateProjectGoalsInput(),
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : anUpdateProjectManagersInput(),
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "sint",
    ownerId:
      overrides && overrides.hasOwnProperty("ownerId")
        ? overrides.ownerId!
        : "8bd1d5e1-144a-4193-b652-367843e68450",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "efc7c590-9610-43bf-b167-0cae0f472312",
    projectStatus:
      overrides && overrides.hasOwnProperty("projectStatus")
        ? overrides.projectStatus!
        : ProjectStatus.Active,
    startDate:
      overrides && overrides.hasOwnProperty("startDate")
        ? overrides.startDate!
        : "1970-01-16T19:29:26.272Z",
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : anUpdateTagsInput(),
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "255fe89e-0c0b-4a4b-9484-bf8fe4f85726",
  };
};

export const anUpdateProjectManagersInput = (
  overrides?: Partial<UpdateProjectManagersInput>
): UpdateProjectManagersInput => {
  return {
    connect:
      overrides && overrides.hasOwnProperty("connect")
        ? overrides.connect!
        : ["c9d93d1d-baf7-4516-9c36-b3f50fc57eb7"],
    disconnect:
      overrides && overrides.hasOwnProperty("disconnect")
        ? overrides.disconnect!
        : ["1caff5ee-58f6-465f-84c1-58b37a90327b"],
  };
};

export const anUpdateReferenceDocumentInput = (
  overrides?: Partial<UpdateReferenceDocumentInput>
): UpdateReferenceDocumentInput => {
  return {
    href:
      overrides && overrides.hasOwnProperty("href") ? overrides.href! : "aut",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "b56849f1-c2d7-49c7-9abf-0b3c10ddd7c5",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "deleniti",
  };
};

export const anUpdateReferenceDocumentsInput = (
  overrides?: Partial<UpdateReferenceDocumentsInput>
): UpdateReferenceDocumentsInput => {
  return {
    create:
      overrides && overrides.hasOwnProperty("create")
        ? overrides.create!
        : [aCreateReferenceDocumentInput()],
    delete:
      overrides && overrides.hasOwnProperty("delete")
        ? overrides.delete!
        : ["59b72a83-5acf-49e7-b0a9-6b7a1cd7b117"],
    update:
      overrides && overrides.hasOwnProperty("update")
        ? overrides.update!
        : [anUpdateReferenceDocumentInput()],
  };
};

export const anUpdateResourceInput = (
  overrides?: Partial<UpdateResourceInput>
): UpdateResourceInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "in",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "optio",
    resourceId:
      overrides && overrides.hasOwnProperty("resourceId")
        ? overrides.resourceId!
        : "6378498a-eb68-4222-9099-ffb3b04b218c",
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["molestiae"],
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "26b30a45-d779-4934-ada3-c0c4207afe7a",
  };
};

export const anUpdateRoleInput = (
  overrides?: Partial<UpdateRoleInput>
): UpdateRoleInput => {
  return {
    newRole:
      overrides && overrides.hasOwnProperty("newRole")
        ? overrides.newRole!
        : Role.Admin,
    userId:
      overrides && overrides.hasOwnProperty("userId")
        ? overrides.userId!
        : "bb9be381-c895-4b70-88c6-be87948af2d9",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "aa824710-5aa3-4c02-a044-4932b6bb6a02",
  };
};

export const anUpdateSchemaInput = (
  overrides?: Partial<UpdateSchemaInput>
): UpdateSchemaInput => {
  return {
    columnIndex:
      overrides && overrides.hasOwnProperty("columnIndex")
        ? overrides.columnIndex!
        : 8228,
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "06150f08-c694-4565-8e53-488fceb184cf",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "perferendis",
    piiTag:
      overrides && overrides.hasOwnProperty("piiTag")
        ? overrides.piiTag!
        : PiiTagType.None,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "c0a52439-7364-4af2-a11c-633886d1b9b9",
  };
};

export const anUpdateTagsInput = (
  overrides?: Partial<UpdateTagsInput>
): UpdateTagsInput => {
  return {
    connectOrCreate:
      overrides && overrides.hasOwnProperty("connectOrCreate")
        ? overrides.connectOrCreate!
        : ["aut"],
    disconnect:
      overrides && overrides.hasOwnProperty("disconnect")
        ? overrides.disconnect!
        : ["placeat"],
  };
};

export const anUpdateTargetSchemaInput = (
  overrides?: Partial<UpdateTargetSchemaInput>
): UpdateTargetSchemaInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "et",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "et",
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : anUpdateManagersInput(),
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "et",
    ownerId:
      overrides && overrides.hasOwnProperty("ownerId")
        ? overrides.ownerId!
        : "5660a90d-775c-4fa3-bcb8-21d46c5170f9",
    piiTypes:
      overrides && overrides.hasOwnProperty("piiTypes")
        ? overrides.piiTypes!
        : anUpdateTagsInput(),
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "35fcfb50-a7df-477d-8a28-61baa1d656f8",
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : TargetSchemaStatus.Draft,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : anUpdateTagsInput(),
    targetSchemaId:
      overrides && overrides.hasOwnProperty("targetSchemaId")
        ? overrides.targetSchemaId!
        : "22db9e1d-0dd8-4e17-a3ed-bdb2b752874b",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "7deea2ba-0bfe-439e-995e-f671be35be87",
  };
};

export const anUpdateTargetSchemaOutput = (
  overrides?: Partial<UpdateTargetSchemaOutput>
): { __typename: "UpdateTargetSchemaOutput" } & UpdateTargetSchemaOutput => {
  return {
    __typename: "UpdateTargetSchemaOutput",
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
    targetSchema:
      overrides && overrides.hasOwnProperty("targetSchema")
        ? overrides.targetSchema!
        : aTargetSchema(),
  };
};

export const anUpdateTermInput = (
  overrides?: Partial<UpdateTermInput>
): UpdateTermInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "sint",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "53161a17-2db8-491f-8a58-d70c1325d897",
    tags:
      overrides && overrides.hasOwnProperty("tags") ? overrides.tags! : ["non"],
    type:
      overrides && overrides.hasOwnProperty("type") ? overrides.type! : "fugit",
  };
};

export const anUpdateTransformationInput = (
  overrides?: Partial<UpdateTransformationInput>
): UpdateTransformationInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "possimus",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "expedita",
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "f1710efd-41a2-4737-9908-d4dd39b4ac07",
    sourceUrl:
      overrides && overrides.hasOwnProperty("sourceUrl")
        ? overrides.sourceUrl!
        : "aut",
    transformationId:
      overrides && overrides.hasOwnProperty("transformationId")
        ? overrides.transformationId!
        : "7d8cccde-30e8-4b43-9079-e5056e9a05d3",
    transformationMethod:
      overrides && overrides.hasOwnProperty("transformationMethod")
        ? overrides.transformationMethod!
        : TransformationMethod.DbtCloud,
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "105d048e-551c-4bf2-9db2-f5f5e344f07c",
  };
};

export const anUpdateWorkspaceInput = (
  overrides?: Partial<UpdateWorkspaceInput>
): UpdateWorkspaceInput => {
  return {
    logo:
      overrides && overrides.hasOwnProperty("logo")
        ? overrides.logo!
        : anUpdateWorkspaceLogoInput(),
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "illum",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "7261980b-72b1-49a1-938e-bd4b8cc50421",
  };
};

export const anUpdateWorkspaceLogoInput = (
  overrides?: Partial<UpdateWorkspaceLogoInput>
): UpdateWorkspaceLogoInput => {
  return {
    mimeType:
      overrides && overrides.hasOwnProperty("mimeType")
        ? overrides.mimeType!
        : "vel",
  };
};

export const anUpdateWorkspaceOutput = (
  overrides?: Partial<UpdateWorkspaceOutput>
): { __typename: "UpdateWorkspaceOutput" } & UpdateWorkspaceOutput => {
  return {
    __typename: "UpdateWorkspaceOutput",
    logoUploadContext:
      overrides && overrides.hasOwnProperty("logoUploadContext")
        ? overrides.logoUploadContext!
        : aLogoUploadContext(),
    success:
      overrides && overrides.hasOwnProperty("success")
        ? overrides.success!
        : false,
  };
};

export const anUpdateWorkspaceRoleScopeInput = (
  overrides?: Partial<UpdateWorkspaceRoleScopeInput>
): UpdateWorkspaceRoleScopeInput => {
  return {
    projectsToAdd:
      overrides && overrides.hasOwnProperty("projectsToAdd")
        ? overrides.projectsToAdd!
        : ["e00dcb27-a804-457d-bb25-1bc9978e8a48"],
    projectsToRemove:
      overrides && overrides.hasOwnProperty("projectsToRemove")
        ? overrides.projectsToRemove!
        : ["23cd5f0f-7cb5-4348-90ea-8487d9eb128a"],
    userId:
      overrides && overrides.hasOwnProperty("userId")
        ? overrides.userId!
        : "adf67fb8-5557-433f-91fc-8d6303a7516d",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "55732a95-7b94-4b4c-9974-c088de3199c5",
  };
};

export const anUpdateWorkspaceSchemaInput = (
  overrides?: Partial<UpdateWorkspaceSchemaInput>
): UpdateWorkspaceSchemaInput => {
  return {
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "porro",
    jsonSchema:
      overrides && overrides.hasOwnProperty("jsonSchema")
        ? overrides.jsonSchema!
        : "atque",
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : anUpdateManagersInput(),
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "nesciunt",
    ownerId:
      overrides && overrides.hasOwnProperty("ownerId")
        ? overrides.ownerId!
        : "8bfaf0ff-a655-4ea6-96cf-46a2d4cc3609",
    piiTypes:
      overrides && overrides.hasOwnProperty("piiTypes")
        ? overrides.piiTypes!
        : anUpdateTagsInput(),
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "33b4b9f3-9af6-4154-90ab-10a3fd1a9d03",
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : TargetSchemaStatus.Draft,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : anUpdateTagsInput(),
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "a2b1779f-907a-415b-9864-2f87b1446d52",
  };
};

export const aUser = (
  overrides?: Partial<User>
): { __typename: "User" } & User => {
  return {
    __typename: "User",
    active:
      overrides && overrides.hasOwnProperty("active")
        ? overrides.active!
        : false,
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "asperiores",
    emailAddress:
      overrides && overrides.hasOwnProperty("emailAddress")
        ? overrides.emailAddress!
        : "veniam",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "qui",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "a5756f00-41a6-422a-8a7d-d13ee6a63750",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "velit",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "qui",
    organizationRole:
      overrides && overrides.hasOwnProperty("organizationRole")
        ? overrides.organizationRole!
        : aGeneralUser(),
    systemAdminRole:
      overrides && overrides.hasOwnProperty("systemAdminRole")
        ? overrides.systemAdminRole!
        : aSystemAdmin(),
    workspaceRoles:
      overrides && overrides.hasOwnProperty("workspaceRoles")
        ? overrides.workspaceRoles!
        : [aCollaborator()],
  };
};

export const aUserDataAssetOutput = (
  overrides?: Partial<UserDataAssetOutput>
): { __typename: "UserDataAssetOutput" } & UserDataAssetOutput => {
  return {
    __typename: "UserDataAssetOutput",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "molestiae",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "b145c58b-ffb4-4de9-9a65-2ba63ef37607",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "repudiandae",
  };
};

export const aUserOutput = (
  overrides?: Partial<UserOutput>
): { __typename: "UserOutput" } & UserOutput => {
  return {
    __typename: "UserOutput",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "voluptatum",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "4e8aacde-d8e0-4502-a7ec-25ab45b8f1d2",
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "nulla",
  };
};

export const aValidateTransformationsInProjectInput = (
  overrides?: Partial<ValidateTransformationsInProjectInput>
): ValidateTransformationsInProjectInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "18e362e1-ea31-42ce-b618-c1a328b2cb91",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "7563f30e-8a95-4255-b2e5-e1bf8901ca1a",
  };
};

export const aViewer = (
  overrides?: Partial<Viewer>
): { __typename: "Viewer" } & Viewer => {
  return {
    __typename: "Viewer",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "dignissimos",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "dolore",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "b786abe6-aab7-4a59-ad28-8aa00011215e",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "est",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "sed",
    policy:
      overrides && overrides.hasOwnProperty("policy")
        ? overrides.policy!
        : aWorkspacePolicy(),
    users:
      overrides && overrides.hasOwnProperty("users")
        ? overrides.users!
        : [aUser()],
    workspace:
      overrides && overrides.hasOwnProperty("workspace")
        ? overrides.workspace!
        : aWorkspace(),
  };
};

export const aWarehouseServiceFilterInput = (
  overrides?: Partial<WarehouseServiceFilterInput>
): WarehouseServiceFilterInput => {
  return {
    warehouseServiceId:
      overrides && overrides.hasOwnProperty("warehouseServiceId")
        ? overrides.warehouseServiceId!
        : "7051d835-c87e-4a32-97e3-daf852be8bd5",
  };
};

export const aWarehouseServiceOutput = (
  overrides?: Partial<WarehouseServiceOutput>
): { __typename: "WarehouseServiceOutput" } & WarehouseServiceOutput => {
  return {
    __typename: "WarehouseServiceOutput",
    configurations:
      overrides && overrides.hasOwnProperty("configurations")
        ? overrides.configurations!
        : "facilis",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "dolores",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "consequatur",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "775466f9-e5cc-4ce8-93bf-3ec93997c125",
    manager:
      overrides && overrides.hasOwnProperty("manager")
        ? overrides.manager!
        : aServiceManagerOutput(),
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "qui",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "tempore",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : aServiceEntityOutput(),
    provider:
      overrides && overrides.hasOwnProperty("provider")
        ? overrides.provider!
        : WarehouseServiceProvider.Redshift,
    status:
      overrides && overrides.hasOwnProperty("status")
        ? overrides.status!
        : ServiceStatus.Connected,
  };
};

export const aWorkflow = (
  overrides?: Partial<Workflow>
): { __typename: "Workflow" } & Workflow => {
  return {
    __typename: "Workflow",
    destinations:
      overrides && overrides.hasOwnProperty("destinations")
        ? overrides.destinations!
        : [aWorkflowDestinationOutput()],
    finalDataProducts:
      overrides && overrides.hasOwnProperty("finalDataProducts")
        ? overrides.finalDataProducts!
        : [aWorkflowFinalDataProductGroupOutput()],
    inputDataAssetGroups:
      overrides && overrides.hasOwnProperty("inputDataAssetGroups")
        ? overrides.inputDataAssetGroups!
        : [aWorkflowInputDataAssetGroupOutput()],
    organizations:
      overrides && overrides.hasOwnProperty("organizations")
        ? overrides.organizations!
        : [aWorkflowOrganizationOutput()],
    projectName:
      overrides && overrides.hasOwnProperty("projectName")
        ? overrides.projectName!
        : "minima",
    state:
      overrides && overrides.hasOwnProperty("state")
        ? overrides.state!
        : WorkflowState.Active,
    transformations:
      overrides && overrides.hasOwnProperty("transformations")
        ? overrides.transformations!
        : [aWorkflowTransformationOutput()],
  };
};

export const aWorkflowDataAssetGroupDataAssetOutput = (
  overrides?: Partial<WorkflowDataAssetGroupDataAssetOutput>
): {
  __typename: "WorkflowDataAssetGroupDataAssetOutput";
} & WorkflowDataAssetGroupDataAssetOutput => {
  return {
    __typename: "WorkflowDataAssetGroupDataAssetOutput",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "fb6a5865-fa2f-4d1d-8c71-72ab87b7f1c7",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "natus",
    ownerName:
      overrides && overrides.hasOwnProperty("ownerName")
        ? overrides.ownerName!
        : "consequatur",
  };
};

export const aWorkflowDataAssetGroupOutput = (
  overrides?: Partial<WorkflowDataAssetGroupOutput>
): {
  __typename: "WorkflowDataAssetGroupOutput";
} & WorkflowDataAssetGroupOutput => {
  return {
    __typename: "WorkflowDataAssetGroupOutput",
    dataAssets:
      overrides && overrides.hasOwnProperty("dataAssets")
        ? overrides.dataAssets!
        : [aWorkflowDataAssetGroupDataAssetOutput()],
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "82247fee-b2bf-444b-b7d2-9ac083b4704a",
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    linkedNodes:
      overrides && overrides.hasOwnProperty("linkedNodes")
        ? overrides.linkedNodes!
        : ["4c1c48ff-813d-4e6d-b529-db61e5e606c4"],
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "at",
    pii: overrides && overrides.hasOwnProperty("pii") ? overrides.pii! : false,
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "44de58b1-110f-441c-bc96-f8624a65d43b",
  };
};

export const aWorkflowDestinationFilterInput = (
  overrides?: Partial<WorkflowDestinationFilterInput>
): WorkflowDestinationFilterInput => {
  return {
    destinationId:
      overrides && overrides.hasOwnProperty("destinationId")
        ? overrides.destinationId!
        : "785f473e-94f9-4737-aa6f-66cb7fbfa33c",
  };
};

export const aWorkflowDestinationOutput = (
  overrides?: Partial<WorkflowDestinationOutput>
): { __typename: "WorkflowDestinationOutput" } & WorkflowDestinationOutput => {
  return {
    __typename: "WorkflowDestinationOutput",
    connectionSettings:
      overrides && overrides.hasOwnProperty("connectionSettings")
        ? overrides.connectionSettings!
        : "ut",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "omnis",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "aspernatur",
    destinationType:
      overrides && overrides.hasOwnProperty("destinationType")
        ? overrides.destinationType!
        : DestinationType.Api,
    destinationUrl:
      overrides && overrides.hasOwnProperty("destinationUrl")
        ? overrides.destinationUrl!
        : "nihil",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "529d3ba4-7ce7-4e82-9ece-a6aafb3cf4d1",
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "tenetur",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "rem",
  };
};

export const aWorkflowFilterInput = (
  overrides?: Partial<WorkflowFilterInput>
): WorkflowFilterInput => {
  return {
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "a2d8f9c2-0a8d-4f40-8754-830442c3d662",
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "9b308660-b102-40ed-88d0-e92914ed9592",
  };
};

export const aWorkflowFinalDataProductGroupDataAsset = (
  overrides?: Partial<WorkflowFinalDataProductGroupDataAsset>
): {
  __typename: "WorkflowFinalDataProductGroupDataAsset";
} & WorkflowFinalDataProductGroupDataAsset => {
  return {
    __typename: "WorkflowFinalDataProductGroupDataAsset",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "cupiditate",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "a688ff33-06b0-4561-ae7b-7344db438943",
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "sunt",
    ownerName:
      overrides && overrides.hasOwnProperty("ownerName")
        ? overrides.ownerName!
        : "quod",
    pii: overrides && overrides.hasOwnProperty("pii") ? overrides.pii! : true,
  };
};

export const aWorkflowFinalDataProductGroupDataAssetFilterInput = (
  overrides?: Partial<WorkflowFinalDataProductGroupDataAssetFilterInput>
): WorkflowFinalDataProductGroupDataAssetFilterInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "15de9f0b-734b-47e1-b5f6-4d0c8d69ebf3",
  };
};

export const aWorkflowFinalDataProductGroupFilterInput = (
  overrides?: Partial<WorkflowFinalDataProductGroupFilterInput>
): WorkflowFinalDataProductGroupFilterInput => {
  return {
    finalDataProductGroupId:
      overrides && overrides.hasOwnProperty("finalDataProductGroupId")
        ? overrides.finalDataProductGroupId!
        : "309015ae-82a7-4720-801e-f2b8879ba2f8",
  };
};

export const aWorkflowFinalDataProductGroupOutput = (
  overrides?: Partial<WorkflowFinalDataProductGroupOutput>
): {
  __typename: "WorkflowFinalDataProductGroupOutput";
} & WorkflowFinalDataProductGroupOutput => {
  return {
    __typename: "WorkflowFinalDataProductGroupOutput",
    dataAssets:
      overrides && overrides.hasOwnProperty("dataAssets")
        ? overrides.dataAssets!
        : [aWorkflowFinalDataProductGroupDataAsset()],
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "molestias",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "306e2380-9e83-47e8-a684-3f0a0d1c8f50",
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    linkedNodes:
      overrides && overrides.hasOwnProperty("linkedNodes")
        ? overrides.linkedNodes!
        : ["25f9031a-d001-4350-bc58-7fd1cd7631e0"],
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "id",
    pii: overrides && overrides.hasOwnProperty("pii") ? overrides.pii! : false,
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "24b90b57-4f3d-4a08-91c7-2596c8dcded9",
    targetSchema:
      overrides && overrides.hasOwnProperty("targetSchema")
        ? overrides.targetSchema!
        : aTargetSchema(),
  };
};

export const aWorkflowInputDataAssetGroupDataAsset = (
  overrides?: Partial<WorkflowInputDataAssetGroupDataAsset>
): {
  __typename: "WorkflowInputDataAssetGroupDataAsset";
} & WorkflowInputDataAssetGroupDataAsset => {
  return {
    __typename: "WorkflowInputDataAssetGroupDataAsset",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "voluptatem",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "8e61f8aa-2c8f-4d6b-83f8-034f9cceb82a",
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "ut",
    ownerName:
      overrides && overrides.hasOwnProperty("ownerName")
        ? overrides.ownerName!
        : "earum",
    pii: overrides && overrides.hasOwnProperty("pii") ? overrides.pii! : false,
  };
};

export const aWorkflowInputDataAssetGroupDataAssetFilterInput = (
  overrides?: Partial<WorkflowInputDataAssetGroupDataAssetFilterInput>
): WorkflowInputDataAssetGroupDataAssetFilterInput => {
  return {
    dataAssetId:
      overrides && overrides.hasOwnProperty("dataAssetId")
        ? overrides.dataAssetId!
        : "8e419ca2-9e1c-41bf-afda-7aa0b0d7c0db",
  };
};

export const aWorkflowInputDataAssetGroupDataAssetSuggestion = (
  overrides?: Partial<WorkflowInputDataAssetGroupDataAssetSuggestion>
): {
  __typename: "WorkflowInputDataAssetGroupDataAssetSuggestion";
} & WorkflowInputDataAssetGroupDataAssetSuggestion => {
  return {
    __typename: "WorkflowInputDataAssetGroupDataAssetSuggestion",
    accessStatus:
      overrides && overrides.hasOwnProperty("accessStatus")
        ? overrides.accessStatus!
        : AccessStatus.Private,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "eaque",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "dc6950b9-aa06-4821-a75d-9f418c37de53",
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    managers:
      overrides && overrides.hasOwnProperty("managers")
        ? overrides.managers!
        : [aUserDataAssetOutput()],
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "eos",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : anInputDataAssetOwnerOutput(),
    pii: overrides && overrides.hasOwnProperty("pii") ? overrides.pii! : false,
    tags:
      overrides && overrides.hasOwnProperty("tags")
        ? overrides.tags!
        : ["iusto"],
  };
};

export const aWorkflowInputDataAssetGroupFilterInput = (
  overrides?: Partial<WorkflowInputDataAssetGroupFilterInput>
): WorkflowInputDataAssetGroupFilterInput => {
  return {
    inputDataAssetGroupId:
      overrides && overrides.hasOwnProperty("inputDataAssetGroupId")
        ? overrides.inputDataAssetGroupId!
        : "5cb23992-deb0-4d2c-9fa6-7742c6fe07f2",
  };
};

export const aWorkflowInputDataAssetGroupOutput = (
  overrides?: Partial<WorkflowInputDataAssetGroupOutput>
): {
  __typename: "WorkflowInputDataAssetGroupOutput";
} & WorkflowInputDataAssetGroupOutput => {
  return {
    __typename: "WorkflowInputDataAssetGroupOutput",
    dataAssetSuggestions:
      overrides && overrides.hasOwnProperty("dataAssetSuggestions")
        ? overrides.dataAssetSuggestions!
        : [aWorkflowInputDataAssetGroupDataAssetSuggestion()],
    dataAssets:
      overrides && overrides.hasOwnProperty("dataAssets")
        ? overrides.dataAssets!
        : [aWorkflowInputDataAssetGroupDataAsset()],
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "facilis",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "14b3fb13-86e9-4d76-9c7b-9635ab465dfe",
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    linkedNodes:
      overrides && overrides.hasOwnProperty("linkedNodes")
        ? overrides.linkedNodes!
        : ["4967f6ae-91de-494d-99cc-54538524090e"],
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "modi",
    pii: overrides && overrides.hasOwnProperty("pii") ? overrides.pii! : false,
    schemaId:
      overrides && overrides.hasOwnProperty("schemaId")
        ? overrides.schemaId!
        : "adf0fe54-b894-4328-a3b8-acc0000e30d0",
    targetSchema:
      overrides && overrides.hasOwnProperty("targetSchema")
        ? overrides.targetSchema!
        : aTargetSchema(),
  };
};

export const aWorkflowOrganizationContributor = (
  overrides?: Partial<WorkflowOrganizationContributor>
): {
  __typename: "WorkflowOrganizationContributor";
} & WorkflowOrganizationContributor => {
  return {
    __typename: "WorkflowOrganizationContributor",
    firstName:
      overrides && overrides.hasOwnProperty("firstName")
        ? overrides.firstName!
        : "suscipit",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "bced8bef-5759-4ea7-ae44-b33cdfa2c3e1",
    isProjectManager:
      overrides && overrides.hasOwnProperty("isProjectManager")
        ? overrides.isProjectManager!
        : true,
    lastName:
      overrides && overrides.hasOwnProperty("lastName")
        ? overrides.lastName!
        : "atque",
  };
};

export const aWorkflowOrganizationFilterInput = (
  overrides?: Partial<WorkflowOrganizationFilterInput>
): WorkflowOrganizationFilterInput => {
  return {
    organizationId:
      overrides && overrides.hasOwnProperty("organizationId")
        ? overrides.organizationId!
        : "5ff6d58b-3299-4e6b-9dd3-6200c8fd862a",
  };
};

export const aWorkflowOrganizationOutput = (
  overrides?: Partial<WorkflowOrganizationOutput>
): {
  __typename: "WorkflowOrganizationOutput";
} & WorkflowOrganizationOutput => {
  return {
    __typename: "WorkflowOrganizationOutput",
    contributors:
      overrides && overrides.hasOwnProperty("contributors")
        ? overrides.contributors!
        : [aProjectOrganizationContributor()],
    dataAssetCount:
      overrides && overrides.hasOwnProperty("dataAssetCount")
        ? overrides.dataAssetCount!
        : 8617,
    dataProductCount:
      overrides && overrides.hasOwnProperty("dataProductCount")
        ? overrides.dataProductCount!
        : 3878,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "voluptate",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "21412766-e6ed-435f-9c55-0e807b82d840",
    inputDataAssetCount:
      overrides && overrides.hasOwnProperty("inputDataAssetCount")
        ? overrides.inputDataAssetCount!
        : 6014,
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    linkedNodes:
      overrides && overrides.hasOwnProperty("linkedNodes")
        ? overrides.linkedNodes!
        : ["0b9c3420-19b1-4274-9498-36b739b112b0"],
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "et",
    projectRole:
      overrides && overrides.hasOwnProperty("projectRole")
        ? overrides.projectRole!
        : ProjectOrganizationRole.CollaboratingOrganization,
  };
};

export const aWorkflowTransformationFilterInput = (
  overrides?: Partial<WorkflowTransformationFilterInput>
): WorkflowTransformationFilterInput => {
  return {
    transformationId:
      overrides && overrides.hasOwnProperty("transformationId")
        ? overrides.transformationId!
        : "e5f6190a-81db-4b5a-93a6-35ed91ada243",
  };
};

export const aWorkflowTransformationJob = (
  overrides?: Partial<WorkflowTransformationJob>
): { __typename: "WorkflowTransformationJob" } & WorkflowTransformationJob => {
  return {
    __typename: "WorkflowTransformationJob",
    arguments:
      overrides && overrides.hasOwnProperty("arguments")
        ? overrides.arguments!
        : "qui",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "ratione",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "pariatur",
    duration:
      overrides && overrides.hasOwnProperty("duration")
        ? overrides.duration!
        : "nobis",
    endedAt:
      overrides && overrides.hasOwnProperty("endedAt")
        ? overrides.endedAt!
        : "velit",
    facets:
      overrides && overrides.hasOwnProperty("facets")
        ? overrides.facets!
        : "animi",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "01898bd5-acdb-466a-ba0a-e93824ffcfd5",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "quasi",
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "pariatur",
    nominalEndTime:
      overrides && overrides.hasOwnProperty("nominalEndTime")
        ? overrides.nominalEndTime!
        : "sit",
    nominalStartTime:
      overrides && overrides.hasOwnProperty("nominalStartTime")
        ? overrides.nominalStartTime!
        : "aut",
    runId:
      overrides && overrides.hasOwnProperty("runId") ? overrides.runId! : "sit",
    startedAt:
      overrides && overrides.hasOwnProperty("startedAt")
        ? overrides.startedAt!
        : "quia",
    state:
      overrides && overrides.hasOwnProperty("state")
        ? overrides.state!
        : JobState.Cancelled,
  };
};

export const aWorkflowTransformationOutput = (
  overrides?: Partial<WorkflowTransformationOutput>
): {
  __typename: "WorkflowTransformationOutput";
} & WorkflowTransformationOutput => {
  return {
    __typename: "WorkflowTransformationOutput",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "cum",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "fa575179-5c8a-4ac1-ba9b-40512611e8cb",
    issueStatus:
      overrides && overrides.hasOwnProperty("issueStatus")
        ? overrides.issueStatus!
        : WorkflowIssueStatus.Incomplete,
    job:
      overrides && overrides.hasOwnProperty("job")
        ? overrides.job!
        : aWorkflowTransformationJob(),
    jobCount:
      overrides && overrides.hasOwnProperty("jobCount")
        ? overrides.jobCount!
        : 7025,
    linkedNodes:
      overrides && overrides.hasOwnProperty("linkedNodes")
        ? overrides.linkedNodes!
        : ["c5e85fef-db63-4dd5-a405-f22f94c68ee8"],
    linkedTransformations:
      overrides && overrides.hasOwnProperty("linkedTransformations")
        ? overrides.linkedTransformations!
        : ["c6216a0d-3fa0-4b4b-88b1-1bfe2250e2cb"],
    name:
      overrides && overrides.hasOwnProperty("name")
        ? overrides.name!
        : "occaecati",
    sourceUrl:
      overrides && overrides.hasOwnProperty("sourceUrl")
        ? overrides.sourceUrl!
        : "dignissimos",
    transformationMethod:
      overrides && overrides.hasOwnProperty("transformationMethod")
        ? overrides.transformationMethod!
        : TransformationMethod.DbtCloud,
  };
};

export const aWorkspace = (
  overrides?: Partial<Workspace>
): { __typename: "Workspace" } & Workspace => {
  return {
    __typename: "Workspace",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "inventore",
    dataAssets:
      overrides && overrides.hasOwnProperty("dataAssets")
        ? overrides.dataAssets!
        : [aDataAsset()],
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "est",
    governance:
      overrides && overrides.hasOwnProperty("governance")
        ? overrides.governance!
        : aGovernance(),
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "8f9aa8c0-65b9-4c18-bed5-dc15056022d7",
    logoUrl:
      overrides && overrides.hasOwnProperty("logoUrl")
        ? overrides.logoUrl!
        : "eius",
    members:
      overrides && overrides.hasOwnProperty("members")
        ? overrides.members!
        : [aMember()],
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "id",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "et",
    owner:
      overrides && overrides.hasOwnProperty("owner")
        ? overrides.owner!
        : anOrganizationOutput(),
    participatingOrganizations:
      overrides && overrides.hasOwnProperty("participatingOrganizations")
        ? overrides.participatingOrganizations!
        : [anOrganizationOutput()],
    projects:
      overrides && overrides.hasOwnProperty("projects")
        ? overrides.projects!
        : [aProjectOutput()],
    resources:
      overrides && overrides.hasOwnProperty("resources")
        ? overrides.resources!
        : [aResourceFolderOutput()],
    schemas:
      overrides && overrides.hasOwnProperty("schemas")
        ? overrides.schemas!
        : [aTargetSchema()],
    services:
      overrides && overrides.hasOwnProperty("services")
        ? overrides.services!
        : aWorkspaceServices(),
    terms:
      overrides && overrides.hasOwnProperty("terms")
        ? overrides.terms!
        : [aTermOutput()],
  };
};

export const aWorkspaceAdmin = (
  overrides?: Partial<WorkspaceAdmin>
): { __typename: "WorkspaceAdmin" } & WorkspaceAdmin => {
  return {
    __typename: "WorkspaceAdmin",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "autem",
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "autem",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "007953f2-e4bb-430c-8585-907f741fd2bb",
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "vel",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "vel",
    policy:
      overrides && overrides.hasOwnProperty("policy")
        ? overrides.policy!
        : aWorkspacePolicy(),
    users:
      overrides && overrides.hasOwnProperty("users")
        ? overrides.users!
        : [aUser()],
    workspace:
      overrides && overrides.hasOwnProperty("workspace")
        ? overrides.workspace!
        : aWorkspace(),
  };
};

export const aWorkspaceInput = (
  overrides?: Partial<WorkspaceInput>
): WorkspaceInput => {
  return {
    workspaceId:
      overrides && overrides.hasOwnProperty("workspaceId")
        ? overrides.workspaceId!
        : "7f00934b-f599-4ed0-b46b-3da0b6009d17",
  };
};

export const aWorkspacePolicy = (
  overrides?: Partial<WorkspacePolicy>
): { __typename: "WorkspacePolicy" } & WorkspacePolicy => {
  return {
    __typename: "WorkspacePolicy",
    createdAt:
      overrides && overrides.hasOwnProperty("createdAt")
        ? overrides.createdAt!
        : "esse",
    dataAssetCreate:
      overrides && overrides.hasOwnProperty("dataAssetCreate")
        ? overrides.dataAssetCreate!
        : true,
    dataAssetRead:
      overrides && overrides.hasOwnProperty("dataAssetRead")
        ? overrides.dataAssetRead!
        : true,
    description:
      overrides && overrides.hasOwnProperty("description")
        ? overrides.description!
        : "et",
    governanceCreate:
      overrides && overrides.hasOwnProperty("governanceCreate")
        ? overrides.governanceCreate!
        : true,
    governanceDelete:
      overrides && overrides.hasOwnProperty("governanceDelete")
        ? overrides.governanceDelete!
        : true,
    governanceRead:
      overrides && overrides.hasOwnProperty("governanceRead")
        ? overrides.governanceRead!
        : true,
    governanceUpdate:
      overrides && overrides.hasOwnProperty("governanceUpdate")
        ? overrides.governanceUpdate!
        : false,
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "9e98972e-6043-40fb-a557-ea85840bbcb2",
    memberCreate:
      overrides && overrides.hasOwnProperty("memberCreate")
        ? overrides.memberCreate!
        : true,
    memberDelete:
      overrides && overrides.hasOwnProperty("memberDelete")
        ? overrides.memberDelete!
        : true,
    memberRead:
      overrides && overrides.hasOwnProperty("memberRead")
        ? overrides.memberRead!
        : true,
    memberUpdate:
      overrides && overrides.hasOwnProperty("memberUpdate")
        ? overrides.memberUpdate!
        : true,
    modifiedAt:
      overrides && overrides.hasOwnProperty("modifiedAt")
        ? overrides.modifiedAt!
        : "aspernatur",
    name:
      overrides && overrides.hasOwnProperty("name") ? overrides.name! : "nihil",
    projectCreate:
      overrides && overrides.hasOwnProperty("projectCreate")
        ? overrides.projectCreate!
        : true,
    projectDelete:
      overrides && overrides.hasOwnProperty("projectDelete")
        ? overrides.projectDelete!
        : false,
    projectRead:
      overrides && overrides.hasOwnProperty("projectRead")
        ? overrides.projectRead!
        : true,
    projectUpdate:
      overrides && overrides.hasOwnProperty("projectUpdate")
        ? overrides.projectUpdate!
        : false,
    workspaceRole:
      overrides && overrides.hasOwnProperty("workspaceRole")
        ? overrides.workspaceRole!
        : aCollaborator(),
  };
};

export const aWorkspaceProjectFilterInput = (
  overrides?: Partial<WorkspaceProjectFilterInput>
): WorkspaceProjectFilterInput => {
  return {
    organizationIds:
      overrides && overrides.hasOwnProperty("organizationIds")
        ? overrides.organizationIds!
        : ["5e95aaa2-4e57-4b38-becd-c78e9ad0f3ba"],
    projectId:
      overrides && overrides.hasOwnProperty("projectId")
        ? overrides.projectId!
        : "38c2606f-42f4-4289-bfed-14ec5211c121",
  };
};

export const aWorkspaceResourceFilterInput = (
  overrides?: Partial<WorkspaceResourceFilterInput>
): WorkspaceResourceFilterInput => {
  return {
    folderId:
      overrides && overrides.hasOwnProperty("folderId")
        ? overrides.folderId!
        : "e7a5c975-6a5d-491c-ad88-4b441989f967",
    id:
      overrides && overrides.hasOwnProperty("id")
        ? overrides.id!
        : "f052de14-2a51-40f1-ab24-ff5fa4a09892",
  };
};

export const aWorkspaceServices = (
  overrides?: Partial<WorkspaceServices>
): { __typename: "WorkspaceServices" } & WorkspaceServices => {
  return {
    __typename: "WorkspaceServices",
    ingestionServices:
      overrides && overrides.hasOwnProperty("ingestionServices")
        ? overrides.ingestionServices!
        : [anIngestionServiceOutput()],
    sourceDefinitionSpecification:
      overrides && overrides.hasOwnProperty("sourceDefinitionSpecification")
        ? overrides.sourceDefinitionSpecification!
        : aSourceDefinitionSpecOutput(),
    sourceDefinitions:
      overrides && overrides.hasOwnProperty("sourceDefinitions")
        ? overrides.sourceDefinitions!
        : [aSourceDefinitionOutput()],
    sources:
      overrides && overrides.hasOwnProperty("sources")
        ? overrides.sources!
        : [aSourceOutput()],
    transformationServices:
      overrides && overrides.hasOwnProperty("transformationServices")
        ? overrides.transformationServices!
        : [aTransformationServiceOutput()],
    warehouseServices:
      overrides && overrides.hasOwnProperty("warehouseServices")
        ? overrides.warehouseServices!
        : [aWarehouseServiceOutput()],
  };
};
