import React from "react";
import { Typography } from "../../common/Typography/Typography";
import { Box, useTheme } from "@mui/material";
import { DataAssetCatalogGrid } from "../DataAssetCatalogGrid/DataAssetCatalogGrid";
import { SideNavigationPage } from "../../common/SideNavigationPage/SideNavigationPage";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetWorkspaceInfoDocument } from "../../generated";
import { AppContext } from "../../App";
import { WorkspaceSidenav } from "../../common/WorkspaceSidenav/WorkspaceSidenav";
import { GridView } from "@mui/icons-material";
import { AddDataAssetButton } from "../../common/ActionBarProjects/AddDataAssetButton";

export interface DataAssetCatalogPageProps {}

export const DataAssetCatalogPage = () => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { data } = useQuery(GetWorkspaceInfoDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });
  const workspaceName = data?.workspace?.name || "";
  const isEditable = data?.workspace?.governance?.isEditable;

  return (
    <AppContext.Consumer>
      {({ showSidenav }) => (
        <SideNavigationPage
          leftHeaderElements={
            <Typography variant="h5">Data Asset Catalog</Typography>
          }
          rightHeaderElements={<AddDataAssetButton onClick={() => {}} />}
          content={
            <Box
              bgcolor={palette.common.white}
              display="flex"
              flexDirection="column"
              boxSizing="border-box"
              flexGrow={1}
            >
              <DataAssetCatalogGrid />
            </Box>
          }
        />
      )}
    </AppContext.Consumer>
  );
};
