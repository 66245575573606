import { Box, CircularProgress, useTheme } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { BrighthiveLogoSvg } from "../../../common/Icons/BrighthiveLogoSvg";
import { Typography } from "../../../common/Typography/Typography";
import { Button } from "../../../common/Button/Button";
import {
  AcceptWorkspacePolicyDocument,
  GetAgreementPolicyDocument,
  GetCurrentUserInfoDocument,
  GetWorkspaceInfoDocument,
  GetWorkspacesDocument,
} from "../../../generated";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useEffect } from "react";

export const PolicyAgreement = () => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { data, loading: loadingAgreementPolicy } = useQuery(
    GetWorkspacesDocument
  );

  const activeWorkspace = data?.currentUser.workspaces.find(
    (workspace) => workspace?.id === workspaceId
  );

  const [acceptPolicy] = useMutation(AcceptWorkspacePolicyDocument, {
    refetchQueries: [GetCurrentUserInfoDocument, GetWorkspacesDocument],
  });

  const acceptHandler = () => {
    acceptPolicy({
      variables: {
        input: {
          workspaceId: workspaceId || "",
        },
      },
    })
      .then((res) => {
        if (res.data?.acceptWorkspacePolicy?.emailAddress) {
          toast.success(
            `A copy of the workspace policy has been sent to ${res.data?.acceptWorkspacePolicy?.emailAddress}`
          );
        }
      })
      .catch(() => navigate("/workspace"));
  };

  useEffect(() => {
    if (activeWorkspace?.acceptedPolicies) {
      navigate(`/workspace/${activeWorkspace.id}/projects`);
    }
  }, [activeWorkspace]);

  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          p: 6,
          m: "auto",
          width: "50rem",
          height: "46.25rem",
          background: "white",
          borderRadius: ".375rem",
          boxShadow: `0 .0625rem .0625rem ${palette.customRolled.border}, 0 .125rem .0625rem ${palette.action.disabledBackground}, 0 .0625rem .1875rem ${palette.customRolled.contrastText}`,
        }}
      >
        {loadingAgreementPolicy ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress sx={{ color: palette.primary.darkest }} />
          </Box>
        ) : (
          <>
            <BrighthiveLogoSvg
              sx={{
                height: "1.875rem",
                width: "initial",
                mb: 6,
              }}
            />
            <Typography variant="h5" mb={6}>
              Agree to workspace governance policies
            </Typography>
            <Box
              sx={{
                height: "37rem",
                overflow: "auto",
              }}
            >
              <Typography gutterBottom variant="body2">
                All members of the workspace,{" "}
                <strong>{activeWorkspace?.name || ""}</strong> (owned by{" "}
                <strong>{activeWorkspace?.ownerOrganizationName}</strong>), must
                agree to the policies governing the collaboration. Please read
                and accept the governing policies for the workspace.
              </Typography>
              <Typography gutterBottom variant="body2">
                Please note that the most up-to-date versions of polices are
                available in the Governance section of the workspace, and all
                workspace members will be notified of if any changes occur to
                these policies.
              </Typography>
              <Typography mb={4} variant="body2">
                By selecting <strong>Agree and continue</strong>, I agree to the
                following policies:
              </Typography>
              <Typography gutterBottom variant="h6">
                Policies
              </Typography>
              <Typography mb={4} variant="body2">
                Explanation of policies
              </Typography>
              <Typography gutterBottom variant="h6">
                Privacy
              </Typography>
              {activeWorkspace?.policies.map((item) => (
                <>
                  <Typography gutterBottom variant="subtitle2">
                    {item?.name}
                  </Typography>
                  <Typography
                    mb={4}
                    gutterBottom
                    variant="body3"
                    color="grayAccent"
                  >
                    {item?.description}
                  </Typography>
                </>
              ))}
            </Box>
            <Button
              onClick={() => acceptHandler()}
              fullWidth
              variant="contained"
            >
              Agree and continue
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
