import { useLocation, useParams } from "react-router-dom";
import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { CollectionIcon } from "../Icons/CollectionIcon";
import { ConnectionIcon } from "../Icons/ConnectionIcon";
import { DataQualityIcon } from "../Icons/DataQualityIcon";
import { WarehouseIcon } from "../Icons/WarehouseIcon";
import { TransformationIcon } from "../Icons/TransformationIcon";
import { UseIcon } from "../Icons/UseIcon";
import { LockIcon } from "../Icons/LockIcon";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { PapersListIcon } from "../Icons/PapersListIcon";
import { PaperInfoIcon } from "../Icons/PaperInfoIcon";
import { VectorIcon } from "../Icons/VectorIcon";

export const KnowledgeLink = ({}) => {
  const { workspaceId } = useWorkspaceId();
  const { pathname } = useLocation();
  return (
    <>
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/the-hive/resources`}
        icon={<PapersListIcon />}
        label="Resources"
        className={pathname.includes("resources") ? "active" : ""}
        active={pathname.includes("resources")}
      />
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/the-hive/terms`}
        icon={<PaperInfoIcon />}
        label="Terms"
        active={pathname.includes("terms")}
        className={pathname.includes("terms") ? "active" : ""}
      />
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/the-hive/schemas`}
        icon={<VectorIcon />}
        label="Schemas"
        active={pathname.includes("schemas")}
        className={pathname.includes("schemas") ? "active" : ""}
      />
    </>
  );
};
