import React from "react";
import { Box, Dialog, FormHelperText, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Label } from "../../common/Label/Label";
import Dropdown from "../../common/Dropdown/Dropdown";

import { DocAddition } from "../DocAddition";
import { Field, Formik } from "formik";
import { TextField } from "formik-mui";
import { MAX_DESCRIPTION_CHARS } from "../../Constants";
import * as Yup from "yup";
import { Policy } from "../types";
import { compact } from "lodash";
import { PolicyCategory, UpdateCustomPolicyInput } from "../../generated";
interface CustomPolicyModalProps {
  show: boolean;
  editMode?: boolean;
  policy?: Policy | null;
  closeDialog: Function;
  submit: Function;
}

export const CustomPolicyModal = ({
  show,
  editMode = false,
  policy,
  closeDialog,
  submit,
}: CustomPolicyModalProps) => {
  const { palette } = useTheme();

  return (
    <Dialog maxWidth="xl" open={show} onClose={() => closeDialog()}>
      <Formik
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          description: Yup.string().required().max(MAX_DESCRIPTION_CHARS),
          policyCategory: Yup.string().required(),
        })}
        initialValues={{
          name: "",
          description: "",
          policyCategory: "",
          ...policy,
          referenceDocuments: {
            create: editMode ? [] : [{ id: "", name: "", href: "" }],
            delete: [],
            update:
              compact(policy?.referenceDocuments || []).map(
                ({ id, name = "", href = "" }) => ({
                  id,
                  name,
                  href,
                })
              ) || [],
          },
        }}
        onSubmit={(values) => {
          const body: Partial<UpdateCustomPolicyInput> = {
            id: "",
            name: values.name,
            description: values.description,
            policyCategory: values.policyCategory as PolicyCategory,
            referenceDocuments: values.referenceDocuments,
          };

          if (values.id) {
            body.id = values.id;
          } else {
            delete body.id;
          }
          submit(body);
        }}
      >
        {({ values, submitForm, setFieldValue, errors, touched }) => {
          const onModify = (
            type: "referenceDocuments",
            index: number,
            modifiedItem: any
          ) => {
            const anyItem = [...(values?.[type]?.update || [])];
            const isNew =
              !values?.[type]?.update.length ||
              !anyItem.filter((item) => item.id === modifiedItem.id).length;
            const createdList: any[] = values?.[type]?.create || [];
            const updatedList = values?.[type]?.update || [];
            if (isNew) {
              createdList[index - updatedList.length] = modifiedItem;
            } else {
              updatedList[index] = modifiedItem;
            }
            setFieldValue(type, {
              delete: [...(values?.[type]?.delete || [])],
              create: [...createdList],
              update: [...updatedList],
            });
          };

          const onNew = (type: "referenceDocuments") => () => {
            setFieldValue(type, {
              delete: [...(values?.[type]?.delete || [])],
              update: [...(values?.[type]?.update || [])],
              create: [
                ...(values?.[type]?.create || []),
                {
                  name: "",
                  href: "",
                },
              ],
            });
          };

          const onDelete =
            (type: "referenceDocuments", item: { id: string; name: string }) =>
            () => {
              setFieldValue(type, {
                create: [
                  ...((values?.[type]?.create as any[]).filter(
                    (aItem: { name: string }) => item.name !== aItem.name
                  ) || []),
                ],
                delete: [
                  ...(values?.[type]?.delete || []),
                  ...(item.id ? [item.id] : []),
                ],
                update: [
                  ...((values?.[type]?.update as any[]).filter(
                    (aItem: { id: string }) => aItem.id !== item.id
                  ) || []),
                ],
              });
            };

          return (
            <Box width="50rem" p={6} boxSizing="border-box">
              <Typography mb={4} variant="h5">
                {editMode ? "Edit" : "Add"} custom policy
              </Typography>
              <Box mt={4} sx={{ height: "30rem", overflowY: "auto" }}>
                <Label mb={1} value="Policy name" width={"15.5rem"} />
                <Field
                  component={TextField}
                  name="name"
                  hiddenLabel
                  fullWidth
                  inputProps={{
                    sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                  }}
                  size="small"
                  sx={{ mb: 6 }}
                />
                <Label mb={1} value="Policy description" width={"15.5rem"} />
                <Field
                  component={TextField}
                  name="description"
                  hiddenLabel
                  fullWidth
                  multiline
                  inputProps={{
                    sx: { fontSize: "1rem" },
                    maxLength: MAX_DESCRIPTION_CHARS,
                  }}
                  size="small"
                  sx={{ mb: 2 }}
                />
                <Typography
                  variant="body3"
                  color={palette.gray.dark}
                  sx={{ mb: 6 }}
                >
                  {values.description.length}/{MAX_DESCRIPTION_CHARS} characters
                </Typography>
                <Label mb={1} value="Policy category" width={"15.5rem"} />
                <Dropdown
                  onChange={(e) => {
                    setFieldValue("policyCategory", e.target.value);
                  }}
                  value={values.policyCategory}
                  options={[
                    "ACCESS",
                    "OTHER",
                    "PRIVACY",
                    "QUALITY",
                    "SECURITY",
                    "TRANSPARENCY",
                    "USE",
                  ]}
                />
                {errors.policyCategory && touched.policyCategory && (
                  <FormHelperText sx={{ marginLeft: "14px" }} error>
                    {errors.policyCategory}
                  </FormHelperText>
                )}
                <Label
                  mt={6}
                  mb={1}
                  value="Reference Document(s)"
                  info
                  width={"15.5rem"}
                />
                {[
                  ...(values.referenceDocuments?.update || []),
                  ...(values.referenceDocuments?.create || []),
                ].map((doc, index) => (
                  <DocAddition
                    key={doc.id}
                    id={doc.id}
                    name={doc.name}
                    href={doc.href}
                    onModify={(aGoal) => {
                      onModify("referenceDocuments", index, aGoal);
                    }}
                    onDelete={onDelete("referenceDocuments", doc)}
                  />
                ))}
                <Button
                  startIcon={<AddIcon />}
                  color={"grayAccent"}
                  variant={"outlined"}
                  onClick={onNew("referenceDocuments")}
                >
                  Add a document link
                </Button>{" "}
              </Box>
              <Box mt={4} display="flex" alignItems={"center"}>
                <Button
                  onClick={() => closeDialog()}
                  color="grayAccent"
                  variant="outlined"
                  sx={{ ml: "auto", mr: 3 }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => submitForm()}
                  color="primary"
                  variant="contained"
                >
                  {editMode ? "Update" : "Add"} policy
                </Button>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
};
