import React, { useContext, useState } from "react";
import { Box, Card, Tooltip, Typography, useTheme } from "@mui/material";
import { OfficeBuildingIcon } from "../../common/Icons/OfficeBuildingIcon";
import { SourceOutput } from "../../generated";
import { ActionCell } from "./ActionCell";
import { dateHelper } from "../../helpers";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ProfileAvatarIcon from "../../common/ProfileAvatarIcon/ProfileAvatarIcon";
import { ResourceHanlder } from "../../common/ResourceHanlder";
import { useNavigate, useParams } from "react-router-dom";
import { ContentCardWrapper } from "./style";
import Resource from "../../contexts/resource";

export const CardView = ({
  emailAddress,
  username,
  rowData,
}: {
  emailAddress: string;
  username: string;
  rowData: any;
}) => {
  const { workspaceId } = useParams();
  const { palette } = useTheme();
  const {
    editMode: { setEditing },
  } = Resource.useContext();

  const navigate = useNavigate();

  return (
    <ContentCardWrapper className="ag-theme-material ContentCardWrapper">
      {rowData?.map((row: any) => (
        <Card
          sx={{
            border: `1px solid ${palette.action.disabledBackground}`,
            borderRadius: "6px",
            cursor: "pointer",
            position: "relative",
            width: "calc(100% - 12px)",
            boxShadow: "none",
            overflow: "hidden",

            "@media (min-width: 992px)": {
              width: "calc(50% - 12px)",
              display: "flex",
              flexDirection: "column",
            },
            "@media (min-width: 1200px)": {
              width: "calc(33.333% - 13px)",
            },
            "@media (min-width: 1600px)": {
              width: "calc(25% - 14px)",
            },
          }}
        >
          <a
            onClick={() => {
              if (row.isFolder || row?.path?.includes("axios")) {
                navigate(
                  `/workspace/${workspaceId}/the-hive/resources/${row?.id}`
                );
              } else {
                window?.open(row.path, "_blank")?.focus();
              }
            }}
            // href={row.path}
            target="_blank"
            rel="noreferrer"
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                paddingTop: "50%",
                borderRadius: "6px 6px 0 0",

                ".resource-icon": {
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  objectFit: "cover",
                  left: "0",
                  top: "0",
                  border: "0",
                  borderRadius: "inherit",
                  maxWidth: "none",
                  maxHeight: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  svg: {
                    width: "50%",
                    height: "50%",
                  },

                  "&.placeholder": {
                    objectFit: "contain",
                  },
                },

                "#header-bar": {
                  display: "none",
                },
              }}
            >
              <ResourceHanlder
                path={row.path}
                isFolder={row.isFolder}
                type={row.type}
              >
                {/* <DocViewer
                  documents={[
                    {
                      uri: row.path,
                    },
                  ]}
                  requestHeaders={{
                    mode: "no-cors",
                  }}
                  pluginRenderers={DocViewerRenderers}
                  config={{
                    header: {
                      disableFileName: true,
                    },
                    noRenderer: {
                      overrideComponent: (<h1>hi</h1>) as any,
                    },
                  }}
                /> */}
              </ResourceHanlder>
            </Box>
          </a>
          <Box
            sx={{
              borderTop: `1px solid ${palette.action.disabledBackground}`,
              padding: "12px",
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: "5px",
                flexGrow: "1",
              }}
            >
              <Box
                sx={{
                  flexGrow: "1",
                  flexBasis: "0",
                  minWidth: "calc(50% - 10px)",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    color: palette.common.black,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {row?.name ? row?.name : row?.path}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "8px",
              }}
            >
              <Box
                sx={{
                  flexGrow: "1",
                  flexBasis: "0",
                  minWidth: "calc(50% - 5px)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "100%",
                      overflow: "hidden",

                      img: {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: "block",
                        borderRadius: "inherit",
                      },
                    }}
                  >
                    <ProfileAvatarIcon
                      email={emailAddress}
                      name={username}
                      width={20}
                      height={20}
                      className="profileAvatarIcon"
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        fontWeight: "500",
                        letterSpacing: "1px",
                        color: palette.gray.darkest,
                      }}
                    >
                      {row.isFolder ? "Folder" : row?.type}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: "1",
                  flexBasis: "0",
                  minWidth: "calc(50% - 5px)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                    gap: "14px",
                  }}
                >
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "12px",
                        lineHeight: "18px",
                        display: "block",
                        color: palette.gray.dark,
                      }}
                    >
                      {dateHelper(row?.createdAt)}
                    </Typography>
                  </Box>
                  <Box>
                    <ActionCell
                      name={row?.name ? row?.name : row?.path}
                      id={row.id}
                      onEdit={() => setEditing(row.id)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
        // <Box
        //   display="flex"
        //   sx={{
        //     cursor: "pointer",
        //     position: "relative",
        //     width: "calc(100% - 12px)",
        //     // minWidth: "18rem",
        //     // padding: "8px",

        //     "@media (min-width: 992px)": {
        //       width: "calc(50% - 12px)",
        //     },
        //     "@media (min-width: 1200px)": {
        //       width: "calc(33.333% - 12px)",
        //     },
        //     "@media (min-width: 1600px)": {
        //       width: "calc(25% - 12px)",
        //     },
        //   }}
        // >
        //   <Box
        //     sx={{
        //       cursor: "pointer",
        //       display: "flex",
        //       flexDirection: "row",
        //       minWidth: "100%",
        //     }}
        //     onClick={() => setDetail(row)}
        //     className="project-card"
        //     tabIndex={1}
        //     aria-labelledby={`${row.name}-title`}
        //     role="link"
        //   >
        //     <Card
        //       sx={{
        //         cursor: "pointer",
        //         color: palette.gray.darkest,
        //         fontSize: "14px",
        //         lineHeight: "20px",
        //         flex: 1,

        //         "&:hover": {
        //           background: `linear-gradient(0deg, ${palette.primary.lighter}, ${palette.primary.lighter}), ${palette.common.white}`,
        //         },

        //         ".csb-top-wrap": {
        //           flexGrow: "1",
        //         },

        //         ".csb-icn": {
        //           display: "block",
        //           marginBottom: "12px",
        //         },

        //         ".csb-status-tag": {
        //           fontWeight: "500",
        //           padding: "2px 8px",
        //           marginBottom: "16px",
        //         },

        //         ".csb-description": {
        //           margin: "12px 0",
        //         },

        //         ".csb-ptner-icn": {
        //           width: "12px",
        //           height: "12px",
        //           color: palette.grayAccent.light,
        //         },
        //       }}
        //     >
        //       <Box
        //         sx={{
        //           padding: "20px 16px",
        //           display: "flex",
        //           flexDirection: "column",
        //         }}
        //       >
        //         <div className="csb-top-wrap">
        //           <Box
        //             sx={{
        //               display: "flex",
        //               columnGap: "10px",
        //               justifyContent: "space-between",

        //               ".csb-data-heading": {
        //                 flexGrow: "1",
        //                 maxWidth: "calc(70% - 5px)",
        //                 color: palette.common.black,
        //                 margin: "0",
        //                 fontWeight: "500",
        //               },

        //               ".csb-data-subheading": {
        //                 flexShrink: "0",
        //                 color: palette.common.black,
        //                 margin: "0",
        //               },

        //               ".csb-data-icn": {
        //                 color: palette.grayAccent.light,
        //                 width: "12px",
        //                 height: "12px",
        //                 marginRight: "6px",
        //                 verticalAlign: "-1px",
        //               },
        //             }}
        //           >
        //             <p className="csb-data-heading">
        //               <OfficeBuildingIcon className="csb-data-icn" />
        //               <span>Name:</span>
        //             </p>
        //             <p className="csb-data-subheading">{row?.name}</p>
        //           </Box>
        //         </div>

        //         <Box>
        //           <p className="csb-data-heading">
        //             <OfficeBuildingIcon className="csb-data-icn" />
        //             <span>Added By:</span>
        //           </p>
        //           <p className="csb-data-subheading">{row?.added_by}</p>
        //         </Box>
        //         <Box>
        //           <p className="csb-data-heading">
        //             <OfficeBuildingIcon className="csb-data-icn" />
        //             <span>Added On:</span>
        //           </p>
        //           <p className="csb-data-subheading">{row?.added_by}</p>
        //         </Box>
        //         <Box>
        //           <p className="csb-data-subheading">:</p>
        //         </Box>
        //       </Box>
        //       <ActionCell />
        //     </Card>
        //   </Box>
        // </Box>
      ))}
    </ContentCardWrapper>
  );
};
