import { styled, Box, IconButton, Typography, Button } from '@mui/material';
import { ArrowBack } from "@mui/icons-material";
import { brighthiveTheme } from "../theme/theme";

export const SidebarLinkButton = styled(Button)`

  &:hover{
    background-color: transparent;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;