import React from "react";
import { Box, Tooltip, useTheme } from "@mui/material";

import { Typography, TypographyProps } from "../Typography/Typography";
import { TooltipHelper } from "../TooltipHelper/TooltipHelper";

export interface LabelProps {
  value: string;
  info?: boolean;
  infoTitle?: string;
  optional?: boolean;
  className?: string;
}

export const Label = ({
  value = "Label",
  info = false,
  optional = false,
  variant = "subtitle2",
  infoTitle,
  className,
  ...rest
}: LabelProps & TypographyProps) => {
  const { palette } = useTheme();

  return (
    <Box
      className={className}
      sx={{
        display: "inline-flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant={variant} {...rest}>
        {value}
        <TooltipHelper
          info={info}
          variant={variant}
          infoTitle={infoTitle || value}
        />
      </Typography>
      {optional && (
        <Typography color={palette.gray.dark} variant="caption">
          Optional
        </Typography>
      )}
    </Box>
  );
};
