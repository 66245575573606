import React, { createContext, useState } from "react";
import { LicenseManager } from "ag-grid-enterprise";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AssetDetailPage } from "./AssetDetail/AssetDetailPage/AssetDetailPage";
import { ConfirmOrganization } from "./Auth/RegisterFlow/ConfirmOrganization/ConfirmOrganization";
import { DataAssetCatalogPage } from "./DataAssetCatalog/DataAssetCatalogPage/DataAssetCatalogPage";
import { GovernancePage } from "./Governance/GovernancePage";
import { GovernancePolicyDetailPage } from "./Governance/GovernancePolicyDetailPage";
import { Login } from "./Auth/Login";
import { ForgotPassword } from "./Auth/ForgotPassword";
import { MembersPage } from "./Members/MembersPage/MembersPage";
import { MyOrganizationPage } from "./MyOrganization/MyOrganizationPage";
import { MyProfilePage } from "./ContentPages/MyProfilePage";
import { PolicyAgreement } from "./Auth/RegisterFlow/PolicyAgreement/PolicyAgreement";
import { ProjectsListPage } from "./Projects/ProjectsListPage/ProjectsListPage";
import { ProtectedRoute } from "./Auth/ProtectedRoute";
import { RegisterUser } from "./Auth/RegisterFlow/RegisterUser/RegisterUser";
import { ProjectWorkflowPage } from "./ProjectWorkflow/ProjectWorkflowPage";
import { ProjectOverviewPage } from "./Projects/ProjectOverviewPage/ProjectOverviewPage";
import { ProjectSchemaPage } from "./Projects/ProjectSchemaPage/ProjectSchemaPage";
import { ProjectDataAssetPage } from "./Projects/ProjectDataAssetPage/ProjectDataAssetPage";
import { CreatedDataProductsPage } from "./Projects/CreatedDataProductsPage/CreatedDataProductsPage";
import { ProjectSchemaDetailPage } from "./Projects/ProjectSchemaDetailPage/ProjectSchemaDetailPage";
import { WorkspaceConfigurationPage } from "./WorkspaceConfiguration/WorkspaceConfigurationPage";
import { ProjectSchemaEditDetailPage } from "./Projects/ProjectSchemaPage/ProjectSchemaDetailsPage";
import { ProtectedNav } from "./Auth/ProtectedNav";
import { MyDataAssetPage } from "./Projects/MyDataAssetPage/MyDataAssetPage";
import { useGetUserRole } from "./hooks/useGetUserRole";
import { useWorkspaceId } from "./hooks/useWorkspace";
import { ResetPassword } from "./Auth/ResetPassword";
import WorkspaceSwitcherPage from "./WorkspaceSwitcher/WorkspaceSwitcherPage";
import { Unauthorized } from "./ErrorPage/Unauthorized";
import { Transformation } from "./DataStackServices/Transformation/Transformation";
import { All } from "./Sources/All/All";
import { WorkspaceSettingsPage } from "./WorkspaceSettings";
import { Warehouse } from "./DataStackServices/Warehouse/Warehouse";
import { Connection } from "./DataStackServices/Connection/Connection";
import { WorkspaceRoleEnum } from "./generated";
import { BrightBotPage } from "./BrightBot/BrightBotPage";
import { KnowledgeCenter } from "./KnowledgeCenter/KnowledgeCenter";
import { Resources } from "./KnowledgeCenter/Resources/Resources";
import { Session } from "./Session/Session";
import { Terms } from "./KnowledgeCenter/Terms";
import { Schemas } from "./KnowledgeCenter/Schema";
import { SchemaDetailPage } from "./KnowledgeCenter/Schema/SchemaDetail/SchemaDetailPage";
import { SchemaCompare } from "./KnowledgeCenter/Schema/SchemaCompare/SchemaCompare";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY || "");

interface AppContextType {
  showSidenav: boolean;
  setShowSidenav: (...args: any[]) => any;
}

export const AppContext = createContext<AppContextType>({
  showSidenav: true,
  setShowSidenav: () => {},
});

const setting = {
  color: {
    theme: "#000",
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help",
    },
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } },
    ],
  },
};

export const App = () => {
  const { workspaceId } = useWorkspaceId();
  const [showSidenav, setShowSidenav] = useState(true);
  const { accessible, role } = useGetUserRole(workspaceId);

  if (!role && workspaceId) return null;

  return (
    <AppContext.Provider value={{ showSidenav, setShowSidenav }}>
      <ToastContainer autoClose={3000} />
      <ProtectedNav>
        <Routes>
          <Route
            path="/workspace/:workspaceId/brightbot"
            element={
              <ProtectedRoute>
                <BrightBotPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/projects"}
            element={
              <ProtectedRoute>
                <ProjectsListPage
                  showSidenav={showSidenav}
                  accessible={accessible}
                />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/project/:projectId/project-overview"}
          >
            <Route
              index
              element={
                <ProtectedRoute>
                  <ProjectOverviewPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={":edit"}
              element={
                <ProtectedRoute
                  access={[
                    WorkspaceRoleEnum.Admin,
                    WorkspaceRoleEnum.Collaborator,
                  ]}
                >
                  <ProjectOverviewPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/workspace"
            element={
              <ProtectedRoute>
                <WorkspaceSwitcherPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/project/:projectId/project-workflow"}
            element={
              <ProtectedRoute
                access={[
                  WorkspaceRoleEnum.Admin,
                  WorkspaceRoleEnum.Collaborator,
                ]}
              >
                <ProjectWorkflowPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={
              "/workspace/:workspaceId/project/:projectId/created-data-products"
            }
            element={
              <ProtectedRoute>
                <CreatedDataProductsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/project/:projectId/data-assets"}
            element={
              <ProtectedRoute>
                {accessible ? <ProjectDataAssetPage /> : <MyDataAssetPage />}
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/project/:projectId/project-schema"}
            element={
              <ProtectedRoute>
                <ProjectSchemaPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={
              "/workspace/:workspaceId/project/:projectId/project-schema/:schemaId"
            }
            element={
              <ProtectedRoute>
                <ProjectSchemaEditDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={
              "/workspace/:workspaceId/project/:projectId/project-schema/edit/:schemaId"
            }
            element={
              <ProtectedRoute>
                <ProjectSchemaEditDetailPage isEditMode={true} />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path={"/workspace/:workspaceId/session"}
            element={
              <ProtectedRoute>
                <Session />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path={"/workspace/:workspaceId/data"}
            element={
              <ProtectedRoute>
                <DataAssetCatalogPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/asset-details/:dataAssetId"}
            element={
              <ProtectedRoute>
                <AssetDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/members"}
            element={
              <ProtectedRoute>
                <MembersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/sources/all"}
            element={
              <ProtectedRoute>
                <All />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/sources/internal"}
            element={
              <ProtectedRoute>
                <All />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/services/transformation"}
            element={
              <ProtectedRoute>
                <Transformation />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/services/warehouse"}
            element={
              <ProtectedRoute>
                <Warehouse />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/services/connections"}
            element={
              <ProtectedRoute>
                <Connection />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/governance"}
            element={
              <ProtectedRoute>
                <GovernancePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/governance/detail-id"}
            element={
              <ProtectedRoute>
                <GovernancePolicyDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/my-organization"}
            element={
              <ProtectedRoute>
                <MyOrganizationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/confirm-your-registration"
            element={
              <ProtectedRoute>
                <ConfirmOrganization />
              </ProtectedRoute>
            }
          />
          <Route
            path="/register-user/:username/:session"
            element={<RegisterUser />}
          />
          <Route
            path="/agree-policy/:workspaceId"
            element={
              <ProtectedRoute>
                <PolicyAgreement />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/my-profile"}
            element={
              <ProtectedRoute>
                <MyProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/workspace-configuration"}
            element={
              <ProtectedRoute>
                <WorkspaceConfigurationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/workspace-settings"}
            element={
              <ProtectedRoute access={[WorkspaceRoleEnum.Admin]}>
                <WorkspaceSettingsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={"/workspace/:workspaceId/the-hive/resources"}
            element={
              <ProtectedRoute>
                <Resources />
              </ProtectedRoute>
            }
          />

          <Route
            path={"/workspace/:workspaceId/the-hive/resources/:folderId"}
            element={
              <ProtectedRoute>
                <Resources />
              </ProtectedRoute>
            }
          />

          <Route
            path={"/workspace/:workspaceId/the-hive/schemas"}
            element={
              <ProtectedRoute>
                <Schemas />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/the-hive/schemas/compare"}
            element={
              <ProtectedRoute>
                <SchemaCompare />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/the-hive/schemas/:schemaId"}
            element={
              <ProtectedRoute>
                <SchemaDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/workspace/:workspaceId/the-hive/terms"}
            element={
              <ProtectedRoute>
                <Terms />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/login/forgotPassword" element={<ForgotPassword />} />
          <Route path="/login/resetPassword" element={<ResetPassword />} />
          <Route path="/404" element={<Unauthorized />} />
          <Route path="*" element={<Navigate to={"/workspace"} />} />
        </Routes>
      </ProtectedNav>
    </AppContext.Provider>
  );
};
