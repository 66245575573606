import { styled, Box } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 45px;
  margin: 10px 0 0;
  position: relative;
`;

export const AvatarWrapper = styled(Box)`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  /* background-color: ${brighthiveTheme.palette.primary.darkest}; */
  color: ${brighthiveTheme.palette.grayAccent.contrastText};
  border: 1px solid ${brighthiveTheme.palette.custom.outline};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 0;
  font-size: 0;
  padding: 3px 2px 0;
  position: absolute;
  left: 0;
  top: 0;

  &.success {
    position: relative;
    border: 1px solid ${brighthiveTheme.palette.success.lighter};

    img,
    svg {
      width: 80%;
      height: 80%;
    }
  }

  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    vertical-align: top;
  }
`;

export const Description = styled(Box)`
  max-width: 560px;
  color: ${brighthiveTheme.palette.common.black};
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.25px;
  display: flex;
  padding: 10px 0 0;

  p {
    a {
      color: ${brighthiveTheme.palette.link.main};
      text-decoration: none;

      &:hover {
        color: ${brighthiveTheme.palette.common.black};
      }
    }
  }
`;

export const Header = styled(Box)`
  display: flex;
  align-items: center;

  .MuiTypography-root {
    text-transform: uppercase;
    font-weight: bold;
    display: inline-flex;
  }
`;

export const Suggestion = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 10px;
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  gap: 10px;
  padding: 10px 0;
`;
