import "ag-grid-enterprise";
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GetQuickFilterTextParams,
  GridApi,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-material.css";
import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, useTheme } from "@mui/material";
import { CorporateFare, ErrorOutline } from "@mui/icons-material";
import { OverridableStringUnion } from "@mui/types";
import { ChipPropsColorOverrides } from "@mui/material/Chip/Chip";
import { ActionsCellRenderer } from "./ActionsCellRenderer";
import { ActionBarDataAssetCatalog } from "../ActionBarDataAssetCatalog/ActionBarDataAssetCatalog";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import { Link } from "../../common/Link/Link";
import { Chip } from "../../common/Chip/Chip";
import {
  FilterOperator,
  SpecificNumberFilterOperator,
  SpecificTextFilterOperator,
} from "../../common/AGGridWrapper/gridHelpers";
import { SharedIcon } from "../../common/Icons/SharedIcon";
import { KeyIcon } from "../../common/Icons/KeyIcon";
import { Tag } from "../../common/Tag/Tag";
import { CheckMarkIcon } from "../../common/Icons/CheckMarkIcon";
import { GridFooter } from "../../common/AGGridWrapper/GridFooter";
import { useQuery } from "@apollo/client";
import { AccessStatus, GetDataAssetsDocument } from "../../generated";
import { GridReadyEvent } from "ag-grid-community";
import { caseInsensitiveSort, convertToTitleCase } from "../../helpers";
import { useParams } from "react-router-dom";
import { useData } from "../../hooks/dataContext";
import AccessStatusChip from "../../AssetDetail/AccessStatusChip/AccessStatusChip";

export const DataAssetCatalogGrid = () => {
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { state } = useData();
  const { data, loading } = useQuery(GetDataAssetsDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  const rowData = useMemo(() => {
    return (
      data?.workspace?.dataAssets?.map((asset) => ({
        ...asset,
        issues1: asset?.issues,
      })) || []
    );
  }, [data?.workspace?.dataAssets]);
  const [columnDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      field: "name",
      headerName: "Data asset name",
      width: 350,
      comparator: caseInsensitiveSort,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <Link
            variant="body3"
            fontWeight={700}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "inline-block",
              textOverflow: "ellipsis",
              color: `${palette.accent.darkest} !important`,
              textDecoration: "none"
            }}
            title={params.value}
            to={`/workspace/${workspaceId}/asset-details/${params?.data?.id}`}
            tabIndex={-1}
          >
            {params.value}
          </Link>
        );
      },
      getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
      headerComponentParams: {
        description: "The name of the data asset.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          SpecificTextFilterOperator.STARTS_WITH,
          SpecificTextFilterOperator.ENDS_WITH,
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
        ],
      },
    },
    // {
    //   field: "description",
    //   headerName: "Description",
    //   getQuickFilterText: (params: GetQuickFilterTextParams) => params.value,
    //   headerComponentParams: {
    //     description:
    //       "The description of the records contained in the data asset.",
    //     filterOperators: [
    //       SpecificTextFilterOperator.CONTAINS,
    //       SpecificTextFilterOperator.NOT_CONTAINS,
    //       SpecificTextFilterOperator.STARTS_WITH,
    //       SpecificTextFilterOperator.ENDS_WITH,
    //       FilterOperator.EQUALS,
    //       FilterOperator.NOT_EQUALS,
    //       FilterOperator.IS_EMPTY,
    //       FilterOperator.IS_NOT_EMPTY,
    //     ],
    //   },
    // },
    {
      field: "issues",
      type: "numberColumn",
      headerName: "Issues",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.issues?.length || 0;
      },
      headerComponentParams: {
        filterOperators: [
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          SpecificNumberFilterOperator.IS_EMPTY,
          SpecificNumberFilterOperator.IS_NOT_EMPTY,
        ],
        headerAlignment: "left",
        description:
          "Indication of any problems that exist in the data asset, including fetching data.",
      },
      filterParams: {
        filterOptions: [
          {
            displayKey: SpecificNumberFilterOperator.IS_EMPTY,
            displayName: "Is Empty",
            predicate: ([filterValue]: any[], cellValue: any) => {
              return isNaN(cellValue) || cellValue === 0;
            },
          },
          {
            displayKey: SpecificNumberFilterOperator.IS_NOT_EMPTY,
            displayName: "Is Not Empty",
            predicate: ([filterValue]: any[], cellValue: any) => {
              return !isNaN(cellValue) && cellValue !== 0;
            },
          },
        ],
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        if (typeof params.value === "number" && params.value > 0) {
          return (
            <>
              <Chip sx={{ mr: ".625rem" }} color="error" label={params.value} />
              <Link
                variant="body3"
                fontWeight={700}
                to={`/workspace/${workspaceId}/asset-details/${params?.data?.id}`}
                tabIndex={-1}
              >
                View Details
              </Link>
            </>
          );
        }
        return null;
      },
      cellClass: "ag-left-aligned-cell",
    },
    {
      field: "owner",
      headerName: "Asset owner",
      valueGetter: (params: ValueGetterParams) => {
        return params.data.owner?.name;
      },
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.value;
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        const ownerName = convertToTitleCase(params.data.owner?.name);
        if (ownerName === "") return "";
        return (
          <Box display="flex" alignItems="center">
            <CorporateFare
              sx={{
                color: palette.gray.dark,
                mr: ".3125rem",
                fontSize: ".875rem",
                width: ".875rem",
                height: ".875rem",
              }}
            />
            {ownerName}
          </Box>
        );
      },
      headerComponentParams: {
        description: "The organization that legally controls the data asset.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          SpecificTextFilterOperator.STARTS_WITH,
          SpecificTextFilterOperator.ENDS_WITH,
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
        ],
      },
    },
    {
      field: "managers",
      headerName: "Asset manager",
      getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.value;
      },
      valueGetter: (params: ValueGetterParams) => {
        const managers = params.data?.managers;
        if (Array.isArray(managers)) {
          return managers.reduce((accum, manger) => {
            if (accum === "") {
              return `${manger.firstName || ""} ${manger.lastName || ""}`;
            } else {
              return `${accum}, ${manger.firstName || ""} ${
                manger.lastName || ""
              }`;
            }
          }, "");
        } else {
          return "";
        }
      },
      cellRenderer: (params: ICellRendererParams) => {
        if (
          params.value === null ||
          params.value === undefined ||
          params.value === ""
        )
          return null;
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              sx={{ height: "1rem", width: "1rem", fontSize: "1rem", mr: 1 }}
            />
            {params.value}
          </Box>
        );
      },
      headerComponentParams: {
        description: "The manager of the data asset.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          SpecificTextFilterOperator.STARTS_WITH,
          SpecificTextFilterOperator.ENDS_WITH,
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    {
      field: "accessStatus",
      headerName: "Access status",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;

        return <AccessStatusChip status={params.value} />;
      },
      headerComponentParams: {
        description: "The status of the data asset.",
        filterOperators: [
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    {
      field: "updatedAt",
      type: "dateColumn",
      headerName: "Updated",
      cellClass: ["dark-cell"],
    },
    // {
    //   field: "tags",
    //   headerName: "Tags",
    //   type: "tagsColumn",
    //   width: 208,
    //   getQuickFilterText: (params: GetQuickFilterTextParams) => {
    //     if (Array.isArray(params.value)) {
    //       return params.value.join(" ");
    //     }
    //     return "";
    //   },
    // },
    {
      field: "projectCount",
      type: "numberColumn",
      headerName: "Projects",
      headerComponentParams: {
        headerAlignment: "left",
      },
      cellClass: ["ag-left-aligned-cell"],
    },
    {
      field: "childAssetCount",
      type: "numberColumn",
      headerName: "Child assets",
      headerComponentParams: {
        headerAlignment: "left",
      },
      cellClass: ["ag-left-aligned-cell"],
    },
    {
      field: "sensitivity",
      headerName: "Sensitivity",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        return (
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
            <Tag label={params.value} dismissable={false} />
          </Box>
        );
      },
      headerComponentParams: {
        description: "The Sensitivity of the data asset.",
        filterOperators: [
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    {
      field: "piiTypes",
      headerName: "PII types",
      type: "tagsColumn",
      width: 200,
      headerComponentParams: {
        description: "The PII types of the data asset.",
        filterOperators: [
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    {
      field: "refreshRate",
      headerName: "Data refresh",
    },
    {
      field: "issues1",
      headerName: "Asset Status",
      cellRenderer: (params: ICellRendererParams) => {
        let color: OverridableStringUnion<
          | "default"
          | "primary"
          | "secondary"
          | "error"
          | "info"
          | "success"
          | "warning",
          ChipPropsColorOverrides
        > = "primary";

        let label = "No Issues";

        if (Array.isArray(params.value) && params.value.length > 1) {
          color = "error";
          label = "Major Issues";
        } else if (
          params.value === Array.isArray(params.value) &&
          params.value.length === 1
        ) {
          color = "warning";
          label = "Minor Issues";
        } else {
          label = "No Issues";
        }
        return <Chip size="small" label={label} color={color} />;
      },
      headerComponentParams: {
        description: "The status of the data asset.",
        filterOperators: [FilterOperator.IS_ANY_OF, FilterOperator.IS_NONE_OF],
      },
    },
    {
      field: "refreshStatus",
      headerName: "Refresh status",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === null || params.value === undefined) return null;
        let avatar = <CheckMarkIcon />;
        let color: OverridableStringUnion<
          | "default"
          | "primary"
          | "secondary"
          | "error"
          | "info"
          | "success"
          | "warning",
          ChipPropsColorOverrides
        > = "primary";
        if (params.value === "Out-of-date" || params.value === "OUT_OF_DATE") {
          avatar = <ErrorOutline />;
          color = "error";
        }
        return (
          <Chip
            avatar={avatar}
            size="small"
            color={color}
            label={params.value}
          />
        );
      },
      headerComponentParams: {
        description: "The refresh status of the data asset.",
        filterOperators: [
          SpecificTextFilterOperator.CONTAINS,
          SpecificTextFilterOperator.STARTS_WITH,
          SpecificTextFilterOperator.ENDS_WITH,
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    {
      field: "connectionType",
      headerName: "Connection type",
      cellRenderer: (params: ICellRendererParams) => {
        if (params.value === "Project") {
          return (
            <Link
              variant="body3"
              fontWeight={700}
              to="/#test-url"
              tabIndex={-1}
            >
              {params.value}
            </Link>
          );
        } else {
          return params.value;
        }
      },
      headerComponentParams: {
        description: "The connection type of the data asset.",
        filterOperators: [
          FilterOperator.IS_ANY_OF,
          FilterOperator.IS_NONE_OF,
          FilterOperator.IS_EMPTY,
          FilterOperator.IS_NOT_EMPTY,
        ],
      },
    },
    // {
    //   field: "targetSchema",
    //   headerName: "Target schema",
    // },
    // {
    //   field: "validationStatus",
    //   headerName: "Validation status",
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (params.value === undefined) return null;
    //     let avatar = <CheckMarkIcon />;
    //     let color: OverridableStringUnion<
    //       | "default"
    //       | "primary"
    //       | "secondary"
    //       | "error"
    //       | "info"
    //       | "success"
    //       | "warning",
    //       ChipPropsColorOverrides
    //     > = "primary";
    //     if (params.value === ValidationStatus.Invalid) {
    //       avatar = <ErrorOutline />;
    //       color = "error";
    //     }
    //     return (
    //       <Chip
    //         avatar={avatar}
    //         size="small"
    //         color={color}
    //         label={params.value}
    //       />
    //     );
    //   },
    // },
    {
      sortable: false,
      pinned: "right",
      field: "actions",
      headerName: "Actions",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <ActionsCellRenderer
            rowIndex={params.rowIndex}
            accessStatus={params.data.accessStatus}
            isEditable={params.data.isEditable}
            id={params.data.id}
          />
        );
      },
      suppressMenu: true,
      width: 75,
    },
  ]);

  const initialPaginationPageSize = 20;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [paginationPageSize, setPaginationPageSize] = useState<number>(
    initialPaginationPageSize
  );
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState<number>(0);
  const [searchText, setSearchText] = useState("");

  const onPaginationChanged = () => {
    if (gridApi) {
      setPaginationTotalPages(gridApi.paginationGetTotalPages());
      setPaginationCurrentPage(gridApi.paginationGetCurrentPage());
      setPaginationPageSize(gridApi.paginationGetPageSize());
    }
  };

  const onChangeSearch = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchText(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params?.api);
    setColumnApi(params?.columnApi);
    params?.api?.paginationSetPageSize(initialPaginationPageSize);
    params?.columnApi?.applyColumnState({
      state: [{ colId: "updated", sort: "asc" }],
    });
  };

  const [noResultsFoundFromFilter, setNoResultsFoundFromFilter] =
    useState(false);

  useEffect(() => {
    const onFilterChanged = ({ api }: { api: GridApi }) => {
      if (rowData.length > 0 && api.getDisplayedRowCount() === 0) {
        setNoResultsFoundFromFilter(true);
      } else {
        setNoResultsFoundFromFilter(false);
      }
    };
    gridApi?.addEventListener("filterChanged", onFilterChanged);
    return () => {
      gridApi?.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, rowData]);

  const userName = `${state.user?.firstName} ${state.user?.lastName}`;
  const amountOfAssetsInUse =
    rowData
      .filter((value) => value?.accessStatus?.toLowerCase() === "shared")
      .filter((item) =>
        item.managers?.some(
          (m) => `${m?.firstName} ${m?.lastName}` === userName
        )
      ).length || 0;

  const amountOfIssues = rowData
    .filter((item) =>
      item.managers?.some((m) => `${m?.firstName} ${m?.lastName}` === userName)
    )
    .reduce((previousValue, currentValue) => {
      const issuesForCurrentValue =
        typeof currentValue?.issues?.length === "number"
          ? currentValue?.issues?.length
          : 0;
      return previousValue + issuesForCurrentValue;
    }, 0);

  const [assetsInUseFilterActive, setAssetsInUseFilterActive] = useState(false);
  const [assetIssuesFilterActive, setAssetIssuesFilterActive] = useState(false);

  const onClickAssetsIssuesFilter = () => {
    const newAssetIssuesFilterActive = !assetIssuesFilterActive;
    setAssetIssuesFilterActive(newAssetIssuesFilterActive);
    const filterInstance = gridApi?.getFilterInstance("issues");
    const filterInstanceManager = gridApi?.getFilterInstance("managers");

    if (newAssetIssuesFilterActive) {
      filterInstance?.setModel({
        filterType: "text",
        type: SpecificNumberFilterOperator.IS_NOT_EMPTY,
        filter: "|||",
      });

      filterInstanceManager?.setModel({
        filterType: "text",
        type: FilterOperator.EQUALS,
        filter: `${state.user?.firstName} ${state.user?.lastName}`,
      });
    } else {
      filterInstance?.setModel(null);
      filterInstanceManager?.setModel(null);
    }
    gridApi?.onFilterChanged();
  };

  const onClickAssetsInUseFilter = () => {
    const newAssetsInUseFilterActive = !assetsInUseFilterActive;
    setAssetsInUseFilterActive(newAssetsInUseFilterActive);
    const filterInstance = gridApi?.getFilterInstance("accessStatus");
    const filterInstanceManager = gridApi?.getFilterInstance("managers");

    if (newAssetsInUseFilterActive) {
      filterInstance?.setModel({
        filterType: "text",
        type: FilterOperator.EQUALS,
        filter: "Shared",
      });

      filterInstanceManager?.setModel({
        filterType: "text",
        type: FilterOperator.EQUALS,
        filter: `${state.user?.firstName} ${state.user?.lastName}`,
      });
    } else {
      filterInstance?.setModel(null);
      filterInstanceManager?.setModel(null);
    }
    gridApi?.onFilterChanged();
  };

  const onResetFilters = (gridApi: GridApi | undefined) => {
    gridApi?.setFilterModel(null);
    gridApi?.setQuickFilter("");
    setSearchText("");
  };

  useEffect(() => {
    const syncAccessStatusFilterWithExternalFilter = ({
      api,
    }: {
      api: GridApi;
    }) => {
      const filterInstance = api?.getFilterInstance("accessStatus");
      const currentModel = filterInstance?.getModel();
      if (
        currentModel?.type === FilterOperator.EQUALS &&
        typeof currentModel?.filter === "string" &&
        currentModel.filter.toLowerCase() === "shared"
      ) {
        setAssetsInUseFilterActive(true);
      } else {
        setAssetsInUseFilterActive(false);
      }
    };

    const syncIssuesFilterWithExternalFilter = ({ api }: { api: GridApi }) => {
      const filterInstance = api?.getFilterInstance("issues");
      const currentModel = filterInstance?.getModel();
      if (currentModel?.type === SpecificNumberFilterOperator.IS_NOT_EMPTY) {
        setAssetIssuesFilterActive(true);
      } else {
        setAssetIssuesFilterActive(false);
      }
    };

    const accessStatusColumn = columnApi?.getColumn("accessStatus");
    const issuesColumn = columnApi?.getColumn("issues");
    accessStatusColumn?.addEventListener(
      "filterChanged",
      syncAccessStatusFilterWithExternalFilter
    );
    issuesColumn?.addEventListener(
      "filterChanged",
      syncIssuesFilterWithExternalFilter
    );
    return () => {
      accessStatusColumn?.removeEventListener(
        "filterChanged",
        syncAccessStatusFilterWithExternalFilter
      );
      issuesColumn?.removeEventListener(
        "filterChanged",
        syncIssuesFilterWithExternalFilter
      );
    };
  }, [columnApi]);

  return (
    <Box
      className="ag-theme-material"
      flexGrow={1}
      display="flex"
      flexDirection="column"
    >
      <ActionBarDataAssetCatalog
        amountOfAssetsInUse={amountOfAssetsInUse}
        amountOfAssetIssues={amountOfIssues}
        assetsInUseFilterActive={assetsInUseFilterActive}
        assetIssuesFilterActive={assetIssuesFilterActive}
        onChangeSearch={onChangeSearch}
        searchText={searchText}
        noResultsFoundFromFilter={noResultsFoundFromFilter}
        onClickAssetsIssuesFilter={onClickAssetsIssuesFilter}
        onClickAssetsInUseFilter={onClickAssetsInUseFilter}
        onResetFilters={() => onResetFilters(gridApi)}
      />
      <Box flexGrow={1}>
        <AGGridWrapper
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={columnDefs}
          onPaginationChanged={onPaginationChanged}
          loading={loading}
        />
      </Box>
      <GridFooter
        totalRows={rowData.length}
        gridApi={gridApi}
        paginationPageSize={paginationPageSize}
        paginationCurrentPage={paginationCurrentPage}
        paginationTotalPages={paginationTotalPages}
        displayNoResults={noResultsFoundFromFilter || rowData.length === 0}
      />
    </Box>
  );
};
