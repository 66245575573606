import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const WarehouseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.5 17.5V6.66667L10 3.33333L17.5 6.66667V17.5"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M10.833 10.8333H14.1663V17.5H5.83301V12.5H10.833"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
      <path
        d="M10.8333 17.5V10C10.8333 9.77898 10.7455 9.56702 10.5893 9.41074C10.433 9.25446 10.221 9.16666 10 9.16666H8.33333C8.11232 9.16666 7.90036 9.25446 7.74408 9.41074C7.5878 9.56702 7.5 9.77898 7.5 10V12.5"
        stroke="currentColor"
        strokeWidth="1.6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
