import { Box, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import { FormLabel } from "../../common/Form/FormLabel";
import { KnowledgeCenter } from "../KnowledgeCenter";
import { SchemaTable } from "./SchemaTable";
import { useQuery } from "@apollo/client";
import { GetSchemasDocument } from "../../generated";
import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { Button } from "../../common/Button/Button";
import { CompareIcon } from "../../common/Icons/CompareIcon";
import { ModalSchemaField } from "../../Projects/ProjectSchemaPage/ModalSchemaField";
import { ButtonDefault, ButtonPrimary, ButtonsContainer } from "./style";
import { PlusIcon } from "../../common/Icons/PlusIcon";
import { SchemaBuilderPage } from "./SchemaBuilder/SchemaBuilderPage";

export const Schemas = ({}) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { workspaceId } = useParams();
  const { data, loading } = useQuery(GetSchemasDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  const rowData = useMemo(() => {
    return (
      data?.workspace?.schemas?.map((asset) => ({
        ...asset,
      })) || []
    );
  }, [data?.workspace?.schemas]);

  return (
    <KnowledgeCenter
      title="Schemas"
      rightElement={
        <ButtonsContainer>
          <ButtonPrimary
            type="button"
            onClick={() =>
              navigate(`/workspace/${workspaceId}/the-hive/schemas/compare`)
            }
          >
            <CompareIcon sx={{ marginRight: "5px" }} /> Compare
          </ButtonPrimary>
          <SchemaBuilderPage buttonText="Create" />
        </ButtonsContainer>
      }
    >
      <SchemaTable data={rowData} loading={loading} />
    </KnowledgeCenter>
  );
};
