import { styled, Box, Typography, Button } from "@mui/material";
import { TextField } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const Wrapper = styled("div")`
  width: 100%;
  max-width: 890px;
  margin-left: auto;
  margin-right: auto;
`;

export const WidgetsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
`;

export const WidgetCol = styled(Box)`
  display: flex;
  width: calc(50% - 8px);

  @media (min-width: 992px) {
    width: calc(33.333% - 11px);
  }
`;

export const EditorWrapper = styled(Box)`
  padding: 16px 24px;
  position: relative;
  border-radius: 4px;
  background-color: ${brighthiveTheme.palette.settings.lighter};
  margin-top: 24px;

  @media (min-width: 992px) {
    padding: 16px 32px;
  }
`;

export const MessagesWrapper = styled(Box)`
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 992px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const FormWrapper = styled(Box)`
  position: relative;
`;

export const ActionsWrapper = styled(Box)`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  gap: 8px;
`;

export const ButtonSubmit = styled(Button)`
  padding: 0;
  min-width: 1px;
  line-height: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root {
    width: 19px;
    height: 19px;
  }
`;

export const FormField = styled(TextField)`
  width: 100%;

  > .MuiInputBase-formControl {
    padding: 0;
    font-size: 14px;
    line-height: 1.2;
  }

  > div.MuiInputBase-formControl {
    padding: 14px 100px 14px 0;
  }

  fieldset {
    border-color: transparent !important;
  }
`;

export const ButtonAttachment = styled(Box)`
  position: relative;
  line-height: 0;
  width: 40px;
  height: 40px;
  pointer-events: none;
  cursor: default;

  .MuiSvgIcon-root {
    width: 19px;
    height: 19px;
    opacity: 0.5;
  }

  label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: ${brighthiveTheme.palette.primary.lighter};
    }
  }

  input {
    opacity: 0;
    clip: rect(0, 0, 0, 0);
    position: absolute;
    width: 1px;
    height: 1px;
  }
`;
