export const testSchemaTwo = {
  $schema: "http://json-schema.org/draft-07/schema#",
  title: "Arkansas Wage Information",
  type: "object",
  properties: {
    FirstName: {
      type: "string",
      description: "First name of the worker.",
    },
    MiddleInitial: {
      type: "string",
      description: "Middle initial of the worker.",
    },
    LastName: {
      type: "string",
      description: "Last name of the worker.",
    },
    SocialSecurityNumber: {
      type: "string",
      description: "Social Security Number of the worker.",
    },
    TotalWages: {
      type: "number",
      description:
        "Total Wages Excluding 125 Cafeteria Plan Wages. Wages include special payments given in return for services performed, i.e., commissions, bonuses, fees, prizes, and back pay awards are wages and reportable under Arkansas Department of Workforce Services Law. These payments (or dollar value) should be reported by the employer in the calendar quarter(s) in which they are given. ITEM 2. Enter the total wages (excluding 125 Cafeteria Plan wages, but before any deductions) paid to all employees, including part-time and temporary, during this calendar quarter. Total wages should agree with the total wages listed in PART B. If the legal business is: (A) A corporation, the wages paid to all employees who performed services in Arkansas should be reported. Corporate officers are employees and their wages and/or draws are reportable. (B) A partnership, the draws or payments made to general partners should not be reported. (C) A sole proprietorship, the draws or payments made to the legal owner of the business (the proprietor) should not be reported. Wages paid to minor (under the age of 21 years) children, spouse, or parents of the proprietor should not be reported. Special payments given in return for services performed, i.e., commissions, bonuses, fees, prizes, and back pay awards are wages and reportable under Arkansas Department of Workforce Services Law. These payments (or dollar value) should be reported by the employer in the calendar quarter(s) in which they are given.",
    },
    TotalWagesPaidInSeason: {
      type: "number",
      description: "Wages paid for employment within a seasonal period.",
    },
    TotalWagesPaidOutOfSeason: {
      type: "number",
      description: "Wages paid for employment outside a seasonal period.",
    },
  },
  required: ["FirstName", "LastName", "SocialSecurityNumber", "TotalWages"],
};
