import React from "react";
import { Navigate } from "react-router-dom";
import { useWorkspaceId } from "../hooks/useWorkspace";
import { useGetUserRole } from "../hooks/useGetUserRole";

export interface ProtectedRouteProps {
  children: React.ReactNode;
  access?: string[];
}

export const ProtectedRoute = ({ children, access }: ProtectedRouteProps) => {
  const { workspaceId } = useWorkspaceId();
  const token = localStorage.getItem("token");
  const { role } = useGetUserRole(workspaceId);

  if (access) {
    if (
      access.includes(role || "") &&
      (process.env.REACT_APP_MOCKS_ENABLED === "true" ||
        (typeof token === "string" && token.length > 0))
    ) {
      return <>{children}</>;
    } else {
      return <Navigate to="/404" replace />;
    }
  } else {
    if (
      (process.env.REACT_APP_MOCKS_ENABLED === "true" ||
        (typeof token === "string" && token.length > 0))
    ) {
      return <>{children}</>;
    }
  }

  return <Navigate to="/login" replace />;
};
