import React from "react";
import { Box, Dialog, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import { Alert } from "../../common/Alert";
import { useMutation } from "@apollo/client";
import { DeleteResourceDocument, GetResourcesDocument } from "../../generated";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonDefault } from "./style";

export const DeleteResourceModal = ({ name, open, setOpen, id }: any) => {
  const { workspaceId } = useParams();
  const { palette } = useTheme();
  const [showSnakBar, setShowSnakBar] = React.useState(false);

  const toggleDialog = () => {
    setOpen(false);
  };

  const toggleSnakBar = () => {
    setShowSnakBar(true);
  };

  const [remove] = useMutation(DeleteResourceDocument, {
    refetchQueries: [GetResourcesDocument],
  });

  return (
    <Dialog maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <Box width="560px" height="auto" p={6} boxSizing="border-box">
        <Typography mb={4} variant="h5" color={palette.common.black}>
          Permanently delete {name} from resource?
        </Typography>
        <Box mb={8}>
          {showSnakBar && (
            <Alert
              severity="warning"
              sx={{
                marginBottom: "16px",
              }}
            >
              This action may cause validation, data lineage, or data quality
              issues.
            </Alert>
          )}
        </Box>
        <Box mt={4} display="flex" alignItems={"center"}>
          <ButtonDefault
            color="grayAccent"
            variant="outlined"
            onClick={toggleDialog}
            sx={{ ml: "auto", mr: 3 }}
          >
            Cancel
          </ButtonDefault>
          {showSnakBar ? (
            <ButtonDefault variant="contained">
              I understand - permanently delete
            </ButtonDefault>
          ) : (
            <ButtonDefault
              onClick={() => {
                remove({
                  variables: {
                    input: {
                      resourceId: id || "",
                      workspaceId: workspaceId || "",
                    },
                  },
                })
                  .then(() => toast.success("Resource Deleted"))
                  .catch(() => toast.error("Interal Server Error"))
                  .finally(() => setOpen(false));
              }}
            >
              Permanently delete
            </ButtonDefault>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
