import { useState } from "react";
import { Button } from "../Button/Button";
import { Box } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { CollapseDrop, CollapseToggler, CollapsibleWrapper, InnerCollapseDrop, InnerCollapseToggler, InnerCollapsibleWrapper, InnerTogllerIcon, TogllerIcon } from "./style";
import { CaretDownViiIcon } from "../Icons/CaretDownViiIcon";
import { CaretUpViiIcon } from "../Icons/CaretUpViiIcon";

export const InnerCollapsibleButton = ({ children, show, label, icon }: any) => {
  const [open, setOpen] = useState<boolean>(show);

  return (
    <InnerCollapsibleWrapper>
      <InnerCollapseToggler className={open ? 'active' : ''} onClick={() => setOpen((prev) => !prev)}>
        {icon &&
          icon
        }
        {label}{" "}
        <InnerTogllerIcon>
          {open ? <CaretUpViiIcon /> : <CaretDownViiIcon />}
        </InnerTogllerIcon>
      </InnerCollapseToggler>
      {open && <InnerCollapseDrop className="inner-dropdown">{children}</InnerCollapseDrop>}
    </InnerCollapsibleWrapper>
  );
};
