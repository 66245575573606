import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { BrighthiveLogoSvg } from "../../../common/Icons/BrighthiveLogoSvg";
import { Typography } from "../../../common/Typography/Typography";
import { CompanyIcon } from "../../../common/Icons/CompanyIcon";
import { Button } from "../../../common/Button/Button";

export interface ConfirmOrganizationProps {}

export const ConfirmOrganization = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        p: 6,
        m: "auto",
        width: "28.5rem",
        background: "white",
        borderRadius: ".375rem",
        boxShadow:
          `0 .0625rem .0625rem ${palette.customRolled.border}, 0 .125rem .0625rem ${palette.action.disabledBackground}, 0 .0625rem .1875rem ${palette.customRolled.contrastText}`,
      }}
    >
      <BrighthiveLogoSvg
        sx={{
          height: "1.875rem",
          width: "initial",
          mb: 6,
        }}
      />
      <Typography variant="h5" mb={6}>
        Confirm your organization
      </Typography>
      <Typography mb={4} variant="body2">
        You’ve been invited to Citrus University’s workspace as a member of the
        organization:
      </Typography>
      <Box display="flex">
        <CompanyIcon />
        <Typography pl={".375rem"} fontWeight={500} mb={4} variant="body2">
          Copper High School
        </Typography>
      </Box>
      <Typography mb={6} variant="body2">
        Please confirm that you belong to this organization to continue.
      </Typography>
      <Button
        onClick={() => navigate("/register-user")}
        fullWidth
        variant="contained"
      >
        Confirm and continue
      </Button>
      <Typography mt={6} variant="body3">
        Not your organization? Get in contact with the administrator who sent
        your invitation.
      </Typography>
    </Box>
  );
};
