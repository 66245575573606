import { useLocation, useParams } from "react-router-dom";
import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { CollectionIcon } from "../Icons/CollectionIcon";
import { ConnectionIcon } from "../Icons/ConnectionIcon";
import { DataQualityIcon } from "../Icons/DataQualityIcon";
import { WarehouseIcon } from "../Icons/WarehouseIcon";
import { TransformationIcon } from "../Icons/TransformationIcon";
import { UseIcon } from "../Icons/UseIcon";
import { LockIcon } from "../Icons/LockIcon";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { AllSources } from "../Icons/AllSources";
import { Internal } from "../Icons/Internal";
import { Partner } from "../Icons/Partner";
import { Subscriptions } from "../Icons/Subscriptions";
import { Marketplace } from "../Icons/Marketplace";

export const SourcesLink = ({}) => {
  const { workspaceId } = useWorkspaceId();
  const { pathname } = useLocation();
  return (
    <>
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/sources/all`}
        icon={<AllSources />}
        label="All"
        className={pathname.includes("all") ? "active" : ""}
        active={pathname.includes("all")}
      />
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/sources/internal`}
        icon={<Internal />}
        label="Internal"
        active={pathname.includes("internal")}
        className={pathname.includes("internal") ? "active" : ""}
      />
      {/* <SidenavButtonLink
        to={`/workspace/${workspaceId}/sources/partners`}
        icon={<Partner />}
        label="Partners"
        active={pathname.includes("partners")}
        className={pathname.includes("partners") ? "active" : ""}
        disabled
      />
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/sources/subscriptions`}
        icon={<Subscriptions />}
        label="Subscriptions"
        active={pathname.includes("subscriptions")}
        className={pathname.includes("subscriptions") ? "active" : ""}
        disabled
      />
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/sources/marketplace`}
        icon={<Marketplace />}
        label="Marketplace"
        active={pathname.includes("marketplace")}
        className={pathname.includes("marketplace") ? "active" : ""}
        disabled
      /> */}
    </>
  );
};
