import React from "react";
import { Typography } from "../../common/Typography/Typography";
import { Box, useTheme } from "@mui/material";
import { FolderOutlined, GridView } from "@mui/icons-material";
import {
  BreadcrumbLink,
  LastBreadcrumbLink,
} from "../../common/BreadcrumbsLinks/BreadcrumbLink";
import { BreadcrumbLinks } from "../../common/BreadcrumbsLinks/BreadcrumbLinks";
import { useQuery } from "@apollo/client";
import { GetProjectDocument, GetWorkspaceInfoDocument } from "../../generated";
import { ProjectPage } from "../../common/ProjectPage/ProjectPage";
import { useParams } from "react-router-dom";
import { DataAssetCatalogGrid } from "./DataAssetCatalogGrid";
import { AddDataAssetButton } from "./AddDataAssetButton";

export interface ProjectDataAssetPageProps {}

export const ProjectDataAssetPage = () => {
  const { palette } = useTheme();
  const { workspaceId, projectId } = useParams();
  const { data: workspaceData } = useQuery(GetWorkspaceInfoDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });
  const { data: projectData } = useQuery(GetProjectDocument, {
    variables: {
      input: { workspaceId: workspaceId || "" },
      projectFilter: {
        projectId: projectId || "",
      },
    },
  });
  const workspaceName = workspaceData?.workspace?.name || "";
  const projects = projectData?.workspace?.projects;
  const project = projectData?.workspace?.projects?.[0];
  const projectName =
    Array.isArray(projects) && typeof projects[0]?.name === "string"
      ? projects[0].name
      : "";
  const isEditable = project?.isEditable || true;

  return (
    <ProjectPage
      projectContent={
        <BreadcrumbLinks>
          <BreadcrumbLink
            icon={<GridView />}
            to={`/workspace/${workspaceId}/projects`}
            iconSize="small"
          >
            <Typography variant="body3">{workspaceName}</Typography>
          </BreadcrumbLink>
          <LastBreadcrumbLink icon={<FolderOutlined />} iconSize="small">
            <Typography variant="body3">{projectName}</Typography>
          </LastBreadcrumbLink>
        </BreadcrumbLinks>
      }
      leftHeaderElements={
        <>
          <Typography mr={2} variant="h5">
            Input Data Assets
          </Typography>
        </>
      }
      rightHeaderElements={
        isEditable ? <AddDataAssetButton onClick={() => {}} /> : null
      }
      content={
        <Box
          bgcolor={palette.common.white}
          display="flex"
          flexDirection="column"
          boxSizing="border-box"
          flexGrow={1}
          border={`0.0625rem solid ${palette.outline}`}
          borderRadius="0.375rem"
        >
          <DataAssetCatalogGrid />
        </Box>
      }
    />
  );
};
