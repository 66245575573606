import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import TextField from "@mui/material/TextField";

import { Typography } from "../../../common/Typography/Typography";
import { BasicCard } from "../../../common/BasicCard/BasicCard";
import { Label } from "../../../common/Label/Label";
import { Button } from "../../../common/Button/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  TargetSchema,
  UpdateTargetSchemaDocument,
  GetTargetSchemasDocument,
  GetSchemasDocument,
  SchemaType,
  UpdateWorkspaceSchemaDocument,
  GetSchemaDocument,
} from "../../../generated";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Tag } from "../../../common/Tag/Tag";
import { valueFromAST } from "graphql";
import AccessStatusChip from "../../../AssetDetail/AccessStatusChip/AccessStatusChip";
import Dropdown from "../../../common/Dropdown/Dropdown";
import { ButtonDefault, ButtonPrimary } from "../style";

export const SchemaInformationEdit = ({
  data,
  onSave,
  onCancel,
  onEdit,
  isEditMode,
  ...rest
}: {
  data: TargetSchema | undefined;
  onSave: () => any;
  onCancel: () => any;
  onEdit: () => any;
  isEditMode: boolean;
}) => {
  const { workspaceId, schemaId } = useParams();
  const { palette } = useTheme();
  const [tag, setTag] = useState("");
  const [piiType, setPiiType] = useState("");

  const [mutateUpdate] = useMutation(UpdateWorkspaceSchemaDocument, {
    refetchQueries: [GetSchemasDocument],
  });

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().optional(),
        description: Yup.string().optional(),
      })}
      initialValues={{
        description: data?.description,
        name: data?.name,
        types: data?.types || [],
        status: data?.status,
        versionNumber: data?.versionNumber,
        refreshRate: "",
        piiTypes: {
          connectOrCreate: data?.piiTypes?.map((tag) => tag || "") || [],
          disconnect: [],
        },
        tags: {
          connectOrCreate: data?.tags?.map((tag) => tag || "") || [],
          disconnect: [],
        },
      }}
      onSubmit={async (values) => {
        mutateUpdate({
          variables: {
            input: {
              schemaId: schemaId || "",
              workspaceId: workspaceId || "",
              name: values?.name,
              description: values?.description,
              jsonSchema: data?.jsonSchema,
              tags: values?.tags,
              status: values?.status,
              piiTypes: values?.piiTypes,
            },
          },
        });
        onSave();
      }}
    >
      {({ handleSubmit, handleChange, values, setFieldValue, resetForm }) => {
        return (
          <BasicCard style={{ width: "100%" }} {...rest}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ padding: ".75rem 1.5rem" }}
            >
              <Typography variant={"h6"}>Target Schema information</Typography>
              {isEditMode ? (
                <Box>
                  <ButtonDefault
                    onClick={() => {
                      onCancel();
                      resetForm();
                    }}
                    size={"small"}
                    variant="outlined"
                    color={"grayAccent"}
                  >
                    Cancel
                  </ButtonDefault>
                  <ButtonPrimary
                    onClick={() => handleSubmit()}
                    size={"small"}
                    variant="contained"
                  >
                    Save Changes
                  </ButtonPrimary>
                </Box>
              ) : (
                <ButtonDefault
                  onClick={onEdit}
                  size={"small"}
                  color={"grayAccent"}
                  startIcon={<EditOutlinedIcon />}
                >
                  Edit
                </ButtonDefault>
              )}
            </Box>
            <Divider />

            <Box
              sx={{
                padding: "1.5rem 2rem",
              }}
            >
              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Target Schema name" width={"15.5rem"} />
                {isEditMode ? (
                  <TextField
                    hiddenLabel
                    fullWidth
                    onChange={handleChange("name")}
                    value={values.name}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                ) : (
                  <Typography variant="body2" color={palette.gray.dark}>
                    {values.name}
                  </Typography>
                )}
              </Box>
              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Description" width={"15.5rem"} />
                {isEditMode ? (
                  <TextField
                    name="description"
                    value={values.description}
                    hiddenLabel
                    fullWidth
                    onChange={handleChange("description")}
                    defaultValue={data?.description}
                    multiline
                    inputProps={{
                      sx: { fontSize: "1rem" },
                    }}
                    size="small"
                  />
                ) : (
                  <Typography variant="body2" color={palette.gray.dark}>
                    {values.description}
                  </Typography>
                )}
              </Box>
              {/* <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Type" width={"15.5rem"} />
                {isEditMode ? (
                  <Dropdown
                    onChange={(e) => {
                      setFieldValue("types", e.target.value);
                    }}
                    value={values.types as any}
                    options={[SchemaType.Input, SchemaType.Output]}
                  />
                ) : (
                  <>
                    {values?.types?.map((type) => (
                      <Typography variant="body2" color={palette.gray.dark}>
                        {type}
                      </Typography>
                    ))}
                  </>
                )}
              </Box> */}
              <Divider sx={{ mb: 10, mt: 4 }} />
              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Schema Owner" width={"15.5rem"} />
                <Typography>{data?.owner?.name}</Typography>
              </Box>
              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Schema manager" width={"15.5rem"} />
                {data?.managers.map((manager) => (
                  <Typography variant="body2" color={palette.gray.dark}>
                    {manager.firstName + " " + manager.lastName}
                  </Typography>
                ))}
              </Box>

              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Status" width={"15.5rem"} />
                {isEditMode ? (
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.status}
                      renderValue={(v) =>
                        values.status ? (
                          <AccessStatusChip status={values.status} />
                        ) : null
                      }
                      label="Status"
                      onChange={(e) => setFieldValue("status", e.target.value)}
                      sx={{
                        fieldset: {
                          top: "0",
                        },
                        legend: {
                          display: "none",
                        },
                        ".MuiInputBase-input": {
                          width: "100%",
                          height: "36px",
                          fontSize: "16px",
                          lineHeight: "20px",
                          padding: "8px",
                          boxSizing: "border-box",
                        },
                      }}
                    >
                      {[
                        { id: "PUBLISHED", name: "PUBLISHED" },
                        { id: "DRAFT", name: "DRAFT" },
                        { id: "PRIVATE", name: "PRIVATE" },
                      ].map((manager: any) => (
                        <MenuItem value={manager.id}>{manager.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography variant="body2" color={palette.gray.dark}>
                    {values?.status}
                  </Typography>
                )}
              </Box>
              <Divider sx={{ mb: 10, mt: 4 }} />
              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Version" width={"15.5rem"} />
                {isEditMode ? (
                  <TextField
                    hiddenLabel
                    fullWidth
                    onChange={handleChange("versionNumber")}
                    value={values.versionNumber}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                ) : (
                  <Typography variant="body2" color={palette.gray.dark}>
                    {data?.versionNumber}
                  </Typography>
                )}
              </Box>

              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="PII Types" width={"15.5rem"} />
                {isEditMode ? (
                  <Box width="100%">
                    <TextField
                      hiddenLabel
                      fullWidth
                      placeholder="Add PiiTypes here"
                      value={piiType}
                      onChange={(e) => setPiiType(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          setFieldValue("piiTypes.connectOrCreate", [
                            ...(values.piiTypes?.connectOrCreate || []),
                            piiType,
                          ]);
                          setPiiType("");
                        }
                      }}
                      inputProps={{
                        sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                      }}
                      size="small"
                    />
                    <Box display={"flex"} pt={"1.125rem"}>
                      {values.piiTypes?.connectOrCreate.map((tag: string) => (
                        <Tag
                          label={tag}
                          dismissable={true}
                          mr={2}
                          onClickDismiss={() => {
                            setFieldValue("piiTypes.connectOrCreate", [
                              ...(values.piiTypes?.connectOrCreate.filter(
                                (aTag) => tag !== aTag
                              ) || []),
                            ]);
                            setFieldValue("piiTypes.disconnect", [
                              ...(values.piiTypes?.disconnect || []),
                              tag,
                            ]);
                          }}
                        ></Tag>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <>
                      <Box display={"flex"}>
                        {data?.piiTypes.map((tag: string) => (
                          <Tag label={tag} dismissable={false} mr={2}></Tag>
                        ))}
                      </Box>
                    </>
                  </>
                )}
              </Box>
              <Divider sx={{ mb: 10, mt: 4 }} />
              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Created" width={"15.5rem"} />
                <Typography variant="body2" color={palette.gray.dark}>
                  {data?.createdAt}
                </Typography>
              </Box>

              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Uploaded" width={"15.5rem"} />
                <Typography variant="body2" color={palette.gray.dark}>
                  {data?.modifiedAt}
                </Typography>
              </Box>
              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Tags" info width={"15.5rem"} />
                {isEditMode ? (
                  <Box width="100%">
                    <TextField
                      hiddenLabel
                      fullWidth
                      placeholder="Add tags here"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          setFieldValue("tags.connectOrCreate", [
                            ...(values.tags?.connectOrCreate || []),
                            tag,
                          ]);
                          setTag("");
                        }
                      }}
                      inputProps={{
                        sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                      }}
                      size="small"
                    />
                    <Box display={"flex"} pt={"1.125rem"}>
                      {values.tags?.connectOrCreate.map((tag: string) => (
                        <Tag
                          label={tag}
                          dismissable={true}
                          mr={2}
                          onClickDismiss={() => {
                            setFieldValue("tags.connectOrCreate", [
                              ...(values.tags?.connectOrCreate.filter(
                                (aTag) => tag !== aTag
                              ) || []),
                            ]);
                            setFieldValue("tags.disconnect", [
                              ...(values.tags?.disconnect || []),
                              tag,
                            ]);
                          }}
                        ></Tag>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box display={"flex"}>
                      {data?.tags.map((tag: string) => (
                        <Tag label={tag} dismissable={false} mr={2}></Tag>
                      ))}
                    </Box>
                  </>
                )}
              </Box>
              <Divider sx={{ mb: 10, mt: 4 }} />
              <Box
                display={"flex"}
                pb={"1.5rem"}
                alignItems={"flex-start"}
                maxWidth={"58rem"}
              >
                <Label value="Refresh rate" width={"15.5rem"} />
                {isEditMode ? (
                  <TextField
                    hiddenLabel
                    fullWidth
                    onChange={handleChange("refreshRate")}
                    value={values.refreshRate}
                    inputProps={{
                      sx: { fontSize: "1rem", padding: ".375rem .75rem" },
                    }}
                    size="small"
                  />
                ) : (
                  <Typography variant="body2" color={palette.gray.dark}>
                    {values.refreshRate}
                  </Typography>
                )}
              </Box>
            </Box>
          </BasicCard>
        );
      }}
    </Formik>
  );
};

export {};
