import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "../../common/Typography/Typography";
import { Box, useTheme } from "@mui/material";
import { SideNavigationPage } from "../../common/SideNavigationPage/SideNavigationPage";
import { Button } from "../../common/Button/Button";
import { ActionBarProjects } from "../../common/ActionBarProjects/ActionBarProjects";
import { ProjectCard } from "../../common/ProjectCard/ProjectCard";
import { SortByType } from "../../common/ActionBarProjects/SortByButtonMenu";
import { stringDateComparator } from "../../helpers";
import { FormikValues } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateProjectDocument,
  GetProjectsDocument,
  GetWorkspaceInfoDocument,
  ProjectStatus,
  GetCurrentUserInfoDocument,
  WorkspaceRoleEnum,
  UserOutput,
} from "../../generated";
import { useParams } from "react-router-dom";
import { SimpleLoader } from "../../common/Loader/Loader";
import { AppContext } from "../../App";
import { WorkspaceSidenav } from "../../common/WorkspaceSidenav/WorkspaceSidenav";
import { useNavigate } from "react-router-dom";
import { GridView } from "@mui/icons-material";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { ProjectGridView } from "./ProjectGridView";
import { lowerCase } from "lodash";
import { palette } from "@mui/system";
import { ButtonPrimary } from "./style";
import { PlusIcon } from "../../common/Icons/PlusIcon";

export interface ProjectsListProps {
  showSidenav: boolean;
  accessible?: boolean;
}

export const ProjectsListPage = ({
  showSidenav,
  accessible,
}: ProjectsListProps) => {
  const [currentIndex, setCurrentIndex] = useState(20);
  const [cardView, setCardView] = useState(true);
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const [mutate] = useMutation(CreateProjectDocument);
  const { data, loading, refetch } = useQuery(GetProjectsDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });
  const { palette } = useTheme();

  const refetchProjectDocuments = useCallback(() => {
    refetch();
  }, [refetch]);

  const { data: userData } = useQuery(GetCurrentUserInfoDocument);

  const { data: workspaceData } = useQuery(GetWorkspaceInfoDocument, {
    variables: {
      input: { workspaceId: workspaceId || "" },
      accessible: accessible,
    },
  });

  const workspaceName = workspaceData?.workspace?.name || "";
  const isEditable = workspaceData?.workspace?.governance?.isEditable;
  const projectDataPaginated = useMemo(
    () =>
      data?.workspace?.projects?.filter((project) => project !== null) || [],
    [data?.workspace]
  );

  const columnSize = showSidenav ? "18.875" : "30.375";
  const [currSortByType, setCurrSortByType] = useState<null | SortByType>(
    SortByType.DateUpdated
  );

  const sortedProjectData = useMemo(() => {
    if (currSortByType === null) return projectDataPaginated;
    return projectDataPaginated.sort((a, b) => {
      if (a && !b) {
        return 1;
      } else if (!a && b) {
        return -1;
      } else if (!a && !b) {
        return 0;
      } else if (currSortByType === SortByType.DateUpdated) {
        const result = stringDateComparator(a?.modifiedAt, b?.modifiedAt);
        return result === undefined ? 0 : result;
      } else if (currSortByType === SortByType.ProjectName) {
        const result = a?.name
          .toLowerCase()
          .localeCompare(b?.name.toLowerCase() || "");
        return result === undefined ? 0 : result;
      } else if (currSortByType === SortByType.ProjectStatus) {
        const sorting = [
          ProjectStatus.Active,
          ProjectStatus.Published,
          ProjectStatus.Draft,
          ProjectStatus.Private,
          ProjectStatus.Complete,
        ];
        if (a?.status && b?.status) {
          return sorting.indexOf(a.status) - sorting.indexOf(b.status);
        } else {
          return 0;
        }
      } else if (currSortByType === SortByType.DateCreated) {
        const result = stringDateComparator(a?.createdAt, b?.createdAt);
        return result === undefined ? 0 : result;
      } else {
        return 0;
      }
    });
  }, [currSortByType, projectDataPaginated]);

  const activeProjects = useMemo(() => {
    return sortedProjectData.filter(
      (curr) => curr?.status === ProjectStatus.Active
    );
  }, [sortedProjectData]);

  const publishedProjects = useMemo(() => {
    return sortedProjectData.filter(
      (curr) => curr?.status === ProjectStatus.Published
    );
  }, [sortedProjectData]);

  const archivedProjects = useMemo(() => {
    return sortedProjectData.filter(
      (curr) => curr?.status === ProjectStatus.Archived
    );
  }, [sortedProjectData]);

  const privateProjects = useMemo(() => {
    return sortedProjectData.filter(
      (curr) => curr?.status === ProjectStatus.Private
    );
  }, [sortedProjectData]);

  const completeProjects = useMemo(() => {
    const abc = sortedProjectData.filter(
      (curr) => curr?.status === ProjectStatus.Complete
    );
    return abc;
  }, [sortedProjectData]);

  const draftProjects = useMemo(() => {
    return sortedProjectData.filter(
      (curr) => curr?.status === ProjectStatus.Draft
    );
  }, [sortedProjectData]);

  const [statusValues, setStatusValues] = useState<FormikValues>({
    all: false,
    [ProjectStatus.Private]: true,
    [ProjectStatus.Published]: true,
    [ProjectStatus.Draft]: true,
    [ProjectStatus.Active]: true,
    [ProjectStatus.Complete]: true,
    [ProjectStatus.Archived]: false,
  });

  const [ownerValues, setOwnerValues] = useState<FormikValues>({
    all: true,
  });

  useEffect(() => {
    const ownerValues: FormikValues = {
      all: true,
    };
    sortedProjectData.forEach((project) => {
      if (project?.owner?.name) {
        ownerValues[project?.owner?.name] = true;
      }
    });
    setOwnerValues(ownerValues);
  }, [sortedProjectData]);

  const filteredProjects = useMemo(() => {
    const getProjectsFilteredByOwnerName = (projects: any[]) => {
      if (ownerValues.all === true) {
        return projects;
      } else {
        return projects.filter((curr) => {
          if (!curr?.owner) return false;
          return ownerValues[curr?.owner?.name] === true;
        });
      }
    };

    const getProjectsFilteredByStatus = (projects: any[]) => {
      if (statusValues.all === true) {
        return projects;
      } else {
        return projects.filter((curr) => {
          if (!curr?.status) return false;
          return statusValues[curr?.status] === true;
        });
      }
    };

    const projectsFilteredByOwnerName =
      getProjectsFilteredByOwnerName(sortedProjectData);
    const projectsFilteredByEverything = getProjectsFilteredByStatus(
      projectsFilteredByOwnerName
    );
    return projectsFilteredByEverything.filter((item) => {
      if (!item.isEditable && item.status === "PRIVATE") {
        return false;
      }

      if (
        item.status === "PRIVATE" &&
        item.managers.find(
          (manager: UserOutput) => manager.id !== userData?.currentUser.id
        )
      ) {
        return false;
      }
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sortedProjectData,
    ownerValues,
    statusValues,
    currSortByType,
    currentIndex,
    userData,
  ]);

  const [searchText, setSearchText] = useState("");

  const paginatedFilteredProjects = useMemo(
    () =>
      filteredProjects
        .slice(0, currentIndex)
        .filter((data) => lowerCase(data.name).includes(lowerCase(searchText))),
    [filteredProjects, currentIndex, searchText]
  );

  const onNewProject = async () => {
    const { data } = await mutate({
      variables: {
        workspaceId: workspaceId || "",
      },
    });

    refetch();
    navigate(
      `/workspace/${workspaceId}/project/${data?.createProject?.projectId}/project-overview/edit`
    );
  };

  return (
    <AppContext.Consumer>
      {({ showSidenav }) => (
        <SideNavigationPage
          leftHeaderElements={
            <Typography variant="h5">Project List</Typography>
          }
          rightHeaderElements={
            isEditable ? (
              <ButtonPrimary
                color="primary"
                variant="contained"
                onClick={onNewProject}
              >
                <PlusIcon />
                Create Project
              </ButtonPrimary>
            ) : null
          }
          // verticalNav={
          //   <WorkspaceSidenav
          //     workspaceName={workspaceName}
          //     show={showSidenav}
          //     isEditable={isEditable}
          //   />
          // }
          content={
            <Box
              display="flex"
              flexDirection="column"
              boxSizing="border-box"
              flexGrow={1}
            >
              <ActionBarProjects
                amountOfProjects={projectDataPaginated.length}
                amountOfActiveProjects={activeProjects.length}
                amountOfPublishedProjects={publishedProjects.length}
                amountOfArchivedProjects={archivedProjects.length}
                amountOfDraftProjects={draftProjects.length}
                amountOfPrivateProjects={privateProjects.length}
                amountOfCompleteProjects={completeProjects.length}
                statusValues={statusValues}
                setStatusValues={setStatusValues}
                ownerValues={ownerValues}
                setOwnerValues={setOwnerValues}
                setCurrSortByType={setCurrSortByType}
                sortByType={currSortByType}
                workspace={data?.workspace}
                sx={{ flexGrow: 0 }}
                cardView={cardView}
                setCardView={setCardView}
                searchText={searchText}
                setSearchText={setSearchText}
              />
              <Box
                // gridTemplateColumns={`repeat(auto-fit, 18.875rem)`}
                sx={{
                  flexWrap: "wrap",
                  gap: "16px",
                  display: "flex",

                  "> p": {
                    width: "100%",
                  },

                  "> .first-project-card, > .project-card": {
                    width: "calc(100% - 12px)",
                    // minWidth: "18.875rem",

                    "@media (min-width: 992px)": {
                      width: "calc(50% - 12px)",
                    },
                    "@media (min-width: 1200px)": {
                      width: "calc(33.333% - 12px)",
                    },
                    "@media (min-width: 1350px)": {
                      width: "calc(25% - 12px)",
                    },

                    "> .project-card >.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded:hover":
                      {
                        background: `linear-gradient(0deg, ${palette.primary.lighter}, ${palette.primary.lighter}),${palette.common.white}`,
                      },
                  },

                  "> .ag-theme-material": {
                    width: "100%",
                  },
                }}
              >
                {typeof projectDataPaginated?.length === "number" && (
                  <Typography variant="body3" mb={2} sx={{ width: "100%" }}>
                    Displaying {paginatedFilteredProjects.length} of{" "}
                    {filteredProjects.length}
                  </Typography>
                )}
                {cardView ? (
                  <>
                    {paginatedFilteredProjects?.map((project, index) => {
                      if (project) {
                        const { id, owner, ...rest } = project;
                        return (
                          <>
                            <ProjectCard
                              workspaceName={workspaceName}
                              workspaceId={workspaceId}
                              id={id}
                              className={
                                index === 0
                                  ? "first-project-card"
                                  : "project-card"
                              }
                              cardSizeExpanded={!showSidenav}
                              key={id}
                              ownerName={owner?.name || ""}
                              refetchProjectDocuments={refetchProjectDocuments}
                              {...rest}
                            />
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                ) : (
                  <ProjectGridView
                    workspaceName={workspaceName}
                    workspaceId={workspaceId}
                    refetchProjectDocuments={refetchProjectDocuments}
                    rowData={paginatedFilteredProjects}
                  />
                )}
              </Box>
              {((Array.isArray(filteredProjects) &&
                filteredProjects?.length > currentIndex) ||
                loading) && (
                <Box
                  mt={10}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {loading ? (
                    <SimpleLoader />
                  ) : (
                    <Button
                      aria-label="load the next 20 projects"
                      onClick={() => setCurrentIndex(currentIndex + 20)}
                      color="grayAccent"
                      variant="contained"
                    >
                      Load 20 Next Projects
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          }
        />
      )}
    </AppContext.Consumer>
  );
};
