import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import { Typography } from "../../common/Typography/Typography";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import {
  GetWorkspaceInfoDocument,
  GetCurrentUserInfoDocument,
  GetWorkspaceMembersDocument,
  WorkspaceRoleEnum,
} from "../../generated";
import { useQuery } from "@apollo/client";
import { AvatarLabel } from "../../common/AvatarLabel/AvatarLabel";
import { convertIsoDateStringToMMDDYYYY } from "../../helpers";

export const Profile = () => {
  const { workspaceId } = useParams();
  const { data: workspaceData } = useQuery(GetWorkspaceInfoDocument, {
    variables: { input: { workspaceId: workspaceId || "" } },
  });
  const { data: dataMembers } = useQuery(GetWorkspaceMembersDocument, {
    variables: {
      input: {
        workspaceId: workspaceId || "",
      },
    },
  });

  const { data } = useQuery(GetCurrentUserInfoDocument);

  const organizationName = data?.currentUser?.organization?.name || "";
  const createdAt = convertIsoDateStringToMMDDYYYY(
    data?.currentUser?.organization?.createdAt
  );
  const description = data?.currentUser?.organization?.description || "";

  const items = [
    {
      label: "Description",
      value: description,
    },
    {
      label: "Created",
      value: convertIsoDateStringToMMDDYYYY(createdAt),
    },
  ];

  const admins = dataMembers?.workspace?.members
    ?.filter((item) => item.workspaceRole === WorkspaceRoleEnum.Admin)
    .map((item) => ({
      label: `${item.firstName || ""} ${item.lastName || ""}`,
    }));

  return (
    <Grid container spacing={6}>
      <Grid item xs={8}>
        <BasicCard sx={{ padding: "1.6rem" }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">{organizationName}</Typography>
            </Grid>
            {items.map((item) => (
              <Grid item xs={12}>
                <Typography variant="subtitle2">{item.label}</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    color: (theme) => theme.palette.gray.darkest,
                  }}
                >
                  {item.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </BasicCard>
      </Grid>
      <Grid item xs={4}>
        <BasicCard sx={{ padding: "1.6rem" }}>
          <Typography variant="subtitle2">Organization admins</Typography>
          {admins?.map((admin) => (
            <Box mt={2}>
              <AvatarLabel label={admin.label} />
            </Box>
          ))}
        </BasicCard>
      </Grid>
    </Grid>
  );
};
