import React, { useEffect } from "react";
import { Box, Divider, Tooltip, useTheme } from "@mui/material";
import { Sidenav } from "../Sidenav/Sidenav";
import { Button } from "../Button/Button";
import { GridView, Home } from "@mui/icons-material";
import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { Typography } from "../Typography/Typography";
import { SquareIcon } from "../SquareIcon/SquareIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProjectIcon } from "../Icons/ProjectIcon";
import { DataAssetLibraryIcon } from "../Icons/DataAssetLibraryIcon";
import { MembersIcon } from "../Icons/MembersIcon";
import { GovernanceIcon } from "../Icons/GovernanceIcon";
import { WorkspaceSetupIcon } from "../Icons/WorkspaceSetupIcon";
import { useGetUserRole } from "../../hooks/useGetUserRole";
import { useWorkspaceId } from "../../hooks/useWorkspace";
import { BrighthiveIcon } from "../Icons/BrighthiveIcon";
import { GridIcon } from "../Icons/GridIcon";
import { SourcesIcon } from "../Icons/SourcesIcon";
import { ServicesIcon } from "../Icons/ServicesIcon";
import { SidebarMenuCollapse } from "../Icons/SidebarMenuCollapse";
import { QuestionCircle } from "../Icons/QuestionCircle";
import { BrighthiveLogoSvgWhite } from "../Icons/BrighthiveLogoSvgWhite";
import {
  GetCurrentUserInfoDocument,
  GetWorkspaceInfoDocument,
  WorkspaceRoleEnum,
} from "../../generated";
import { useQuery } from "@apollo/client";
import { getRandomNumber } from "../../helpers";
import { ChatBotIcon } from "../Icons/ChatBotIcon";
import { SearchListIcon } from "../Icons/SearchListIcon";
import { CollapsibleButton } from "./CollapsibleButton";
import { ServicesLink } from "./ServicesLink";
import { SourcesLink } from "./SourcesLink";
import { KnowledgeLink } from "./KnowledgeLink";
import { ProjectsLink } from "./ProjectsLink";
import {
  InnerCollapsibleWrapper,
  PostTitle,
  SidebarOwnCollapseButton,
  SidebarOwnCollapseWrapper,
  SideNavLogo,
  SideNavLogoLink,
  SideNavLogoText,
  SideNavLogoTypo,
  SideNavTopWrap,
  Wrapper,
} from "./style";
import { BrighthiveLogoSvg } from "../Icons/BrighthiveLogoSvg";
import { BrighthiveLogoSvgVii } from "../Icons/BrighthiveLogoSvgVii";
import { HomeViiIcon } from "../Icons/HomeViiIcon";
import { DabbeViiIcon } from "../Icons/DabbeViiIcon";
import { BrightBotViiIcon } from "../Icons/BrightBotViiIcon";
import { NavConnectViiIcon } from "../Icons/NavConnectViiIcon";
import { NavGovernViiIcon } from "../Icons/NavGovernViiIcon";
import { NavBuildViiIcon } from "../Icons/NavBuildViiIcon";
import { NavShareViiIcon } from "../Icons/NavShareViiIcon";
import { InnerCollapsibleButton } from "./InnerCollapsibleButton";
import { SourcesViiIcon } from "../Icons/SourcesViiIcon";
import { LayoutGridViiIcon } from "../Icons/LayoutGridViiIcon";
import { TablerListSearchViiIcon } from "../Icons/TablerListSearchViiIcon";

export interface WorkspaceSidenavProps {
  workspaceName?: string;
  collapse: boolean;
  setCollapse: Function;
  isEditable?: boolean;
}

export const SideBarV2 = ({
  workspaceName,
  collapse,
  setCollapse,
  isEditable,
}: WorkspaceSidenavProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { pathname } = useLocation();
  const { workspaceId } = useWorkspaceId();
  const { accessible, role, loading } = useGetUserRole(workspaceId);
  const { data, loading: workspaceLoading } = useQuery(
    GetWorkspaceInfoDocument,
    {
      variables: {
        input: {
          workspaceId: workspaceId || "",
        },
      },
    }
  );
  const showConfig = false;

  const { data: userInfo } = useQuery(GetCurrentUserInfoDocument);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo?.currentUser.workspaces.find(
          (workpace) => workpace?.id === workspaceId
        )?.acceptedPolicies
      )
        return;
      else {
        navigate(`/agree-policy/${workspaceId}`);
      }
    }
  }, [userInfo]);

  if (!data) return null;

  return (
    <>
      <Sidenav show={collapse} aria-label={workspaceName}>
        <Wrapper className={collapse ? "" : "sidenav-collapsed"}>
          <SideNavLogo aria-label="global navigation" role="navigation">
            <SideNavLogoLink
              tabIndex={1}
              removeStyling
              to={`/workspace/${workspaceId}/projects`}
              sx={{
                ".large-logo": {
                  display: collapse ? "none" : "inline",

                  "@media (min-width: 768px)": {
                    display: collapse ? "inline" : "none",
                  },
                },
                ".logo-icon": {
                  display: collapse ? "inline" : "none",

                  "@media (min-width: 768px)": {
                    display: collapse ? "none" : "inline",
                  },
                },
              }}
            >
              {data?.workspace?.logoUrl ? (
                <SideNavLogoText>
                  <img
                    src={
                      `${data?.workspace?.logoUrl}?${getRandomNumber()}` || ""
                    }
                    width={24}
                    height={24}
                  />
                  <SideNavLogoTypo
                    variant="subtitle3"
                    sx={{
                      opacity: collapse ? "0" : "1",
                      visibility: collapse ? "hidden" : "visible",

                      "@media (min-width: 768px)": {
                        opacity: collapse ? "1" : "0",
                        visibility: collapse ? "visible" : "hidden",
                      },
                    }}
                  >
                    <Tooltip
                      title={workspaceName}
                      placement="bottom"
                      componentsProps={{
                        tooltip: {
                          sx: { backgroundColor: palette.common.black },
                        },
                        arrow: { sx: { color: palette.common.black } },
                      }}
                      arrow
                    >
                      <span>{workspaceName}</span>
                    </Tooltip>
                  </SideNavLogoTypo>
                </SideNavLogoText>
              ) : (
                <>
                  <BrighthiveLogoSvg className="large-logo" />
                  <BrighthiveIcon className="logo-icon" />
                </>
              )}
            </SideNavLogoLink>
          </SideNavLogo>

          {/*
            <Box alignItems="center" display="flex" sx={{ padding: "13px 16px" }}>
              <SquareIcon
                icon={<GridIcon />}
                backgroundColor={`${palette.gray.lighter}`}
                color={`${palette.common.white}`}
                sx={{
                  width: "28px",
                  height: "28px",
                  minWidth: "28px",
                  mr: 2,
                  borderRadius: "6px",
                }}
              />

              <Typography
                variant="subtitle3"
                sx={{
                  color: palette.common.white,
                  maxWidth: "180px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  opacity: collapse ? "0" : "1",
                  visibility: collapse ? "hidden" : "visible",
                  transition: "opacity 0.3s ease, visibility 0.3s ease",

                  "@media (min-width: 768px)": {
                    opacity: collapse ? "1" : "0",
                    visibility: collapse ? "visible" : "hidden",
                  },
                }}
              >
                <Tooltip
                  title={workspaceName}
                  placement="bottom"
                  componentsProps={{
                    tooltip: { sx: { backgroundColor: palette.common.black } },
                    arrow: { sx: { color: palette.common.black } },
                  }}
                  arrow
                >
                  <span>{workspaceName}</span>
                </Tooltip>
              </Typography>
            </Box>
          */}
          {!loading && (
            <SideNavTopWrap
            // sx={{
            //   a: {
            //     color: palette.common.white,
            //     width: collapse ? "36px" : "100%",
            //     height: collapse ? "36px" : "100%",
            //     transition: "all ease-in-out 0.25s",
            //     borderRadius: "6px",

            //     "@media (min-width: 768px)": {
            //       width: collapse ? "100%" : "36px",
            //       height: collapse ? "100%" : "36px",
            //     },
            //     button: {
            //       color: palette.common.white,
            //       minWidth: "1px",
            //       padding: 0,
            //       width: collapse ? "36px" : "100%",
            //       maxWidth: collapse ? "36px" : "100%",
            //       height: collapse ? "36px" : "100%",
            //       transition: "all ease-in-out 0.25s",

            //       "@media (min-width: 768px)": {
            //         width: collapse ? "100%" : "36px",
            //         maxWidth: collapse ? "100%" : "36px",
            //         height: collapse ? "100%" : "36px",
            //       },
            //       svg: {
            //         marginRight: "5px",
            //       },
            //       ".MuiButton-startIcon": {
            //         position: "absolute",
            //         left: "9px",
            //         top: "8px",
            //         pointerEvents: "none",
            //       },
            //       ".sidebutton-text": {
            //         padding: "8px 7px 8px 36px",
            //         display: "block",
            //       },
            //     },

            //     "button p": {
            //       transition: "opacity 0.3s ease, visibility 0.3s ease",
            //       whiteSpace: "nowrap",
            //       textOverflow: "ellipsis",
            //       overflow: "hidden",
            //       textAlign: "left",
            //     },

            //     "&.bot-link": {
            //       ".MuiButton-startIcon": {
            //         marginTop: "2px",
            //       },
            //     },
            //   },
            //   "a:hover, a:active, a:focus": {
            //     color: palette.custom.darkest,
            //     background: "none",
            //     "button, button:hover": {
            //       color: palette.custom.darkest,
            //       background: "none",
            //     },
            //   },
            //   "a.disabled": {
            //     color: palette.common.white,
            //     opacity: 0.5,
            //     background: "none",
            //     "button:disabled": {
            //       color: palette.common.white,
            //       background: "none",
            //     },
            //   },
            //   "a.active, a.active:hover, a.active:active, a.active:focus": {
            //     backgroundColor: palette.link.lighter,
            //     color: palette.custom.darkest,
            //     button: {
            //       backgroundColor: palette.link.lighter,
            //       color: palette.custom.darkest,
            //     },
            //   },
            // }}
            >
              {/* <PostTitle variant="caption">
                <DabbeViiIcon />
                <span className="cs-hidden-text">{workspaceName}</span>
              </PostTitle> */}
              <SidenavButtonLink
                active={pathname.includes("projects")}
                to={`/workspace/${workspaceId}/projects`}
                className={pathname.includes("project") ? "active" : ""}
                icon={<HomeViiIcon />}
                label="Home"
              />

              <SidenavButtonLink
                active={pathname.includes("brightbot")}
                to={`/workspace/${workspaceId}/brightbot`}
                className={
                  pathname.includes("brightbot")
                    ? "active bot-link"
                    : "bot-link"
                }
                icon={<BrightBotViiIcon />}
                label="BrightBot"
              />
              {accessible && (
                <>
                  {role === WorkspaceRoleEnum.Admin && (
                    <CollapsibleButton
                      label="Connect"
                      icon={<NavConnectViiIcon />}
                    >
                      <InnerCollapsibleButton
                        label="Sources"
                        icon={<SourcesViiIcon />}
                      >
                        <SourcesLink />
                      </InnerCollapsibleButton>
                      <InnerCollapsibleButton
                        label="Service"
                        icon={<LayoutGridViiIcon />}
                      >
                        <ServicesLink />
                      </InnerCollapsibleButton>
                    </CollapsibleButton>
                  )}

                  <CollapsibleButton label="Govern" icon={<NavGovernViiIcon />}>
                    {role !== WorkspaceRoleEnum.Viewer && (
                      <InnerCollapsibleWrapper className="InnerCollapsibleWrapper">
                        <SidenavButtonLink
                          to={`/workspace/${workspaceId}/data`}
                          active={pathname === `/workspace/${workspaceId}/data`}
                          className={
                            pathname === `/workspace/${workspaceId}/data`
                              ? "active"
                              : ""
                          }
                          icon={<DataAssetLibraryIcon />}
                          label="Data Assets"
                        />
                      </InnerCollapsibleWrapper>
                    )}

                    <InnerCollapsibleButton
                      label="The Hive"
                      icon={<TablerListSearchViiIcon />}
                    >
                      <KnowledgeLink />
                    </InnerCollapsibleButton>

                    <InnerCollapsibleWrapper className="InnerCollapsibleWrapper">
                      <SidenavButtonLink
                        to={`/workspace/${workspaceId}/governance`}
                        icon={<GovernanceIcon />}
                        label="Policies"
                        active={pathname.includes("governance")}
                        className={
                          pathname.includes("governance") ? "active" : ""
                        }
                      />
                    </InnerCollapsibleWrapper>
                  </CollapsibleButton>
                </>
              )}
              <CollapsibleButton label="Build" icon={<NavBuildViiIcon />}>
                {/* <InnerCollapsibleWrapper className="InnerCollapsibleWrapper">
                  <SidenavButtonLink
                    active={pathname.includes("projects")}
                    className={pathname.includes("projects") ? "active" : ""}
                    to={`/workspace/${workspaceId}/projects`}
                    icon={<GovernanceIcon />}
                    label="Explorer"
                  />
                </InnerCollapsibleWrapper> */}
                <InnerCollapsibleWrapper className="InnerCollapsibleWrapper">
                  <ProjectsLink />
                </InnerCollapsibleWrapper>
                {/* <InnerCollapsibleWrapper className="InnerCollapsibleWrapper">
                  <SidenavButtonLink
                    active={pathname.includes("governance")}
                    className={pathname.includes("governance") ? "active" : ""}
                    to={`/workspace/${workspaceId}/governance`}
                    icon={<GovernanceIcon />}
                    label="Priorities"
                  />
                  <SidenavButtonLink
                    active={pathname.includes("governance")}
                    className={pathname.includes("governance") ? "active" : ""}
                    to={`/workspace/${workspaceId}/governance`}
                    icon={<GovernanceIcon />}
                    label="Products"
                  />
                </InnerCollapsibleWrapper> */}
              </CollapsibleButton>

              {/* <CollapsibleButton label="Share" icon={<NavShareViiIcon />}>
                <InnerCollapsibleWrapper className="InnerCollapsibleWrapper">
                  <SidenavButtonLink
                    active={pathname.includes("projects")}
                    className={pathname.includes("projects") ? "active" : ""}
                    to={`/workspace/${workspaceId}/projects`}
                    icon={<GovernanceIcon />}
                    label="Explorer"
                  />
                </InnerCollapsibleWrapper>
                <InnerCollapsibleButton label="Projects">
                  <ProjectsLink />
                </InnerCollapsibleButton>
                <InnerCollapsibleWrapper className="InnerCollapsibleWrapper">
                  <SidenavButtonLink
                    active={pathname.includes("governance")}
                    className={pathname.includes("governance") ? "active" : ""}
                    to={`/workspace/${workspaceId}/governance`}
                    icon={<GovernanceIcon />}
                    label="Priorities"
                  />
                  <SidenavButtonLink
                    active={pathname.includes("governance")}
                    className={pathname.includes("governance") ? "active" : ""}
                    to={`/workspace/${workspaceId}/governance`}
                    icon={<GovernanceIcon />}
                    label="Products"
                  />
                </InnerCollapsibleWrapper>
              </CollapsibleButton> */}

              {/* {accessible && (
                <>
                  {role !== WorkspaceRoleEnum.Viewer && (
                    <SidenavButtonLink
                      to={`/workspace/${workspaceId}/data`}
                      active={
                        pathname.includes("data") &&
                        !pathname.includes("data-assets") &&
                        !pathname.includes("created-data-products")
                      }
                      className={
                        pathname.includes("data") &&
                        !pathname.includes("data-assets") &&
                        !pathname.includes("created-data-products")
                          ? "active"
                          : ""
                      }
                      icon={<DataAssetLibraryIcon />}
                      label="Data"
                    />
                  )}
                  <SidenavButtonLink
                    active={pathname.includes("the-hive")}
                    className={pathname.includes("the-hive") ? "active" : ""}
                    to={`/workspace/${workspaceId}/the-hive/resources`}
                    icon={<SearchListIcon />}
                    label="The Hive"
                  />
                  {role === WorkspaceRoleEnum.Admin && (
                    <>
                      <SidenavButtonLink
                        to={`/workspace/${workspaceId}/sources/all`}
                        active={pathname.includes("/sources")}
                        className={
                          pathname.includes("/sources") ? "active" : ""
                        }
                        icon={<SourcesIcon />}
                        label="Sources"
                      />
                      <SidenavButtonLink
                        to={`/workspace/${workspaceId}/services/transformation`}
                        active={pathname.includes("services")}
                        className={
                          pathname.includes("services") ? "active" : ""
                        }
                        icon={<ServicesIcon />}
                        label="Services"
                      />
                    </>
                  )}
                  <SidenavButtonLink
                    active={pathname.includes("governance")}
                    className={pathname.includes("governance") ? "active" : ""}
                    to={`/workspace/${workspaceId}/governance`}
                    icon={<GovernanceIcon />}
                    label="Governance"
                  />
                  {role !== WorkspaceRoleEnum.Viewer && (
                    <SidenavButtonLink
                      active={pathname.includes("members")}
                      to={`/workspace/${workspaceId}/members`}
                      className={pathname.includes("members") ? "active" : ""}
                      icon={<MembersIcon />}
                      label="Members"
                    />
                  )}

                  <SidenavButtonLink
                    active={pathname.includes("session")}
                    className={pathname.includes("session") ? "active" : ""}
                    to={`/workspace/${workspaceId}/session`}
                    icon={<GovernanceIcon />}
                    label="Session"
                  />
                </>
              )} */}
            </SideNavTopWrap>
          )}

          <Box
            display="none"
            mt="auto"
            p={2}
            justifyContent="center"
            sx={{
              a: {
                textDecoration: "none",
              },
              "a:hover, a:active": {
                textDecoration: "underline",
              },
            }}
          >
            {showConfig && (
              <Link to={`/workspace/${workspaceId}/workspace-configuration`}>
                <Button
                  variant="outlined"
                  color="grayAccent"
                  startIcon={<WorkspaceSetupIcon />}
                  sx={{ textTransform: "initial" }}
                >
                  Configure Workspace
                </Button>
              </Link>
            )}
          </Box>

          <SidebarOwnCollapseWrapper>
            <SidebarOwnCollapseButton
              sx={{
                transform: collapse ? "rotate(0)" : "rotate(180deg)",
                left: collapse ? "16px" : "20px",

                "@media (min-width: 768px)": {
                  transform: collapse ? "rotate(180deg)" : "rotate(0)",
                  left: collapse ? "20px" : "16px",
                },
              }}
              onClick={() => setCollapse((prev: boolean) => !prev)}
            >
              <SidebarMenuCollapse />
            </SidebarOwnCollapseButton>
          </SidebarOwnCollapseWrapper>
        </Wrapper>
      </Sidenav>
    </>
  );
};
