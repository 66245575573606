import { Box, useTheme, Link as MuiLink } from "@mui/material";
import { Link } from "../Link/Link";
import React from "react";
import { Typography } from "../Typography/Typography";

const PRIVACY_LINK = "https://brighthive.io/privacy-policy-2/";

interface FooterLinkProps {
  to: string;
  children: React.ReactNode;
}

const FooterLink = ({ to, children }: FooterLinkProps) => {
  const { palette } = useTheme();

  return (
    <Link
      to={to}
      sx={{
        ":focus-visible": {
          outlineOffset: 1,
        },
        "&:visited": {
          color: palette.link.dark,
        },
      }}
    >
      <Typography variant="body3" color={palette.link.light}>
        {children}
      </Typography>
    </Link>
  );
};

const FooterAnchor = ({ to, children }: FooterLinkProps) => {
  const { palette } = useTheme();

  return (
    <MuiLink
      href={to}
      sx={{
        ":focus-visible": {
          outlineOffset: 1,
        },
        "&:visited": {
          color: palette.link.dark,
        },
      }}
    >
      <Typography variant="body3" color={palette.link.light}>
        {children}
      </Typography>
    </MuiLink>
  );
};

export const Footer = () => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{ borderTop: `0.0625rem solid ${palette.outline}` }}
      pt={2}
      pb={2}
      pl={8}
      pr={8}
      display="flex"
      justifyContent="space-between"
      bgcolor={palette.common.white}
    >
      <Typography variant="body3" color={palette.gray.dark} fontFamily="Gotham">
        © Brighthive {new Date().getFullYear()}
      </Typography>
      <Box display="flex">
        <FooterAnchor to={PRIVACY_LINK}>Privacy</FooterAnchor>
      </Box>
    </Box>
  );
};
