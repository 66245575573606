import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <>
      <h1>404 Unauthorized User</h1>
      <Button onClick={() => navigate("workspace/")}>Navigate Home</Button>
    </>
  );
};
