import { styled, Box } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const Wrapper = styled("div")`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`;

export const AvatarWrapper = styled(Box)`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  /* background-color: ${brighthiveTheme.palette.primary.darkest}; */
  color: ${brighthiveTheme.palette.grayAccent.contrastText};
  border: 1px solid ${brighthiveTheme.palette.custom.outline};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 0;
  font-size: 0;
  padding: 3px 2px 0;

  img,
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    vertical-align: top;
  }
`;

export const Description = styled(Box)`
  max-width: 560px;
  border-radius: 0 8px 8px 8px;
  padding: 12px 18px;
  color: ${brighthiveTheme.palette.common.black};
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0.25px;
  border: 1px solid ${brighthiveTheme.palette.custom.contrastText};

  @media (min-width: 992px) {
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 0 12px 12px 12px;
  }

  p {
    a {
      color: ${brighthiveTheme.palette.link.main};
      text-decoration: none;

      &:hover {
        color: ${brighthiveTheme.palette.common.black};
      }
    }
  }
`;
