import { Box, useTheme, Popover, Button, Drawer, Modal } from "@mui/material";
import { TrippleVerticalDotsIcon } from "../../common/Icons/TrippleVerticalDotsIcon";
import { useMenu } from "../../common/Menu/useMenu";
import { EditPencilIcon } from "../../common/Icons/EditPencilIcon";
import { DownloadFileIcon } from "../../common/Icons/DownloadFileIcon";
import { MoveFileIcon } from "../../common/Icons/MoveFileIcon";
import { ShareIcon } from "../../common/Icons/ShareIcon";
import { DeleteIcon } from "../../common/Icons/DeleteIcon";
import { useMutation } from "@apollo/client";
import { DeleteResourceDocument, GetResourcesDocument } from "../../generated";
import { toast } from "react-toastify";
import { useState } from "react";
import { Move } from "../AddResource/Move";
import { useParams } from "react-router-dom";
import { DeleteResourceModal } from "../AddResource/DeleteResourceModal";
import { ButtonDefault } from "./style";
import Resource from "../../contexts/resource";

export const ActionCell = ({
  id,
  name,
  onEdit,
}: {
  id: string;
  name: string;
  onEdit: () => void;
}) => {
  const { workspaceId } = useParams();
  const [open, setOpen] = useState(false);
  const [menuOpen, anchorEl, openMenuFromAnchorElement, closeMenu] = useMenu();

  const { palette } = useTheme();

  const [openMove, setOpenMove] = useState(false);

  return (
    <Box>
      <ButtonDefault className="small" onClick={openMenuFromAnchorElement}>
        <TrippleVerticalDotsIcon />
      </ButtonDefault>
      <Popover
        id="project-action-items"
        aria-labelledby="project-action-button"
        open={menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        sx={{
          ".MuiPaper-root": {
            background: palette.common.white,
            border: "0",
            boxShadow: `0 2px 2px ${palette.gray.main}, 0 3px 1px ${palette.action.disabledBackground}, 0 1px 5px ${palette.action.disabled}`,
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            padding: "0",
            width: "246px",
          },
          ".MuiPaper-elevation > .MuiButtonBase-root": {
            padding: "12px 19px",
            textAlign: "left",
            boxShadow: "none",
            border: "0 !important",
            color: palette.accent.contrastText,
            borderRadius: "0",

            "&:hover": {
              backgroundColor: palette.primary.active,
              color: palette.primary.dark,
            }
          },
        }}
      >
        <>
          <Button
            onClick={() => {
              setOpenMove(true);
              closeMenu();
            }}
            sx={{
              display: "flex",
              columnGap: "13px",
              alignItems: "center",
              borderRadius: "4px",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "8px 12px",
              justifyContent: "space-between",
              color: palette.common.black,
              fontWeight: "400",

              "&:hover": {
                background: palette.settings.lighter,
                color: palette.common.black,
              },

              ".csb-dropdown-icn": {
                width: "13px",
                height: "13px",
              },

              ".icn": {
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "0",
                color: palette.grayAccent.light,
              },

              ".MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
              },
            }}
          >
            <span>Move</span>
            <i className="icn">
              <MoveFileIcon />
            </i>
          </Button>
          <Button
            onClick={() => {
              setOpen(true);
              closeMenu();
            }}
            sx={{
              display: "flex",
              columnGap: "13px",
              alignItems: "center",
              borderRadius: "0",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "8px 12px",
              justifyContent: "space-between",
              color: palette.common.black,
              fontWeight: "400",
              borderTop: `1px solid ${palette.action.disabledBackground}`,

              "&:hover": {
                background: palette.settings.lighter,
                color: palette.common.black,
              },

              ".csb-dropdown-icn": {
                width: "13px",
                height: "13px",
              },

              ".icn": {
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "0",
                color: palette.grayAccent.light,
              },

              ".MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
              },
            }}
          >
            <span>Delete</span>
            <i className="icn">
              <DeleteIcon />
            </i>
          </Button>
          <Button
            id="project-action-button"
            aria-controls={menuOpen ? "project-action-items" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={() => {
              onEdit();
              closeMenu();
            }}
            sx={{
              display: "flex",
              columnGap: "13px",
              alignItems: "center",
              borderRadius: "0",
              fontSize: "16px",
              lineHeight: "24px",
              padding: "8px 12px",
              justifyContent: "space-between",
              color: palette.common.black,
              fontWeight: "400",
              borderTop: `1px solid ${palette.action.disabledBackground}`,

              "&:hover": {
                background: palette.settings.lighter,
                color: palette.common.black,
              },

              ".csb-dropdown-icn": {
                width: "13px",
                height: "13px",
              },

              ".icn": {
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "0",
                color: palette.grayAccent.light,
              },

              ".MuiSvgIcon-root": {
                width: "15px",
                height: "15px",
              },
            }}
          >
            <span>Edit</span>
            <i className="icn">
              <EditPencilIcon />
            </i>
          </Button>
        </>
      </Popover>
      <DeleteResourceModal name={name} open={open} setOpen={setOpen} id={id} />

      <Modal open={openMove} onClose={() => setOpenMove(false)}>
        <Move name={name} id={id} onClose={() => setOpenMove(false)} />
      </Modal>
    </Box>
  );
};
