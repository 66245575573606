import { styled, Box, IconButton, Typography, Button } from '@mui/material';
import { ArrowBack } from "@mui/icons-material";
import { SquareIcon } from "../SquareIcon/SquareIcon";
import { brighthiveTheme } from "../../theme/theme";

export const Wrapper = styled('div')`
  color: ${brighthiveTheme.palette.primary.dark};
  border: 1px solid ${brighthiveTheme.palette.primary.dark};
  background-color: ${brighthiveTheme.palette.primary.active};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;
  transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease;
  font-size: 8px;
  width: 19px;
  height: 19px;
  font-weight: 700;

  @media (min-width: 992px){
    font-size: 10px;
    width: 32px;
    height: 32px;
  }

  &.active,
  &:hover{
    border-color: ${brighthiveTheme.palette.primary.main};
    background-color: ${brighthiveTheme.palette.primary.main};
    color: ${brighthiveTheme.palette.common.white};
  }

  svg{
    width: 18px;
    height: 18px;
  }
`;