import { useEffect, useState } from "react";
import { ActionBar } from "./ActionBar";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import {
  Box,
  Dialog,
  Drawer,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { GridView } from "./GridView";
import { CardView } from "./CardView";
import { Close, Palette } from "@mui/icons-material";
import { SimpleLoader } from "../../common/Loader/Loader";
import { size } from "lodash";
import { CreateFolder } from "../AddResource/CreateFolder";
import { AddResource } from "../AddResource/AddResource";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../../generated";
import { UploadResource } from "../AddResource/UploadResource";
import { AddResourceTab } from "../AddResource";
import { FolderIcon } from "../../common/Icons/FolderIcon";
import { useLocation } from "react-router-dom";

// const data = [
//   {
//     name: "PortFort",
//     status: "Connected",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     url: "https://www.google.com/",
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
//   {
//     name: "BrightHive",
//     status: "Connected",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
//   {
//     name: "RotMOt",
//     status: "Requested",
//     type: "Partner",
//     dataAsset: 40,
//     owner: {
//       name: "BrightHivee",
//       email: "brightgive@hibve.com",
//     },
//     dataServiceType: "Connection Type",
//     description: "A new eve of motion... awpdawdawdk awdnaiwdniawd awd",
//     tags: ["one", "two", "three"],
//   },
// ];

export const AllGrid = ({
  data,
  loading,
  isFolder,
}: {
  data: any;
  loading: boolean;
  isFolder?: any;
}) => {
  const { state }: any = useLocation();
  const [resource, setResource] = useState<string | boolean>("");
  const [cardView, setCardView] = useState(true);
  const [rowData, setRowData] = useState<any>(data);
  const [detail, setDetail] = useState<any>();

  const { data: userInfo } = useQuery(GetCurrentUserInfoDocument);

  const fullName = `${userInfo?.currentUser?.firstName || ""} ${
    userInfo?.currentUser?.lastName
  }`;
  const emailAddress = userInfo?.currentUser?.emailAddress;

  const closeDialog = () => {
    setResource(false);
  };

  useEffect(() => {
    setRowData(data);
  }, [data]);

  useEffect(() => {
    if(state?.add) {
      setResource("File")
    }
  }, [state?.add])

  const { palette } = useTheme();

  return (
    <Box>
      <BasicCard
        sx={{
          background: "transparent",
          boxShadow: "none",
          border: "none",
        }}
      >
        <ActionBar
          resetRowData={() => setRowData(data)}
          setRowData={setRowData}
          data={data}
          setCardView={setCardView}
          setResource={setResource}
          cardView={cardView}
        />
        <Box className="ag-theme-material">
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "8rem 0" }}
            >
              <SimpleLoader />
            </Box>
          ) : size(rowData) >= 1 ? (
            cardView ? (
              <>
                <Box
                  sx={{
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      display: "block",
                      color: palette.gray.darkest,
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "400",
                    }}
                  >
                    Displaying {size(rowData)} resources
                  </Typography>
                </Box>
                <CardView
                  rowData={rowData}
                  username={fullName}
                  emailAddress={emailAddress}
                />
              </>
            ) : (
              <GridView
                rowData={rowData}
                username={fullName}
                emailAddress={emailAddress}
              />
            )
          ) : isFolder ? (
            <Box
              sx={{
                background: palette.common.white,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                minHeight: "200px",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  color: palette.gray.darkest,
                  width: "72px",
                  height: "72px",
                  background: palette.gray.light,
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "40px",
                  lineHeight: "1.1",

                  svg: {
                    width: "40px",
                    height: "40px",
                  },
                }}
              >
                <FolderIcon />
              </Box>
              <Typography
                mt={2}
                textAlign={"center"}
                sx={{
                  display: "block",
                  color: palette.gray.darkest,
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "400",
                }}
              >
                No Record Found
              </Typography>
            </Box>
          ) : (
            <Typography
              mt={8}
              textAlign={"center"}
              sx={{
                display: "block",
                color: palette.gray.darkest,
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "400",
              }}
            >
              No Record Found
            </Typography>
          )}
        </Box>
      </BasicCard>
      {/* <AddDetail data={detail} onClose={() => setDetail(undefined)} /> */}

      {/* <Drawer
        anchor="right"
        open={(resource && true) || false}
        onClose={closeDialog}
      >
        <Box width={400} p={8}>
          <Box position="relative" mb={4}>
            <Box position="absolute" top={-10} right={0}>
              <IconButton onClick={() => closeDialog()} size="small">
                <Close />
              </IconButton>
            </Box>
            <AddSource
              source={source}
              setSource={setSource}
              close={closeDialog}
            />
          </Box>
        </Box>

      </Drawer> */}

      {resource === "Folder" ? (
        <Modal open={resource as any} onClose={() => setResource("")}>
          <CreateFolder onClose={() => setResource("")} />
        </Modal>
      ) : (
        <Modal
          open={resource as any}
          onClose={() => setResource("")}
          sx={{
            overflow: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AddResourceTab setResource={setResource} resource={resource} />
        </Modal>
      )}
    </Box>
  );
};
