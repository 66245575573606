import { styled, Box } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const Wrapper = styled("div")`
  margin-top: 24px;
  background-color: ${brighthiveTheme.palette.primary.darkest};
  color: ${brighthiveTheme.palette.grayAccent.contrastText};
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  border-radius: 12px 0 12px 12px;
  padding: 12px 16px;

  p:only-child {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
