import { useContext, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { AGGridWrapper } from "../../common/AGGridWrapper/AGGridWrapper";
import {
  convertToTitleCase,
  dateHelper,
  updateSvgDimension,
} from "../../helpers";
import { ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { FilterOperator } from "../../common/AGGridWrapper/gridHelpers";
import { ActionsCellRenderer } from "../../AssetDetail/ActionsCellRenderer/ActionsCellRenderer";
import { ActionCell } from "./ActionCell";
import { ActionCellHorizontal } from "./ActionCellHorizontal";
import { convertDateTime } from "../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import Resource from "../../contexts/resource";

export const GridView = ({
  rowData,
  username,
}: {
  emailAddress: string;
  username: string;
  rowData: any;
}) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const {
    editMode: { setEditing },
  } = Resource.useContext();

  const { palette } = useTheme();
  const columns = [
    {
      field: "path",
      headerName: "Path",
      cellRenderer: (params: any) => {
        const item = params.data;
        return (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              ".name, .name-link": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: palette.accent.darkest,
                textDecoration: "none",

                "&:hover": {
                  textDecoration: "underline",
                },
              },
            }}
          >
            <a
              // onClick={() => setResource(item.path)}
              onClick={() => {
                if (item.isFolder || item?.path?.includes("axios")) {
                  navigate(
                    `/workspace/${workspaceId}/the-hive/resources/${item?.id}`
                  );
                } else {
                  window?.open(item.path, "_blank")?.focus();
                }
              }}
              target="_blank"
              rel="noreferrer"
              className="name-link"
            >
              <Box
                sx={{
                  svg: {
                    width: "24px",
                    height: "24px",
                    verticalAlign: "top",
                  },
                }}
              >
                {/* <div
                dangerouslySetInnerHTML={{
                  __html: item?.logoUrl && updateSvgDimension(item?.logoUrl),
                }}
              /> */}
              </Box>
              <Typography
                fontWeight="bold"
                ml={3}
                variant="body3"
                className="name"
              >
                {item.path || item.name}
              </Typography>
            </a>
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      cellRenderer: (params: ICellRendererParams) => {
        return params.data.isFolder ? "Folder" : params.data.type;
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      cellRenderer: (params: any) => {
        return username;
      },
    },
    {
      field: "createdAt",
      headerName: "Upload Date",
      cellRenderer: (params: any) => {
        return dateHelper(params.data.createdAt);
      },
    },
    {
      sortable: false,
      pinned: "right",
      field: "actions",
      headerName: "Actions",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <ActionCell
            name={params?.data?.name ? params?.data?.name : params?.data?.path}
            id={params.data.id}
            onEdit={() => setEditing(params.data.id)}
          />
        );
      },
      suppressMenu: true,
      width: 75,
    },
  ];

  return (
    <Box
      className="ag-theme-material"
      sx={{
        marginTop: "-1px",

        ".ag-theme-material .ag-overlay-no-rows-wrapper.ag-layout-auto-height":
          {
            paddingTop: "0 !important",
          },
        ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value > div.MuiBox-root":
          {
            flex: "1",
            maxWidth: "100%",
          },
        ".ag-row .ag-cell.ag-cell-not-inline-editing.ag-cell-value p": {
          maxWidth: "calc(100% - 36px)",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },

        ".ag-root-wrapper .ag-has-focus .ag-pinned-right-cols-container .ag-cell.ag-cell-focus":
          {
            borderLeftWidth: "1px !important",
            borderStyle: "solid !important",
          },

        ".ag-header-cell": {
          paddingLeft: "16px",
          paddingRight: "16px",
        },

        ".ag-pinned-right-cols-container": {
          ".ag-cell": {
            borderLeft: "0 !important",

            "> div": {
              textAlign: "right",
            },
          },
        },

        ".ag-row": {
          ".ag-cell:first-child": {
            paddingLeft: "2px",
          },
        },

        ".ag-header-cell:not(:last-child)": {
          borderRight: "0 !important",
        },

        ".ag-pinned-right-header": {
          borderLeft: "0 !important",

          ".ag-left-aligned-header": {
            justifyContent: "flex-end",
          },
        },
      }}
    >
      <AGGridWrapper
        // loading={loading}
        rowData={rowData}
        columnDefs={columns as any}
        defaultColDef={{
          sortable: true,
          flex: 1,
          minWidth: 200,
        }}
        getRowHeight={(params) => (params.node.group ? 32 : 62)}
        groupDefaultExpanded={1}
        domLayout="autoHeight"
      />
    </Box>
  );
};
