import React from "react";
import { Box, BoxProps } from "@mui/material";

export interface SpecificLeftRightContentProps {
  leftElements: React.ReactNode;
  rightElements: React.ReactNode;
  leftContainerProps?: BoxProps;
  rightContainerProps?: BoxProps;
}

export type LeftRightContentProps = BoxProps & SpecificLeftRightContentProps;

export const LeftRightContent = ({
  leftElements,
  rightElements,
  leftContainerProps,
  rightContainerProps,
  ...rest
}: LeftRightContentProps) => {
  return (
    <Box
      flexGrow={1}
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      {...rest}
    >
      <Box
        className="left-elements"
        alignItems="center"
        display="flex"
        {...leftContainerProps}
      >
        {leftElements}
      </Box>
      <Box
        className="right-elements"
        alignItems="center"
        display="flex"
        {...rightContainerProps}
        sx={{
          flexShrink: "0",
          
          "> .MuiTypography-root.MuiTypography-button": {
            marginLeft: "12px"
          },

          "> button": {

            "&:nth-child(1), &:nth-child(2)": {
              marginLeft: "12px"
            }
          }
        }}
      >
        {rightElements}
      </Box>
    </Box>
  );
};
