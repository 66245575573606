import { useTheme } from "@mui/material";
import { LinesEllipsisTypography } from "../../common/LinesEllipsisTypography/LinesEllipsisTypography";
import { Wrapper, Heading, Description } from "./style";

export const ChatBotWidget = ({ title, description, onClick }: any) => {
  const { palette } = useTheme();

  return (
    <Wrapper onClick={onClick}>
      {title && <Heading variant="h2">{title}</Heading>}
      {description && (
        <Description variant="body1">
          <LinesEllipsisTypography
            color={palette.gray.dark}
            variant="body3"
            text={description}
            maxLines={2}
            sx={{
              mb: 4,
            }}
          />
        </Description>
      )}
    </Wrapper>
  );
};
