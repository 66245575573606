import { useEffect, useState } from "react";
import { ActionBar } from "./ActionBar";
import { BasicCard } from "../../common/BasicCard/BasicCard";
import {
  Box,
  Dialog,
  Drawer,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { Close, Palette } from "@mui/icons-material";
import { SimpleLoader } from "../../common/Loader/Loader";
import { size } from "lodash";
import { CreateFolder } from "../AddResource/CreateFolder";
import { AddResource } from "../AddResource/AddResource";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateSchemaDocument,
  GetCurrentUserInfoDocument,
  GetSchemasDocument,
  TargetSchema,
} from "../../generated";
import { GridView } from "./GridView";
import { ModalSchemaField } from "../../Projects/ProjectSchemaPage/ModalSchemaField";
import { CardView } from "./CardView";
import { SchemaBuilderPage } from "./SchemaBuilder/SchemaBuilderPage";

export const SchemaTable = ({
  data,
  loading,
}: {
  data: any;
  loading: boolean;
}) => {
  // const [open, setOpen] = useState(false);
  const [resource, setResource] = useState<string | boolean>("");
  const [cardView, setCardView] = useState(true);
  const [rowData, setRowData] = useState<any>(data);
  const [detail, setDetail] = useState<any>();

  const [createSchema] = useMutation(CreateSchemaDocument, {
    refetchQueries: [GetSchemasDocument],
  });

  // const closeDialog = () => {
  //   setOpen(false);
  // };

  // const openDialog = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    setRowData(data);
  }, [data]);

  const { palette } = useTheme();

  return (
    <Box>
      <BasicCard
        sx={{
          background: "transparent",
          boxShadow: "none",
          border: "none",
        }}
      >
        <ActionBar
          resetRowData={() => setRowData(data)}
          setRowData={setRowData}
          data={data}
          setCardView={setCardView}
          setResource={setResource}
          cardView={cardView}
        />
        <Box className="ag-theme-material">
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "8rem 0" }}
            >
              <SimpleLoader />
            </Box>
          ) : size(rowData) >= 1 ? (
            cardView ? (
              <CardView rowData={data} />
            ) : (
              <GridView data={rowData} loading={loading} />
            )
          ) : (
            <Typography
              mt={8}
              textAlign={"center"}
              sx={{
                display: "block",
                color: palette.gray.darkest,
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "400",
              }}
            >
              No Record Found
            </Typography>
          )}
        </Box>
      </BasicCard>

      {/* <ModalSchemaField
        show={open}
        closeDialog={closeDialog}
        submit={console.log}
      /> */}
      {/* <AddDetail data={detail} onClose={() => setDetail(undefined)} /> */}

      {/* <Drawer
        anchor="right"
        open={(resource && true) || false}
        onClose={closeDialog}
      >
        <Box width={400} p={8}>
          <Box position="relative" mb={4}>
            <Box position="absolute" top={-10} right={0}>
              <IconButton onClick={() => closeDialog()} size="small">
                <Close />
              </IconButton>
            </Box>
            <AddSource
              source={source}
              setSource={setSource}
              close={closeDialog}
            />
          </Box>
        </Box>
      </Drawer> */}

      {/* {detail && <TermDetail data={detail} setDetail={setDetail} />} */}
    </Box>
  );
};
