import { useTheme } from "@mui/material";
import { Wrapper, IconWrapper, MainHeading } from "./style";
import { BrightBotSmileIcon } from "../../common/Icons/BrightBotSmileIcon";

export const ChatBotHeader = () => {
  const { palette } = useTheme();

  return (
    <Wrapper>
      <IconWrapper>
        <BrightBotSmileIcon />
      </IconWrapper>
      <MainHeading variant="h1">BrightBot</MainHeading>
    </Wrapper>
  );
};
