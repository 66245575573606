import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { Button, ButtonProps } from "../../common/Button/Button";
import { AddMemberDialogContent } from "../AddMemberDialogContent/AddMemberDialogContent";
import { InviteOutsideMemberDialogContent } from "../InviteOutsideMemberDialogContent/InviteOutsideMemberDialogContent";
import { useMutation } from "@apollo/client";
import {
  CreateUserDocument,
  OrganizationOutput,
  GetWorkspaceMembersDocument,
  WorkspaceRoleEnum,
} from "../../generated";
import { useParams } from "react-router-dom";
import { useUserRole } from "stream-chat-react";
import { useGetUserRole } from "../../hooks/useGetUserRole";

interface SpecificAddMemberButtonProps { }

type AddMemberButtonProps = ButtonProps & SpecificAddMemberButtonProps;

enum CurrentDialogStep {
  AddMember,
  InviteOutsideMember,
}

export const AddMemberButton = (props: AddMemberButtonProps) => {
  const { workspaceId } = useParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { role: userRole } = useGetUserRole(workspaceId)

  const [currentDialogStep, setCurrentDialogStep] = useState(
    CurrentDialogStep.AddMember
  );
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(WorkspaceRoleEnum.Viewer);
  const [organization, setOrganization] = useState<Partial<OrganizationOutput>>(
    { id: "-1" }
  );

  const closeDialog = () => {
    setDialogOpen(false);
    setCurrentDialogStep(CurrentDialogStep.AddMember);
    setEmail("");
    setOrganization({ id: "-1" });
  };
  const [mutate, { loading }] = useMutation(CreateUserDocument, {
    refetchQueries: [GetWorkspaceMembersDocument],
  });

  const openDialog = () => {
    setDialogOpen(true);
  };

  const onSubmit = async () => {
    await mutate({
      variables: {
        input: {
          workspaceId: workspaceId || "-1",
          emailAddress: email,
          organizationId: organization.id || "-1",
          workspaceRole: role,
        },
      },
    });
    closeDialog();
  };

  return (
    <>
      {userRole === WorkspaceRoleEnum.Admin &&
        <Button
          {...props}
          onClick={openDialog}
          color="primary"
          variant="contained"
        >
          Add Member
        </Button>
      }
      <Dialog open={dialogOpen} onClose={closeDialog}>
        {currentDialogStep === CurrentDialogStep.AddMember && (
          <AddMemberDialogContent
            email={email}
            setEmail={setEmail}
            role={role}
            setRole={setRole}
            organization={organization}
            setOrganization={setOrganization}
            closeDialog={closeDialog}
            loading={loading}
            onClickInvite={() => {
              if (
                !email
                  .toLowerCase()
                  .includes(organization?.domain?.toLowerCase() || "")
              ) {
                setCurrentDialogStep(CurrentDialogStep.InviteOutsideMember);
              } else {
                onSubmit();
              }
            }}
          />
        )}
        {currentDialogStep === CurrentDialogStep.InviteOutsideMember && (
          <InviteOutsideMemberDialogContent
            email={email}
            organization={organization}
            closeDialog={closeDialog}
            loading={loading}
            onClickInviteAnyway={() => {
              onSubmit();
            }}
          />
        )}
      </Dialog>
    </>
  );
};
