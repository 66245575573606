import { Drawer, useTheme } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const anchor = "right";
const RightSidePanel = ({
  isOpen,
  onToggle,
  children,
}: {
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}) => {
  const { palette } = useTheme();
  return (
    <React.Fragment key={anchor}>
      <Drawer
        anchor={anchor}
        open={isOpen}
        onClose={onToggle}
        sx={{
          zIndex: "99999",
          "~ .MuiModal-root": {
            zIndex: "99999",
          },
          ".MuiDrawer-paper": {
            width: "500px",
            padding: "24px",
            boxSizing: "border-box",
          },
        }}
      >
        {children}
        <CloseIcon
          onClick={onToggle}
          sx={{
            position: "absolute",
            right: "20px",
            top: "20px",
            cursor: "pointer",
            width: "20px",
            height: "20px",
            color: palette.gray.dark,
          }}
        />
      </Drawer>
    </React.Fragment>
  )
};

export default RightSidePanel;
