import React from "react";
import { Box, useTheme } from "@mui/material";
import { TypographyProps } from "../Typography/Typography";
import { LeftRightContent } from "../LeftRightContent/LeftRightContent";
import { LinesEllipsisTypography } from "../LinesEllipsisTypography/LinesEllipsisTypography";

export interface ProjectCardDataRowProps {
  icon?: React.ReactNode;
  name: string;
  value?: string | number;
  valueContainerProps?: TypographyProps;
}

export const ProjectCardDataRow = ({
  name,
  value,
  icon,
  valueContainerProps,
}: ProjectCardDataRowProps) => {
  const { palette } = useTheme();

  return (
    <LeftRightContent
      flexGrow="initial"
      mb={2}
      alignItems="initial"
      leftContainerProps={{ width: "50%", alignItems: "initial" }}
      rightContainerProps={{
        width: "50%",
        alignItems: "initial",
        justifyContent: "flex-end",
      }}
      leftElements={
        <Box
          display="flex"
          sx={{
            ".MuiSvgIcon-root": {
              fontSize: ".875rem",
              mt: ".1875rem",
              mr: 1,
              color: palette.grayAccent.light,
            },
          }}
        >
          {icon}
          <LinesEllipsisTypography
            color={palette.common.black}
            variant="body3"
            fontWeight={500}
            text={`${name}:`}
            maxLines={2}
          />
        </Box>
      }
      rightElements={
        <Box display="flex">
          {(typeof value === "number" || typeof value === "string") && (
            <LinesEllipsisTypography
              color={palette.common.black}
              variant="body3"
              text={value.toString()}
              maxLines={2}
              {...valueContainerProps}
            />
          )}
        </Box>
      }
    />
  );
};
