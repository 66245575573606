import { styled, Box, Tab } from '@mui/material';
import { Tabs } from "../common/Tabs/Tabs";
import { brighthiveTheme } from "../theme/theme";

export const Wrapper = styled('div')`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  *,
  *:after,
  *:before{
    box-sizing: inherit;
  }
`;

export const Tabset = styled(Tabs)`
  position: relative;
  z-index: 1;
  margin-bottom: 0 !important;

  &:after{
    content: '';
    border-bottom: 1px solid ${brighthiveTheme.palette.error.lighter};
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .MuiTabs-flexContainer{
    justify-content: center;
  }
`;

export const TabsWrapper = styled(Tabs)`
  margin-bottom: 32px;

  .MuiTabs-flexContainer{
    justify-content: center;
  }

  .MuiTabs-indicator{
    background-color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabButton = styled(Tab)`
  font-size: 16px;
  line-height: 1.42;
  padding: 12px 24px !important;
  min-width: 1px;
  color: ${brighthiveTheme.palette.gray.dark};
  background-color: transparent;
  border-radius: 4px 4px 0 0;

  &.Mui-selected,
  &:hover{
    background-color: transparent !important;
    color: ${brighthiveTheme.palette.primary.dark};
  }
`;

export const TabItem = styled(Tab)`

`;

export const TopArea = styled(Box)`
  
`;

export const BottomArea = styled(Box)`
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;

  @media (min-width: 1200px){
    padding: 32px;
  }
`;