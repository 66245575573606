import { useState } from "react";

import { Tab } from "@mui/material";

import { SchemaDetailsOverview } from "./SchemaDetailsOverview";
import { Tabs } from "../../../common/Tabs/Tabs";
import {
  GetSchemaDocument,
  GetSchemasDocument,
  TargetSchema,
  UpdateWorkspaceSchemaDocument,
} from "../../../generated";
import { ProjectSchemaDetailPage } from "../../../Projects/ProjectSchemaDetailPage/ProjectSchemaDetailPage";
import { SchemaBuilderPage } from "../SchemaBuilder/SchemaBuilderPage";
import { JSONSchema } from "../JSONSchema/JSONSchema";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { TabButton, TabsWrapper } from "../style";

export const SchemaDetail = ({
  data,
  loading,
  isEditMode,
}: {
  data: any | undefined;
  loading: boolean;
  isEditMode: boolean;
}) => {
  const { workspaceId, schemaId } = useParams();
  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const [mutateUpdate] = useMutation(UpdateWorkspaceSchemaDocument, {
    refetchQueries: [GetSchemasDocument],
  });

  const updateSchemaJson = (parse: any) =>
    mutateUpdate({
      variables: {
        input: {
          schemaId: schemaId || "",
          workspaceId: workspaceId || "",
          name: data?.name,
          description: data?.description,
          jsonSchema: JSON.stringify(parse),
        },
      },
    });

  return (
    <>
      <TabsWrapper
        value={tab}
        onChange={handleChange}
        aria-label="asset detail tab"
      >
        <TabButton label="Overview" {...a11yProps(0)} />
        <TabButton label="Schema" {...a11yProps(0)} />
        <TabButton label="JSON Schema" {...a11yProps(0)} />
      </TabsWrapper>

      {tab === 0 && (
        <SchemaDetailsOverview
          isEditMode={isEditMode}
          data={data}
          loading={loading}
        />
      )}
      {tab === 1 && (
        <ProjectSchemaDetailPage data={data} update={updateSchemaJson} />
      )}
      {tab === 2 && <JSONSchema data={data} />}
    </>
  );
};

export {};
