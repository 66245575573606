export const responseCompare = {
  field_comparison: [
    {
      schema1_field: "SocialSecurityNumber",
      schema2_field: "SocialSecurityNumber",
      comparison_score: 5,
    },
    {
      schema1_field: "MiddleInitial",
      schema2_field: "MiddleName",
      comparison_score: 3,
    },
    {
      schema1_field: "FirstName",
      schema2_field: "FirstName",
      comparison_score: 5,
    },
    {
      schema1_field: "LastName",
      schema2_field: "LastName",
      comparison_score: 5,
    },
    {
      schema2_field: "TotalWages",
      schema1_field: "MilitaryStatus",
      comparison_score: 4,
    },
    {
      schema2_field: "TotalWagesPaidInSeason",
      schema1_field: "Citizenship",
      comparison_score: 1,
    },
    {
      schema2_field: "TotalWagesPaidOutOfSeason",
      schema1_field: "VISAType",
      comparison_score: 2,
    },
  ],
  overlap_percentage: "100%",
  overall_comparison_score: 4,
};
