import React, { useState } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";
import { Navbar } from "../Navbar/Navbar";
import { useQuery } from "@apollo/client";
import { GetCurrentUserInfoDocument } from "../../generated";
import { SideNavBar } from "./SideNavBar";

export interface BasicPageProps {
  content?: React.ReactNode;
  leftHeaderElements?: React.ReactNode;
  rightHeaderElements?: React.ReactNode;
}

export const BasicPageNav = ({
  content,
  leftHeaderElements,
  rightHeaderElements,
  ...rest
}: BasicPageProps & BoxProps) => {
  const { data } = useQuery(GetCurrentUserInfoDocument);
  const [collapse, setCollapse] = useState(true);

  const fullName = `${data?.currentUser?.firstName || ""} ${
    data?.currentUser?.lastName
  }`;

  const emailAddress = data?.currentUser?.emailAddress;
  const { palette } = useTheme();

  if (!data) return null;

  return (
    <SideNavBar content={content} collapse={collapse} setCollapse={setCollapse}>
      <Box
        sx={{
          position: "fixed",
          left: "61px",
          transition: "all ease-in-out 0.25s",
          right: 0,
          top: 0,
          background: palette.common.white,
          height: "48px",
          justifyContent: "center",
          zIndex: "0",
          boxShadow: `inset 0px -1px 0px ${palette.action.disabledBackground}`,

          "@media (min-width: 768px)": {
            left: collapse ? "260px" : "61px",
            zIndex: "99",
          },
        }}
        display="flex"
        flexDirection="column"
        {...rest}
      >
        <Navbar
          username={fullName}
          emailAddress={emailAddress}
          multipleWorkspaces
        />
      </Box>
    </SideNavBar>
  );
};
