import React from "react";
import { LeftRightContent } from "../../common/LeftRightContent/LeftRightContent";
import { SearchBar } from "../../common/SearchBar/SearchBar";
import { alpha, Box, useTheme } from "@mui/material";
import { Typography } from "../../common/Typography/Typography";

export interface ActionBarMembersProps {
  onChangeSearch?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  searchText?: string;
  noResultsFoundFromFilter: boolean;
}

export const ActionBarMembers = ({
  onChangeSearch,
  searchText,
  noResultsFoundFromFilter,
}: ActionBarMembersProps) => {
  const { palette } = useTheme();

  const border = `.0625rem  ${palette.outline} solid`;

  return (
    <Box
      sx={{
        flexGrow: 0,
        borderLeft: border,
        borderRight: border,
        borderTop: border,
        borderRadius: ".25rem .25rem 0 0",
      }}
    >
      <LeftRightContent
        sx={{ pb: 2, pt: 2, pr: 4, pl: 4 }}
        // leftElements={<Link to="/#">View workspace role definitions</Link>}
        leftElements={<></>}
        rightElements={
          <SearchBar
            sx={{ width: "18rem", mr: 2 }}
            placeholder="Search name, email, or organization"
            onChange={onChangeSearch}
            value={searchText}
          />
        }
      />
      {searchText && searchText.length > 0 && (
        <Box
          borderTop={border}
          pt={1}
          pb={1}
          pl={4}
          pr={4}
          bgcolor={alpha(palette.primary.main, palette.action.hoverOpacity)}
        >
          <Typography variant="body3">
            {noResultsFoundFromFilter
              ? `0 results for "${searchText}"`
              : `Results for ${searchText}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
