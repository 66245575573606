import React, { useState, useRef, Fragment } from "react";
import {
  Box,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Paper,
  FormHelperText,
} from "@mui/material";
import { Typography } from "../../common/Typography/Typography";
import { Button } from "../../common/Button/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DestinationDownload } from "../../common/Icons/DestinationDownload";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ApplySchemaTemplateModal } from "./ApplySchemaTemplateModal";
import { SimpleLoader } from "../../common/Loader/Loader";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import {
  CreateInputDataAssetGroupDocument,
  InputDataAssetGroupCreationMethod,
} from "../../generated";
import { useParams } from "react-router-dom";
import { CustomTextArea } from "../../common/CustomTextArea/CustomTextArea";
import { CustomTextField } from "../../common/CustomTextField/CustomTextField";
import { SnackBar } from "../../common/SnakBar/SnakBar";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ViewSchema } from "../../common/ViewSchema/ViewSchema";
import { ViewSelectedSchema } from "../../common/ViewSelectedSchema/ViewSelectedSchema";
import { ButtonDefault, ButtonPrimary } from "./style";

const inputSchemaData: string | any[] = [
  {
    name: "primary-key-schema-name.json",
    time: "Updated 10/20/2021 at 11:34 AM",
    href: "www.google.com",
  },
];
const reader = new FileReader();

export const CreateInputSchemaSetDetails = ({
  toggleRightSidePanel,
  showToast,
}: {
  toggleRightSidePanel: any;
  showToast: any;
}) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const { palette } = useTheme();
  const [toggleSchemaTemplate, setToggleSchemaTemplate] = useState(false);
  const [show, setShow] = useState(false);
  const [success, setSubmitSuccess] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [fileName, setFilename] = useState(null);

  const { projectId, workspaceId } = useParams();
  const [mutate] = useMutation(CreateInputDataAssetGroupDocument);

  const onChangeHandler = (event: any) => {
    console.log(event);
    if (event?.target?.files?.[0]) {
      setFilename(event.target.files[0].name);
      reader.readAsText(event.target.files[0]);
    }
  };

  return (
    <Formik
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        creationMethod: Yup.string().required(),
        jsonSchema: Yup.string(),
      })}
      initialValues={{
        name: "",
        description: "",
        creationMethod: "NO_SCHEMA",
        jsonSchema: "",
      }}
      onSubmit={async (values) => {
        if (
          values.creationMethod !==
            InputDataAssetGroupCreationMethod.NoSchema &&
          !values.jsonSchema
        )
          return null;

        await mutate({
          variables: {
            input: {
              description: values.description,
              name: values.name,
              projectId: projectId || "",
              workspaceId: workspaceId || "",
              method:
                values.creationMethod as InputDataAssetGroupCreationMethod,
              ...(values.creationMethod !==
                InputDataAssetGroupCreationMethod.NoSchema &&
                ((values.creationMethod ===
                  InputDataAssetGroupCreationMethod.JsonUpload && {
                  jsonSchema: values.jsonSchema,
                }) || { targetSchemaId: selectedTemplate?.id || "" })),
            },
          },
          refetchQueries: ["getWorkflow"],
        });

        setSubmitSuccess(true);
        toggleRightSidePanel();
        showToast("Input schema set added");
      }}
    >
      {({
        handleChange,
        errors,
        touched,
        values,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => {
        reader.onload = (e) => {
          if (reader.result) {
            try {
              const schema = JSON.parse(reader.result as string);

              setFieldValue("jsonSchema", JSON.stringify(schema));
            } catch (e) {
              console.log("invalid json file");
              setFieldTouched("jsonSchema", true, false);
              setFilename(null);
            }
          }
        };

        const setSchema = (selectedTemplete: any) => {
          console.log("setting schema");

          let schemaJson = JSON.parse(selectedTemplete?.jsonSchema);
          setFieldValue("jsonSchema", JSON.stringify(schemaJson));
          setSelectedTemplate(selectedTemplete);
        };
        return (
          <Box
            boxSizing="border-box"
            sx={{
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <SnackBar open={success} message={"Input Dataset Created"} />
            <Box>
              <Box
                sx={{
                  paddingRight: "20px",
                  paddingBottom: "16px",
                  borderBottom: `1px solid ${palette.outline}`,
                }}
                mb={6}
              >
                <Typography
                  mb={2}
                  variant="h5"
                  color={palette.gray.dark}
                  sx={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                  }}
                >
                  Input Data Asset Group
                </Typography>
                <Typography
                  mb={4}
                  variant="h5"
                  color={palette.common.black}
                  sx={{ wordBreak: "break-word" }}
                >
                  New Input Data Asset Group
                </Typography>
              </Box>
              <Box boxSizing="border-box">
                <Box
                  sx={{
                    paddingRight: "20px",
                    paddingBottom: "16px",
                    borderBottom: `1px solid ${palette.outline}`,
                  }}
                  mb={4}
                >
                  <Box mb={6}>
                    <Typography
                      mb={2}
                      variant="h6"
                      color={palette.common.black}
                      sx={{ fontWeight: "400" }}
                    >
                      Overview
                    </Typography>
                    <Box mb={6} display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        mb={2}
                        fontSize="0.875rem"
                        fontWeight={500}
                        color={palette.common.black}
                      >
                        Input data asset group name
                      </Typography>

                      <CustomTextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={errors.name}
                        touched={touched.name}
                      />
                    </Box>
                    <Box mb={6} display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        mb={2}
                        fontSize="0.875rem"
                        fontWeight={500}
                        color={palette.common.black}
                      >
                        Description
                      </Typography>
                      {/* <TextareaAutosize
                  name="description"
                  value={values.description}
                  style={{
                    width: "100%",
                    fontSize: "16px",
                    lineHeight: "20px",
                    padding: "8px",
                    height: "116px",
                    boxSizing: "border-box",
                  }}
                  onChange={handleChange}
                /> */}
                      <CustomTextArea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description}
                        touched={touched.description}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    paddingRight: "20px",
                    paddingBottom: "16px",
                  }}
                  mb={4}
                >
                  <Typography
                    mb={2}
                    variant="h6"
                    color={palette.common.black}
                    sx={{ fontWeight: "400" }}
                  >
                    Creation method
                  </Typography>
                  <Typography
                    mb={2}
                    variant="h5"
                    color={palette.gray.darkest}
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "normal",
                    }}
                  >
                    Create a target schema that all input data assets in this
                    set will be validated against.
                  </Typography>
                  <Box mb={3} display="flex" flexDirection="column">
                    <Typography
                      variant="body1"
                      mb={2}
                      fontSize="0.875rem"
                      fontWeight={500}
                      color={palette.common.black}
                    >
                      Method
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="creationMethod"
                        name="creationMethod"
                        value={values.creationMethod}
                        placeholder="Select target schema creation method"
                        label="Owner"
                        onChange={handleChange}
                        sx={{
                          fieldset: {
                            top: "0",
                          },
                          legend: {
                            display: "none",
                          },
                          ".MuiInputBase-input": {
                            width: "100%",
                            height: "36px",
                            fontSize: "16px",
                            lineHeight: "20px",
                            padding: "8px",
                            boxSizing: "border-box",
                          },
                        }}
                      >
                        <MenuItem value="NO_SCHEMA">No Schema</MenuItem>
                        <MenuItem value="SELECT_SCHEMA">Select Schema</MenuItem>
                        <MenuItem value="JSON_UPLOAD" selected>
                          JSON Upload
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {values.creationMethod ===
                    InputDataAssetGroupCreationMethod.SelectSchema &&
                    selectedTemplate !== null && (
                      <ViewSelectedSchema
                        name={selectedTemplate?.name}
                        onDelete={() => {
                          setSelectedTemplate(null);
                        }}
                      />
                    )}
                  {values.creationMethod ===
                  InputDataAssetGroupCreationMethod.JsonUpload ? (
                    <>
                      <Box
                        mb={4}
                        sx={{
                          background: palette.settings.lighter,
                          borderRadius: "4px",
                          padding: "20px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          mb={1}
                          fontSize="0.875rem"
                          fontWeight={400}
                          color={palette.common.black}
                        >
                          Schema source file
                        </Typography>

                        {fileName && <span>{fileName}</span>}
                        <input
                          type="file"
                          id="file"
                          ref={inputFile}
                          style={{ display: "none" }}
                          onChange={onChangeHandler}
                          accept=".json"
                        />
                        <ButtonPrimary
                          color="grayAccent"
                          variant="contained"
                          onClick={() => {
                            inputFile.current?.click();
                          }}
                        >
                          Upload JSON
                        </ButtonPrimary>
                      </Box>
                      {touched.jsonSchema && fileName == null && (
                        <Box
                          display="flex"
                          mt={1}
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <ErrorOutlineIcon
                            htmlColor={palette.error.dark}
                            style={{
                              width: "13.33px",
                              height: "13.33px",
                              marginTop: "3px",
                              marginRight: "5px",
                            }}
                          />

                          <FormHelperText
                            style={{
                              color: palette.error.dark,
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: 400,
                            }}
                          >
                            Please provide valid JSON file
                          </FormHelperText>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      {values.creationMethod ===
                        InputDataAssetGroupCreationMethod.SelectSchema &&
                        selectedTemplate === null && (
                          <Box
                            mb={4}
                            sx={{
                              background: palette.settings.lighter,
                              borderRadius: "4px",
                              padding: "20px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              mb={1}
                              fontSize="0.875rem"
                              fontWeight={400}
                              color={palette.common.black}
                            >
                              Schema
                            </Typography>

                            <ButtonPrimary
                              color="grayAccent"
                              variant="contained"
                              onClick={() => {
                                setToggleSchemaTemplate(true);
                              }}
                            >
                              Select schema
                            </ButtonPrimary>
                          </Box>
                        )}
                      {values.creationMethod ===
                        InputDataAssetGroupCreationMethod.SelectSchema &&
                        selectedTemplate !== null && (
                          <>
                            <ViewSchema
                              name={selectedTemplate?.name}
                              modifiedAt={selectedTemplate?.modifiedAt}
                              jsonSchema={selectedTemplate?.jsonSchema}
                            />

                            <Button
                              color="grayAccent"
                              variant="contained"
                              onClick={(e) => {
                                setToggleSchemaTemplate(true);
                              }}
                              //href={`/workspace/${workspaceId}/project/${projectId}/project-schema/${inputSchemaAsset?.targetSchema?.id}`}
                            >
                              Edit Schema
                            </Button>
                          </>
                        )}
                      {touched.jsonSchema &&
                        values.creationMethod !==
                          InputDataAssetGroupCreationMethod.NoSchema &&
                        selectedTemplate === null && (
                          <Box
                            display="flex"
                            mt={1}
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <ErrorOutlineIcon
                              htmlColor={palette.error.dark}
                              style={{
                                width: "13.33px",
                                height: "13.33px",
                                marginTop: "3px",
                                marginRight: "5px",
                              }}
                            />

                            <FormHelperText
                              style={{
                                color: palette.error.dark,
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: 400,
                              }}
                            >
                              Please choose schema template
                            </FormHelperText>
                          </Box>
                        )}
                    </>
                  )}
                </Box>

                {show && (
                  <Box
                    sx={{
                      paddingBottom: "16px",
                      borderBottom: `1px solid ${palette.outline}`,
                    }}
                    mb={6}
                  >
                    <Box>
                      <Typography
                        mb={4}
                        variant="h5"
                        color={palette.common.black}
                        sx={{
                          fontSize: "18px",
                          lineHeight: "24px",
                          fontWeight: "normal",
                        }}
                      >
                        Input target schema
                      </Typography>

                      <Box
                        mb={4}
                        sx={{
                          background: palette.settings.lighter,
                          borderRadius: "4px",
                          padding: "20px",
                          "> .MuiBox-root": {
                            width: "100%",
                          },
                        }}
                      >
                        <SimpleLoader
                          text="Generating input target schema..."
                          className="custom-loader"
                          customTextClass="custom-text"
                        />
                      </Box>

                      <Box>
                        <Grid container>
                          {inputSchemaData && inputSchemaData.length > 0 ? (
                            <>
                              {inputSchemaData.map((product) => (
                                <Grid
                                  item
                                  xs={12}
                                  mb={4}
                                  sx={{ minWidth: "360px" }}
                                >
                                  <Paper
                                    variant="outlined"
                                    sx={{
                                      p: 4,
                                      display: "flex",
                                      minHeight: "100%",
                                      boxSizing: "border-box",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box display="flex">
                                      <Box
                                        mr={3}
                                        width="2rem"
                                        sx={{
                                          ".MuiSvgIcon-root": {
                                            minWidth: "2rem",
                                            width: "2rem",
                                            height: "2rem",
                                            path: {
                                              fill: palette.grayAccent.light,
                                            },
                                          },
                                        }}
                                      >
                                        <DestinationDownload />
                                      </Box>

                                      <Box sx={{ paddingLeft: 3 }}>
                                        <Typography
                                          variant="subtitle2"
                                          color={palette.common.black}
                                          sx={{
                                            fontSize: "0.875rem",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {product?.name}
                                        </Typography>
                                        <Typography
                                          variant="subtitle2"
                                          color={palette.gray.darkest}
                                          sx={{
                                            fontSize: "12px",
                                            fontWeight: 400,
                                          }}
                                        >
                                          {product?.time}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() =>
                                        window.open(
                                          product?.href || "#",
                                          "_blank"
                                        )
                                      }
                                      endIcon={
                                        <FileDownloadOutlinedIcon fontSize="small" />
                                      }
                                      sx={{
                                        ".MuiSvgIcon-root": {
                                          fontSize: "1.5rem !important",
                                        },
                                      }}
                                    ></Button>
                                  </Paper>
                                </Grid>
                              ))}
                              <Button color="grayAccent" variant="contained">
                                Edit Schema
                              </Button>
                            </>
                          ) : (
                            <Typography
                              mb={2}
                              variant="h5"
                              color={palette.error.dark}
                              sx={{
                                fontSize: "14px",
                                lineHeight: "20px",
                                fontWeight: "400",
                              }}
                            >
                              <InfoOutlinedIcon
                                sx={{
                                  fontSize: "16px",
                                  lineHeight: "20px",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                  marginRight: "3px",
                                  marginBottom: "3px",
                                  color: palette.error.dark,
                                }}
                              />
                              No schema defined
                            </Typography>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {toggleSchemaTemplate && (
              <ApplySchemaTemplateModal
                selectedTemplate={selectedTemplate}
                setToggleSchemaTemplate={setToggleSchemaTemplate}
                setSchema={setSchema}
              />
            )}
            <Box>
              <ButtonPrimary
                color="primary"
                variant="contained"
                sx={{ width: "100%", marginBottom: "10px" }}
                onClick={(e) => handleSubmit()}
              >
                Save
              </ButtonPrimary>
              <ButtonDefault
                color="grayAccent"
                variant="outlined"
                sx={{ width: "100%" }}
                onClick={toggleRightSidePanel}
              >
                Cancel
              </ButtonDefault>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};
