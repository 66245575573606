import React from "react";
import { GridApi } from "ag-grid-enterprise";
import { Box, IconButton, SelectChangeEvent, useTheme } from "@mui/material";
import { MenuItem } from "../Menu/MenuItem";
import { Typography } from "../Typography/Typography";
import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from "@mui/icons-material";
import { Select } from "../Form/Select";

export interface GridFooterProps {
  gridApi: GridApi | undefined;
  paginationPageSize: number;
  paginationTotalPages: number;
  paginationCurrentPage: number;
  totalRows: number;
  displayNoResults: boolean;
}

export const GridFooter = ({
  gridApi,
  paginationPageSize,
  paginationCurrentPage,
  paginationTotalPages,
  totalRows,
  displayNoResults,
}: GridFooterProps) => {
  const { palette } = useTheme();

  const onBtFirst = () => {
    if (gridApi?.paginationGetCurrentPage() !== 0) {
      gridApi?.paginationGoToFirstPage();
    }
  };

  const onBtLast = () => {
    if (
      typeof gridApi?.paginationGetTotalPages() === "number" &&
      gridApi?.paginationGetCurrentPage() !==
        gridApi?.paginationGetTotalPages() - 1
    ) {
      gridApi?.paginationGoToLastPage();
    }
  };

  const onBtNext = () => {
    if (
      typeof gridApi?.paginationGetTotalPages() === "number" &&
      gridApi?.paginationGetCurrentPage() !==
        gridApi?.paginationGetTotalPages() - 1
    ) {
      gridApi?.paginationGoToNextPage();
    }
  };

  const onBtPrevious = () => {
    if (gridApi?.paginationGetCurrentPage() !== 0) {
      gridApi?.paginationGoToPreviousPage();
    }
  };

  const onPageSizeChanged = (event: SelectChangeEvent<unknown>) => {
    gridApi?.paginationSetPageSize(Number(event.target.value));
  };

  const rowsDisplayedLowerBound: number =
    1 + paginationPageSize * paginationCurrentPage;
  const rowsDisplayedUpperBound: number =
    paginationPageSize * (paginationCurrentPage + 1);

  const onFirstPage = paginationCurrentPage === 0;
  const onLastPage = paginationCurrentPage === paginationTotalPages - 1;

  const border = `.0625rem ${palette.outline} solid`;

  return displayNoResults ? (
    <Box
      display="flex"
      height="2.5rem"
      pl={4}
      pr={4}
      borderRight={border}
      borderLeft={border}
      borderBottom={border}
      borderRadius="0 0 .25rem .25rem"
      alignItems="center"
    >
      <Typography variant="body2">0 total results</Typography>
    </Box>
  ) : (
    <Box
      justifyContent="space-between"
      display="flex"
      pt=".5625rem"
      pb=".5625rem"
      pl={4}
      pr={4}
      borderRight={border}
      borderLeft={border}
      borderBottom={border}
      borderRadius="0 0 .25rem .25rem"
    >
      <Box display="flex" alignItems="center">
        <Typography mr={3} variant="body2">
          Rows per page
        </Typography>
        <Select
          sx={{
            width: "6.25rem",
            mr: 8,
          }}
          value={paginationPageSize.toString()}
          onChange={onPageSizeChanged}
        >
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <Typography variant="body2">
          {rowsDisplayedLowerBound} -{" "}
          {rowsDisplayedUpperBound < totalRows
            ? rowsDisplayedUpperBound
            : totalRows}{" "}
          of {totalRows}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography mr={11} variant="body2">
          Pages: {paginationCurrentPage + 1} - {paginationTotalPages}
        </Typography>
        <IconButton disabled={onFirstPage} onClick={onBtFirst}>
          <FirstPage />
        </IconButton>
        <IconButton disabled={onFirstPage} onClick={onBtPrevious}>
          <ChevronLeft />
        </IconButton>
        <IconButton disabled={onLastPage} onClick={onBtNext}>
          <ChevronRight />
        </IconButton>
        <IconButton disabled={onLastPage} onClick={onBtLast}>
          <LastPage />
        </IconButton>
      </Box>
    </Box>
  );
};
