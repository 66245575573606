import { styled, Box, Typography } from "@mui/material";
import { brighthiveTheme } from "../../theme/theme";

export const Wrapper = styled("div")`
  text-align: center;
  padding: 24px;

  @media (min-width: 1200px) {
    padding: 32px;
  }
`;

export const IconWrapper = styled("i")`
  margin: 0 auto 10px;
  /* border-radius: 4px; */
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  /* background: linear-gradient(133.39deg, #9EEFCE 2.78%, #008575 100.69%); */
  color: ${brighthiveTheme.palette.grayAccent.contrastText};

  @media (min-width: 1200px) {
    width: 60px;
    height: 60px;
  }

  .MuiSvgIcon-root {
    width: 100%;
    height: 100%;
  }
`;

export const MainHeading = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.15px;

  @media (min-width: 1200px) {
    font-size: 24px;
  }
`;
