import { SvgIcon, SvgIconProps } from "@mui/material";

export const TransformationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 8.16667V5.66667C16.5 5.22464 16.3244 4.80072 16.0118 4.48816C15.6993 4.17559 15.2754 4 14.8333 4H9.83333M9.83333 4L12.3333 6.5M9.83333 4L12.3333 1.5M1.5 9.83333V12.3333C1.5 12.7754 1.67559 13.1993 1.98816 13.5118C2.30072 13.8244 2.72464 14 3.16667 14H8.16667M8.16667 14L5.66667 11.5M8.16667 14L5.66667 16.5M1.5 4C1.5 4.66304 1.76339 5.29893 2.23223 5.76777C2.70107 6.23661 3.33696 6.5 4 6.5C4.66304 6.5 5.29893 6.23661 5.76777 5.76777C6.23661 5.29893 6.5 4.66304 6.5 4C6.5 3.33696 6.23661 2.70107 5.76777 2.23223C5.29893 1.76339 4.66304 1.5 4 1.5C3.33696 1.5 2.70107 1.76339 2.23223 2.23223C1.76339 2.70107 1.5 3.33696 1.5 4ZM11.5 14C11.5 14.663 11.7634 15.2989 12.2322 15.7678C12.7011 16.2366 13.337 16.5 14 16.5C14.663 16.5 15.2989 16.2366 15.7678 15.7678C16.2366 15.2989 16.5 14.663 16.5 14C16.5 13.337 16.2366 12.7011 15.7678 12.2322C15.2989 11.7634 14.663 11.5 14 11.5C13.337 11.5 12.7011 11.7634 12.2322 12.2322C11.7634 12.7011 11.5 13.337 11.5 14Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
