import { useLocation, useParams } from "react-router-dom";
import { SidenavButtonLink } from "../Sidenav/SidenavButtonLink";
import { CollectionIcon } from "../Icons/CollectionIcon";
import { ConnectionIcon } from "../Icons/ConnectionIcon";
import { DataQualityIcon } from "../Icons/DataQualityIcon";
import { WarehouseIcon } from "../Icons/WarehouseIcon";
import { TransformationIcon } from "../Icons/TransformationIcon";
import { UseIcon } from "../Icons/UseIcon";
import { LockIcon } from "../Icons/LockIcon";
import { useWorkspaceId } from "../../hooks/useWorkspace";

export const ServicesLink = ({}) => {
  const { workspaceId } = useWorkspaceId();
  const { pathname } = useLocation();
  return (
    <>
      {/* <SidenavButtonLink
        to={`/workspace/${workspaceId}/services/collection`}
        icon={<CollectionIcon />}
        label="Collection"
        className={pathname.includes("collection") ? "active" : ""}
        active={pathname.includes("collection")}
        disabled
      /> */}
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/services/connections`}
        icon={<ConnectionIcon />}
        label="Connections"
        active={pathname.includes("connections")}
        className={pathname.includes("connections") ? "active" : ""}
      />
      {/* <SidenavButtonLink
        to={`/workspace/${workspaceId}/services/data-quality`}
        icon={<DataQualityIcon />}
        label="DataQuality"
        active={pathname.includes("data-quality")}
        className={pathname.includes("data-quality") ? "active" : ""}
        disabled
      /> */}
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/services/warehouse`}
        icon={<WarehouseIcon />}
        label="Warehouse"
        active={pathname.includes("warehouse")}
        className={pathname.includes("warehouse") ? "active" : ""}
      />
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/services/transformation`}
        icon={<TransformationIcon />}
        label="Transformation"
        active={pathname.includes("transformation")}
        className={pathname.includes("transformation") ? "active" : ""}
      />
      {/* <SidenavButtonLink
        to={`/workspace/${workspaceId}/services/use`}
        icon={<UseIcon />}
        label="Use"
        active={pathname.includes("use") && !pathname.includes("warehouse")}
        className={
          pathname.includes("use") && !pathname.includes("warehouse")
            ? "active"
            : ""
        }
        disabled
      />
      <SidenavButtonLink
        to={`/workspace/${workspaceId}/services/cleanroom`}
        icon={<LockIcon />}
        label="Cleanroom"
        active={pathname.includes("cleanroom")}
        className={pathname.includes("cleanroom") ? "active" : ""}
        disabled
      /> */}
    </>
  );
};
